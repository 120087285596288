import React, { useCallback, useState } from 'react'
import { HelpCircle as Question } from 'react-feather'
import styled from 'styled-components'
import Tooltip, { TooltipNew } from '../Tooltip'

const QuestionWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.2rem;
  border: none;
  background: none;
  outline: none;
  cursor: default;
  border-radius: 36px;
  background-color: ${({ theme }) => theme.bg2};
  color: ${({ theme }) => theme.text2};

  :hover,
  :focus {
    opacity: 0.7;
  }
`

const LightQuestionWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.2rem;
  border: none;
  background: none;
  outline: none;
  cursor: default;
  border-radius: 36px;
  width: 24px;
  height: 24px;
  background-color: rgba(255, 255, 255, 0.1);
  color: ${({ theme }) => theme.white};

  :hover,
  :focus {
    opacity: 0.7;
  }
`

const QuestionMark = styled.span`
  font-size: 1rem;
`

interface TooltipFunProps {
  Type?:any
  text: string
  props?: any
  apr?: string
}

const TooltipFun = ({ Type, text, props, apr }: TooltipFunProps) => {
  const [show, setShow] = useState<boolean>(false)
  const open = useCallback(() => setShow(true), [setShow])
  const close = useCallback(() => setShow(false), [setShow])
  return <>
    <span style={{ marginLeft: 4 }}>
      <Type text={text} show={show} apr={apr}>
        <QuestionWrapper onClick={open} onMouseEnter={open} onMouseLeave={close} style={{ ...props }}>
          <Question size={16} />
        </QuestionWrapper>
      </Type>
    </span>
  </>
}


export default function QuestionHelper({ text, props }: { text: string, props?: any }) {
  /**
  const [show, setShow] = useState<boolean>(false)
  const open = useCallback(() => setShow(true), [setShow])
  const close = useCallback(() => setShow(false), [setShow])
  return (
    <span style={{ marginLeft: 4 }}>
      <Tooltip text={text} show={show}>
        <QuestionWrapper onClick={open} onMouseEnter={open} onMouseLeave={close} style={{ ...props }}>
          <Question size={16} />
        </QuestionWrapper>
      </Tooltip>
    </span>
  )
   * 
  */
  return <TooltipFun Type={Tooltip} text={text} props={props}></TooltipFun>
}

export function QuestionHelpers({ text, apr, props }: TooltipFunProps) {
  return (
    <TooltipFun Type={TooltipNew} text={text} props={props} apr={apr}></TooltipFun>
  )
}

export function LightQuestionHelper({ text }: { text: string }) {
  const [show, setShow] = useState<boolean>(false)

  const open = useCallback(() => setShow(true), [setShow])
  const close = useCallback(() => setShow(false), [setShow])

  return (
    <span style={{ marginLeft: 4 }}>
      <Tooltip text={text} show={show}>
        <LightQuestionWrapper onClick={open} onMouseEnter={open} onMouseLeave={close}>
          <QuestionMark>?</QuestionMark>
        </LightQuestionWrapper>
      </Tooltip>
    </span>
  )
}
