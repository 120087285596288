import { useEffect, useState } from "react"
import { client } from "./initApollo"
import { PAIRS_BULK_INFO, PAIRS_CURRENT } from "./query"
import { useSwapBlockNumber, useSwapBlockNumbers } from "state/block/hooks"
interface BasicData {
  token0?: {
    id: string
    name: string
    symbol: string
  }
  token1?: {
    id: string
    name: string
    symbol: string
  }
}
// Override data return from graph - usually because proxy token has changed
// names since entitiy was created in subgraph
// keys are lowercase token addresses <--------
const TOKEN_OVERRIDES: { [address: string]: { name: string; symbol: string } } = {
  '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2': {
    name: 'Ether (Wrapped)',
    symbol: 'ETH',
  },
  '0x1416946162b1c2c871a73b07e932d2fb6c932069': {
    name: 'Energi',
    symbol: 'NRGE',
  },
}

// override tokens with incorrect symbol or names
export function updateNameData(data: BasicData): BasicData | undefined {
  if (data?.token0?.id && Object.keys(TOKEN_OVERRIDES).includes(data.token0.id)) {
    data.token0.name = TOKEN_OVERRIDES[data.token0.id].name
    data.token0.symbol = TOKEN_OVERRIDES[data.token0.id].symbol
  }

  if (data?.token1?.id && Object.keys(TOKEN_OVERRIDES).includes(data.token1.id)) {
    data.token1.name = TOKEN_OVERRIDES[data.token1.id].name
    data.token1.symbol = TOKEN_OVERRIDES[data.token1.id].symbol
  }

  return data
}

export function usePairList(isGet:boolean) {
  const [pariList, setPairList] = useState<any>(null)
  const { onChangePariListStory } = useSwapBlockNumbers()
  const { pariListStory } = useSwapBlockNumber()
  useEffect(() => {
    const ignore = ['0x99f3de1d2807ff22af7193014a9674b9403c914c', '0x3ea5701010815c1cc45e584cf63034d78bf47932','0x46cf281d2df940bbc03e710953429166e4b59781']
    async function getData() {
      // get top pairs by reserves
      try {
        let {
          data: { pairs },
        } = await client.query({
          query: PAIRS_CURRENT,
          fetchPolicy: 'cache-first',
        })

        // format as array of addresses
        const formattedPairs = pairs.map((pair: any) => pair.id)
        // get data for every pair in list
        let { data: { pairs: topPairs } } = await client.query({
          query: PAIRS_BULK_INFO,
          variables: {
            allPairs: formattedPairs,
          },
          fetchPolicy: 'cache-first',
        })

        topPairs = (topPairs || []).filter((i: any) => !ignore.includes(i.id)).map((i: any) => {
          i.PairToken = [i.token0.symbol, i.token1.symbol]
          return i
        })
        onChangePariListStory(topPairs)
        setPairList(topPairs)
      } catch (error) {

        return null
      }
      return null
    }
    
    if (!!pariListStory) {
      setPairList(pariListStory)
    } else {
      isGet&&getData()
    }

  }, [isGet])
  return pariList
}