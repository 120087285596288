import React, {useState, useCallback, useMemo, useEffect, useContext} from 'react'
import {message, Spin} from 'antd'
import {AutoColumn, ColumnCenter} from '../../components/Column'
import styled, {ThemeContext} from 'styled-components'
import {TYPE} from '../../theme'
import {RowBetween, AutoRow, RowCenter} from '../../components/Row'
import {
  GetInvitationData,
  AddInvitationUser,
  // HandlePledge,
  // HandleRedeem,
  // GetStatusByAddress,
  // FetchInvitationList,
  // getListLimit,
  // useGetShareByPer,
  truncateAddressString,
  getLockTime,
  getBonusByBlock,
  getExpirationCycle,
  toNonExponential
} from '../../utils/invitationCallback'
import {
  useUserUpper,
  useStakeInfo,
  useProfitInfo,
  GetStake,
  useMinStakeAmount,
  useUnstakeInfo,
  GetUnStake,
  SetAddUpper,
  GetReceiveProfit,
  useUserInviteInfo,
  GetUserChilds,
  useCheckUpper,
  // useGas,
} from '../../utils/invitationCallback_v2'
import {
  TextBox,
  TextButton,
  TextButton1,
  CardSection,
  DataCard,
  InputBox,
  Text,
  CopyBox,
  // TextHide,
  SectionBox,
  Line,
  SectionContainerBox,
  ColumnSection
} from '../../components/invitation/styled'
import {useActiveWeb3React} from '../../hooks'
import {modalType} from '../../constants/invitation'
import Modal from '../../components/Modal'
import InvitationPlayRule from './components/playRuleModal'
import PledgeRecordList from './components/pledgeRecordList'
import OldPledgeRedeem from './components/oldPledgeRedeem'
import {useWalletModalToggle} from '../../state/application/hooks'
import {isTransactionRecent, useAllTransactions} from '../../state/transactions/hooks'
import {TransactionDetails} from '../../state/transactions/reducer'
// import { useBlockNumber } from '../../state/application/hooks'
import {useETHBalances1} from '../../state/wallet/hooks'
import {useTranslation} from 'react-i18next'
import {CloseOutlined, DownOutlined} from '@ant-design/icons'
import TableCom from './components/tableCom'
import {lte, toBigNumber, numToFixed} from '../../utils/bigNumber';
import InviteImg from '../../assets/images/invitePng.png'
import TipImg from '../../assets/images/tip.png'
import SearchAddress from './components/searchAddress'

import InviteCopy from '../../assets/svg/inviteCopy.svg'
// import InvitePlus from '../../assets/svg/invitePlus.svg'
import {useLocation} from 'react-router-dom'
import {useGetIsOpenGiftCard} from "../../utils/giftCard";

const PageWrapper = styled(AutoColumn)`
  max-width: 640px;
  width: 100%;
`

const TopSection = styled(AutoColumn)`
  max-width: 720px;
  width: 100%;
`

const ColumnCenterEle = styled(ColumnCenter)`
  width: initial;
  min-width: 140px;
  margin-bottom: 10px;
`

const DataRow = styled(AutoRow)`
  justify-items: flex-start;
  margin: 20px 0;
`
const Sections = styled.div`
  text-align: center;
  width: 100%;

`
const OldLpRedemption = styled.div`
  text-align: center;
  background-color: rgba(249, 213, 179, 1);
  color: #E53434;
  padding: 6px;
  margin: -15px -20px 15px;
  border-radius: 15px 15px 0 0;
`
const PlayDescription = styled.div`
  position: fixed;
  top: 10.1875rem;
  right: 1rem;
  display: flex;
  align-items: center;
  gap: 0.625rem;
  background: #2750EB;
  padding: 8px 0 8px 12px;
  height: 36px;
  border-radius: 24px 0 0 24px;
  cursor: pointer;
  z-index: 10;

  img {
    width: 3rem;
    height: 3rem;
    margin-right: -1rem;
  }

  @media screen and (min-width: 400px) {
    right: calc(50vw - 192px);
  }
  @media screen and (min-width: 768px) {
    background: transparent ;
    > div {
      display: none;
    }

    &:hover {
      background: #2750EB;

      > div {
        display: block;
      }
    }
  }

`
const PlayDescriptionText = styled.div`
  font-size: 0.875rem;
  line-height: 0.875rem;
  color: #ffffff;
`
const BalanceRow = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  text-align: left;
  padding: 0.5rem 1rem;
`
const CanRedemptionRow = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  text-align: left;
  padding: 0.5rem 1rem;
`
const RedemShowList = styled.div`
  margin: 1rem;
  padding-top: 0.5rem;
  border-top: 0.1rem solid rgba(14, 37, 74, 0.06);
`
const RedemShowListItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 0;
`
const CheckMoreRedemList = styled.div`
  padding: 0.5rem;
  text-align: center;
  color: rgb(39, 80, 235);
  cursor: pointer;
`

/**
 * 处理链接参数
 * @param url string
 * @returns object
 */
export function dealUrlParam(url: string) {
  if (url.indexOf('?') === -1) {
    return {}
  }
  var arr = decodeURIComponent(url.split('?')[1]).split('&');
  var request: any = {};
  var temp = [];
  for (var i = 0; i < arr.length; i++) {
    temp = arr[i].split('=');
    request[temp[0]] = temp[1];
  }
  return request;
}

export default function Invitation() {
  const theme = useContext(ThemeContext)
  const {account} = useActiveWeb3React()
  // const blockNumber = useBlockNumber()
  // console.log('blockNumber',blockNumber)
  // const accPerShare = useGetShareByPer()
  const location = useLocation();
  const linkParam = dealUrlParam(location.search);
  const [isOpen, setModel] = useState<boolean>(false)
  const [isPlayRuleOpen, setPlayRuleOpen] = useState<boolean>(false)
  const [isPledgeRecordOpen, setPledgeRecordOpen] = useState<boolean>(false)
  const [isOldPledgeOpen, setOldPledgeOpen] = useState<boolean>(false)

  const [inputValue, setValue] = useState<string>('')
  const [modelTitle, setModleTitle] = useState<string>('')
  const [modelType, setModelType] = useState<string>('')
  const [placeholder, setPlaceholder] = useState<string>('')
  const [useInvitation] = AddInvitationUser()
  // const [usePledge] = HandlePledge()
  // const [useRedeem] = HandleRedeem()
  const toggleWalletModal = useWalletModalToggle()
  const {
    invatationUser,
    totalMortgageMount,//v1 当前质押数量
    accumulatedRebateMount,
    accumulatedMount,
    // min,
    // max,
    isRedeemed,
    totalAmount
  } = GetInvitationData();
  // const ethBalance = useETHBalances(account ? [account] : [])?.[account ?? '']?.toSignificant(4)
  const ethBalance = useETHBalances1();
  // console.log('ethBalance',ethBalance)
  // 每天收益 = 每块的奖励 / total * 自己的质押数量 * 28800
  // 每天收益 * 合约上获取到期周期
  const bonus = toNonExponential(
    `${(getBonusByBlock() / totalAmount) * totalMortgageMount * 28800 * getExpirationCycle()}`
  )
  // const APR = toNonExponential(
  //   `${(getBonusByBlock() / totalAmount) * 28800 * 365 * 100}`
  // )
  // const APY = !isNaN(Number(APR)) ? (Math.pow((1 + Number(APR)*0.01 / 365), 365)- 1) * 100 : 0
  const {t} = useTranslation()
  useEffect(() => {
    sessionStorage.removeItem('startIndex')
    sessionStorage.removeItem('endIndex')
  }, [])

  // we want the latest one to come first, so return negative if a is after b
  function newTransactionsFirst(a: TransactionDetails, b: TransactionDetails) {
    return b.addedTime - a.addedTime
  }

  const [isPledge, unlockRemainingTime] = getLockTime();
  const userInviteLink = useMemo(() => {
    let link = `${window.location.origin}/#/invitation`
    if (!account) {
      return link
    }
    return `${link}?address=${account}`
  }, [account])
  // 
  const {checkUpper} = useCheckUpper()
  // 获取邀请人
  const {userUpper, getUserUpper} = useUserUpper()
  // 邀请
  const {addUpper} = SetAddUpper();
  // 查询用户邀请信息 [当前质押总量,质押结束时间text,质押结束时间戳] 
  const {totalInvite, validInvite, totalReward} = useUserInviteInfo()
  // 获取用户质押信息
  const stakeInfo = useStakeInfo();
  // [质押池总量,每个区块奖励,预估到期总收益,当前质押收益] 
  const profitInfo = useProfitInfo();
  // 最小质押数量
  const minStakeAmount = useMinStakeAmount()
  // 质押
  const {goStake} = GetStake();
  // 获取用户赎回信息:[赎回释放比例,总可赎回数量,已赎回数量,可赎回数量]
  const unstakeInfo = useUnstakeInfo()
  // 赎回
  const {unStake} = GetUnStake();
  // 赎回收益
  const {receiveProfit} = GetReceiveProfit();
  // 分页查询用户下级
  const {userChildsLoaing, getUserChilds} = GetUserChilds();
  const [listQuery, setListQuery] = useState({pageNo: 1, pageSize: 10});
  const [childList, setChildList] = useState<any>([]);
  // 搜索下级 modal
  const [searchVisible, setSearchVisible] = useState<boolean>(false);
  // // gas 请求
  // const {getGas} = useGas();
  // 携带邀请链接，参数处理
  const [linkAddress, setLinkAddress] = useState('');
  useEffect(() => {
    dealLinkAddrss();
    return () => {
      setLinkAddress('');
    }

    async function dealLinkAddrss() {
      try {
        if (!account || !linkParam.address) throw new Error('')
        if (account?.toLowerCase() === linkParam.address?.toLowerCase()) throw new Error('')
        let address = await getUserUpper();//是否存在父级
        if (address) throw new Error('');
        let checkResult = await checkUpper(linkParam.address);//地址检测
        if (checkResult.toString() !== '0') throw new Error('')
        setLinkAddress(linkParam.address)
      } catch {
        setLinkAddress('')
      }
    }
  }, [account])
  useEffect(() => {
    if (!!linkAddress) {//// 携带邀请链接，未填写邀请，则调起邀请弹框
      handleAddInvitation()
    } else {
      close();
    }
  }, [linkAddress])

  // console.log(`邀请人：%s,\n
  // 用户质押信息：%s,\n
  // 获取用户收益信息:%s,\n
  // 质押状态：%s,\n
  // 用户赎回信息：%s,\n
  // `,
  // userUpper,
  // stakeInfo,
  // profitInfo,
  // unstakeInfo
  // )
  // console.log('modelType',modelType)
  const getUserChildList = async () => {
    let list: any = await getUserChilds(listQuery);
    setChildList([...childList, ...(list || [])])
  }
  useEffect(() => {
    // if(!account)return 
    getUserChildList()
  }, [listQuery])
  useEffect(() => {
    if (!account) return
    setListQuery({pageNo: 1, pageSize: 10})
    setChildList([])
  }, [account])


  const Status = () => {
    const allTransactions = useAllTransactions()
    const sortedRecentTransactions = useMemo(() => {
      const txs = Object.values(allTransactions);
      return txs.filter(isTransactionRecent).sort(newTransactionsFirst);
    }, [allTransactions])

    const pending = sortedRecentTransactions.filter(tx => !tx.receipt).map(tx => tx.hash);
    return !!pending.length
  }
  const transactionStatus = Status()

  const toggleClaimModal = () => {
    //
  }
  const handleAll = () => {
    if ((modelType === modalType.REDEEM && toBigNumber(unstakeInfo[3]).isEqualTo(0))) {
      return
    }
    modelType === modalType.MORTGAGE ? setValue(ethBalance ?? '') : setValue(unstakeInfo[3] ?? '')
  }
  const getButtons = () => {
    return (
      <TextButton
        className={(modelType === modalType.REDEEM && toBigNumber(unstakeInfo[3]).isEqualTo(0)) ? 'disabled' : ''}
        style={{
          color: '#FFFFFF',
          background: '#2750EB',
          width: '6.875rem',
          textAlign: 'center',
          marginLeft: '10px',
          padding: '10px',
          boxShadow: '0px 2px 0px 1px #0090FF'
        }}
        onClick={handleAll}>
        {t('ALL')}
      </TextButton>
    )
  }
  const getAllButton = () => {
    switch (modelType) {
      case modalType.MORTGAGE:
        return !toBigNumber(inputValue || '0').isEqualTo(ethBalance || '0') ? getButtons() : ''
      case modalType.REDEEM:
        return !toBigNumber(inputValue || '0').isEqualTo(unstakeInfo[3] || '0') ? getButtons() : ''
      default:
        return ''
    }
  }
  const close = () => {
    setValue('')
    setModel(false)
  }
  const ConfirmModel = async () => {
    //赎回判断
    if ((modelType === modalType.REDEEM && toBigNumber(unstakeInfo[3]).isEqualTo(0))) {
      return
    }
    let eventFun = null
    // let eventName:any = null
    let checkUpperEvent = checkUpper
    let reg: any = true
    let error = ''
    switch (modelType) {
      case modalType.INVITATION://邀请
        eventFun = addUpper;
        // eventName = 'addUpper';
        let isNull = !inputValue;
        let isAddress = inputValue && /^(0x)?[0-9a-fA-F]{40}$/i.test(inputValue + '')
        let isSelf = inputValue?.toLowerCase() === account?.toLowerCase()
        reg = !isNull && isAddress && !isSelf;
        error = isNull ? t(`Fill in the inviter's address`) :
          !isAddress ? t('The address of the inviter is incorrect') :
            isSelf ? t('Invitees cannot be themselves') : ''
        break
      case modalType.MORTGAGE://质押
        eventFun = goStake;
        // eventName = 'stake';
        const isNumberReg = /^(([1-9][0-9]*\.[0-9][0-9]*)|([0]\.[0-9][0-9]*)|([1-9][0-9]*)|([0]{1}))$/
        if (isNumberReg.test(inputValue)) {
          let isZoreReg = toBigNumber(inputValue).isEqualTo(0);
          let minReg = !!(inputValue && lte(minStakeAmount, inputValue));
          let balanceReg = !!(inputValue && lte(inputValue, ethBalance || '0'));
          reg = minReg && balanceReg;
          error = !minReg || isZoreReg ? t('Starting from 100000', {amount: minStakeAmount}) : !balanceReg ? t('Insufficient Balance') : ''
        } else {
          reg = false;
          error = t('Please enter the correct amount');
        }
        break
      case modalType.REDEEM://赎回
        eventFun = unStake;
        // eventName = 'unstake';
        const isNumber = /^(([1-9][0-9]*\.[0-9][0-9]*)|([0]\.[0-9][0-9]*)|([1-9][0-9]*)|([0]{1}))$/
        reg = isNumber.test(inputValue) && toBigNumber(inputValue).gt(0) && !!lte(inputValue, unstakeInfo[3])
        error = t('Please enter the correct Redemption amount');
        break
      default:
        eventFun = useInvitation
        reg = /^(0x)?[0-9a-fA-F]{40}$/i.test(inputValue + '')
        error =
          modelType === modalType.INVITATION ? t('One cannot invite oneself') : t('Please enter the correct Addresses')
    }
    if (!reg || inputValue?.toLowerCase() === account?.toLowerCase()) {
      message.error(error)
      return
    }
    if (modelType === modalType.INVITATION) {//邀请前检测
      let result = await checkUpperEvent(inputValue);
      if (result.toString() !== '0') {
        let errorTip: any = {
          1: t('This address is not bindable'),
          2: t(`Can't be your own invitee`),
          3: t('Bound Invitee'),
          4: t('Unable to bind, the address is your inviting member')
        }
        message.error(errorTip[result])
        return
      }
    }
    try {
      eventFun(inputValue)
      close()
    } catch (error) {
      console.log('error', error)
    }
  }
  const handleMortgage = useCallback(() => {

    if (transactionStatus || !Number(ethBalance)) {
      return
    }
    if (account) {
      setModelType(modalType.MORTGAGE)
      setModleTitle(t('Mining'))
      setPlaceholder(t('Starting from 100000', {amount: minStakeAmount}))
      setModel(true)
    } else {
      toggleWalletModal()
    }
  }, [transactionStatus, ethBalance, account, minStakeAmount, t, toggleWalletModal])
  const handleRedeem = useCallback(() => {
    if (transactionStatus || !Number(ethBalance)) return
    if (account) {
      // if (isRedeemed && totalMortgageMount) {
      setModelType(modalType.REDEEM)
      setModleTitle(t('Redemption'))
      setPlaceholder(t('Please enter your redemption amount'))
      setModel(true)
      // }
    } else {
      toggleWalletModal()
    }
  }, [transactionStatus, account, t, ethBalance, toggleWalletModal])

  const handleRedeemProfit = useCallback(() => {
    if (account) {
      if (Number(profitInfo[3]) && !transactionStatus) {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        receiveProfit()
      }
    } else {
      toggleWalletModal()
    }
  }, [account, profitInfo[3], toggleWalletModal, transactionStatus, receiveProfit])
  const handleChange = (event: any) => {
    let value = event.target.value;
    if (value !== '') {
      if (modelType !== modalType.INVITATION) {//非邀请,验证数量填写
        let reg = /^(0|[1-9]\d*)((\.)?|(\.\d{1,18}))?$/;
        if (reg.test(value)) {
          setValue(event.target.value)
        }
      } else {
        setValue(event.target.value)
      }
    } else {
      setValue(event.target.value)
    }
  }
  const handleCopy = () => {
    const aux = document.createElement('input')
    aux.setAttribute('value', userInviteLink || '')
    document.body.appendChild(aux)
    aux.select()
    document.execCommand('copy')
    document.body.removeChild(aux)
    message.success(t('Copy Successfully'))
  }

  const handleAddInvitation = useCallback(async () => {
    if (transactionStatus) {
      return
    }
    if (account) {
      setModelType(modalType.INVITATION)
      setModleTitle(t('Add Invitation'))
      setPlaceholder(t('Please enter your Referral Addresses'))
      setValue(linkAddress)
      setModel(true)
    } else {
      toggleWalletModal()
    }
  }, [account, t, toggleWalletModal, linkAddress, transactionStatus])
  const Tables = React.memo(TableCom)


  // const [pageSize] = useState<number>(0)
  // const [list, setList] = useState<any[]>([])
  // const [startPre] = useState(0)
  // const [endNext, setEndNext] = useState(10)
  // const [pageChange, setPageChange] = useState<boolean>(false)
  // const [isSend, setIsend] = useState<boolean>(false)
  // const startIndex = sessionStorage.getItem('startIndex') || 0
  // const endIndex = sessionStorage.getItem('endIndex') || 10

  // const invitationListOne = FetchInvitationList(startPre, endNext)
  // const { totalCount } = getListLimit()

  // const handlePre = () => {

  //   const start = Number(startPre) - 10
  //   const end = Number(endNext) - 10
  //   if (start < 0) {
  //     return
  //   }
  //   if(isSend){
  //     return
  //   }
  //   setIsend(true)
  //   setStartPre(start)
  //   setEndNext(end)
  //   sessionStorage.setItem('startIndex', `${start}`)
  //   sessionStorage.setItem('endIndex', `${end}`)
  //   setPageChange(!pageChange)
  //   setTimeout(() => {
  //     setPageChange(!pageChange)
  //     const res = getListLimit()
  //     // const { invitationList } = res
  //     setList([...res?.invitationList])
  //     setPageSize(pageSize - 1)
  //     setIsend(false)
  //   }, 500)
  // }
  const handleNext = () => {
    setListQuery({...listQuery, pageNo: listQuery.pageNo + 1})
    //   if (totalCount <= endNext) {
    //     return
    //   }
    //   if(isSend){
    //     return
    //   }
    //   setIsend(true)
    //   const start = Number(startPre) + 10
    //   const end = Number(endNext) + 10
    //   setStartPre(start)
    //   setEndNext(end)
    //   sessionStorage.setItem('startIndex', `${start}`)
    //   sessionStorage.setItem('endIndex', `${end}`)
    //   setPageChange(!pageChange)
    //   setTimeout(() => {
    //     setPageChange(!pageChange)
    //     const res = getListLimit()
    //     // const { invitationList } = res
    //     setList([...res?.invitationList])
    //     setPageSize(pageSize + 1)
    //     setIsend(false)
    //   }, 500)
  }


  // useEffect(() => {

  //   setList([...invitationListOne])
  // }, [invitationListOne])
  // useEffect(() => {
  //   setStartPre(0)
  //   setPageSize(0)
  //   // setEndNext(10)
  //   // setList([])
  // }, [account])

  // const Table = () => {
  //   return (
  //     <Tables
  //       invitationList={list.length ? list : invitationList || []}
  //       handlePre={handlePre}
  //       handleNext={handleNext}
  //       start={startIndex}
  //       end={endIndex}
  //       endDisabled={totalCount <= endIndex}
  //       size={pageSize}
  //       total={totalCount || 0}
  //     ></Tables>
  //   )
  // }
  // console.log('transactionStatus || !Number(ethBalance)',transactionStatus ,(ethBalance))

  const bankCards = useMemo(()=>{
    const minStakeAmountNum = Number(minStakeAmount) || 0
    if(+inputValue > 0 && minStakeAmountNum > 0) {
       return Math.floor(+inputValue / minStakeAmountNum)
    }
    return 0;
  }, [minStakeAmount, inputValue])

  // 是否开启赠卡活动
  const {isOpenGiftCard} = useGetIsOpenGiftCard();


  return (
    <PageWrapper gap="lg" justify="center" style={{display: 'block', maxWidth: '400px', position: 'relative'}}>
      <SectionContainerBox>
        {
          toBigNumber(totalMortgageMount || 0).gt(0) &&
          <OldLpRedemption onClick={() => setOldPledgeOpen(true)}>
            {t('There are assets in the old pledge pool, click here to redeem')}&gt;
          </OldLpRedemption>
        }
        <ColumnSection>
          <PlayDescription onClick={() => setPlayRuleOpen(true)}>
            <PlayDescriptionText dangerouslySetInnerHTML={{__html: t('How to play')}}></PlayDescriptionText>
            <img src={TipImg} alt="tip"/>
          </PlayDescription>
          <RowCenter>
            <img src={InviteImg} alt="AIA" width={84}></img>
          </RowCenter>
          <RowCenter>
            <TYPE.three fontWeight={500} color={theme.text11} fontSize={12} style={{marginTop: '10px'}}>
              {t('Currently mining')}
            </TYPE.three>
          </RowCenter>
          <RowCenter>
            <TYPE.three fontWeight={700} fontSize={20} style={{marginTop: '0px', cursor: 'pointer'}}
                        onClick={() => setPledgeRecordOpen(true)}>
              {numToFixed(stakeInfo[0])}&nbsp; AIA &gt;
            </TYPE.three>
          </RowCenter>
          {stakeInfo[1] !== '0' && <RowCenter style={{marginTop: '10px'}}>
            <TYPE.three color={theme.glay} fontWeight={400} style={{fontSize: '12px'}}>
              {`${t('Staking end in', {time: stakeInfo[1]})}`}
            </TYPE.three>
          </RowCenter>}
          <RowBetween style={{marginTop: '25px'}}>
            <TextButton1
              className={transactionStatus || !Number(ethBalance) ? 'disabled' : ''}
              onClick={handleMortgage}
              style={{
                background: '#2750EB',
                color: '#ffffff',
                // marginRight: '10px',
                minWidth: '45%',
                textAlign: 'center',
                backgroundColor: '#2750EB'
              }}
            >
              {t('Mining')}
              {/* {Number(ethBalance) ? t('Mining') : t('Insufficient Balance')} */}
            </TextButton1>
            <TextButton
              className={transactionStatus || !Number(ethBalance) ? 'disabled' : ''}
              onClick={handleRedeem}
              style={{
                color: theme.primaryText1,
                boxShadow: '0 0 0 1px #2750EB', backgroundColor: theme.primary6,
                minWidth: '45%', textAlign: 'center'
              }}
            >
              {t('Redemption')}
            </TextButton>
          </RowBetween>
          <RowBetween style={{marginTop: '40px'}}>
            <Text style={{minWidth: '60px', color: '#333333', fontWeight: 700}}>{t('Referral')}:</Text>
            {/* <Text style={{ flex: 'auto', display: 'flex', alignItems: 'center', fontWeight: 700 }}>
            </Text> */}
            {/* <Text style={{ color: '#333333', flex: 'auto', maxWidth: '160px' }}>
                {truncateAddressString(invatationUser && invatationUser[0] ? invatationUser[1] : '') || ' --'}
              </Text> */}
            <Text style={{
              flex: 1,
              color: '#333333',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              gap: '1rem',
              fontWeight: 700
            }}>
              {truncateAddressString(userUpper) || ' '}
              {(!userUpper) && (
                // <img src={InvitePlus} alt="AIA" onClick={handleAddInvitation} width={20}></img>
                <Text style={{cursor: 'pointer', color: '#EC7B0D'}}
                      onClick={handleAddInvitation}>{t(`Fill in the inviter's address`)}</Text>
              )}
            </Text>

          </RowBetween>
          <RowBetween style={{marginTop: '30px', display: 'flex', gap: '10px'}}>
            <Text style={{minWidth: '60px', color: '#333333', fontWeight: 700}}>{t('My invitation link')}：</Text>
            {/* <Text style={{ flex: 'auto', display: 'flex', alignItems: 'center', fontWeight: 700 }}>
            </Text> */}
            <Text style={{
              flex: 1,
              overflow: 'hidden',
              color: '#333333',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              gap: '1rem',
              fontWeight: 700
            }}>
              {truncateAddressString(userInviteLink, 15, 4)}
              {/* <Text style={{flex:1, overflow: 'hidden',whiteSpace:'nowrap',textOverflow: 'ellipsis'}}>{userInviteLink}</Text> */}
              <img src={InviteCopy} alt="AIA" onClick={handleCopy} width={20}></img>
            </Text>
          </RowBetween>
        </ColumnSection>
      </SectionContainerBox>
      <SectionContainerBox style={{transform: 'translateY(-25px)', zIndex: 1}}>
        <ColumnSection>
          <RowBetween style={{padding: '30px 0 15px'}}>
            {/* 质押总量 */}
            <Text style={{
              color: theme.text8,
              fontWeight: 700,
              flex: 1,
              marginRight: '10px'
            }}>{t('Total mining amount')}</Text>
            <Text style={{
              color: theme.text8,
              maxWidth: '160px',
              fontWeight: 'bold',
              overflow: 'hidden',
              textOverflow: 'ellipsis'
            }}>{numToFixed(profitInfo[0])}</Text>
          </RowBetween>
          {/* 当前收益 */}
          {/* <RowBetween style={{
            padding: '15px 0',
            borderTop: '1px solid #F5F5F5'
          }}>
            <Text style={{ color: theme.text8, fontWeight: 700 }}>{t('Current Earnings')}</Text>
            <Text style={{ color: theme.text8, maxWidth: '160px', fontWeight: 'bold' }}>{accumulatedMount}</Text>
          </RowBetween> */}
          {/* 累积返利奖励 */}
          {/* <RowBetween style={{
            padding: '15px 0',
            borderTop: '1px solid #F5F5F5'
          }}>
            
            <Text style={{ color: theme.text8, fontWeight: 700 }}>{t('Accumulated Rebate Rewards')}</Text>
            <Text style={{ color: theme.text8, maxWidth: '160px', fontWeight: 'bold' }}>{accumulatedRebateMount}</Text>
          </RowBetween> */}
          <RowBetween style={{
            padding: '15px 0',
            borderTop: '1px solid #F5F5F5'
          }}>
            {/* 年利率（APR） */}
            <Text style={{
              color: theme.text8,
              fontWeight: 700,
              flex: 1,
              marginRight: '10px'
            }}>{t('Annual Percentage Rate')}</Text>
            <Text style={{color: theme.text8, maxWidth: '160px', fontWeight: 'bold', display: 'flex'}}>
              <Text style={{flex: 1, overflow: 'hidden', textOverflow: 'ellipsis'}}>{profitInfo[4]}</Text>%
            </Text>
          </RowBetween>
          <RowBetween style={{
            padding: '15px 0',
            borderTop: '1px solid #F5F5F5'
          }}>
            {/* 年溢率（APY） */}
            <Text style={{
              color: theme.text8,
              fontWeight: 700,
              flex: 1,
              marginRight: '10px'
            }}>{t('Annual Percentage Yield')}</Text>
            <Text style={{color: theme.text8, maxWidth: '160px', fontWeight: 'bold', display: 'flex'}}>
              <Text style={{flex: 1, overflow: 'hidden', textOverflow: 'ellipsis'}}>{profitInfo[5]}</Text>%
            </Text>
          </RowBetween>
          {/* 预估到期总收益 */}
          <RowBetween style={{
            padding: '15px 0',
            borderTop: '1px solid #F5F5F5'
          }}>

            <Text style={{
              color: theme.text8,
              fontWeight: 700,
              flex: 1,
              marginRight: '10px'
            }}>{t('Estimated total income due')}</Text>
            <Text style={{
              color: theme.text8,
              fontWeight: 'bold',
              maxWidth: '160px',
              overflow: 'hidden',
              textOverflow: 'ellipsis'
            }}>{numToFixed(profitInfo[2])}</Text>
          </RowBetween>
          {/* 预估{{cycle}}天收益 */}
          {/* <RowBetween style={{
            padding: '15px 0',
            borderTop: '1px solid #F5F5F5'
          }}>
            
            <Text style={{ color: theme.text8, fontWeight: 700 }}>{t('Estimation', { cycle: getExpirationCycle() })}</Text>
            <Text style={{ color: theme.text8, maxWidth: '160px', fontWeight: 'bold' }}>{bonus >= 0 ? bonus : ''}</Text>
          </RowBetween> */}
          <TextBox style={{
            display: 'flex', justifyContent: 'space-between',
            padding: '15px 0',
            marginRight: 0,
            borderTop: '1px solid #F5F5F5'
          }}>
            <Text style={{color: theme.text8, fontWeight: 700, flex: 1, overflow: 'hidden'}}>
              <Text>{t('Current pledge income')}</Text>
              <Text style={{
                maxWidth: '140px',
                overflow: 'hidden',
                textOverflow: 'ellipsis'
              }}>{numToFixed(profitInfo[3])}</Text>
            </Text>
            <TextButton
              className={!Number(profitInfo[3]) || transactionStatus ? 'disabled' : ''}
              onClick={handleRedeemProfit}
              style={{
                padding: '10px 20px',
                color: theme.primaryText1,
                boxShadow: '0 0 0 1px #2750EB',
                minWidth: '35%',
                textAlign: 'center',
                backgroundColor: theme.primary6
              }}
            >
              {t('Redeemed Earnings')}
            </TextButton>
          </TextBox>
        </ColumnSection>
      </SectionContainerBox>
      <SectionContainerBox style={{transform: 'translateY(-25px)', zIndex: 1}}>
        <ColumnSection>
          {/* <RowBetween>
              <Text style={{ color: theme.text8, fontWeight: 700 }}>{t('Members Info')}</Text>
            </RowBetween> */}
          <RowBetween>
            <Text style={{color: theme.glay, fontWeight: 700}}>{t('Accumulated invitations')}</Text>
            <Text style={{color: theme.text8, fontWeight: 700}}>{validInvite + '/' + totalInvite}</Text>
          </RowBetween>
          <RowBetween style={{marginTop: '0.5rem'}}>
            <Text style={{color: theme.glay, fontWeight: 700}}>{t('Accumulated rewards')}</Text>
            <Text style={{color: theme.text8, fontWeight: 700}}>{totalReward}</Text>
          </RowBetween>
          <Spin spinning={userChildsLoaing}>
            <Tables
              invitationList={childList.length ? childList : []}
              showSearch={() => setSearchVisible(true)}
              // handlePre={handlePre}
              // handleNext={handleNext}
              // start={startPre}
              // end={endIndex}
              // endDisabled={totalCount <= endIndex}
              total={totalInvite || 0}
            ></Tables>
            {
              toBigNumber(totalInvite).gt(listQuery.pageNo * listQuery.pageSize) &&
              (<CheckMoreRedemList onClick={handleNext}>{t('View more')}
                <DownOutlined></DownOutlined></CheckMoreRedemList>)
            }
          </Spin>
        </ColumnSection>
      </SectionContainerBox>
      {false && <>
        <SectionBox>
          <TextBox style={{flexWrap: 'wrap', marginRight: '0px', maxWidth: '400px'}}>
            <Text style={{flex: 'auto', display: 'flex', alignItems: 'center'}}>
              <Text style={{minWidth: '60px', color: '#666666'}}>{t('Referral')}:</Text>
              <Text style={{color: theme.text8, flex: 'auto', maxWidth: '160px'}}>
                {truncateAddressString(invatationUser && invatationUser[0] ? invatationUser[1] : '') || ' --'}
              </Text>
            </Text>
            {(!invatationUser || !invatationUser[0]) && (
              <CopyBox onClick={handleAddInvitation}>{t('Add Invitation')}</CopyBox>
            )}
          </TextBox>
          <TextBox style={{marginTop: '10px', flexWrap: 'wrap', marginRight: '0px', maxWidth: '385px'}}>
            <Text style={{flex: 'auto', display: 'flex', alignItems: 'center'}}>
              <Text style={{minWidth: '60px', color: '#666666'}}>{t('My invitation link')}：</Text>
              <Text style={{color: '#333333', width: '150px'}}>{truncateAddressString(account || '')}</Text>
            </Text>
            <TextBox style={{marginLeft: '10px'}}>
              <CopyBox onClick={handleCopy}>{t('Copy')}</CopyBox>
            </TextBox>
          </TextBox>
          <Line></Line>
          <DataRow>
            <ColumnCenterEle style={{alignItems: 'flex-start', marginRight: '20px'}}>
              <Text style={{color: '#666666'}}>{t('Total mining amount')}</Text>
              <TYPE.white fontWeight={600} style={{margin: '10px 0', color: '#000000', fontWeight: 'bold'}}>
                {totalAmount}
              </TYPE.white>
            </ColumnCenterEle>
            <ColumnCenterEle style={{alignItems: 'flex-start', marginRight: '20px'}}>
              <Text style={{color: '#666666'}}>{t('Accumulated Rebate Rewards')}</Text>
              <TYPE.white fontWeight={600} style={{margin: '10px 0', color: '#000000', fontWeight: 'bold'}}>
                {accumulatedRebateMount}
              </TYPE.white>
            </ColumnCenterEle>
            <ColumnCenterEle style={{alignItems: 'flex-start', marginRight: '20px'}}>
              <Text style={{color: '#666666'}}>
                {t('Current Earnings')}
                {/* {`(块高度：${blockNumber || ''}, 累计收益率：${accPerShare})`} */}
              </Text>
              <TYPE.white fontWeight={600} style={{margin: '10px 0', color: '#000000', fontWeight: 'bold'}}>
                {accumulatedMount}
              </TYPE.white>
            </ColumnCenterEle>
            <ColumnCenterEle style={{alignItems: 'flex-start'}}>
              <Text style={{color: '#666666'}}>{t('Estimation', {cycle: getExpirationCycle()})}</Text>
              <TYPE.white fontWeight={600} style={{margin: '10px 0', color: '#000000', fontWeight: 'bold'}}>
                {bonus >= 0 ? bonus : ''}
              </TYPE.white>
            </ColumnCenterEle>
          </DataRow>
          <TextBox style={{display: 'flex', justifyContent: 'flex-end'}}>
            <TextButton
              className={!Number(accumulatedMount) || transactionStatus ? 'disabled' : ''}
              onClick={handleRedeemProfit}
              style={{color: '#ffffff', backgroundColor: '#8151DB', marginRight: '10px'}}
            >
              {t('Redeemed Earnings')}
            </TextButton>
          </TextBox>
        </SectionBox>
        <SectionBox style={{background: 'linear-gradient(333deg,#eff2f7 40%, #cee0f5 95%)', margin: '15px 0'}}>
          <Text style={{fontSize: '12px', color: '#333333'}}>{t('Members Info')}</Text>
          {/* <Table></Table> */}
        </SectionBox>
        <TopSection gap="md">
          <DataCard style={{background: 'linear-gradient(170deg,#074da8 0%, #000000 82%)'}}>
            <CardSection>
              <RowBetween style={{width: '100%'}}>
                <ColumnCenter style={{alignItems: 'flex-start', wordBreak: 'break-all', width: '100%'}}>
                  <RowBetween style={{alignItems: 'baseline'}}>
                    <TYPE.white fontWeight={500} style={{marginBottom: '10px', fontSize: '12px'}}>
                      {t('Currently mining')}
                    </TYPE.white>
                    <TYPE.white style={{fontSize: '12px'}}>
                      {isPledge ? `${t('Staking end in', {time: unlockRemainingTime})}` : ''}
                    </TYPE.white>
                  </RowBetween>
                  <TextBox>
                    <TYPE.white fontWeight={600} style={{fontSize: '20px'}}>
                      {totalMortgageMount}&nbsp; AIA
                    </TYPE.white>
                  </TextBox>
                </ColumnCenter>
              </RowBetween>
              <RowBetween style={{marginTop: '20px'}}>
                <TextButton
                  className={transactionStatus || !Number(ethBalance) ? 'disabled' : ''}
                  onClick={handleMortgage}
                  style={{
                    color: '#ffffff',
                    border: '1px solid #ffffff',
                    marginRight: '10px',
                    width: '45%',
                    textAlign: 'center'
                  }}
                >
                  {Number(ethBalance) ? t('Mining') : t('Insufficient Balance')}
                </TextButton>
                <TextButton
                  className={!isRedeemed || !Number(totalMortgageMount) || transactionStatus ? 'disabled' : ''}
                  onClick={handleRedeem}
                  style={{color: '#ffffff', background: '#8151DB', width: '45%', textAlign: 'center'}}
                >
                  {t('Redemption')}
                </TextButton>
              </RowBetween>
            </CardSection>
          </DataCard>
        </TopSection>
      </>}
      {
        isPlayRuleOpen &&
        <InvitationPlayRule isOpen={isPlayRuleOpen} onDismiss={() => {
          setPlayRuleOpen(false)
        }}/>
      }
      {
        isPledgeRecordOpen &&
        <PledgeRecordList onDismiss={() => {
          setPledgeRecordOpen(false)
        }}/>
      }
      {
        isOldPledgeOpen &&
        <OldPledgeRedeem onDismiss={() => {
          setOldPledgeOpen(false)
        }}/>
      }
      {
        searchVisible &&
        <SearchAddress onDismiss={() => {
          setSearchVisible(false)
        }}/>
      }
      <Modal isOpen={isOpen} onDismiss={toggleClaimModal}>
        <ColumnCenterEle
          style={{
            width: '100%'
          }}
        >
          <RowBetween style={{padding: '1rem', borderBottom: '1px solid rgba(14,37,74,0.06)'}}>
            <Text style={{fontWeight: 600, color: theme.text7, fontSize: '16px'}}>{modelTitle} </Text>
            <Text style={{color: '#666666', fontSize: '16px'}} onClick={close}>
              <CloseOutlined/>
            </Text>
          </RowBetween>

          {
            modelType === modalType.MORTGAGE && isOpenGiftCard && <div style={{
              width: '100%',
            }}>
              <div style={{
                background: '#FFF8EA',
                color: '#F26008',
                width: '100%',
                fontSize:'12px',
                marginBottom: '20px',
                borderRadius: '4px',
                padding: '10px 2rem'
              }}> {t('conditionsForObtainingBankCard', {amount: minStakeAmount})}</div>
            </div>
          }

          <Sections>
            <RowBetween style={{padding: '0 1rem', marginBottom: '25px', alignItems: 'center'}}>
              <InputBox
                disabled={(modelType === modalType.REDEEM && toBigNumber(unstakeInfo[3]).isEqualTo(0))}
                placeholder={(modelType === modalType.REDEEM && toBigNumber(unstakeInfo[3]).isEqualTo(0)) ? '--' : placeholder}
                value={inputValue}
                onChange={event => {
                  handleChange(event)
                }}
              ></InputBox>
              {getAllButton()}
            </RowBetween>
            {
              modelType === modalType.MORTGAGE ?
                <BalanceRow style={{padding: '0 1rem', marginBottom: '8px'}}>
                  <span style={{color: '#666'}}>{t('Wallet balance')}</span>：
                  <Text style={{fontSize: '15px', fontWeight: 600, color: '#333'}}>{ethBalance}</Text></BalanceRow>
                : modelType === modalType.REDEEM && (<CanRedemptionRow>
                {t('Redeemable quantity')}：<Text style={{fontSize: '15px', fontWeight: 600}}>{unstakeInfo[3]}</Text>
              </CanRedemptionRow>)
            }
            {
              modelType === modalType.MORTGAGE && isOpenGiftCard &&   <BalanceRow style={{padding: '0 1rem'}}>
                <span style={{color: '#666'}}> {t('currentlyYouCanGetComplimentaryBankCards')}</span>：
                <Text style={{fontSize: '15px', fontWeight: 600 , color: '#333'}}>{bankCards} {t('pieces')}</Text></BalanceRow>
            }
            {/* {messageError && <Sections style={{ color: 'red',padding: '0.5rem 1rem' }}>{messageError}</Sections>} */}
            {
              modelType === modalType.REDEEM && <RedemShowList>
                <RedemShowListItem>
                  <Text style={{color: theme.glay}}>{t('Redemption release ratio')}</Text>
                  <Text>{unstakeInfo[0]}%</Text>
                </RedemShowListItem>
                <RedemShowListItem>
                  <Text style={{color: theme.glay}}>{t('Total redeemable quantity')}</Text>
                  <Text>{unstakeInfo[1]}</Text>
                </RedemShowListItem>
                <RedemShowListItem>
                  <Text style={{color: theme.glay}}>{t('Quantity redeemed')}</Text>
                  <Text>{unstakeInfo[2]}</Text>
                </RedemShowListItem>
              </RedemShowList>
            }
          </Sections>
          <TextBox style={{width: '100%', justifyContent: 'center', paddingTop: '24px', paddingBottom:'30px'}}>
            <TextButton
              style={{
                color: '#878A93',
                background: '#edeef2',
                width: '40%',
                margin: '0 10px',
                textAlign: 'center',
                boxShadow: '0px 2px 0px 1px #edeef2'
              }}
              onClick={close}
            >
              {t('Cancel')}
            </TextButton>
            <TextButton
              style={{
                color: '#FFFFFF',
                background: '#2750EB',
                width: '40%',
                margin: '0 10px',
                textAlign: 'center',
                boxShadow: '0px 2px 0px 1px #0090FF'
              }}
              onClick={ConfirmModel}
              className={(modelType === modalType.REDEEM && toBigNumber(unstakeInfo[3]).isEqualTo(0)) || transactionStatus ? 'disabled' : ''}
            >
              {t('Confirm')}
            </TextButton>
          </TextBox>
        </ColumnCenterEle>
      </Modal>
    </PageWrapper>
  )
}
