import { Skeleton } from 'antd'
import React, { memo } from 'react'
import styled from "styled-components"
import { typography, TypographyProps,space } from "styled-system";


export const Td = styled.td<TypographyProps>`
  border-bottom: 1px solid "#E7E3EB";
  color:"#280D5F";
  padding: 16px;
  vertical-align: middle;
  ${typography}
`;
export const Th = styled(Td).attrs({ as: "th" })`
  color: ${({ theme }) => theme.text7};
  font-size: 12px;
  text-transform: uppercase;
`;
export const Table = styled.table`
  max-width: 100%;
  width: 100%;
  tbody tr:last-child {
    ${Td} {
      border-bottom: 0;
    }
  }
  ${space}
`;


const LoadingTable = () => (
    <Table>
        <tbody>
            <tr>
                <Td>
                    <Skeleton />
                </Td>
                <Td>
                    <Skeleton />
                </Td>
                <Td>
                    <Skeleton />
                </Td>
            </tr>
            <tr>
                <Td>
                    <Skeleton />
                </Td>
                <Td>
                    <Skeleton />
                </Td>
                <Td>
                    <Skeleton />
                </Td>
            </tr>
            <tr>
                <Td>
                    <Skeleton />
                </Td>
                <Td>
                    <Skeleton />
                </Td>
                <Td>
                    <Skeleton />
                </Td>
            </tr>
        </tbody>
    </Table>
)

export default memo(LoadingTable)
