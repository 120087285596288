import React from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'

const SwapLimitHeader: React.FC<any> = ({ active = true }) => {
    const { t } = useTranslation()

    return (<>
        <Wrap>
            <WrapContainer>
                <Button id={`swap-nav-link`} to={'/swap'} className={active ? 'active' : ''}>
                    {t('swap')}
                </Button>
                <Button id={`limit-nav-link`} to={'/limit'} className={!active ? 'active' : ''}>{t('Limit')}</Button>
            </WrapContainer>
        </Wrap>
    </>)
}
const Wrap = styled.div`
    width:280px;
    max-width:420px;
    transform: translateY(-50px);
    @media (max-width: 720px){
      transform: translateY(-1rem);
      width: 328px;
    }
`
const WrapContainer = styled.div`
    display:flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    border: 1px solid #94A0B4;
    .active{
        background-color: #0E254A;
        color:#ffffff;
    &:hover{
        color: #ffffff;
    }
    }
`
const Button = styled(NavLink)`
    text-transform: uppercase;
    color: #94A0B4;
    font-size: 18px;
    background-color: transparent;
    width: 50%;
    border: none;
    cursor: pointer;
    border-radius: 20px;
    padding:5px 0.5rem;
    text-align: center;
    &:hover{
        opacity: 0.85;
        color: #94A0B4;
    }
    @media (max-width: 720px){
        font-size: 14px;
        padding:5px 0;
    }
`
export default SwapLimitHeader;