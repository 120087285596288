/**
 * @name: giftCardOrder
 * @author: mahuanhuan
 * @date: 2024/1/9
 * @description：赠卡订单
 * @update: 2024/1/9
 */
import React, {useContext, useEffect, useState} from 'react';
import {
  AntdList,
  CardInfo,
  CardLabel,
  DescriptionInfo,
  FormStyle,
  GiftCardOrderDiv,
  ModalInfoScrollDiv,
  OrderListDiv,
  ParagraphInfo,
  UserInfoDiv
} from "./styleds";
import GiftCardImg from "../../assets/images/giftCard.png";
import AddressSvg from "../../assets/svg/address.svg";
import UserCardSvg from "../../assets/svg/userCard.svg";
import HarvestInformationSvg from "../../assets/svg/harvestInformation.svg";
import BindingAddressSvg from "../../assets/svg/bindingAddress.svg";
import Card1Svg from "../../assets/svg/card1.svg";
import Card2Svg from "../../assets/svg/card2.svg";
import {useTranslation} from "react-i18next";
import {CloseOutlined, RightOutlined} from '@ant-design/icons'
import {Avatar, Button, Form, Input, List, message} from 'antd';
import CardInfoModal from "./components/cardInfoModal";
import Modal from '../../components/Modal';
import {useActiveWeb3React} from "../../hooks";
import {RowBetween} from "../../components/Row";
import {ThemeContext} from "styled-components";
import {Text} from '../../components/invitation/styled'
import {isAddress, stringLengthChange} from "../../utils";
import AddMyInfoModal from "./components/addMyInfoModal";
import CardLeaderSelect from "./components/cardLeaderSelect";
import {PageSize} from "../../constants";
import Empty from "../../components/Empty";
import {OrderStateEnum, OrderTypeEnum} from "./types";
import pubSub from "../../utils/pub-sub";
import {cardAddrSaveOrUpdate, getAddrByUser, getCardAddrByUser} from "../../services/card";


const GiftCardOrderPage = ({list, getGiftCardOrder, total, page}: {
  list: Record<string, any>[];
  getGiftCardOrder: Function;
  total: number;
  page: number;
}) => {
  const {t} = useTranslation();
  const [isShowMore, setIsShow] = useState(false);
  const [isShowUserInfo, setIsShowUserInfo] = useState(false);
  const [isShowAgentInfo, setIsShowAgentInfo] = useState(false);
  const [currentCardInfo, setCurrentCardInfo] = useState<Record<string, any>>({});
  const [openCardInfo, setOpenCardInfo] = useState(false);
  const {account} = useActiveWeb3React()

  useEffect(() => {
    const handleScroll = () => {
      const {scrollTop, clientHeight, scrollHeight} = document.documentElement;
      if (scrollTop + clientHeight === scrollHeight) {
        if (PageSize * page < total) {
          getGiftCardOrder(+page + 1);
        }
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [page, total]);

  useEffect(() => {
    if (currentCardInfo?.id) {
      const newData = list.find((item) => item.id == currentCardInfo.id) || currentCardInfo
      setCurrentCardInfo(newData);
    }
  }, [list])

  const clickHandle = (info: Record<string, any>) => {
    setOpenCardInfo(true);
    setCurrentCardInfo(info);
  }

  const switchSvg = (type: number) => {
    switch (type) {
      case OrderTypeEnum.ordinary:
        return Card2Svg;
      default:
        return Card1Svg;
    }
  }

  const switchOrderState = (orderState: number) => {
    switch (orderState) {
      case OrderStateEnum.progress:
        return t('In Progress');
      case OrderStateEnum.completed:
        return t('completed');
      case OrderStateEnum.cancelled:
        return t('cancelTheOrder');
    }
  }

  useEffect(() => {
    getGiftCardOrder()
  }, [])

  return (
    <GiftCardOrderDiv>
      <div className='topInfo'>
        {
          account &&
          (
            <div className='myInfo' onClick={() => {
              setIsShowUserInfo(true)
            }}>
              <img src={UserCardSvg} alt="UserCardSvg"/>
              {t('myInfo')}
            </div>
          )
        }
        {
          account && <UserInfoModal isOpen={isShowUserInfo} setOpen={setIsShowUserInfo}></UserInfoModal>
        }
        <div className='title'>
          <img src={GiftCardImg} alt={t('miningBonusCard')}/>
          <span>{t('miningBonusCard')}</span>
        </div>
        <div className='introduction'>
          <span onClick={() => {
            setIsShow(true)
          }}>{t('View more')} {`>>`}</span>
          {t('miningBonusCardExplain')}
        </div>
      </div>
      <DescriptionDetailsInfo isOpen={isShowMore} setOpen={setIsShow}/>

      <div className='agentInfo' onClick={() => {
        setIsShowAgentInfo(true)
      }}>
        <div className='agentInfoTitle'>
          <span className='cardCollectionAgent'>
            <img src={AddressSvg} alt="AddressSvg"/>
            {t('cardCollectionAgent')}
          </span>
          <span><RightOutlined/></span>
        </div>
        <div className='cardCollectionAgentInfo'>
          {t('cardCollectionAgentInfo')}
        </div>
      </div>
      <CardLeaderSelect isOpen={isShowAgentInfo} setOpen={setIsShowAgentInfo} isSelect={false}/>
      <OrderListDiv>
        <div className='orderListTitle'>{t('myGiftCardOrder')}</div>
        {
          list.length == 0 ?
            <Empty title={t('noContent')}></Empty>
            : <AntdList
              dataSource={list}
              renderItem={(item: any) => (
                <List.Item
                  key={item.id}
                >
                  <List.Item.Meta
                    avatar={<Avatar src={switchSvg(item.orderType)}/>}
                    title={`${t('ReceiveMultipleBankCards')}x${item.cardNum}`}
                    description={item.createTime}
                  >
                  </List.Item.Meta>
                  <div className={`OrderStata ${item.orderState == OrderStateEnum.progress ? 'OrderStataIng' : ''}`}>
                    <div style={{marginBottom: '4px'}}> {switchOrderState(item.orderState)}</div>
                    <div
                      className='alreReceivedNum'>{item.alreReceivedNum > 0 ? `${t('cancelledCard')} ${item.alreReceivedNum}` : ''}</div>
                  </div>
                  <CardInfo>
                    <div className='cardInfoList'>
                      <CardLabel>{t('orderReference')}</CardLabel>
                      <ParagraphInfo copyable={{tooltips: [t('Copy'), t('Copy Successfully')]}}
                                     style={{marginBottom: 0}}>{item.orderId}</ParagraphInfo>
                    </div>
                    <div className='cardInfoList'>
                      <CardLabel>{t('txHash')}</CardLabel>
                      <ParagraphInfo copyable={{tooltips: [t('Copy'), t('Copy Successfully')], text: item.pledgeHash}}
                                     style={{marginBottom: 0}}>{stringLengthChange(item.pledgeHash)}</ParagraphInfo>
                    </div>
                    <div className='cardInfoList'>
                      <CardLabel>{t('quantityPledged')}</CardLabel>
                      <span>{item.pledgeNum}{' '}AIA</span>
                    </div>
                    <div className='cardInfoList'>
                      <CardLabel>{t('cardRequisitionInformation')}</CardLabel>
                      <span className='clickToView' onClick={() => {
                        clickHandle(item)
                      }}>{t('clickToView')} <RightOutlined/></span>
                    </div>
                  </CardInfo>
                </List.Item>
              )}
            >
            </AntdList>
        }

      </OrderListDiv>
      <CardInfoModal isOpen={openCardInfo} getGiftCardOrder={getGiftCardOrder} setOpen={setOpenCardInfo}
                     currentCardInfo={currentCardInfo}/>
    </GiftCardOrderDiv>
  );
}

export default GiftCardOrderPage;


const UserInfoModal = ({isOpen, setOpen}: {
  isOpen: boolean;
  setOpen: Function;
}) => {
  const {t} = useTranslation();
  const {account} = useActiveWeb3React()
  const theme = useContext(ThemeContext);
  const [isOpenBinding, setIsOpenBinding] = useState(false);
  const [isOpenAddInfo, setIsOpenAddInfo] = useState(false);
  const [bindingInfo, setBindingInfo] = useState<Record<string, any>>({});
  const [addressInfo, setAddressInfo] = useState<Record<string, any>>({});
  const close = () => {
    setOpen(false)
  }
  const getMyInfo = () => {
    getCardAddrByUser({
      userAddr: account
    }).then((res) => {
      setBindingInfo(res);
    })
    getAddrByUser({
      userAddr: account
    }).then((res) => {
      setAddressInfo(res);
    })
  }
  useEffect(() => {
    if (isOpen) {
      getMyInfo();
      pubSub.on('getMyInfo', () => {
        getMyInfo();
      })
    }
    return () => {
      pubSub.off('getMyInfo')
    }
  }, [isOpen])
  return (
    <Modal isOpen={isOpen} onDismiss={close}>
      <UserInfoDiv>
        <RowBetween style={{padding: '1rem 20px', borderBottom: '1px solid rgba(14,37,74,0.06)'}}>
          <Text style={{fontWeight: 600, color: theme.text7, fontSize: '16px'}}>
            {t('myInfo')}
          </Text>
          <Text style={{color: '#666666', fontSize: '16px'}} onClick={close}>
            <CloseOutlined/>
          </Text>
        </RowBetween>
        <div className='userInfo'>
          {
            addressInfo?.address ?
              (
                <div className='addInfo'>
                  <div className='title' style={{marginBottom: '12px'}}>
                    <span style={{fontWeight: 500}}>
                    <img src={HarvestInformationSvg} alt="HarvestInformationSvg"/>
                      {t('HarvestInformation')}
                    </span>
                    <span className='Add' onClick={() => {
                      setIsOpenAddInfo(true)
                    }}>
                      {t('fix')}{' '}{'>'}
                    </span>
                  </div>
                  <div className='fixList'>
                    <span className='label'>{t('userName')}:</span>
                    <span className='value'>{addressInfo.name} </span>
                  </div>
                  <div className='fixList'>
                    <span className='label'>{t('phone')}:</span>
                    <span className='value'>{addressInfo.phone}</span>
                  </div>
                  <div className='fixList'>
                    <span className='label'>{t('address')}:</span>
                    <span className='value'>{addressInfo.area}{addressInfo.address} </span>
                  </div>
                </div>
              ) : (
                <div className='addInfo'>
                  <div className='title'>
                    <span style={{fontWeight: 500}}>
                    <img src={HarvestInformationSvg} alt="HarvestInformationSvg"/>
                      {t('HarvestInformation')}
                    </span>
                    <span className='Add' onClick={() => {
                      setIsOpenAddInfo(true)
                    }}>
                  {t('Add')}{' '}{'>'}
                </span>
                  </div>
                </div>
              )
          }
          {
            bindingInfo?.stairLevel ?
              (
                <div className='addInfo'>
                  <div className='title' style={{marginBottom: '12px'}}>
                    <span style={{fontWeight: 500}}>
                    <img src={BindingAddressSvg} alt="BindingAddressSvg"/>
                      {t('bindingAddress')}
                    </span>
                    {/*<span className='Add' onClick={() => {*/}
                    {/*  setIsOpenBinding(true)*/}
                    {/*}}>*/}
                    {/*  {t('fix')}{' '}{'>'}*/}
                    {/*</span>*/}
                  </div>
                  <div className='fixList'>
                    <span className='label'
                          style={{display: 'block', marginBottom: '4px'}}>{t('Level1ProxyBindingAddress')}:</span>
                    <span className='value'>{bindingInfo.stairLevel} </span>
                  </div>
                  <div className='fixList'>
                    <span className='label'
                          style={{display: 'block', marginBottom: '4px'}}>{t('Level2ProxyBindingAddress')}:</span>
                    <span className='value'>{bindingInfo.secondLevel} </span>
                  </div>
                </div>
              )
              : (
                <div className='addInfo'>
                  <div className='title'>
                    <span style={{fontWeight: 500}}>
                      <img src={BindingAddressSvg} alt="BindingAddressSvg"/>
                      {t('bindingAddress')}
                    </span>
                    <span className='Add' onClick={() => {
                      setIsOpenBinding(true)
                    }}>
                        {t('Add')}{' '}{'>'}
                      </span>
                  </div>
                </div>
              )
          }
        </div>
        <AddMyInfoModal isOpen={isOpenAddInfo} setOpen={setIsOpenAddInfo} info={addressInfo}></AddMyInfoModal>
        <CardBadBindingAddress isOpen={isOpenBinding} setOpen={setIsOpenBinding}
                               info={bindingInfo}></CardBadBindingAddress>
      </UserInfoDiv>
    </Modal>
  )
}


const CardBadBindingAddress = ({isOpen, setOpen, info}: {
  isOpen: boolean;
  setOpen: Function;
  info: Record<string, any>
}) => {
  const {t} = useTranslation();
  const theme = useContext(ThemeContext);
  const [form] = Form.useForm();
  const {account} = useActiveWeb3React()
  const [initialValues, setInitialValues] = useState<Record<string, any>>({
    stairLevel: '',
    secondLevel: '',
  })
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (info.stairLevel) {
      setInitialValues(info)
    }
  }, [info])
  const close = () => {
    setOpen(false)
    form.resetFields(); // 重置表单的值为初始值
  }

  const stairLevelcheckAddress = (_: any, value: string) => {
    if (!value) {
      return Promise.reject(
        new Error(t('pasteAddress')),
      );
    }
    if (isAddress(value)) {
      return Promise.resolve();
    }
    return Promise.reject(
      new Error(t('Please enter the correct Addresses')),
    );
  };
  const secondLevelcheckAddress = (_: any, value: string) => {
    const {stairLevel} = form.getFieldsValue();
    if (!value) {
      return Promise.reject(
        new Error(t('pasteAddress')),
      );
    }
    if (isAddress(value)) {
      if (value !== stairLevel) {
        return Promise.resolve();
      }
      return Promise.reject(new Error(t('AddressCannotBeSame')));
    }
    return Promise.reject(
      new Error(t('Please enter the correct Addresses')),
    );
  };

  const onFinish = (values: any) => {
    if (loading) return;
    setLoading(true);
    cardAddrSaveOrUpdate({
      ...values,
      userAddr: account
    }).then(() => {
      setOpen(false);
      pubSub.emit('getMyInfo');
      message.success(t('bindingAddressSucceeded'))
    }).catch(() => {
    }).finally(() => {
      setLoading(false);
    })
  };

  return (
    <Modal isOpen={isOpen} onDismiss={close}>
      <ModalInfoScrollDiv>
        <RowBetween style={{padding: '1rem 0', marginBottom: '1rem', borderBottom: '1px solid rgba(14,37,74,0.06)'}}>
          <Text style={{fontWeight: 600, color: theme.text7, fontSize: '16px'}}>
            {t('bindingAddress')}(ARC20)
          </Text>
          <Text style={{color: '#666666', fontSize: '16px'}} onClick={close}>
            <CloseOutlined/>
          </Text>
        </RowBetween>
        <FormStyle form={form} initialValues={initialValues} onFinish={onFinish}>
          <Form.Item required name="stairLevel" label={t('Level1ProxyBindingAddress')}
                     rules={[{validator: stairLevelcheckAddress}]}>
            <Input allowClear placeholder={t('pasteAddress')}/>
          </Form.Item>
          <Form.Item required name="secondLevel" label={t('Level2ProxyBindingAddress')}
                     rules={[{validator: secondLevelcheckAddress}]}>
            <Input allowClear placeholder={t('pasteAddress')}/>
          </Form.Item>
          <div className='tips'>
            <div className='title'>{t('fillingNote')}：</div>
            <p>{t('fillingNote1')}</p>
            <p>{t('fillingNote2')}</p>
            <p>{t('fillingNote3')}</p>
            <p>{t('fillingNote5')}</p>
            <p>{t('fillingNote6')}</p>
            <p>{t('fillingNote7')}</p>
          </div>
          <Form.Item style={{position: 'absolute', bottom: '40px', left: '1rem', right: '1rem', marginBottom: 0}}>
            <Button loading={loading} type="primary" htmlType="submit">{t('save')}</Button>
          </Form.Item>
        </FormStyle>
      </ModalInfoScrollDiv>
    </Modal>
  )
}

const DescriptionDetailsInfo = (
  {isOpen, setOpen}: {
    isOpen: boolean;
    setOpen: Function;
  }) => {
  const {t} = useTranslation();
  const theme = useContext(ThemeContext);
  const close = () => {
    setOpen(false)
  }

  return (
    <Modal isOpen={isOpen} onDismiss={close}>
      <DescriptionInfo>
        <RowBetween style={{padding: '1rem', marginBottom: '1rem', borderBottom: '1px solid rgba(14,37,74,0.06)'}}>
          <Text style={{fontWeight: 600, color: theme.text7, fontSize: '16px'}}>
            {t('miningBonusCard')}
          </Text>
          <Text style={{color: '#666666', fontSize: '16px'}} onClick={close}>
            <CloseOutlined/>
          </Text>
        </RowBetween>
        <div className='miningBonusCardExplain'>
          {t('miningBonusCardExplain')}
        </div>
      </DescriptionInfo>
    </Modal>
  )
}