import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = (props) => {
    return (
        <Svg width="11.184" height="11.187" viewBox="0 0 11.184 11.187" {...props}>
            <path className="a"
                d="M-1716.255,10.29l-.23-.125a.5.5,0,0,1-.116-.8l1.811-1.815a.7.7,0,0,1,.988,0,.7.7,0,0,1,0,.988l-.877.88a4.193,4.193,0,0,0,5.133-2.97,4.194,4.194,0,0,0-.366-3.081.7.7,0,0,1,.281-.947.7.7,0,0,1,.947.281,5.585,5.585,0,0,1-2.238,7.575,5.585,5.585,0,0,1-2.687.679,5.555,5.555,0,0,1-2.644-.666Zm-2.257-2.263a5.591,5.591,0,0,1,2.247-7.58,5.591,5.591,0,0,1,5.323-.006l.23.125a.505.505,0,0,1,.116.8l-2.015,2.019a.7.7,0,0,1-.989,0,.7.7,0,0,1,0-.989l1.082-1.085a4.194,4.194,0,0,0-5.132,2.972,4.193,4.193,0,0,0,.365,3.078.7.7,0,0,1-.281.947.7.7,0,0,1-.947-.281Z"
                transform="translate(1719.19 0.231)" />
        </Svg>
    );
};

export default Icon;
