import React,{ useContext, useState} from 'react';
import { message} from 'antd';
import { useTranslation } from 'react-i18next'
import Modal from '../../../components/Modal';
import styled, { ThemeContext } from 'styled-components'
import { ColumnCenter } from '../../../components/Column';
import { RowBetween} from '../../../components/Row';
import {Text,InputBox,TextButton} from '../../../components/invitation/styled';
import { CloseOutlined } from '@ant-design/icons'
import { GetChildInfo } from 'utils/invitationCallback_v2'
import { truncateAddressString} from 'utils/invitationCallback'

const ColumnCenterEle = styled(ColumnCenter)`
  width: initial;
  min-width: 140px;
  margin-bottom: 10px;
`
const RecordList = styled.ul`
 border-top: 1px solid rgba(14, 37, 74, 0.06);
 margin: 1rem 1rem 0;
 padding: 0;
 width: calc(100% - 2rem);
`
const RecordListItem= styled.li`
 display: flex;
 justify-content: space-between;
 padding: 1rem 0;
`
export default function SearchAddress(props:any){
  const { t } = useTranslation();
  const theme = useContext(ThemeContext);
  const [inputValue, setValue] = useState<string>('');
  const [result, setResult] = useState<any>({});
  const { childInfoLoading,getChildInfo } = GetChildInfo();
  const close = () => {
    setValue('');
    props?.onDismiss()
  }

  const onSearch = async() => {
    if(childInfoLoading)return
    setResult({});
    if(!inputValue || !/^0x[a-fA-F0-9]{40}$/.test(inputValue)){
      message.error(t(`Please enter the correct Addresses`));
      return
    }
    let getData = await getChildInfo(inputValue);
    setResult(getData);
  }

  return (<Modal isOpen={true} onDismiss={()=>{}} >
    <ColumnCenterEle style={{ width: '100%' }}>
      <RowBetween style={{ padding: '1rem', marginBottom: '1rem', borderBottom: '1px solid rgba(14,37,74,0.06)' }}>
        <Text style={{ fontWeight: 600, color: theme.text7, fontSize: '16px' }}>{t('Search for member addresses')} </Text>
        <Text style={{ color: '#666666', fontSize: '16px' }} onClick={close}>
          <CloseOutlined />
        </Text>
      </RowBetween>
      <RowBetween style={{ padding: '0 1rem', alignItems: 'center' }}>
        <InputBox
          placeholder={t('Please enter the address')}
          value={inputValue}
          onChange={event => setValue(event.target.value)}
        ></InputBox>
        <TextButton
          style={{
            color: '#FFFFFF',
            background: '#2750EB',
            width: '10%',
            textAlign: 'center',
            marginLeft: '10px',
            boxShadow: '0px 2px 0px 1px #0090FF',
            opacity: childInfoLoading?0.35:1
          }}
          onClick={onSearch}>
          {t('Search for')}
        </TextButton>
      </RowBetween>
      <RecordList>
        {
          !result.address?(<Text style={{ color: '#666666', fontSize: '15px',textAlign: 'center',padding: '1rem 0' }} >{t('There are currently no records available')}</Text>)
          :(
            <RecordListItem>
              <Text>
                <Text style={{ color: theme.glay,}} >{t('Member Addresses')}</Text>
                <Text style={{ fontSize: '15px',fontWeight: 600 }} >{truncateAddressString(result.address,4,4)}</Text>
                <Text style={{ color: theme.glay }} >{result.time}</Text>
              </Text>
              <Text>
                <Text style={{ color:theme.glay,}} >{t('Reward quantity')}</Text>
                <Text style={{ fontSize: '15px',fontWeight: 600 }} >{result.amount}</Text>
              </Text>
            </RecordListItem>
          )
        }
        
      </RecordList>
    </ColumnCenterEle>
  </Modal>)
}