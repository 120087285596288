/**
 * @name: styles
 * @author: mahuanhuan
 * @date: 2024/1/3
 * @description：样式文件
 * @update: 2024/1/3
 */
import styled from "styled-components";
import { List, Form, Typography } from 'antd';
import cardCollectionAgentBg from '../../assets/images/cardCollectionAgentBg.png';
import cardCollectionAgentGrayBg from '../../assets/images/cardCollectionAgentGrayBg.png';

const {Paragraph} = Typography;

export const MobileHeaderRowContainer = styled.div`
    display: none;
    ${({theme}) => theme.mediaWidth.upToMedium`
    display: block;
    padding: 0.5rem 1rem;
    max-width: 960px;
    position: fixed;
    bottom: 0.5rem;
    left: 0px;
    width: 100%;
    z-index: 99;
  `}
    ${({theme}) => theme.mediaWidth.upToMedium`
    display: block;
`}
  `
export const MobileHeaderRow = styled.div`
    display: flex;
    max-width: 960px;
    padding: 0.5rem 0;
    width: 100%;
    z-index: 99;
    // height: 72px;
    border-radius: 12px;
    background-color: #FFFFFF;
    justify-content: space-around;
    box-shadow: 0px 5px 2px 1px rgba(14, 37, 74, 0.1);
  `

export const MobileHeaderItem = styled.div`
    flex: 1;
  
    & > a, div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      & > span {
        font-size: 14px;
        font-family: PingFang SC-Bold, PingFang SC;
        font-weight: bold;
        margin-top: 0.1rem;
        color: #333333;
        text-align: center;
        padding: 0 3px;
      }
    }
  `


export const StyledNavDiv = styled.div`
    .title {
      color: #989898;
    }

    img {
      width: 28px;
      height: 28px;
    }

    &.activeClassName {
      color: ${({theme}) => theme.text12} !important;
      
      span {
        color: #333333;
      }
    }
  `

export const PopoverInfo = styled.div`
  background: #FEEFE9;
  width: 200px;
  padding: 10px;
  border: 1px solid #F2601F;
  position: absolute;
  border-radius: 8px;
  top: -220%; /* 负值使其位于按钮之上 */
  left: 50%;
  transform: translate(-50%);
  z-index: 10;
  
  .ISee {
    margin-top: 10px;
    width: 100%;
    display: block !important;
    text-align: left;
    
    span {
      background: #F2601F;
      font-size: 12px;
      color: #fff;
      border-radius: 22px;
      padding: 2px 10px;
    }
  }

  .angle {
    bottom: -20px;
    position: absolute;
    display: block;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
    border-top-width: 0;
    border-bottom-color: #F2601F;
    border-width: 10px;
    transform: rotate(180deg);
  }
  .angle::after {
    position: absolute;
    display: block;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
    content: " ";
    border-width: 10px;
    top: 1px;
    margin-left: -10px;
    border-top-width: 0;
    border-bottom-color: #FEEFE9;
  }


`

export const GiftCardOrderDiv = styled.div`
  .topInfo {
    position: relative;
    background: #fff;
    box-shadow: 0px 5px 10px 1px rgba(20, 72, 195, 0.05);
    border-radius: 15px;
    padding: 10px 16px;
    margin-bottom: 16px;
  }

  .myInfo {
    position: absolute;
    right: 15px;
    top: 15px;
    border: 1px solid #2750EB;
    color: #2750EB;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-content: center;
    border-radius: 20px;
    padding: 5px 10px;

    img {
      width: 16px;
      height: 16px;
      margin-right: 5px;
      margin-top: 1px;
    }
  }

  .title {
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
    text-align: center;

    img {
      height: 143px;
      object-fit: contain;
    }

    span {
      font-size: 18px;
      font-weight: bold;
      color: #333;
    }
  }

  .introduction {
    color: #333;
    font-size: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: justify;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    position: relative;
    line-height: 24px;

    &:before {
      content: '';
      height: 48px;
      float: right;
    }

    span {
      float: right;
      clear: both;
      font-size: 12px;
      padding: 0 8px;
      line-height: 24px;
      color: #2750EB;
      cursor: pointer;
    }
  }

  .agentInfo {
    padding: 10px 16px;
    font-size: 12px;
    background: #fff;
    border-radius: 8px;

    .agentInfoTitle {
      height: auto;
      display: flex;
      justify-content: space-between;
      margin-bottom: 6px;
      align-items: center;
    }

    .cardCollectionAgent {
      font-size: 12px;
      font-weight: bold;
      color: #333333;
      display: flex;
      align-items: center;

      img {
        width: 16px;
        height: 16px;
        margin-right: 6px;
        color: rgba(51, 51, 51, 1);
      }
    }
    .cardCollectionAgentInfo {
      color: #2750EB;
    }
  }
`

export const OrderListDiv = styled.div`
  margin-top: 16px;
 .orderListTitle {
   font-size: 14px;
   font-weight: bold;
   color:#333333;
   padding-bottom: 10px;
 }
`

export const AntdList = styled(List)`
   .ant-list-item {
    background: #FFFFFF;
    box-shadow: 0 5px 10px 1px rgba(20,72,195,0.05);
    border-radius: 15px;
    border: 1px solid rgba(69,92,142,0.2);
     padding: 15px 16px;
     margin-bottom: 12px;
     .ant-list-item-meta-avatar {
       margin-right: 12px;
     }
     .ant-list-item-meta-title {
       color: #333333;
       font-size: 14px;
       margin-bottom: 4px;
       font-weight: 600;
     }
     .OrderStata {
       font-weight: 600;
       color: #333333;
       font-size: 14px;
       text-align: right;
     }
     .OrderStataIng, .alreReceivedNum {
       color: #F2601F;
     }
     .alreReceivedNum {
       font-size: 12px;
       font-weight: 400;
       height: 22px;
     }
     .ant-list-item-meta-description {
       color: #656565;
     }
  }
`

export const CardCancellationPageDiv = styled.div`
  width: 100%;
  position: relative;
  .title {
    height: auto;
  }
  .titleInfo {
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
    text-align: center;
    background: url(${cardCollectionAgentBg}) no-repeat;
    background-size: 100%;
    height: 270px;

    img{
      height: 154px;
      object-fit: contain;
    }
    span {
      color: #fff;
      font-size: 16px;
      font-weight: bold;
      padding: 0 16px;
    }
  }
  .titleInfoGray {
    background: url(${cardCollectionAgentGrayBg}) no-repeat;
    background-size: 100%;
  }
  .cardNum {
    margin-top: -20px;
    padding: 15px;
    background: #FFFFFF;
    box-shadow: 0 8px 16px 1px rgba(0,24,111,0.08);
    border-radius: 15px;
    z-index: 10;
    .cardNumList {
      display: flex;
      justify-content: space-between;
      align-items: center;
      span {
        flex: 1;
        font-size: 12px;
        color: #656565;
        text-align: center;
      }
    }
    .total{
     font-weight: 500;
      .num {
        font-size: 24px;
        font-weight: bold;
      }
      span {
        color: #333333;
      }
    }
  }
  .writeOffButton {
    margin-top: 20px;
  }
  .cardsCurrently {
    font-size: 12px;
    color: #656565;
    text-align: center;
    margin-top: 6px;
  }
  .selectionInfo {
    margin-bottom: 12px;
    margin-top: 12px;
    justify-content: space-between;
    align-items: center;
    display: flex;
    .ant-input-affix-wrapper {
      width: 230px;
      border-radius: 20px;
    }
    input.ant-input {
      background: #fff;
      padding-left: 14px;
    }
    .select {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      flex: 1;
      overflow: hidden;
      a {
        font-size: 13px;
        color: #333;
      }
    }
  }
`


export const VerifyVoucherCodeDiv = styled.div`
  width: 100%;
  height: auto;
  padding: 0 20px 40px 20px;
  .title {
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    font-weight: bold;
  }
`
export const WriteOff = styled.div`
  padding: 40px 0 0 0;
  .ant-input-affix-wrapper {
    border: none;
    padding: 8px 11px;
  }
  .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
    border: none;
  }
  .ant-input-affix-wrapper:focus, .ant-input-affix-wrapper-focused {
    box-shadow: none;
  }
  .ant-input-group {
    border-bottom: 1px solid #DEDEDE;
  }
  .ant-input-group-addon {
    background: none;
    border: none;
  }
  .ant-input {
    font-size: 16px;
  }
`

export const WriteOffSuc = styled.div`
  text-align: center;
  .title {
    color: #2750EB;
    font-size: 14px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 16px;
    img {
      width: 68px;
      height: 68px;
      margin-bottom: 8px;
    }
  }
`
export const CardInfo = styled.div`
  width: 100%;
  border-radius: 4px;
  padding: 8px 0;
  .cardInfoList {
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    &:not(:last-child) {
      margin-bottom: 12px;
    }
    .clickToView {
      color: #2750EB;
    }
  }
  .addressInfo {
    display: flex;
    flex-direction: column;
    text-align: right;
    font-size: 12px;
    color: #333;
  }
`

export const CardInfoModalDiv = styled.div`
  width: 100%;
  height: auto;
  padding: 15px 20px 40px 20px;
  .reason {
    background: rgba(252, 95, 95, 0.1);
    padding: 10px;
    color: #FC5F5F;
    font-size: 12px;
    margin-bottom: 15px;
  }
  .ant-statistic-content {
    text-align: left;
  }
  .cardReceiptCodeQRCode {
    text-align: center;
    .code {
      font-size: 16px;
      font-weight: bold;
      text-align: center;
      height: 30px;
      color:#333333;
      margin: 6px 0;
    }
    .keyTips {
      color: #FB8026;
      font-size: 12px;
    }
  }
  .completed {
    .QRCode {
      position: relative;
      width: 128px;
      height: 128px;
      margin: 0 auto;
      .invalid {
        position: absolute;
        color: #FC5F5F;
        background: #fff;
        font-size: 14px;
        padding: 5px 10px;
        border-radius: 10px;
        top: 50px;
        left: 35px;
      }
    }
    .code {
      text-decoration: line-through;
      color: #989898;
    }
  }
`

export const ConfirmButtonDiv= styled.div`
  padding: 0 20px;
  button {
    color: rgb(255, 255, 255);
    background: rgb(39, 80, 235);
    border-color: rgb(39, 80, 235);
    width: 100%;
    margin:0;
    text-align: center;
    box-shadow: rgb(0, 144, 255) 0px 2px 0px 1px;
    padding: 10px 20px;
    border-radius: 11px;
    height: 42px;
  }
  .ant-btn-primary:hover, .ant-btn-primary:focus {
    background: rgb(39, 80, 235);
    border-color: rgb(39, 80, 235);
  }
  .ant-btn-primary[disabled], .ant-btn-primary[disabled]:hover, .ant-btn-primary[disabled]:focus, .ant-btn-primary[disabled]:active {
    opacity: 0.6;
    background: rgb(39, 80, 235);
    border-color: rgb(39, 80, 235);
    box-shadow: rgb(0, 144, 255) 0px 2px 0px 1px;
    color: #fff;
  }
`

export const CardLabel = styled.label`
  font-size: 12px;
  color: #656565;
`

export const CardInfoModalList = styled.div`
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  .agentInfo {
    .ant-typography {
      display: inline-block;
    }
  }
  .add {
    color: #2750EB;
  }
  .gray {
    color: rgb(163, 163, 163);
  }
  .alreReceivedNum {
    font-size: 12px;
    font-weight: 400;
    color: #F2601F;
  }
`

export const UserInfoDiv = styled.div`
  width: 100%;
  height: auto;
  .userInfo {
    //height: calc(100% - 80px);
    //overflow: scroll;
    padding: 20px 20px 40px 20px;
  }
  .addInfo {
    background: rgba(39,80,235,0.1);
    border-radius: 15px;
    padding: 20px 14px;
    margin-bottom: 12px;
    span {
      color: #333333;
      font-size: 15px;
      display: flex;
      align-items: center;
      img {
        width: 26px;
        margin-right: 12px;
      }
    }
    
    .title {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
   
    .Add {
      color: #627EE5;
      font-size: 12px;
    }
    .fixList {
      margin-bottom: 8px;
      .label {
        color: #656565;
        font-size: 14px;
        display: inline;
      }
      .value {
        color: #333333;
        font-size: 14px;
        display: inline;
      }
    }
  }
`

export const AddInfo = styled.div`
  height: auto;
  width: 100%;
  padding: 0 1rem;
`

export const ModalInfoScrollDiv = styled.div`
  width: 100%;
  padding: 0 22px;
  position: relative;
  height: 80vh;
  overflow: hidden;

  .ant-form {
    height: calc(100% - 160px);
    overflow: scroll;
  }

  .tips {
    background: #F7F7F7;
    padding: 10px 12px;
    border-radius: 6px;
    margin: 34px 0 10px 0;
    .title {
      font-size: 14px;
      color: #333333;
      margin-bottom: 6px;
    }
    p {
      color: #656565;
      font-size: 12px;
      margin-bottom: 0;
    }
  }
`

export const FormStyle = styled(Form)`
  .ant-input-affix-wrapper {
    border: none;
    background: #F4F5F7;
    border-radius: 10px;
  }
  .ant-form-item .ant-form-item-label {
    color: #333333;
    font-size: 14px;
  }
  .ant-input {
    background: #F4F5F7;
    border-radius: 10px;
    height: 40px;
    border: none;
  }
  .ant-form-item-has-error :not(.ant-input-disabled).ant-input, .ant-form-item-has-error :not(.ant-input-affix-wrapper-disabled).ant-input-affix-wrapper, .ant-form-item-has-error :not(.ant-input-disabled).ant-input:hover, .ant-form-item-has-error :not(.ant-input-affix-wrapper-disabled).ant-input-affix-wrapper:hover {
    background: #F4F5F7;
  }
  .ant-btn-primary {
    color: #FFFFFF;
    background: #2750EB;
     width: 100%;
    text-align: center;
    box-shadow: 0 2px 0 1px #0090FF;
    height: 42px;
    border-radius: 11px;
    margin-top: 10px;
  }
  .ant-btn-primary:hover, .ant-btn-primary:focus {
    background: #2750EB;
    border-color: #2750EB;
    box-shadow: 0 2px 0 1px #0090FF;
  }
`

export const DescriptionInfo = styled.div`
  width: 100%;
  height: 90vh;
  padding: 1rem 1rem 20px 1rem;
  .miningBonusCardExplain {
    height: calc(100% - 80px);
    overflow: scroll;
    color: #666666;
    font-size: 14px;
    line-height: 22px;
  }
`

export const CardLeaderSelectDiv =styled.div`
  width: 100%;
  height: 80vh;
  padding: 0 20px 40px 20px;
  position: relative;

  .header {
    border-bottom: 5px solid #F4F5F7;
    padding-bottom: 15px;
    
    .ant-input-group-addon {
      padding: 0;
    }
  }

  .newTabs {
    padding-top: 10px;

    .ant-tabs-tab {
      background: #F4F4F4;
      color: #676767;
      font-size: 12px;
      padding: 8px 20px;
      border-radius: 3px;
    }

    .ant-tabs-top > .ant-tabs-nav::before, .ant-tabs-bottom > .ant-tabs-nav::before, .ant-tabs-top > div > .ant-tabs-nav::before, .ant-tabs-bottom > div > .ant-tabs-nav::before {
      border-bottom: none;
    }

    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
      color: #fff;
    }

    .ant-tabs-tab-active {
      background: #2750EB;
    }

    .ant-tabs-ink-bar {
      display: none;
    }

    .ant-tabs-tab + .ant-tabs-tab {
      margin: 0 0 0 10px;
    }
  }
  
  .agentList {
    overflow: scroll;
  }

  .agentListInfo {
    padding: 13px 11px;
    background: #FFFFFF;
    box-shadow: 0 5px 10px 1px rgba(20, 72, 195, 0.05);
    border-radius: 15px;
    opacity: 1;
    border: 1px solid rgba(69, 92, 142, 0.2);

    &:not(:last-child) {
      margin-bottom: 12px;
    }

    .name, .address {
      margin-bottom: 8px;
    }
    .name, .address, .AIABlockId {
      display: flex;
      color: #727272;
      font-size: 14px;
    }

    .name {
      font-size: 14px;
      font-weight: 500;
      color: #333333;
      display: flex;
      align-items: center;

      img {
        width: 16px;
        margin-right: 5px;
      }
    }
    

    .ant-typography {
      font-size: 14px;
      color: #727272;
      font-weight: 400;
    }

    .holdOrder, .selected {
      display: none;
    }
  }

  .disabled {
    position: relative;

    .name, .address, .AIABlockId {
      color:  #999999;
      
    }

    .holdOrder {
      display: block;
      position: absolute;
      right: 0;
      top: 0;
      background: #EEEEEE;
      border-radius: 0 10px 0 8px;
      padding: 5px 10px;
      color: #727272;
      font-size: 12px;
    }
  }

  .active {
    position: relative;
    background: rgba(39, 80, 235, 0.05);
    border: 3px solid #2750EB;

    .selected {
      display: block;
      position: absolute;
      right: -1px;
      top: -1px;
      background: #2750EB;
      border-radius: 0 3px 0 8px;
      width: 27px;
      text-align: center;

      .anticon-check {
        color: #fff;
      }
    }
  }

  .confirmSelection {
    position: absolute;
    bottom: 40px;
    left: 0;
    right: 0;
    .tips {
      background: #FFF8EA;
      font-weight: 500;
      color: #F26008;
      font-size: 12px;
      margin-bottom: 10px;
      padding:  10px 20px;
    }
  }
`


export const InputInfo = styled.div`
  position: relative;

  //.ant-input-affix-wrapper {
  //  background: #F4F5F7;
  //  border: none;
  //  padding: 0 100px 0 0;
  //  border-radius: 20px;
  //}

  input.ant-input {
    background: #F4F5F7;
    border-radius: 20px;
    height: 40px;
    border: none;
    padding-left: 36px;
    padding-right: 90px;
  }

  img {
    position: absolute;
    z-index: 10;
    width: 14px;
    height: 14px;
    top: 14px;
  }

  .SearchSvg {
    left: 14px;
  }

  .SearchClose {
    right: 70px;
  }

  .search {
    position: absolute;
    right: 0;
    top: 9px;
    width: 60px;
    z-index: 10;
    border-left: 1px solid #C8C8C8;
    text-align: center;
    color: #323232;
    font-size: 13px;
    padding-left: 14px;
    padding-right: 18px;
  }
`

export const CardInfoAddAgent = styled.div`
  background: rgba(28,80,199,0.04);
  border-radius: 5px;
  color: #333333;
  font-size: 14px;
  padding: 10px;
  text-align: right;
  margin-bottom: 10px;
  line-height: 22px;
  .ant-typography {
    color: #333333;
  }
  div:not(:last-child) {
    margin-bottom: 4px;
  }
`

export const WriteOffBefore = styled.div`
  .cardInfo {
    background: #F7F7F7;
    border-radius: 6px;
    padding: 15px;
    text-align: center;
    .num {
      font-weight: bold;
      color: #333333;
      font-size: 34px;
    }
    .tips {
      font-weight: 400;
      font-size: 12px;
      color: #F24343;
      margin-top: 8px;
    }
  }
`

export const ParagraphInfo = styled(Paragraph)`
  .ant-typography {
    color: #333;
    font-size: 12px;
  }
  .ant-typography-expand, .ant-typography-edit, .ant-typography-copy {
    color: #656565;
  }
  .ant-typography-expand:focus, .ant-typography-edit:focus, .ant-typography-copy:focus, .ant-typography-expand:hover, .ant-typography-edit:hover, .ant-typography-copy:hover {
    color: #656565;
  }
`