import React,{ useContext, useState} from 'react';
import { useTranslation } from 'react-i18next'
import Modal from '../../../components/Modal';
import styled, { ThemeContext } from 'styled-components'
import { ColumnCenter } from '../../../components/Column';
import { RowBetween} from '../../../components/Row';
import {Text,InputBox,TextButton} from '../../../components/invitation/styled';
import { CloseOutlined } from '@ant-design/icons'
// import { useActiveWeb3React } from '../../../hooks'
import { useETHBalances1 } from '../../../state/wallet/hooks'
import {
  GetInvitationData,
  getLockTime,
  HandleRedeem,
  HandlePledge
} from '../../../utils/invitationCallback'
import { toBigNumber } from 'utils/bigNumber';
import { message } from 'antd';
const ColumnCenterEle = styled(ColumnCenter)`
  width: initial;
  min-width: 140px;
  margin-bottom: 10px;
`
const BalanceSpan = styled.span`
  font-weight: 600;
  word-break: break-all;
`
export default function OldPledgeRedeem(props:any){
  const { t } = useTranslation()
  const theme = useContext(ThemeContext);
  // const { account } = useActiveWeb3React()
  const [inputValue, setValue] = useState<string>('0.1')
  const ethBalance = useETHBalances1();
  // const ethBalance = useETHBalances(account ? [account] : [])?.[account ?? '']?.toSignificant(4)
  const {
    totalMortgageMount,//v1 当前质押数量
  } = GetInvitationData();
  const [useRedeem] = HandleRedeem();
  const [usePledge] = HandlePledge();
  const [status, unLocktimeStr,unLockTime]:any = getLockTime();
  // const [redeemVal, setRedeemVal] = useState<string>('')
  const close = () => {
    setValue('');
    props?.onDismiss()
  }
  const GoPledge = async() => {
    let eventFun = usePledge
    if(toBigNumber(unLockTime).isEqualTo(0)){
      return
    }
    let reg = /^(([1-9][0-9]*\.[0-9][0-9]*)|([0]\.[0-9][0-9]*)|([1-9][0-9]*)|([0]{1}))$/
    if(!reg.test(inputValue)){
      message.error(t('Please enter the correct amount'))
      return
    }
    await eventFun(inputValue)
  }

  const ConfirmRedeem  =() => {
    useRedeem(totalMortgageMount)
    close()
  }

  return (<Modal isOpen={true} onDismiss={()=>{}} data-status={status}>
    <ColumnCenterEle style={{ width: '100%' }}>
      <RowBetween style={{ padding: '1rem', marginBottom: '1rem', borderBottom: '1px solid rgba(14,37,74,0.06)' }}>
        <Text style={{ fontWeight: 600, color: theme.text7, fontSize: '16px' }}>{t('Redemption of old pledge pool assets')} </Text>
        <Text style={{ color: '#666666', fontSize: '16px' }} onClick={close}>
          <CloseOutlined />
        </Text>
      </RowBetween>
      <Text style={{padding: '0 1rem',width: '100%'}}>
        <Text style={{width: '100%',padding: '0.5rem 0 ',fontWeight: 600}}>{t(`Pledge 1 small asset`)}</Text>
        <RowBetween style={{ alignItems: 'center' }}>
          <InputBox style={{flex: 1}}
          disabled={toBigNumber(unLockTime).isEqualTo(0)}
            placeholder={toBigNumber(unLockTime).isEqualTo(0)?'--':t('Please enter your AIA Amount')}
            value={toBigNumber(unLockTime).isEqualTo(0)?'':inputValue}
            onChange={event => setValue(event.target.value)}
          ></InputBox>
          <TextButton
            className={toBigNumber(unLockTime).isEqualTo(0)?'disabled':''}
            style={{
              color: '#FFFFFF',
              background: '#2750EB',
              width: '6.875rem',
              textAlign: 'center',
              marginLeft: '10px',
              boxShadow: '0px 2px 0px 1px #0090FF',
              padding: '10px'
            }}
            onClick={GoPledge}>
            {t('Mining')}
          </TextButton>
        </RowBetween>
        <Text style={{padding: '0.5rem 0 0', textAlign: 'left',width: '100%'}}>
          {t('Wallet balance')}：<BalanceSpan>{ethBalance}</BalanceSpan>
        </Text>
        <Text style={{margin: '1rem 0', border: '0.1rem solid rgba(14, 37, 74, 0.06)',width: '100%'}}></Text>
        <Text style={{width: '100%',padding: '0.5rem 0 ',fontWeight: 600}}>{t(`Asset Redemption`)}</Text>
        <RowBetween style={{  alignItems: 'center' }}>
          <InputBox
            style={{flex: 1}}
            disabled
            placeholder={''}
            value={totalMortgageMount}
          ></InputBox>
          <TextButton
            className={!toBigNumber(unLockTime).isEqualTo(0)?'disabled':''}
            style={{
              color: '#FFFFFF',
              background: '#2750EB',
              width: '6.875rem',
              textAlign: 'center',
              marginLeft: '10px',
              boxShadow: '0px 2px 0px 1px #0090FF',
              padding: '10px'
            }}
            onClick={ConfirmRedeem}>
            {t('Redemption')}
          </TextButton>
        </RowBetween>
        {
          !toBigNumber(unLockTime).isEqualTo(0) && 
          <Text style={{padding: '0.5rem 0', color: theme.glay, fontSize: '0.75rem'}}>{t('After completing the first step, you can redeem it, or wait for 31 days at 13/51 to unlock it',{time: unLocktimeStr})}</Text>
        }
      </Text>
    </ColumnCenterEle>
  </Modal>)
}