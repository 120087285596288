import React, {useEffect, useMemo, useState} from 'react'
import {useActiveWeb3React} from '../../hooks'
import InvitationInfo from "./invitation";
import GiftCardOrderPage from './giftCardOrder';

import CardAgent from "../../assets/svg/cardAgent.svg";
import CardOrder from "../../assets/svg/cardOrder.svg";
import InvitationSvg from "../../assets/svg/invitation.svg";
import CardAgentActive from "../../assets/svg/cardAgent_active.svg";
import CardOrderActive from "../../assets/svg/cardOrder_active.svg";
import InvitationSvgActive from "../../assets/svg/invitation_active.svg";
import {useTranslation} from "react-i18next";
import {MenuEnum, OrderStateEnum, OrderTypeEnum, RoleEnum} from './types';
import {Badge} from 'antd';
import {
  MobileHeaderRowContainer,
  MobileHeaderRow,
  MobileHeaderItem,
  StyledNavDiv, PopoverInfo,
} from "./styleds";
import pubSub from "../../utils/pub-sub";
import CardCancellationPage from "./cardCancellationPage";
import {getCardOne, getMyOrder, login} from "../../services/card";
import {PageSize} from "../../constants";
import {useGetSignature} from "../../utils/giftCard";
import {generateRandomNumber} from "../../utils";
import storage from "../../utils/storage";
import {useWalletModalToggle} from "../../state/application/hooks";


export default function Invitation() {
  const toggleWalletModal = useWalletModalToggle()
  const {account, chainId} = useActiveWeb3React()
  const {t, i18n} = useTranslation()
  const i18nAny: any = i18n
  const [currentMenu, setCurrentMenu] = useState<MenuEnum>(MenuEnum.invitation)
  // 气泡卡控制
  const [openPopover, setOpenPopover] = useState(false);
  const [giftCardOrderListAll, setGiftCardOrderListAll] = useState<Record<string, any>[]>([]);
  const [showRedDot, setShowRedDot] = useState(false);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  // 当前角色
  const [role, setRole] = useState<RoleEnum>(RoleEnum.ordinary);
  // 签名事件
  const getSignatureInfo = useGetSignature();



  // 获取当前账户的订单数据
  const getGiftCardOrder = async (page: number = 1, isShow = false) => {
    setPage(page);
    if (page == 1) {
      setGiftCardOrderListAll([]);
    }
    const res = await getMyOrder({
      pageNo: page,
      pageSize: PageSize,
      orderType: OrderTypeEnum.ordinary,
    })
    const giftCardOrder: Record<string, any>[] = res.records;
    const isShowPopover = localStorage.getItem('isShowPopover') || 'false';
    // 每个地址成功质押产生第一笔银行卡订单时，弹出气泡提示。点击【我知道了】关闭气泡，后续不再显示
    res.total > 0 && setTotal(res.total);
    if (giftCardOrder.length > 0) {
      if(isShow) {
        if (isShowPopover == 'false') {
          setOpenPopover(true);
        }
        const newData = giftCardOrder[0];
        const newDataTime:any = storage.localGet('newDataTime') || {};
        if (newData.orderState == OrderStateEnum.progress && newDataTime.time != newData.createTime) {
          setShowRedDot(true)
        } else {
          setShowRedDot(false)
        }
      }
      setGiftCardOrderListAll((pre) => {
        if (page == 1) {
          return [...giftCardOrder]
        }
        return [...pre, ...giftCardOrder]
      })
    }
  }

  // 获取当前账户的角色

  const getRole = () => {
    getCardOne({
      powerAddr: account
    }).then((res) => {
      const type = res.type ?? 0;
      switch (type) {
        case RoleEnum.cardIssuingCenter:
          setRole(RoleEnum.cardIssuingCenter)
          break;
        case RoleEnum.cardCollectionAgent:
          setRole(RoleEnum.cardCollectionAgent)
          break;
        default:
          setRole(RoleEnum.ordinary)
      }
    })
  }

  const clean = () => {
    storage.localRemove('loginInfo');
  };

  // 登录
  const getLogin = async (signer: string, msg: string) => {
    const res = await login({
      signature: signer,
      userAddress: account,
      msg,
    })
    storage.localSet('loginInfo', {
      loginToken: res.loginToken,
      historyAddress: account,
      historyChainId: chainId
    })
    storage.localRemove('signer');
    getData();
    return res.loginToken
  }
  // 签名
  const getSignatureHandle = async () => {
    const signerInfo : any = storage.localGet('signer');
    if(signerInfo?.account?.toLocaleString() == account?.toLocaleString() && signerInfo?.signer) {
      return await getLogin(signerInfo?.signer, signerInfo?.msg);
    }
    const nonce = generateRandomNumber();
    const msg = `Please sign to let us verify that you are the owner of this address:${account},noce:${nonce}`
    try {
      const signer = await getSignatureInfo(msg);
      if (signer) {
        storage.localSet('signer', {
          account: account as string,
          signer: signer as string,
          msg: msg as string,
        })
        return await getLogin(signer, msg);
      }
    } catch (e) {
      console.log(e, '签名失败')
      clean();
      return false;
    }
  }

  // 获取数据
  const getData = () => {
    getRole()
    getGiftCardOrder(1, true).then();
    pubSub.on('stakeSucHandle', () => {
      getGiftCardOrder(1, true).then();
    })
  }

  useEffect(() => {
    if (account) {
      const loginInfo: any = storage.localGet('loginInfo');
      if (!loginInfo?.loginToken || account.toLocaleLowerCase() != loginInfo?.historyAddress?.toLocaleLowerCase()) {
        getSignatureHandle();
      } else {
        getData();
      }
      const newDataTime:any = storage.localGet('newDataTime') || {};
      if(newDataTime?.account?.toLocaleLowerCase() != account?.toLocaleLowerCase()) {
        storage.localRemove('newDataTime');
      }
    }
    return () => {
      clean();
      setCurrentMenu(MenuEnum.invitation)
      setOpenPopover(false)
      localStorage.setItem('newDataTime', '')
      setShowRedDot(false)
      setRole(RoleEnum.ordinary)
      setGiftCardOrderListAll([]);
      setTotal(0);
      setPage(1);
      pubSub.off('stakeSucHandle')
    }
  }, [account])

  const changeMenu = (menu: MenuEnum) => {
    setCurrentMenu(menu)
  }


  const hidePopover = () => {
    localStorage.setItem('isShowPopover', 'true');
    setOpenPopover(false);
  };

  const menuClickHandle = (menu: MenuEnum) => {
    if(!account) {
      toggleWalletModal();
    }
    const loginInfo: any = storage.localGet('loginInfo');
    if (loginInfo?.loginToken) {
      changeMenu(menu)
    } else {
      getSignatureHandle().then((res) => {
        if(res) {
          changeMenu(menu)
        }
      })
    }
  }

  const language = useMemo(() => {
    return i18nAny.language == 'turkey' ? 'tr' : i18nAny.language;
  }, [i18nAny.language])

  return (
    <div style={{display: 'block', maxWidth: '400px', position: 'relative', paddingBottom: '100px', width: '100%'}}>
      {
        currentMenu === MenuEnum.invitation && <InvitationInfo/>
      }
      {
        currentMenu === MenuEnum.card &&
        <GiftCardOrderPage total={total} page={page} getGiftCardOrder={getGiftCardOrder} list={giftCardOrderListAll}/>
      }
      {
        (currentMenu === MenuEnum.cardIssuingCenter || currentMenu === MenuEnum.cardCollectionAgent) &&
        <CardCancellationPage role={role}/>
      }
      <MobileHeaderRowContainer>
        <MobileHeaderRow>
          <MobileHeaderItem>
            <StyledNavDiv className={currentMenu === MenuEnum.invitation ? 'activeClassName' : ''} onClick={() => {
              menuClickHandle(MenuEnum.invitation)
            }}>
              <img width={'28px'} src={currentMenu === MenuEnum.invitation ? InvitationSvgActive : InvitationSvg}
                   alt="invitationMining"/>
              <span className='title'>{t('invitationMining')}</span>
            </StyledNavDiv>
          </MobileHeaderItem>
          <MobileHeaderItem style={{position: "relative"}}>
            {
              openPopover && <PopoverInfo style={{top: (language =='tr' ||language =='en' || language =='jp') ? '-250%' :  '-220%' }}>
                <div className='popoverContent'>{t('miningBonusCardTips')}</div>
                <div className='ISee'>
                  <span onClick={hidePopover}>{t('ISee')}</span>
                </div>
                <div className="angle"/>
              </PopoverInfo>
            }

            <StyledNavDiv
              className={
                `${currentMenu === MenuEnum.card ? 'activeClassName' : ''}`
              }
              onClick={() => {
                openPopover && hidePopover()
                setShowRedDot(false)
                storage.localSet('newDataTime', {
                  account: account,
                  time: giftCardOrderListAll[0]?.createTime
                })
                menuClickHandle(MenuEnum.card)
              }}>
              <Badge dot={showRedDot}>
                <img width={'28px'} src={currentMenu === MenuEnum.card ? CardOrderActive : CardOrder}
                     alt="miningBonusCard"/>
              </Badge>
              <span className='title'>{t('miningBonusCard')}</span>
            </StyledNavDiv>
          </MobileHeaderItem>

          {
            role === RoleEnum.cardIssuingCenter && <MobileHeaderItem>
              <StyledNavDiv className={currentMenu === MenuEnum.cardIssuingCenter ? 'activeClassName' : ''}
                            onClick={() => {
                              changeMenu(MenuEnum.cardIssuingCenter)
                            }}>
                <img width={'28px'} src={currentMenu === MenuEnum.cardIssuingCenter ? CardAgentActive : CardAgent}
                     alt="cardIssuingCenter"/>
                <span className='title'>{t('cardIssuingCenter')}</span>
              </StyledNavDiv>
            </MobileHeaderItem>
          }
          {
            role === RoleEnum.cardCollectionAgent && <MobileHeaderItem>
              <StyledNavDiv className={currentMenu === MenuEnum.cardCollectionAgent ? 'activeClassName' : ''}
                            onClick={() => {
                              changeMenu(MenuEnum.cardCollectionAgent)
                            }}>
                <img width={'28px'} src={currentMenu === MenuEnum.cardCollectionAgent ? CardAgentActive : CardAgent}
                     alt="cardCollectionAgent"/>
                <span className='title'>{t('cardCollectionAgent')}</span>
              </StyledNavDiv>
            </MobileHeaderItem>
          }
        </MobileHeaderRow>
      </MobileHeaderRowContainer>
    </div>
  );
}
