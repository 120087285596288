import { Currency, CurrencyAmount, Price, Token, TokenAmount } from '@uniswap/sdk'
import { useActiveWeb3React } from 'hooks'
import { Order } from 'hooks/limitOrders/useGelatoLimitOrdersHandlers'
import { useCurrency } from 'hooks/Tokens'
import { useMemo } from 'react'
import { getPriceForOneToken } from 'state/limitOrders/hooks'
import { NETWORK_CHAIN_ID } from 'state/stake/hooks'
import { useIsTransactionPending } from 'state/transactions/hooks'
import { getEtherscanLink } from 'utils'
import useGelatoLimitOrdersLib from '../constants/useGelatoLimitOrdersLib'

export interface FormattedOrderData {
    inputToken: Currency | Token | any
    outputToken: Currency | Token | any
    inputAmount: string | undefined
    outputAmount: string | undefined
    executionPrice: string | undefined
    invertedExecutionPrice: string | undefined
    isOpen: boolean
    isCancelled: boolean
    isExecuted: boolean
    isDealing: boolean
    isSubmissionPending: boolean
    isCancellationPending: boolean
    isPengding: boolean
    isCancelling: boolean
    bscScanUrls: {
        created: string | null
        executed: string | null
        cancelled: string | null
    }
}
enum LimitOrderStatus {
    OPEN = 'open',
    CANCELLED = 'cancelled',
    EXECUTED = 'executed',
    DEALING = 'dealing',
    PENGDING='pengding',
    CANCELLING = 'cancelling'
}
const formatForDisplay = (amount: CurrencyAmount | Price | undefined) => {
    if (!amount) {
        return undefined
    }
    return parseFloat(amount.toSignificant(18)).toLocaleString(undefined, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 8,
    })
}

const useFormattedOrderData = (order: Order): FormattedOrderData => {
    const { chainId } = useActiveWeb3React()
    const gelatoLibrary = useGelatoLimitOrdersLib()
    const getOriginToken = (token: string | undefined) => {
        const e = "0x00eeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee"
        const e1 = "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee"
        return token?.toLowerCase() === e || token?.toLowerCase() === e1 ? 'AIA' : token
    }
    const inputToken = useCurrency(getOriginToken(order?.inputToken))
    const outputToken = useCurrency(getOriginToken(order?.outputToken))
    const isSubmissionPending = useIsTransactionPending(order?.createdTxHash)
    const isCancellationPending = useIsTransactionPending(order?.cancelledTxHash ?? undefined)

    const inputAmount = useMemo(() => {
        if (inputToken && order?.inputAmount) {
            if (inputToken instanceof Token) {
                return new TokenAmount(inputToken, order?.inputAmount)
            }
            return CurrencyAmount.ether(order?.inputAmount)
        }
        return undefined
    }, [inputToken, order?.inputAmount])

    const rawMinReturn = useMemo(
        () =>
            order?.adjustedMinReturn ? order?.adjustedMinReturn : gelatoLibrary && chainId && order?.minReturn ? gelatoLibrary.getAdjustedMinReturn(order?.minReturn)
                    : undefined,
        [chainId, gelatoLibrary, order?.adjustedMinReturn, order?.minReturn],
    )
    const outputAmount = useMemo(() => {
        if (outputToken && rawMinReturn) {

            if (outputToken instanceof Token) {
                return new TokenAmount(outputToken, rawMinReturn)
            }
            return CurrencyAmount.ether(rawMinReturn)
        }
        return undefined
    }, [outputToken, rawMinReturn])

    const executionPrice = useMemo(() => getPriceForOneToken(inputAmount, outputAmount), [inputAmount, outputAmount])

    return {
        inputToken,
        outputToken,
        inputAmount: formatForDisplay(inputAmount),
        outputAmount: formatForDisplay(outputAmount),
        executionPrice: formatForDisplay(executionPrice),
        invertedExecutionPrice: formatForDisplay(executionPrice?.invert()),
        isOpen: order?.status === LimitOrderStatus.OPEN,
        isCancelled: order?.status === LimitOrderStatus.CANCELLED,
        isExecuted: order?.status === LimitOrderStatus.EXECUTED,
        isDealing:order?.status === LimitOrderStatus.DEALING,
        isPengding:order?.status === LimitOrderStatus.PENGDING,
        isCancelling:order?.status === LimitOrderStatus.CANCELLING,
        isSubmissionPending,
        isCancellationPending,
        bscScanUrls: {
            created: order?.createdTxHash ? getEtherscanLink(chainId || NETWORK_CHAIN_ID, order.createdTxHash, 'transaction') : null,
            executed: order?.executedTxHash ? getEtherscanLink(chainId || NETWORK_CHAIN_ID, order.executedTxHash, 'transaction') : null,
            cancelled: order?.cancelledTxHash ? getEtherscanLink(chainId || NETWORK_CHAIN_ID, order.cancelledTxHash, 'transaction') : null,
        },
    }
}

export default useFormattedOrderData