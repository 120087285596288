/**
 * @name: types
 * @author: mahuanhuan
 * @date: 2024/1/8
 * @description：
 * @update: 2024/1/8
 */

export enum MenuEnum {
  invitation = 0, // 邀请挖矿
  card = 1, // 赠卡
  cardIssuingCenter = 2, // 发卡中心
  cardCollectionAgent = 3 // 领卡代理
}

export enum RoleEnum {
  ordinary = 0, // 普通角色
  cardIssuingCenter = 1,  // 发卡中心
  cardCollectionAgent = 2  // 领卡代理
}


export enum OrderStateEnum {
  progress, //进行中
  completed, // 已完成
  cancelled // 已取消
}

export enum OrderTypeEnum {
  ordinary, //普通订单
  cardCollectionAgent, // 领卡队长
  cardIssuingCenter // 发卡中心
}
// 发卡状态
export enum SendStateEnum {
  cardNotIssued, //未发卡
  cardHasBeenIssued, // 已发卡
  noCardIssued, // 不发卡
  partiallyIssuedCards // 部分发卡
}