import React, { useState } from 'react'
import { memo } from 'react'
import { useTranslation } from 'react-i18next'
import { Text } from 'rebass'
import FullRow from './FullRow'
import styled from "styled-components"
import { Table, Th } from './LoadingTable'
import DetailLimitOrderModal from '../Modal'


const SpaciousLimitOrderTable: React.FC<{ orders?: any; }> = ({ orders }) => {
    const { t } = useTranslation()
    const [isOpen, setIsopen] = useState(false)
    const [cuurentOrder, setCurrentOrder] = useState<any>(undefined)
    const onDismiss = () => setIsopen(false)
    const showClickModalFn = (order: any) => {
        setIsopen(true)
        setCurrentOrder(order)
    }
    
    return (
        <>
            {
               isOpen&&<DetailLimitOrderModal
                    order={cuurentOrder}
                    isOpen={isOpen}
                    onDismiss={onDismiss} />
            }
            <Table>
                <>
                    <thead>
                        <tr>
                            <Th>
                                <Texts fontSize="12px" textAlign="left">
                                    {t('From')}
                                </Texts>
                            </Th>
                            <Th>
                                <Texts fontSize="12px" textAlign="left">
                                    {t('To')}
                                </Texts>
                            </Th>
                            <Th>
                                <Texts fontSize="12px" textAlign="left">
                                    {t('Limit Price')}
                                </Texts>
                            </Th>
                            <Th>
                                <Texts fontSize="12px" textAlign="left">
                                    {t('Status')}
                                </Texts>
                            </Th>
                            <Th>
                                <Texts fontSize="12px" textAlign="left">
                                    {t('Time')}
                                </Texts>
                            </Th>
                            <Th />
                        </tr>
                    </thead>
                    <tbody>
                        {orders.map((order: any) => (
                            <FullRow key={order.id} order={order} showClickModal={() => showClickModalFn(order)} />
                        ))}
                    </tbody>
                </>
            </Table>
        </>
    )
}

const Texts = styled(Text)`
    text-transform: uppercase;
    font-weight:400;
    color:#929FB5;
`

export default memo(SpaciousLimitOrderTable)
