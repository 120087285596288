import { createReducer } from '@reduxjs/toolkit'
import { REACT_APP_AITDSWAP_USDT_ADDRESS } from '../../constants'

import {
    replaceLimitOrdersState, selectCurrency, setRateType,
    setOrderList, setCancel, setHistoryTotalData,
    setRecipient, setTotalData, switchCurrencies, typeInput
} from './actions'
import { Field, OrderState, Rate } from './types'

export const initialState: OrderState = {
    independentField: Field.INPUT,
    basisField: Field.INPUT,
    typedValue: '',
    inputValue: '',
    outputValue: '',
    [Field.INPUT]: {
        currencyId: 'AIA',
    },
    [Field.OUTPUT]: {
        currencyId: REACT_APP_AITDSWAP_USDT_ADDRESS,
    },
    rateType: Rate.MUL,
    orderList: [],
    cancel: null,
    totalOrderList: [],
    historyTotalData: []
}



export default createReducer<OrderState>(initialState, builder =>
    builder
        .addCase(
            replaceLimitOrdersState,
            (state, { payload }) => {
                return payload
            }
        ).addCase(selectCurrency, (state: any, { payload: { currencyId, field } }) => {
            const otherField = field === Field.INPUT ? Field.OUTPUT : Field.INPUT
            if (field === Field.PRICE) {
                return {
                    ...state,
                }
            }
            if (currencyId === state[otherField].currencyId) {
                // the case where we have to swap the order
                return {
                    ...state,
                    independentField: state.independentField === Field.INPUT ? Field.OUTPUT : Field.INPUT,
                    [field]: { currencyId },
                    [otherField]: { currencyId: state[field].currencyId }
                }
            } else {
                // the normal case
                return {
                    ...state,
                    typedValue: state.basisField === Field.INPUT ? state.inputValue : state.outputValue,
                    independentField: state.basisField,
                    [field]: { currencyId }
                }
            }
        })
        .addCase(switchCurrencies, (state: any) => {

            return {
                ...state,
                typedValue: state.inputValue,
                independentField: Field.INPUT,
                basisField: Field.INPUT,
                rateType: state.rateType,
                [Field.INPUT]: { currencyId: state[Field.OUTPUT].currencyId },
                [Field.OUTPUT]: { currencyId: state[Field.INPUT].currencyId }
            }
        })
        .addCase(typeInput, (state, { payload: { field, typedValue } }) => {
            return field === Field.INPUT
                ? {
                    ...state,
                    inputValue: typedValue,
                    independentField: field,
                    basisField: field,
                    typedValue,
                }
                : {
                    ...state,
                    independentField: field,
                    basisField: field !== Field.PRICE ? field : state.basisField,
                    outputValue: field !== Field.PRICE ? typedValue : state.outputValue,
                    typedValue,
                }
        })
        .addCase(setRateType, (state, { payload: { rateType } }) => {
            state.rateType = rateType
        })
        .addCase(setRecipient, (state, { payload: { recipient } }) => {
            state.recipient = recipient
        })
        .addCase(setOrderList, (state, { payload: { orderList } }) => {
            state.orderList = orderList
        }).addCase(setCancel, (state, { payload: { cancel } }) => {
            state.cancel = cancel
        })
        .addCase(setTotalData, (state, { payload: { totalOrderList } }) => {
            state.totalOrderList = totalOrderList
        })
        .addCase(setHistoryTotalData, (state, { payload: { historyTotalData } }) => {
            state.historyTotalData = historyTotalData
        }),
)
