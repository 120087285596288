import { createReducer } from '@reduxjs/toolkit'
import { Order } from 'hooks/limitOrders/useGelatoLimitOrdersHandlers'
import { saveOrder } from 'pages/LimitOrders/utils/localStorageOrders'
import {
  addTransaction,
  checkedTransaction,
  clearAllTransactions,
  finalizeTransaction,
  SerializableTransactionReceipt,
  TransactionType,
  updateTransaction
} from './actions'

const now = () => new Date().getTime()

export interface TransactionDetails {
  hash: string
  approval?: { tokenAddress: string; spender: string }
  summary?: string
  claim?: { recipient: string }
  receipt?: SerializableTransactionReceipt
  lastCheckedBlockNumber?: number
  addedTime: number
  confirmedTime?: number
  from: string
  type?: TransactionType
  order?: Order
  initHash?: string | undefined
  orderstatus?: string
}

export interface TransactionState {
  [chainId: number]: {
    [txHash: string]: TransactionDetails
  }
}

export const initialState: TransactionState = {}

export default createReducer(initialState, builder =>
  builder
    .addCase(addTransaction, (transactions, { payload: { chainId, from, hash, approval, summary, claim, type, order } }) => {
      if (transactions[chainId]?.[hash]) {
        throw Error('Attempted to add existing transaction.')
      }
      const txs = transactions[chainId] ?? {}
      txs[hash] = { hash, approval, summary, claim, from, addedTime: now(), type, order }
      transactions[chainId] = txs
      if (order) saveOrder(chainId, from, order, true)
    })
    .addCase(clearAllTransactions, (transactions, { payload: { chainId } }) => {
      if (!transactions[chainId]) return
      transactions[chainId] = {}
    })
    .addCase(checkedTransaction, (transactions, { payload: { chainId, hash, blockNumber } }) => {
      const tx = transactions[chainId]?.[hash]
      if (!tx) {
        return
      }
      if (!tx.lastCheckedBlockNumber) {
        tx.lastCheckedBlockNumber = blockNumber
      } else {
        tx.lastCheckedBlockNumber = Math.max(blockNumber, tx.lastCheckedBlockNumber)
      }
    })
    .addCase(finalizeTransaction, (transactions, { payload: { hash, chainId, receipt } }) => {
      const tx = transactions[chainId]?.[hash]
      if (!tx) {
        return
      }
      tx.receipt = receipt;
      tx.confirmedTime = now();
    })


    .addCase(updateTransaction, (transactions, { payload: { chainId, hash } }) => {
      if (!transactions[chainId]?.[hash]) {
        return
      }
      const txs = transactions[chainId] ?? {}

      const orderOld = txs[hash]

      if (orderOld.order&&orderOld?.order.status==='cancelled') {
        const updateHash = txs[orderOld.order.createdTxHash]
        if (updateHash&&updateHash.order&&updateHash.order.status) {
          updateHash.order.status = "cancelled"
          txs[orderOld.order.createdTxHash] = updateHash
        }
      }
     
      transactions[chainId] = txs
    })
)
