import { createReducer } from '@reduxjs/toolkit'
import { setLatestBlock, setPariListStory } from './actions'

export interface BlockState {
    latestBlock: number | null
    pariListStory: any[] | null
}
const initialState: BlockState = {
    latestBlock: null,
    pariListStory: null
}
export default createReducer<BlockState>(initialState, builder =>
    builder
        .addCase(setLatestBlock, (state, { payload: { latestBlock } }) => {
            state.latestBlock = latestBlock
        }).addCase(setPariListStory, (state, { payload: { pariListStory } }) => {
            state.pariListStory = pariListStory
        })
    )