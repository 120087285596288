import { Currency, Percent, Price } from '@uniswap/sdk'
import React, { useContext } from 'react'
import { Text } from 'rebass'
import { useTranslation } from 'react-i18next'
import { ThemeContext } from 'styled-components'
import { AutoColumn } from '../../components/Column'
import { ONE_BIPS } from '../../constants'
import { Field } from '../../state/mint/actions'
import { TYPE } from '../../theme'
import { RowBetween } from 'components/Row'

export function PoolPriceBarNew({
    currencies,
    noLiquidity,
    poolTokenPercentage,
    price
}: {
    currencies: { [field in Field]?: Currency }
    noLiquidity?: boolean
    poolTokenPercentage?: Percent
    price?: Price
}) {
    const theme = useContext(ThemeContext)
    const { t } = useTranslation()
    return (
        <AutoColumn gap="md" style={{padding:'0.6rem 0 1rem'}}>
            <RowBetween>
                <Text fontWeight={600} fontSize={14} color={theme.text7} pt={1}>
                    {currencies[Field.CURRENCY_B]?.symbol} per {currencies[Field.CURRENCY_A]?.symbol}
                </Text>
                <TYPE.black fontWeight={600} color={theme.text7}>{price?.toSignificant(6) ?? '-'}</TYPE.black>
            </RowBetween>
            <RowBetween >
                <Text fontWeight={600} fontSize={14} color={theme.text7} pt={1}>
                    {currencies[Field.CURRENCY_A]?.symbol} per {currencies[Field.CURRENCY_B]?.symbol}
                </Text>
                <TYPE.black fontWeight={600} color={theme.text7}>{price?.invert()?.toSignificant(6) ?? '-'}</TYPE.black>
            </RowBetween>
            <RowBetween>
                <Text fontWeight={500} fontSize={14} color={theme.text7} pt={1}>
                    {t('Share of Pool')}
                </Text>
                <TYPE.black fontWeight={600} color={theme.text7}>
                    {noLiquidity && price
                        ? '100'
                        : (poolTokenPercentage?.lessThan(ONE_BIPS) ? '<0.01' : poolTokenPercentage?.toFixed(2)) ?? '0'}
                    %
                </TYPE.black>
            </RowBetween>

        </AutoColumn>
    )
}
