import { Price, Percent } from "@uniswap/sdk"


const getRatePercentageDifference = (currentMarketRate: Price | undefined, price: Price | undefined) => {
  if (currentMarketRate && price) {
    const percentageAsFraction = price.subtract(currentMarketRate).divide(currentMarketRate)
    return new Percent(percentageAsFraction.numerator, percentageAsFraction.denominator)
  }
  return undefined
}

export default getRatePercentageDifference
