import { Contract } from '@ethersproject/contracts'
import { getAddress } from '@ethersproject/address'
import { AddressZero } from '@ethersproject/constants'
import { JsonRpcSigner, Web3Provider } from '@ethersproject/providers'
import { BigNumber } from '@ethersproject/bignumber'
import { abi as IUniswapV2Router02ABI } from '@uniswap/v2-periphery/build/IUniswapV2Router02.json'
import { ROUTER_ADDRESS } from '../constants'
import { ChainId, JSBI, Percent, Token, CurrencyAmount, Currency, ETHER } from '@uniswap/sdk'
import { TokenAddressMap } from '../state/lists/hooks'
const NETWORK_CHAIN_ID: ChainId = parseInt(process.env.REACT_APP_CHAIN_ID ?? '1')

// returns the checksummed address if the address is valid, otherwise returns false
export const REACT_APP_APY_URL = process.env.REACT_APP_APY_URL || '';
export const REACT_APP_POOL_URL = process.env.REACT_APP_POOL_URL || '';
export const REACT_APP_INFO = process.env.REACT_APP_INFO || '';
export function isAddress(value: any): string | false {
  try {
    return getAddress(value)
  } catch {
    return false
  }
}

// const ETHERSCAN_PREFIXES: { [chainId in ChainId]: string } = {
//   1: '',
//   3: 'ropsten.',
//   4: 'rinkeby.',
//   5: 'goerli.',
//   42: 'kovan.',
//   1320: 'aitd.',
//   1319: 'aitd.'
// }

const NETWORK_URL = process.env.REACT_APP_AITDSCAN || ''

export function getEtherscanLink(
  chainId: ChainId,
  data: string,
  type: 'transaction' | 'token' | 'address' | 'block'
): string {
  // let prefix = `https://${ETHERSCAN_PREFIXES[chainId] || ETHERSCAN_PREFIXES[1]}etherscan.io`
  let prefix = 'https://testnet.aiascan.com'
  if (chainId === NETWORK_CHAIN_ID) {
    prefix = NETWORK_URL
  }
  // const path = '?path='
  const routerPageSwitch = (types: string) => {
    // if (1320 === NETWORK_CHAIN_ID) {
    //   // prefix = 'https://testnet.aiascan.com/ '
    //   isencode = true
    // }
    // return isencode ? `${prefix}/${types}/${data}` : `${prefix}${path}${encodeURIComponent(`/${types}/${data}`)}`
    return `${prefix}/${types}/${data}`
  }

  switch (type) {
    case 'transaction': {
      return routerPageSwitch('tx')
    }
    case 'token': {
      return routerPageSwitch('token')
    }
    case 'block': {
      return routerPageSwitch('block')
    }
    case 'address':
    default: {
      return routerPageSwitch('address')
    }
  }
}

// shorten the checksummed version of the input address to have 0x + 4 characters at start and end
export function shortenAddress(address: string, chars = 4): string {
  const parsed = isAddress(address)
  if (!parsed) {
    throw Error(`Invalid 'address' parameter '${address}'.`)
  }
  return `${parsed.substring(0, chars + 2)}...${parsed.substring(42 - chars)}`
}

// add 10%
export function calculateGasMargin(value: BigNumber): BigNumber {
  return value.mul(BigNumber.from(10000).add(BigNumber.from(6000))).div(BigNumber.from(10000))
}

// converts a basis points value to a sdk percent
export function basisPointsToPercent(num: number): Percent {
  return new Percent(JSBI.BigInt(num), JSBI.BigInt(10000))
}

export function calculateSlippageAmount(value: CurrencyAmount, slippage: number): [JSBI, JSBI] {
  if (slippage < 0 || slippage > 10000) {
    throw Error(`Unexpected slippage value: ${slippage}`)
  }
  return [
    JSBI.divide(JSBI.multiply(value.raw, JSBI.BigInt(10000 - slippage)), JSBI.BigInt(10000)),
    JSBI.divide(JSBI.multiply(value.raw, JSBI.BigInt(10000 + slippage)), JSBI.BigInt(10000))
  ]
}

// account is not optional
export function getSigner(library: Web3Provider, account: string): JsonRpcSigner {
  return library.getSigner(account).connectUnchecked()
}

// account is optional
export function getProviderOrSigner(library: Web3Provider, account?: string): Web3Provider | JsonRpcSigner {
  return account ? getSigner(library, account) : library
}

// account is optional
export function getContract(address: string, ABI: any, library: Web3Provider, account?: string): Contract {
  if (!isAddress(address) || address === AddressZero) {
    throw Error(`Invalid 'address' parameter '${address}'.`)
  }

  return new Contract(address, ABI, getProviderOrSigner(library, account) as any)
}

// account is optional
export function getRouterContract(_: number, library: Web3Provider, account?: string): Contract {
  return getContract(ROUTER_ADDRESS, IUniswapV2Router02ABI, library, account)
}

export function escapeRegExp(string: string): string {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') // $& means the whole matched string
}

export function isTokenOnList(defaultTokens: TokenAddressMap, currency?: Currency): boolean {
  if (currency === ETHER) return true
  return Boolean(currency instanceof Token && defaultTokens[currency.chainId]?.[currency.address])
}


export const stringLengthChange = (
  info: string,
  frontNum = 6,
  lastNum = 4,
) => {
  if (!info) return '';
  const length = info.length;
  return `${info.substring(0, frontNum)}...${info.substring(
    length - lastNum,
    length,
  )}`;
};

// 生成一个18位的随机数
export function generateRandomNumber() {
  let randomNumber = '';
  const digits = '0123456789';

  for (let i = 0; i < 18; i++) {
    const randomIndex = Math.floor(Math.random() * digits.length);
    randomNumber += digits[randomIndex];
  }

  return randomNumber;
}