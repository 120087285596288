import React
, { useEffect }   // { useContext }
    from 'react'
import Card from 'components/Card'
import { useState, useCallback, memo } from 'react'
import { Flex } from 'rebass'
import { ORDER_CATEGORY } from '../../types'
import OrderTab from './OrderTab'
import Navigation from './TableNavigation'
import SpaciousLimitOrderTable from './SpaciousLimitOrderTable'
import useGelatoLimitOrdersHistory from 'pages/LimitOrders/hooks/useGelatoLimitOrdersHistory'
import CompactLimitOrderTable from './CompactLimitOrderTable'
import { useTotalAwiat } from 'pages/LimitOrders/utils/getHttpDatas'
import { useActiveWeb3React } from 'hooks'


const OrderTable: React.FC<{
    isPhoneSize?: boolean;
    orderCategory: ORDER_CATEGORY,
    page: number,
    pageFn?: Function
}> = memo(
    ({ orderCategory, isPhoneSize,pageFn,page }) => {
        const total = useTotalAwiat(orderCategory,page)
        const orders = useGelatoLimitOrdersHistory(orderCategory,page)

        return (
            <Navigation data={orders} total={total} orderCategory={orderCategory} onPage={pageFn}>
                {
                    ({ paginatedData }) => isPhoneSize ? <CompactLimitOrderTable orders={paginatedData} /> : <SpaciousLimitOrderTable orders={paginatedData} />
                }
            </Navigation>
        )
    },
)

const LimitOrderTable: React.FC<{ isPhoneSize?: boolean, hasChart?: boolean }> = ({ hasChart, isPhoneSize }) => {
    const { account, chainId } = useActiveWeb3React()
    const [activeTab, setIndex] = useState<ORDER_CATEGORY>(ORDER_CATEGORY.Open)
    const [getPage, setPage] = useState(1)
    const handleClick = useCallback((tabType: ORDER_CATEGORY) => {
        setPage(1)
        setIndex(tabType)
    }, [])
    useEffect(()=>{
        setPage(1)
    },[account, chainId])

    return (
        <Flex
            flex="1"
            justifyContent="center"
            pb={isPhoneSize ? '12px' : '24px'}
            mt={hasChart ? '0' : '24px'}
            style={{ backgroundColor: '#ffffff', borderRadius: '16px' }}>
            <Card style={{ width: '100%', height: 'max-content', padding: '0', backgroundColor: '#ffffff' }}>
                <OrderTab onItemClick={handleClick} activeIndex={activeTab} />
                <OrderTable pageFn={setPage} page={getPage} orderCategory={activeTab} isPhoneSize={isPhoneSize} />
            </Card>
        </Flex>
    )
}

export default memo(LimitOrderTable)