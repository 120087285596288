import { Order } from "hooks/limitOrders/useGelatoLimitOrdersHandlers"

export enum ORDER_CATEGORY {
    Open = 0,
    History = 1,
  }
  
  export enum LimitOrderStatus {
    OPEN = 'open',
    CANCELLED = 'cancelled',
    EXECUTED = 'executed',
    DEALING = 'dealing',
    PENGDING = 'pengding'
  }
  
  export interface LimitOrderTableProps {
    orders: Order[]
    orderCategory: ORDER_CATEGORY
    isCompact: boolean
  }