/**
 * @name: cardInfoModal
 * @author: mahuanhuan
 * @date: 2024/1/10
 * @description：领卡信息
 * @update: 2024/1/10
 */
import React, {useContext, useEffect, useMemo, useState} from 'react';
import Modal from '../../../components/Modal';
import {CardInfoAddAgent, CardInfoModalDiv, CardInfoModalList, CardLabel, ConfirmButtonDiv, ParagraphInfo} from "../styleds";
import {Text} from "../../../components/invitation/styled";
import {CloseOutlined} from "@ant-design/icons";
import {RowBetween} from "../../../components/Row";
import {useTranslation} from "react-i18next";
import {ThemeContext} from "styled-components";
import {Button} from 'antd';
import QRCode from 'qrcode.react';
import {useActiveWeb3React} from "../../../hooks";
import {getCardOne} from "../../../services/card";
import AddMyInfoModal from "./addMyInfoModal";
import CardLeaderSelect from "./cardLeaderSelect";
import {dividedBy} from "../../../utils/bigNumber";
import {PayPostage, useAddressInfo, useGetTransInfo} from "../../../utils/giftCard";
import pubSub from "../../../utils/pub-sub";
import {OrderStateEnum, OrderTypeEnum} from "../types";

const CardInfoModal = ({isOpen, setOpen, currentCardInfo, getGiftCardOrder}: {
  isOpen: boolean;
  setOpen: Function;
  currentCardInfo: Record<string, any>;
  getGiftCardOrder: Function;
}) => {
  const {t, i18n} = useTranslation();
  const i18nAny: any = i18n
  const {account} = useActiveWeb3React()
  const theme = useContext(ThemeContext)
  const [cardLeaderInfo, setCardLeaderInfo] = useState<Record<string, any>>({});
  const [isOpenAddInfo, setIsOpenAddInfo] = useState(false);
  const [isShowAgentInfo, setIsShowAgentInfo] = useState(false);
  const {addressInfo, getAccountAddressInfo} = useAddressInfo();
  const {loading, goPay, SetLoading} = PayPostage(currentCardInfo);
  const {getTransInfo} = useGetTransInfo();
  const [isPay, setIsPay] = useState(false);

  const language = useMemo(() => {
    return i18nAny.language == 'turkey' ? 'tr' : i18nAny.language;
  }, [i18nAny.language])

  useEffect(() => {
    if (currentCardInfo?.id && currentCardInfo?.shippingAddress == null) {
      getAccountAddressInfo(account as string)
    }
  }, [currentCardInfo])


  const close = () => {
    setOpen(false)
  }

  useEffect(() => {
    return () => {
      setCardLeaderInfo({});
      pubSub.off('updateAgent')
      pubSub.off('getMyInfo')
    }
  }, [isOpen])
  // 获取领卡队长
  const getCardOneInfo = () => {
    getCardOne({
      id: currentCardInfo.orderType == OrderTypeEnum.ordinary ? currentCardInfo.getCardId : currentCardInfo.cardCoreId,
    }).then((res) => {
      setCardLeaderInfo(res);
    })
  }
  useEffect(() => {
    if (isOpen && currentCardInfo.orderType != OrderTypeEnum.cardIssuingCenter) {
      currentCardInfo.orderType == OrderTypeEnum.ordinary && currentCardInfo.getCardId && getCardOneInfo();
      currentCardInfo.orderType == OrderTypeEnum.cardCollectionAgent && currentCardInfo.cardCoreId && getCardOneInfo();
    }
    if (isOpen && currentCardInfo.orderType == OrderTypeEnum.ordinary && currentCardInfo.pledgeState == 0) {
      getTransInfoHandle();
    }
  }, [currentCardInfo, isOpen])

  // 查询挖矿赠卡信息

  const getTransInfoHandle = async () => {
    const res = await getTransInfo(currentCardInfo.transId)
    const payTime = res.payTime.toNumber();
    setIsPay(payTime > 0);
  }

  const confirmHandle = async () => {
    const res = await goPay()
    if (res) {
      setTimeout(() => {
        getGiftCardOrder();
        // setOpen(false)
        SetLoading(false)
      }, 18000)
    }
  }


  return (
    <Modal isOpen={isOpen} onDismiss={close}>
      <CardInfoModalDiv>
        <RowBetween style={{padding: '1rem 0', marginBottom: '1rem', borderBottom: '1px solid rgba(14,37,74,0.06)'}}>
          <Text style={{fontWeight: 600, color: theme.text7, fontSize: '16px'}}>
            {t('cardRequisitionInformation')}
          </Text>
          <Text style={{color: '#666666', fontSize: '16px'}} onClick={close}>
            <CloseOutlined/>
          </Text>
        </RowBetween>
        {
          currentCardInfo.orderState == OrderStateEnum.cancelled && <div className='reason'>
            {currentCardInfo.desc[language]}
          </div>
        }
        <CardInfoModalList>
          <CardLabel style={{color: '#666', fontSize:'14px' }}>{t('orderReference')}</CardLabel>
          <span className='orderReference'> {currentCardInfo.orderId}</span>
        </CardInfoModalList>
        <CardInfoModalList>
          <CardLabel style={{color: '#666', fontSize:'14px' }}>{t('HarvestInformation')}</CardLabel>
          {
            currentCardInfo.orderState == OrderStateEnum.progress && currentCardInfo?.pledgeState == 0 && (
              <span className={`add ${loading ? 'gray': ''}`} onClick={() => {
                if(loading) return;
                setIsOpenAddInfo(true)
                pubSub.on('getMyInfo', () => {
                  getGiftCardOrder();
                })
              }}>
              {currentCardInfo?.shippingAddress?.name ? '' : t('pleaseFilInformation')}{' '}{'>'}
             </span>
            )
          }
          {
            currentCardInfo.orderState == OrderStateEnum.progress && currentCardInfo?.pledgeState == 2 && !currentCardInfo?.shippingAddress?.name && (
              <span className='add' onClick={() => {
                setIsOpenAddInfo(true)
                pubSub.on('getMyInfo', () => {
                  getGiftCardOrder();
                })
              }}>
              {t('pleaseFilInformation')}{' '}{'>'}
             </span>
            )
          }
        </CardInfoModalList>
        {
          currentCardInfo?.shippingAddress?.name && <CardInfoAddAgent>
            <div>{currentCardInfo?.shippingAddress?.name}</div>
            <div>{currentCardInfo?.shippingAddress?.phone}</div>
            <div>{currentCardInfo?.shippingAddress?.area}{currentCardInfo?.shippingAddress?.address}</div>
          </CardInfoAddAgent>
        }
        {
          currentCardInfo.orderType != OrderTypeEnum.cardIssuingCenter && <CardInfoModalList>
            <CardLabel style={{color: '#666', fontSize:'14px' }}>{currentCardInfo.orderType == OrderTypeEnum.ordinary ? t('cardCollectionAgent') : t('cardIssuingCenter')}</CardLabel>
            {
              currentCardInfo.orderType == OrderTypeEnum.ordinary && currentCardInfo.orderState == OrderStateEnum.progress && currentCardInfo.updateNum == 1 && (
                <span
                  className={`add ${loading ? 'gray': ''}`}
                  onClick={() => {
                    if(loading) return;
                    setIsShowAgentInfo(true)
                    pubSub.on('updateAgent', () => {
                      getGiftCardOrder();
                    })
                  }}>
              {cardLeaderInfo?.aiaId ? '' : t('SelectCardCollectionAgent')} {' '}{'>'}
            </span>
              )
            }
          </CardInfoModalList>
        }
        {
          cardLeaderInfo?.aiaId && (
            <CardInfoAddAgent>
              <div>{cardLeaderInfo.nameAddress[language].name}</div>
              <div style={{display: 'flex', justifyContent: 'end'}}>
                AIABlock ID：<ParagraphInfo copyable={{
                tooltips: [t('Copy'), t('Copy Successfully')]
              }} style={{marginBottom: 0}}>{cardLeaderInfo?.aiaId}</ParagraphInfo>
              </div>
              <ParagraphInfo copyable={{
                        tooltips: [t('Copy'), t('Copy Successfully')]
                      }} style={{marginBottom: 0}}>{cardLeaderInfo.nameAddress[language].address}</ParagraphInfo>
            </CardInfoAddAgent>
          )
        }
        <CardInfoModalList>
          <CardLabel style={{color: '#666', fontSize:'14px' }}>{t('NumberCardsReceived')}</CardLabel>
          <span>{currentCardInfo.cardNum} {t('pieces')}
            {
              currentCardInfo.alreReceivedNum > 0 && <span className='alreReceivedNum'>
              {`（${t('cancelledCard')} ${currentCardInfo.alreReceivedNum}）`}
            </span>
            }

          </span>
        </CardInfoModalList>
        <CardInfoModalList>
          <CardLabel style={{color: '#666', fontSize:'14px' }}>{t('cardReceiptCode')}</CardLabel>
          {
            currentCardInfo.orderType == OrderTypeEnum.ordinary && currentCardInfo.pledgeState == 0 &&
            <span>{t('GetItAfterPayingPostage', {amount: currentCardInfo.postage})}</span>
          }
          {
            currentCardInfo.orderType != OrderTypeEnum.ordinary && !currentCardInfo?.shippingAddress?.name &&
            <span>{t('fillInformation')}</span>
          }
        </CardInfoModalList>

        {
          currentCardInfo.orderType == OrderTypeEnum.ordinary && currentCardInfo.pledgeState == 0 && <div style={{
            fontSize: '12px',
            textAlign: 'right',
            color: '#A3A3A3'
          }}>{t('postage', {amount: dividedBy(currentCardInfo.postage, currentCardInfo.cardNum)})}</div>
        }
        {
          currentCardInfo.pledgeState != 0 && currentCardInfo.qr &&
          <div className='cardReceiptCodeQRCode'>

            {
              currentCardInfo.orderState == OrderStateEnum.progress && <>
                <QRCode value={currentCardInfo.qr}/>
                <ParagraphInfo className='code' copyable={{
                        tooltips: [t('Copy'), t('Copy Successfully')]
                      }} style={{marginBottom: 0}}>{currentCardInfo.qr}</ParagraphInfo>
              </>
            }
            {
              currentCardInfo.orderState == OrderStateEnum.completed && <div className='completed'>
                <div className='QRCode'>
                  <QRCode value={currentCardInfo.qr} fgColor="#989898"/>
                  <span className='invalid'>{t('cancelledCard')}</span>
                </div>
                <div className='code'>{currentCardInfo.qr}</div>
              </div>
            }
            {
              currentCardInfo.orderState == OrderStateEnum.cancelled && <div className='completed'>
                <div className='QRCode'>
                  <QRCode value={currentCardInfo.qr} fgColor="#989898"/>
                  <span className='invalid'>{t('expired')}</span>
                </div>
                <div className='code'>{currentCardInfo.qr}</div>
              </div>
            }
            <div className='keyTips'>（{t('voucherCodeTips')}）</div>
          </div>
        }

        {
          currentCardInfo.orderType == OrderTypeEnum.ordinary && currentCardInfo.orderState == OrderStateEnum.progress && currentCardInfo.pledgeState == 0 &&
          <ConfirmButtonDiv style={{marginTop: '20px'}}>
            <Button loading={loading}
                    type="primary"
                    disabled={isPay || !(cardLeaderInfo?.aiaId && currentCardInfo?.shippingAddress?.name)}
                    onClick={confirmHandle}
            >{t('confirmPayment')}</Button>
          </ConfirmButtonDiv>
        }
      </CardInfoModalDiv>

      <AddMyInfoModal isOpen={isOpenAddInfo} setOpen={setIsOpenAddInfo}
                      orderId={currentCardInfo.id}
                      info={currentCardInfo?.shippingAddress || addressInfo}></AddMyInfoModal>
      <CardLeaderSelect currentCardInfo={currentCardInfo} selected={cardLeaderInfo} isOpen={isShowAgentInfo}
                        setOpen={setIsShowAgentInfo} isSelect={true}/>
    </Modal>
  )
}

export default CardInfoModal;