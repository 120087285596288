
import React
// , { useContext } 
from 'react'
import { Flex, Text } from 'rebass'
// import { ThemeContext } from 'styled-components'
interface TextIconProps {
  text: string | undefined
  bold?: boolean
  icon?: any
  color?:string
}

const TextIcon: React.FC<TextIconProps> = ({ icon, text,bold = false }) => {
  // const theme = useContext(ThemeContext)
  return (
    <Flex alignItems="center">
      <Text color={'rgba(14,37,74,.9)'} mr="5px" style={{ "fontWeight": bold ? 600 : 'normal', textTransform: "uppercase" }}>
        {text}
      </Text>
      {icon&&icon}
    </Flex>
  )
}

export default TextIcon
