/**
 * @name: cardLeaderSelect
 * @author: mahuanhuan
 * @date: 2024/1/15
 * @description：领卡队长
 * @update: 2024/1/15
 */
import React, {useContext, useEffect, useMemo, useState} from 'react';
import {useTranslation} from "react-i18next";
import {ThemeContext} from "styled-components";
import Modal from '../../../components/Modal';
import {CardLeaderSelectDiv, ConfirmButtonDiv, InputInfo, ParagraphInfo} from "../styleds";
import {Text as TextInfo} from "../../../components/invitation/styled";
import {CloseOutlined} from "@ant-design/icons";
import {RowBetween} from "../../../components/Row";
import {Button, Input, Tabs} from "antd";
import {CheckOutlined} from '@ant-design/icons';
import AgentAddress from '../../../assets/svg/agentAddress.svg'
import AgentAddressActive from '../../../assets/svg/agentAddressActive.svg'
import SearchSvg from '../../../assets/svg/search.svg'
import SearchClose from '../../../assets/svg/searchClose.svg'
import {getCardPage, updateOrder} from "../../../services/card";
import pubSub from "../../../utils/pub-sub";
import Empty from "../../../components/Empty";
import {stringLengthChange} from "../../../utils";

const {TabPane} = Tabs;

const CardLeaderSelect = ({isOpen, setOpen, isSelect, selected, currentCardInfo}: {
  isOpen: boolean;
  isSelect: boolean;
  setOpen: Function;
  selected?: Record<string, any>;
  currentCardInfo?: Record<string, any>;
}) => {
  const {t, i18n} = useTranslation();
  const i18nAny: any = i18n
  const theme = useContext(ThemeContext);
  const [tabActiveKey, setTabActiveKey] = useState<string>('');
  const [agentList, setAgentList] = useState<Record<string, any>[]>([]);
  const [cardIssuingCenter, setCardIssuingCenter] = useState<Record<string, any>[]>([]);
  const [currentSelectAgent, setCurrentSelectAgent] = useState<Record<string, any>>({});
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState('');
  const close = () => {
    setOpen(false)
  }

  useEffect(() => {
    if (selected?.id && isOpen) {
      setCurrentSelectAgent(selected)
    }
  }, [selected, isOpen])

  const searchHandle = () => {
    getCardPageInfo(2, search, tabActiveKey)
  }
  // 选中发卡中心
  const onChange = (key: string) => {
    setTabActiveKey(key);
    getCardPageInfo(2, search, key)
  };
  // 选中领卡代理
  const handleSelectChange = (info: Record<string, any>) => {
    setCurrentSelectAgent(info);
  }

  const confirmHandle = () => {
    if (loading) return
    setLoading(true);
    updateOrder({
      id: currentCardInfo?.id,
      getCardId: currentSelectAgent.id,
      cardCoreId: currentSelectAgent.parentId,
    }).then(() => {
      pubSub.emit('updateAgent')
      setOpen(false);
    }).finally(() => {
      setLoading(false);
    })
  }
  // 获取发卡中心-领卡队长
  const getCardPageInfo = (type: number, search: string = '', tabActiveKey: string = '') => {
    getCardPage({
      type: type,
      likeParam: search ? search : null,
      parentId: tabActiveKey ? tabActiveKey : null
    }).then((res) => {
      if (type === 1) {
        setCardIssuingCenter(res.records);
      } else {
        agentListHandle(res.records);
      }
    })
  }

  // 领卡队长数据处理
  // 已选中的数据放在第一条

  const agentListHandle = (data: Record<string, any>[]) => {
    if (selected?.id) {
      const index = data.findIndex(item => item.id === selected.id);
      if (index > -1) {
        setAgentList(data);
      } else {
        data.sort((x, y) => {
          if (x.id === selected.id) {
            return -1; // 将匹配到的元素b排在前面
          } else if (y.id === selected.id) {
            return 1; // 将匹配到的元素b排在前面
          } else {
            return 0; // 其他情况保持原有顺序
          }
        });
        setAgentList(data);
      }
    } else {
      setAgentList(data);
    }
  }

  useEffect(() => {
    setAgentList([]);
    if (isOpen) {
      getCardPageInfo(1); // 发卡中心
      getCardPageInfo(2); // 领卡中心
    }
  }, [isOpen])

  const lang = useMemo(() => {
    return i18nAny.language == 'turkey' ? 'tr' : i18nAny.language;
  }, [i18nAny.language])

  const cardIssuingCenterInfo = useMemo(() => {
    const arr: Record<string, any>[] = [];
    if (cardIssuingCenter.length > 0) {
      cardIssuingCenter.forEach((item) => {
        arr.push({
          key: item.id,
          label: item.nameAddress[lang].name
        })
      })
    }
    return arr
  }, [cardIssuingCenter, lang])


  const extraContent = {
    left: <span className='filler'> </span>,
    right: <span className='filler'> </span>,
  };


  return (
    <Modal isOpen={isOpen} onDismiss={close}>
      <CardLeaderSelectDiv>
        <div className='header'>
          <RowBetween style={{padding: '1rem 0'}}>
            <TextInfo style={{fontWeight: 600, color: theme.text7, fontSize: '16px'}}>
              {t('cardCollectionAgent')}
            </TextInfo>
            <TextInfo style={{color: '#666666', fontSize: '16px'}} onClick={close}>
              <CloseOutlined/>
            </TextInfo>
          </RowBetween>
          <InputInfo>
            <Input value={search} placeholder={t('pasteAgentInfo')}
                   onChange={(e) => {
                     setSearch(e.target.value)
                   }}/>
            <img className='SearchSvg' src={SearchSvg} alt="SearchSvg"/>
            {
              search && <img className='SearchClose' onClick={()=>{
                setSearch('')
                getCardPageInfo(2, '', tabActiveKey)
              }} src={SearchClose} alt="SearchClose"/>
            }

            <span className='search' onClick={searchHandle}>{t('Search for')}</span>
          </InputInfo>
        </div>
        <div className='newTabs'>
          <Tabs tabBarExtraContent={extraContent}
                activeKey={tabActiveKey}
                centered
                onChange={onChange}>
            <TabPane key={''} tab={t('all')}></TabPane>
            {
              cardIssuingCenterInfo.map((item) => (
                <TabPane key={item.key} tab={item.label}></TabPane>
              ))
            }
          </Tabs>
        </div>
        <div className='agentList' style={{height: isSelect ? 'calc(100% - 320px)' : 'calc(100% - 200px)'}}>
          {
            agentList.length == 0
              ? (<Empty title={t('noContent')}></Empty>)
              : agentList.map((item, index) => (
                <div key={index}
                     className={`agentListInfo ${currentSelectAgent.id == item.id && 'active'} ${item.state == 1 && 'disabled'} `}
                     onClick={() => {
                       if (isSelect) {
                         // 状态：0.正常 1停用
                         if (item.state == 1) return;
                         handleSelectChange(item);
                       }
                     }}>
                  <div className='selected'>
                    <CheckOutlined/>
                  </div>
                  <div className='holdOrder'>
                    {t('HoldOrder')}
                  </div>
                  <div className='name'>
                    <img src={item.state == 1 ? AgentAddress : AgentAddressActive} alt=""/>
                    {item.nameAddress[lang].name}
                  </div>
                  <div className='address'>
                    <span>{t('address')}：</span>
                    <ParagraphInfo
                      copyable={{tooltips: [t('Copy'), t('Copy Successfully')], text: item.nameAddress[lang].address}}
                      style={{marginBottom: 0}}>
                      {item.nameAddress[lang].address.length > 15 ? stringLengthChange(item.nameAddress[lang].address, 15, 0) : item.nameAddress[lang].address}
                    </ParagraphInfo>
                  </div>
                  <div className='AIABlockId'>
                    AIABlock ID：<ParagraphInfo copyable={{tooltips: [t('Copy'), t('Copy Successfully')]}}
                                               style={{marginBottom: 0}}>{item.aiaId}</ParagraphInfo>
                  </div>

                </div>
              ))
          }
        </div>
        {
          isSelect &&
          <div className='confirmSelection'>
            <div className='tips'>{t('cardCollectionAgentTips')}</div>
            <ConfirmButtonDiv style={{marginTop: '20px'}}>
              <Button loading={loading}
                      type="primary"
                      disabled={!currentSelectAgent?.id || currentSelectAgent?.id == selected?.id}
                      onClick={confirmHandle}
              >{t('confirmSelection')}</Button>
            </ConfirmButtonDiv>
          </div>
        }

      </CardLeaderSelectDiv>
    </Modal>
  )
}

export default CardLeaderSelect;