import { NETWORK_CHAIN_ID } from "connectors"
import { Order } from "hooks/limitOrders/useGelatoLimitOrdersHandlers"
import { get, set, clear } from 'local-storage'
import orderBy from 'lodash/orderBy'
export const LS_ORDERS = 'gorders_'

export const hashOrder = (order: Order) => order.id
export function lsKey(key: string, account: string, chainId: number) {
  chainId = chainId || NETWORK_CHAIN_ID
  return key + account + chainId.toString()
}
export function clearOrdersLocalStorage() {
  return clear()
}
export const hashOrderSet = (orders: Order[]) => new Set(orders.map(hashOrder))
export function getLSOrders(chainId: any, account: any, pending = false) {

  chainId = chainId || NETWORK_CHAIN_ID
  const key = pending ? lsKey(`${LS_ORDERS}pending_`, account, chainId) : lsKey(LS_ORDERS, account, chainId)
  const orders = get<Order[]>(key)
  return orders ? getUniqueOrders(orders) : []
}
export function saveOrder(chainId: number, account: string, order: Order | any, pending = false) {
  saveOrders(chainId, account, [order], pending)
}

export function savePendingOrders(chainId: any, account: any, order: Order | any) {
  const key = lsKey(`${LS_ORDERS}pending_`, account, chainId);
  set(key, order)
}

export function savePendingOrder(chainId: any, account: any, order: Order | any) {
  const key = lsKey(`${LS_ORDERS}pending_`, account, chainId);
  const getOrder = get<Order[]>(key)
  if (getOrder) {
    const resultData = getUniqueOrders([...order, ...getOrder])
    set(key, resultData)
  } else {
    set(key, order)
  }
}

export function getPendingOrder(chainId: any, account: any) {
  const key = lsKey(`${LS_ORDERS}pending_`, account, chainId);
  return get<Order[]>(key) || []
}


export function saveTotalOrders(chainId: any, account: any, orders: Order[]) {
  const key = lsKey(`${LS_ORDERS}total_`, account, chainId)
  set(key, orders)
}

export function saveCancelOrders(chainId: any, account: any, orders: Order[]) {
  const key = lsKey(`${LS_ORDERS}cancel_`, account, chainId)
  set(key, orders)
}
export function getCancelOrders(chainId: any, account: any) {
  const key = lsKey(`${LS_ORDERS}cancel_`, account, chainId);
  return get<Order[]>(key) || []
}
export function getTotalOrders(chainId: any, account: any) {
  const key = lsKey(`${LS_ORDERS}total_`, account, chainId)
  return get<Order[]>(key) || []
}
export function removeOrderTotal(chainId: any, account: any) {
  const key = lsKey(LS_ORDERS, account, chainId)
  set(key, [])
}
export function saveOrders(chainId: number, account: string, orders: Order[], pending = false) {
  if (!orders || !orders.length) return

  const key = pending ? lsKey(`${LS_ORDERS}pending_`, account, chainId) : lsKey(LS_ORDERS, account, chainId)

  if (!pending) {
    removeOrders(chainId, account, orders, true)
  }

  const result = removeOrders(chainId, account, orders, pending)

  if (!result.length) {
    set(key, orders)
  } else {
    result.push(...orders)
    set(key, result)
  }
}
export function removeOrders(chainId: number, account: string, orders: Order[], pending = false) {
  const key = pending ? lsKey(`${LS_ORDERS}pending_`, account, chainId) : lsKey(LS_ORDERS, account, chainId)

  const prev = get<Order[]>(key)

  if (!prev) return []

  if (!orders || !orders.length) return prev

  const orderHashSet = hashOrderSet(orders)
  const result = prev.filter((orderInLS: Order) => !orderHashSet.has(hashOrder(orderInLS)))

  set(key, result)

  return result
}

export function removeOrder(chainId: number, account: string, order: Order, pending = false) {
  return removeOrders(chainId, account, [order], pending)
}
export const getUniqueOrders = (allOrders: Order[], by?: boolean | "desc" | "asc"): any => [
  ...new Map(
    orderBy(allOrders, (order: any) => parseFloat(order.updatedAt), by ? by : 'desc').map((order) => [order.id, order]),
  ).values(),
]



