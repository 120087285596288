import React from 'react'
import { Box } from 'rebass/styled-components'

interface CellFormatProps {
  firstRow: any
  secondRow: any
}

const CellFormat: React.FC<CellFormatProps> = ({ firstRow, secondRow }) => {
  return (
    <Box>
      <Box mb="4px" sx={{color:'rgba(14,37,74,.9)',fontWeight:'600'}}>{firstRow}</Box>
      <Box>{secondRow}</Box>
    </Box>
  )
}

export default CellFormat
