import {  CurrencyAmount, ETHER, JSBI, Price, Token, TokenAmount } from "@uniswap/sdk"
import { BigNumber } from "ethers"
import { formatUnits, parseUnits } from "ethers/lib/utils"
import { useActiveWeb3React } from "hooks"
import { useTradeExactIn } from "hooks/Trades"
import { useMemo } from "react"
import { BIG_INT_TEN, getPriceForOneToken } from "state/limitOrders/hooks"
import { Rate } from "state/limitOrders/types"
import { tryParseAmount } from "state/swap/hooks"

export const GENERIC_GAS_LIMIT_ORDER_EXECUTION = BigNumber.from(500000)
export enum GAS_PRICE {
    default = '5',
    fast = '6',
    instant = '7',
    testnet = '10',
}
export const GAS_PRICE_GWEI = {
    default: parseUnits(GAS_PRICE.default, 'gwei').toString(),
    fast: parseUnits(GAS_PRICE.fast, 'gwei').toString(),
    instant: parseUnits(GAS_PRICE.instant, 'gwei').toString(),
    testnet: parseUnits(GAS_PRICE.testnet, 'gwei').toString(),
}
export const getGasPrice = (): string => {
    const chainId: number = parseInt(process.env.REACT_APP_CHAIN_ID ?? '1')
    const userGas = GAS_PRICE_GWEI.default
    return chainId === 1319 ? userGas : GAS_PRICE_GWEI.testnet
}

export default function useGasOverhead(
    inputAmount: CurrencyAmount | undefined,
    outputAmount: CurrencyAmount | undefined,
    rateType: Rate,
): {
    realExecutionPrice: Price | undefined | null
    realExecutionPriceAsString: string | undefined
    gasPrice: string | undefined
} {
    const { chainId } = useActiveWeb3React()
    const gasPrice = getGasPrice()
    const requiredGas = formatUnits(gasPrice ? BigNumber.from(gasPrice).mul(GENERIC_GAS_LIMIT_ORDER_EXECUTION) : '0')
    const requiredGasAsCurrencyAmount = tryParseAmount(requiredGas, ETHER)
    const inputIsAITD = inputAmount?.currency.symbol === 'AIA'
    const gasCostInInputTokens = useTradeExactIn(requiredGasAsCurrencyAmount, inputIsAITD ? undefined : inputAmount?.currency)
    const bufferedOutputAmount = useMemo(() => {
        if (inputIsAITD) return requiredGasAsCurrencyAmount
        if (!gasCostInInputTokens || !gasCostInInputTokens?.outputAmount) return undefined
        const margin = gasCostInInputTokens.outputAmount.multiply(JSBI.BigInt(2000)).divide(JSBI.BigInt(10000))
        const numberNum = gasCostInInputTokens.outputAmount.multiply(JSBI.BigInt(1))
        const adjustedGas = numberNum.add(margin)
        // const adjustedGas = gasCostInInputTokens.outputAmount.add(margin)
        const adjustedGasInWei = adjustedGas.multiply(
            JSBI.exponentiate(BIG_INT_TEN, JSBI.BigInt(gasCostInInputTokens.outputAmount.currency.decimals)),
        )
        if (gasCostInInputTokens.outputAmount.currency instanceof Token) {
            return new TokenAmount(gasCostInInputTokens.outputAmount.currency, adjustedGasInWei.toFixed(0))
        }
        return undefined
    }, [requiredGasAsCurrencyAmount, gasCostInInputTokens])

    const realInputAmount = useMemo(() =>
        bufferedOutputAmount &&
        inputAmount &&
        inputAmount.greaterThan(bufferedOutputAmount) &&
        inputAmount.subtract(bufferedOutputAmount), [bufferedOutputAmount, inputAmount])
 
    const realExecutionPrice = useMemo(() => {
        if (!inputAmount || (!gasCostInInputTokens && !inputIsAITD) || !realInputAmount || !outputAmount) return null
        if (inputIsAITD && requiredGasAsCurrencyAmount?.greaterThan(inputAmount)) return undefined
        if (gasCostInInputTokens && gasCostInInputTokens.outputAmount.greaterThan(inputAmount)) return undefined
        return getPriceForOneToken(realInputAmount, outputAmount)
    }, [inputAmount, gasCostInInputTokens, inputIsAITD, realInputAmount, outputAmount])
    // console.log(realExecutionPrice&&realExecutionPrice.toSignificant(6));
    const realExecutionPriceAsString = useMemo(() => {
        if (!realExecutionPrice) return 'never executes'
        return rateType === Rate.DIV ? realExecutionPrice.invert().toSignificant(6) : realExecutionPrice.toSignificant(6)
    }, [rateType, realExecutionPrice])

    return chainId
        ? { realExecutionPrice, gasPrice, realExecutionPriceAsString }
        : {
            realExecutionPrice: undefined,
            realExecutionPriceAsString: undefined,
            gasPrice: undefined,
        }
}