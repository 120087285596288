import { JSBI, Percent } from '@uniswap/sdk'


export enum PercentageDirection {
    ABOVE = 'above',
    BELOW = 'below',
    MARKET = 'market',
}



export const getRatePercentageMessage = (
    percentageRateDifference: Percent | undefined,
    t: any
): [string | null, PercentageDirection | null] => {
    if (percentageRateDifference) {
        if (percentageRateDifference.equalTo(JSBI.BigInt(0))) {
            return [t('AtMarketPrice'), PercentageDirection.MARKET]
        }
        const percentageAsString = percentageRateDifference.toSignificant(6)
        const formattedPercentage = parseFloat(percentageAsString).toLocaleString(undefined, {
            minimumFractionDigits: 0,
            maximumFractionDigits: 2,
        })
        if (percentageRateDifference.lessThan('0')) {
            return [t('BelowMarket', { percentage: formattedPercentage }), PercentageDirection.BELOW]
        }
        return [t('AboveMarket', { percentage: formattedPercentage }), PercentageDirection.ABOVE]
    }
    return [null, null]
}
