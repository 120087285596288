import React
    // { useContext }
    from 'react'
import styled
    // , { ThemeContext } 
    from 'styled-components'
import { Text } from 'rebass'
import { Rate } from 'state/limitOrders/types'
import { Input } from 'components/NumericalInput'
import { useTranslation } from 'react-i18next'
import { Currency, JSBI, Percent, Price } from '@uniswap/sdk'
import { StyledBalanceMaxMini } from 'components/swap/styleds'
import repeatSvg from '../../../assets/svg/repeatSvg.svg'
import { RowCenter } from 'components/Row'
import { getRatePercentageMessage, PercentageDirection } from '../utils/getRatePercentageMessage'
import { ReloadIcon } from 'components/Svg'

const DIRECTION_COLORS = {
    [PercentageDirection.ABOVE]: 'success',
    [PercentageDirection.BELOW]: 'failure',
    [PercentageDirection.MARKET]: 'textSubtle'
}
interface LimitOrderPriceProps {
    value: string
    onUserInput: (value: string) => void
    inputCurrency?: Currency
    outputCurrency?: Currency
    percentageRateDifference: Percent | undefined
    rateType: Rate
    handleRateType: (rateType: Rate, price?: Price) => void
    price?: Price
    handleResetToMarketPrice?: () => void
    realExecutionPriceAsString?: string
    disabled?: boolean
}

const LimitOrderPrice: React.FC<LimitOrderPriceProps> = ({
    value,
    disabled,
    onUserInput,
    percentageRateDifference,
    handleResetToMarketPrice,
    rateType,
    inputCurrency,
    outputCurrency,
    price,
    handleRateType
}) => {
    const { t } = useTranslation()
    const label =
    rateType === Rate.MUL
      ? `${outputCurrency?.symbol} per ${inputCurrency?.symbol}`
      : `${inputCurrency?.symbol} per ${outputCurrency?.symbol}`
    const isAtMarketPrice = percentageRateDifference?.equalTo(JSBI.BigInt(0)) ?? true
    const [ratePercentageMessage, direction] = getRatePercentageMessage(percentageRateDifference, t)
    const priceLabelColor = direction ? DIRECTION_COLORS[direction] : null
    const hasCurrencyInfo = inputCurrency && outputCurrency
    const toggleRateType = () => {
        handleRateType(rateType, price)
      }
    return (
        <>
            <Flex>
                <RowCenterStart justify="center">
                    <Text mr="8px"
                        className='upc'
                        color="secondary"
                        fontSize="12px"
                        fontWeight={'bold'}>
                        {t('Price')}
                    </Text>
                    <ShowPrice
                        onClick={handleResetToMarketPrice}
                        disabled={isAtMarketPrice}>
                        <ReloadIcon />
                        <Text fontSize="12px"
                            className='upc'
                            color={isAtMarketPrice ? 'textDisabled' : 'primary'}>
                            {t('Market')}
                        </Text>
                    </ShowPrice>
                </RowCenterStart>
                {ratePercentageMessage && (
                    <Text color={priceLabelColor} fontSize="12px">
                        {ratePercentageMessage}
                    </Text>
                )}
            </Flex>
            <OrderPriceInput
                id="limit-order-desired-rate-input"
                value={value}
                disabled={disabled}
                onUserInput={onUserInput}
            />

            {hasCurrencyInfo && (
                    <LabelContainer >
                    <Text>
                        
                        {label}
                    </Text>
                    <StyledBalanceMaxMini onClick={toggleRateType}>
                        {/* <Repeat size={14} /> */}
                        <img src={repeatSvg} style={{ width: '14px' }} alt="" />
                    </StyledBalanceMaxMini>
                    </LabelContainer>
            )}
        </>
    )
}


const OrderPriceInput = styled(Input) <{ disabled?: boolean }>`
    text-align: right;
    background-color: #F2F4F6;
    border: 0;
    border-radius: 16px;
    color: #280D5F;
    display: block;
    font-size: 23px;
    height: 60px;
    outline: 0;
    padding: 0 16px;
    width: 100%;
    border: 1px solid #F2F4F6;
    cursor: ${({ disabled }) => disabled ? 'not-allowed' : 'auto'};  
`

const ShowPrice = styled.div<{ disabled?: boolean }>`
    display: flex;
    /* background-color:${({ disabled }) => disabled ? '#2750EB' : 'primary'}; */
    color:${({ disabled }) => disabled ? 'rgb(189, 194, 196)' : '#2750EB'};
    border:1px solid ${({ disabled }) => disabled ? 'rgb(233, 234, 235)' : '#2750EB'};
    box-sizing: border-box;
    padding: 1px 10px;
    border-radius: 10px;
    cursor: ${({ disabled }) => disabled ? 'not-allowed' : 'pointer'};
    & > svg{
        margin-right: 5px;
        fill: ${({ disabled }) => disabled ? 'rgb(186 186 186)' : '#2750EB'};
    }
`
const RowCenterStart = styled(RowCenter)`
    justify-content:flex-start;
    flex:1;
`
const Flex = styled.div`
    display:flex;
    justify-content:space-between;
    padding-top: 10px;
    border-top: 1px solid rgba(14, 37, 74, 0.06);
`
const LabelContainer = styled.div`
  cursor: pointer;
  display:flex;
  justify-content:flex-end;
  align-items:center;
`
export default LimitOrderPrice
