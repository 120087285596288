import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, AppState } from '../index'
import { setLatestBlock, setPariListStory } from './actions'

export function useSwapBlockNumber(): AppState['blocks'] {
    return useSelector<AppState, AppState['blocks']>(state => state.blocks)
}
export function useSwapBlockNumbers(): {
    onChangeBlockNumber: (latestBlock: number | null) => void
    onChangePariListStory: (pariListStory: any[] | null) => void
} {
    const dispatch = useDispatch<AppDispatch>()
    const onChangeBlockNumber = useCallback(
        (latestBlock: number | null) => {
            dispatch(setLatestBlock({ latestBlock }))
        },
        [dispatch]
    )
    const onChangePariListStory = useCallback(
        (pariListStory: any[] | null) => {
            dispatch(setPariListStory({ pariListStory }))
        },
        [dispatch]
    )

    return { onChangeBlockNumber, onChangePariListStory }
}