import React, { useContext } from "react"
import { Currency } from "@uniswap/sdk"
import { useActiveWeb3React } from "hooks"
// import { wrappedCurrency } from "utils/wrappedCurrency"
import { useTranslation } from "react-i18next"
import styled, { ThemeContext } from 'styled-components'
import { AutoColumn, ColumnCenter } from "components/Column"
import { ArrowUpCircle, CheckCircle } from 'react-feather'
import { Text } from 'rebass'

import { ExternalLink } from "theme/components"
import { getEtherscanLink } from "utils"
import { ButtonLightNew, ButtonPrimary } from "components/Button"
import { RowFixed } from "components/Row"
import useAddTokenToMetamask from "hooks/useAddTokenToMetamask"

export function TransactionSubmittedContent({
    onDismiss,
    hash,
    currencyToAdd,
}: {
    onDismiss: () => void
    hash: string | undefined
    currencyToAdd?: Currency | undefined
}) {
    const { chainId, library } = useActiveWeb3React()

    const { t } = useTranslation()

    const { addToken, success } = useAddTokenToMetamask(currencyToAdd)
    const theme = useContext(ThemeContext)
    return (
        <Wrapper>
            <Section>
                <ConfirmedIcon>
                    <ArrowUpCircle strokeWidth={0.5} size={90} color={'#39A739'} />
                </ConfirmedIcon>
                <AutoColumn gap="12px" justify={'center'}>
                    <Text fontWeight={500} fontSize={20}>
                        {t('Transaction Submitted')}
                    </Text>
                    {chainId && hash && (
                        <ExternalLink href={getEtherscanLink(chainId, hash, 'transaction')}>
                            <Text fontWeight={500} fontSize={14} color={theme.primary1}>
                                {t('View on Aiascan')}
                            </Text>
                        </ExternalLink>
                    )}
                    {currencyToAdd && library?.provider?.isMetaMask && (
                        <ButtonLightNew mt="30px" padding="6px 20px" width="fit-content" onClick={addToken}>
                            {!success ? (
                                <RowFixed>
                                    Add {currencyToAdd.symbol} to Metamask
                                    {/* <StyledLogo src={MetaMaskLogo} /> */}
                                </RowFixed>
                            ) : (
                                <RowFixed>
                                    Added {currencyToAdd.symbol}{' '}
                                    <CheckCircle size={'16px'} stroke={theme.green1} style={{ marginLeft: '6px' }} />
                                </RowFixed>
                            )}
                        </ButtonLightNew>
                    )}
                    <ButtonPrimary onClick={onDismiss} style={{ padding: '6px 0', margin: '40px 0 0 0', boxShadow: '0px 2px 0px 1px #0090FF' }}>
                        <Text fontWeight={500} fontSize={20}>
                            {t('Close')}
                        </Text>
                    </ButtonPrimary>
                </AutoColumn>
            </Section>
        </Wrapper>
    )
}
const Wrapper = styled.div`
  width: 100%;
`
const Section = styled(AutoColumn)`
  padding: 24px;
`
const ConfirmedIcon = styled(ColumnCenter)`
  padding: 24px 0;
`