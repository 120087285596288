import { CurrencyAmount, Token, Trade } from '@uniswap/sdk'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
// import { ArrowDown } from 'react-feather'
import { Text } from 'rebass'
import { useTranslation } from 'react-i18next'
import { useMedia } from 'react-use'
import styled from 'styled-components'
import { ButtonError, ButtonLightWallet, ButtonPrimary, ButtonConfirmed } from '../../components/Button'
// import Card from '../../components/Card'
import { AutoColumn } from '../../components/Column'
import DownNew from '../../assets/svg/downNew.svg'
// import ShowchartSvg from '../../assets/svg/showchart.svg'
// import NotshowchartSvg from '../../assets/svg/notshowchart.svg'
import CurrencyInputPanelNew from '../../components/CurrencyInputPanel/newIndex'
import { AutoRow, RowBetween } from '../../components/Row'
import { ArrowWrapper, BottomGrouping, Wrapper } from '../../components/swap/styleds'
// import TradePrice from '../../components/swap/TradePrice'
import TokenWarningModal from '../../components/TokenWarningModal'
import SwapHeader from '../../components/swap/SwapHeader'

import { useActiveWeb3React } from '../../hooks'
import { useCurrency, useAllTokens } from '../../hooks/Tokens'
import { ApprovalState, useApproveCallbackFromInputCurrencyAmount } from '../../hooks/useApproveCallback'
import useWrapCallback, { WrapType } from '../../hooks/useWrapCallback'
import { useWalletModalToggle } from '../../state/application/hooks'

import { useDefaultsFromURLSearch } from '../../state/swap/hooks'
import useGelatoLimitOrders from 'hooks/limitOrders/useGelatoLimitOrders'
import { useExpertModeManager } from '../../state/user/hooks'
import { TYPE } from '../../theme'
import { maxAmountSpend } from '../../utils/maxAmountSpend'
import { computeTradePriceBreakdown, warningSeverity } from '../../utils/prices'
import AppBody from '../AppBody'
import Loader from '../../components/Loader'
import { useIsTransactionUnsupported } from 'hooks/Trades'
import { RouteComponentProps } from 'react-router-dom'
// import CartChart from 'apollo/components'
// import ChartModal from 'apollo/components/chartModal'
import { isMobile } from 'react-device-detect'
// import { usePairList } from 'apollo/data'
import { Field } from 'state/limitOrders/types'
import LimitOrderPrice from './components/LimitOrderPrice'
import getRatePercentageDifference from './utils/getRatePercentageDifference'
import useGasOverhead from './utils/useGasOverhead'
import { ConfirmLimitOrderModal } from './components/ConfirmLimitOrderModal'
import LimitOrderTable from './components/LimitOrderTable'
import SwapLimitHeader from 'components/SwapHeader'
import { removeOrderTotal, savePendingOrders } from './utils/localStorageOrders'
import { ATM, CAC, CACII, CADS, CADSII, MANEKI } from '../../constants'

export default function LimitOrders({ history }: RouteComponentProps) {
  const isPhoneSize = useMedia('(max-width: 960px)')
  const loadedUrlParams = useDefaultsFromURLSearch()
  const [showChart] = useState(!isMobile)

  // console.log(usePairData(pairAddress), 'usePairsByAddressusePairsByAddress');

  // token warning stuff
  const [loadedInputCurrency, loadedOutputCurrency] = [
    useCurrency(loadedUrlParams?.inputCurrencyId),
    useCurrency(loadedUrlParams?.outputCurrencyId)
  ]
  const [dismissTokenWarning, setDismissTokenWarning] = useState<boolean>(false)
  const urlLoadedTokens: Token[] = useMemo(
    () => [loadedInputCurrency, loadedOutputCurrency]?.filter((c): c is Token => c instanceof Token) ?? [],
    [loadedInputCurrency, loadedOutputCurrency]
  )
  const handleConfirmTokenWarning = useCallback(() => {
    setDismissTokenWarning(true)
  }, [])

  // dismiss warning if all imported tokens are in active lists
  const defaultTokens = useAllTokens()
  const importTokensNotInDefault =
    urlLoadedTokens &&
    urlLoadedTokens.filter((token: Token) => {
      return !Boolean(token.address in defaultTokens)
    })

  const { account, chainId } = useActiveWeb3React()

  // toggle wallet when disconnected
  const toggleWalletModal = useWalletModalToggle()

  const [isExpertMode] = useExpertModeManager()

  // get custom setting values for user
  const { t } = useTranslation()
  const {
    handlers: { handleInput, handleCurrencySelection, handleSwitchTokens, handleRateType, handleLimitOrderSubmission },
    derivedOrderInfo: {
      currencies,
      currencyBalances,
      parsedAmounts,
      formattedAmounts,
      // recipient,
      typedValue,
      rawAmounts,
      trade,
      price,
      inputError,
      wrappedCurrencies
      // singleTokenPrice,
      // currencyIds,
    },
    orderState: { independentField, basisField, rateType }
  } = useGelatoLimitOrders()

  const { wrapType, inputError: wrapInputError } = useWrapCallback(
    currencies[Field.INPUT],
    currencies[Field.OUTPUT],
    typedValue
  )
  const showWrap: boolean = wrapType !== WrapType.NOT_APPLICABLE
  // const { address: recipientAddress } = useENSAddress(recipient)

  const handleTypeInput = useCallback(
    (value: string) => {
      handleInput(Field.INPUT, value)
    },
    [handleInput]
  )
  const handleTypeOutput = useCallback(
    (value: string) => {
      handleInput(Field.OUTPUT, value)
    },
    [handleInput]
  )

  // reset if they close warning without tokens in params
  const handleDismissTokenWarning = useCallback(() => {
    setDismissTokenWarning(true)
    history.push('/swap/')
  }, [history])

  const [showConfirm, setShowConfirm] = useState(false)
  // modal and loading
  const [{ swapErrorMessage, attemptingTxn, txHash }, setSwapState] = useState<{
    tradeToConfirm?: Trade | undefined
    attemptingTxn: boolean
    swapErrorMessage: string | undefined
    txHash: string | undefined
  }>({
    tradeToConfirm: undefined,
    attemptingTxn: false,
    swapErrorMessage: undefined,
    txHash: undefined
  })

  // check whether the user has approved the router on the input token
  // const [approval, approveCallback] = useApproveCallbackFromTrade(trade, allowedSlippage)
  const [approvalState, approveCallback] = useApproveCallbackFromInputCurrencyAmount(parsedAmounts[Field.INPUT])
  // check if user has gone through approval process, used to show two step buttons, reset on token change
  const [approvalSubmitted, setApprovalSubmitted] = useState<boolean>(false)

  // mark when a user has submitted an approval, reset onTokenSelection for input field
  useEffect(() => {
    if (approvalState === ApprovalState.PENDING) {
      setApprovalSubmitted(true)
    }
  }, [approvalState, approvalSubmitted])

  const maxAmountInput: CurrencyAmount | undefined = maxAmountSpend(currencyBalances['input'])
  const atMaxAmountInput = Boolean(maxAmountInput && parsedAmounts.input?.equalTo(maxAmountInput))

  // the callback to execute the swap
  const { priceImpactWithoutFee } = computeTradePriceBreakdown(trade)
  // errors
  // const [showInverted, setShowInverted] = useState<boolean>(false)

  // warnings on slippage
  const priceImpactSeverity = warningSeverity(priceImpactWithoutFee)

  // show approve flow when: no error on inputs, not approved or pending, or approved in current session
  // never show if price impact is above threshold in non expert mode
  const showApproveFlow =
    !inputError && (approvalState === ApprovalState.NOT_APPROVED || approvalState === ApprovalState.PENDING)
  // ||(approvalSubmitted && approvalState === ApprovalState.APPROVED)
  const handleConfirmDismiss = useCallback(() => {
    setShowConfirm(false)
    setSwapState({ attemptingTxn, swapErrorMessage, txHash })
    // if there was a tx hash, we want to clear the input
    if (txHash) {
      handleInput(Field.INPUT, '')
    }
  }, [attemptingTxn, handleInput, swapErrorMessage, txHash])

  // input value
  const handleInputSelect = useCallback(
    inputCurrency => {
      setApprovalSubmitted(false) // reset 2 step UI for approvals
      handleCurrencySelection(Field.INPUT, inputCurrency)
    },
    [handleCurrencySelection]
  )
  // input max vallue
  const handleMaxInput = useCallback(() => {
    maxAmountInput && handleInput(Field.INPUT, maxAmountInput.toExact())
  }, [maxAmountInput, handleInput])
  // select tokens
  const handleOutputSelect = useCallback(outputCurrency => handleCurrencySelection(Field.OUTPUT, outputCurrency), [
    handleCurrencySelection
  ])
  // switch tokens
  const handleTokenSwitch = useCallback(() => {
    setApprovalSubmitted(false)
    handleSwitchTokens()
  }, [handleSwitchTokens])

  const handleTypeDesiredRate = useCallback(
    (value: string) => {
      handleInput(Field.PRICE, value)
    },
    [handleInput]
  )

  // init market price
  const handleResetToMarketPrice = useCallback(() => {
    if (basisField === Field.INPUT) {
      handleTypeInput(formattedAmounts[Field.INPUT])
    } else {
      handleTypeOutput(formattedAmounts[Field.OUTPUT])
    }
  }, [handleTypeInput, handleTypeOutput, formattedAmounts[Field.INPUT], formattedAmounts[Field.OUTPUT], basisField])

  const handlePlaceOrder = useCallback(() => {
    if (attemptingTxn) return
    const GELATO_NATIVE = '0x00eeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee'
    if (!handleLimitOrderSubmission) {
      return
    }
    setShowConfirm(true)
    setSwapState((prev: any) => ({
      attemptingTxn: true,
      swapErrorMessage: undefined,
      txHash: undefined
    }))
    try {
      if (!wrappedCurrencies?.[Field.INPUT].address) {
        throw new Error('Invalid input currency')
      }
      if (!wrappedCurrencies?.[Field.OUTPUT].address) {
        throw new Error('Invalid output currency')
      }
      if (!rawAmounts?.[Field.INPUT]) {
        throw new Error('Invalid input amount')
      }
      if (!rawAmounts?.[Field.OUTPUT]) {
        throw new Error('Invalid output amount')
      }

      if (!account) {
        throw new Error('No account')
      }
      const inputToken =
        currencies[Field.INPUT] instanceof Token ? wrappedCurrencies?.[Field.INPUT].address : GELATO_NATIVE
      const outputToken =
        currencies[Field.OUTPUT] instanceof Token ? wrappedCurrencies?.[Field.OUTPUT].address : GELATO_NATIVE

      const orderToSubmit = {
        inputToken,
        outputToken,
        inputAmount: rawAmounts[Field.INPUT],
        outputAmount: rawAmounts[Field.OUTPUT],
        owner: account
      }

      handleLimitOrderSubmission(orderToSubmit)
        .then(({ hash }) => {
          setSwapState(prev => ({
            attemptingTxn: false,
            swapErrorMessage: undefined,
            txHash: hash
          }))
        })
        .catch(error => {
          setSwapState(prev => ({
            attemptingTxn: false,
            swapErrorMessage: error.message,
            txHash: undefined
          }))
        })
    } catch (error) {
      const err: any = error
      setSwapState(prev => ({
        attemptingTxn: false,
        swapErrorMessage: err.message,
        txHash: undefined
      }))
      console.error(error)
    }
  }, [
    attemptingTxn,
    handleLimitOrderSubmission,
    account,
    rawAmounts?.[Field.INPUT],
    rawAmounts?.[Field.OUTPUT],
    currencies[Field.INPUT],
    currencies[Field.OUTPUT],
    wrappedCurrencies?.[Field.INPUT]?.address,
    wrappedCurrencies?.[Field.OUTPUT]?.address
  ])
  const currentMarketRate = trade?.executionPrice
  const swapIsUnsupported = useIsTransactionUnsupported(currencies?.input, currencies?.output)
  const percentageRateDifference = getRatePercentageDifference(trade?.executionPrice, price)
  const { realExecutionPriceAsString } = useGasOverhead(
    parsedAmounts[Field.INPUT],
    parsedAmounts[Field.OUTPUT],
    rateType
  )
  // const pairList = usePairList(showChart)

  useEffect(() => {
    if (account && chainId) {
      if (account) {
        savePendingOrders(chainId, account, [])
        removeOrderTotal(chainId, account)
        // saveTotalOrders(chainId, account, [])
      }
    }
  }, [chainId, account])

  const filterCurrency: Token[] = [CADS, CADSII, MANEKI, CAC, CACII, ATM]
  return (
    <>
      <SwapLimitHeader active={false} />
      <Wrap>
        <LeftContiner>
          {/* { showChart && (isPhoneSize ? <ChartModal
            pairList={pairList}
            onDismiss={() => setShowChart(!showChart)}
            isOpen={showChart}
            currencies={currencies} /> : <CartChart
            pairList={pairList}
            currencies={currencies} />)
          } */}
          <LimitOrderTable isPhoneSize={isPhoneSize} hasChart={!showChart} />
        </LeftContiner>

        <ContainerLeft>
          <ContainerContext>
            <TokenWarningModal
              isOpen={importTokensNotInDefault.length > 0 && !dismissTokenWarning}
              tokens={importTokensNotInDefault}
              onConfirm={handleConfirmTokenWarning}
              onDismiss={handleDismissTokenWarning}
            />
            <AppBody
              style={{
                boxShadow: ' 0px 2px 0px 0px rgba(14, 37, 74, 0.1)',
                minHeight: '513px',
                borderRadius: '16px'
              }}
            >
              <SwapHeaderContainer>
                <div>
                  {/* <img style={{ width: '28px', height: '25px' }}
                    src={!showChart ? ShowchartSvg : NotshowchartSvg}
                    onClick={() => setShowChart(!showChart)} /> */}
                </div>
                <SwapHeader show showSetting={false} />
              </SwapHeaderContainer>
              <SwapHeaderText>{t('Place a limit order to trade at a set price')}</SwapHeaderText>
              <WrapperContainer id="swap-page">
                <ConfirmLimitOrderModal
                  limitPriceInverted={price?.invert().toSignificant(6) || ''}
                  percentageRateDifference={parseFloat(percentageRateDifference?.toSignificant(3) || '').toLocaleString(
                    undefined,
                    {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 3
                    }
                  )}
                  onConfirm={handlePlaceOrder}
                  currentMarketRateInverted={currentMarketRate?.invert().toSignificant(4) || ''}
                  currentMarketRate={currentMarketRate?.toSignificant(4)}
                  currencies={currencies}
                  formattedAmounts={formattedAmounts}
                  onDismiss={handleConfirmDismiss}
                  limitPrice={price?.toSignificant(6)}
                  isOpen={showConfirm}
                  title={t('Confirm Limit Order')}
                  attemptingTxn={attemptingTxn}
                  txHash={txHash}
                  swapErrorMessage={swapErrorMessage}
                />

                <AutoColumn gap={'md'}>
                  <CurrencyInputPanelNew
                    showFocus={true}
                    label={
                      independentField === Field.OUTPUT && !showWrap && trade
                        ? t('From (estimated)') || 'From (estimated)'
                        : t('from') || 'From'
                    }
                    value={formattedAmounts[Field.INPUT]}
                    showMaxButton={!atMaxAmountInput}
                    currency={currencies[Field.INPUT]}
                    onUserInput={handleTypeInput}
                    onMax={handleMaxInput}
                    onCurrencySelect={handleInputSelect}
                    otherCurrency={currencies[Field.OUTPUT]}
                    id="swap-currency-input"
                    errorTip={!!wrapInputError}
                    filterCurrency={filterCurrency}
                  />
                  <AutoColumn justify="space-between">
                    <AutoRow justify={isExpertMode ? 'space-between' : 'center'} style={{ padding: '0 1rem' }}>
                      <ArrowWrapper clickable>
                        <img
                          style={{ height: '20px', width: '20px' }}
                          src={DownNew}
                          onClick={handleTokenSwitch}
                          alt=""
                        ></img>
                      </ArrowWrapper>
                    </AutoRow>
                  </AutoColumn>

                  <CurrencyInputPanelNew
                    sx={{
                      background: '#ffffff'
                    }}
                    isDisabled={true}
                    value={formattedAmounts[Field.OUTPUT]}
                    onUserInput={handleTypeOutput}
                    label={
                      independentField === Field.INPUT && !showWrap && trade
                        ? t('To (estimated)') || 'To (estimated)'
                        : t('out') || 'To'
                    }
                    showMaxButton={false}
                    currency={currencies[Field.OUTPUT]}
                    onCurrencySelect={handleOutputSelect}
                    otherCurrency={currencies[Field.INPUT]}
                    errorTip={!!wrapInputError}
                    id="swap-currency-output"
                    filterCurrency={filterCurrency}
                  />
                  <LimitOrderPrice
                    handleResetToMarketPrice={handleResetToMarketPrice}
                    percentageRateDifference={percentageRateDifference}
                    rateType={rateType}
                    value={formattedAmounts.price}
                    inputCurrency={currencies[Field.INPUT]}
                    outputCurrency={currencies[Field.OUTPUT]}
                    onUserInput={handleTypeDesiredRate}
                    realExecutionPriceAsString={!inputError ? realExecutionPriceAsString : undefined}
                    handleRateType={() => {
                      handleRateType(rateType, price)
                    }}
                    disabled={!formattedAmounts[Field.INPUT] && !formattedAmounts[Field.OUTPUT]}
                  />

                  {/* {showWrap ? null : (
                    <Card padding={showWrap ? '.25rem 1rem 0 1rem' : '0px'} borderRadius={'20px'}>
                      <RowFlatEnd>
                        {Boolean(trade) && (
                          <TradePrice
                            price={trade?.executionPrice}
                            showInverted={showInverted}
                            setShowInverted={setShowInverted}
                          />
                        )}
                      </RowFlatEnd>
                    </Card>
                  )} */}
                  {!inputError && (
                    <AutoColumn justify="flex-end">
                      <Text fontSize="12px" color={'#0E254A'} fontWeight={600}>
                        {realExecutionPriceAsString === 'never executes'
                          ? t('never executes')
                          : t('Real execution price', { price: realExecutionPriceAsString })}
                      </Text>
                    </AutoColumn>
                  )}
                </AutoColumn>
                <BottomGrouping>
                  {swapIsUnsupported ? (
                    <ButtonPrimary style={{ padding: '12px 18px' }} disabled={true}>
                      <TYPE.main mb="4px">{t('Unsupported Asset')}</TYPE.main>
                    </ButtonPrimary>
                  ) : !account ? (
                    <ButtonLightWallet onClick={toggleWalletModal}>
                      {t('Connect Wallet') || 'Connect Wallet'}
                    </ButtonLightWallet>
                  ) : showApproveFlow ? (
                    <RowBetween>
                      <ButtonConfirmed
                        onClick={approveCallback}
                        disabled={approvalSubmitted}
                        altDisabledStyle={approvalState === ApprovalState.PENDING} // show solid button while waiting
                        style={{ padding: '12px 18px' }}
                      >
                        {approvalState === ApprovalState.PENDING ? (
                          <AutoRow gap="6px" justify="center">
                            {t('Approving')} <Loader stroke="white" />
                          </AutoRow>
                        ) : (
                          t('Approve') + ' ' + currencies[Field.INPUT]?.symbol
                        )}
                      </ButtonConfirmed>
                    </RowBetween>
                  ) : (
                    <ButtonError
                      onClick={() => {
                        if (isExpertMode) {
                          handlePlaceOrder()
                        } else {
                          setShowConfirm(true)
                          setSwapState({
                            attemptingTxn: false,
                            swapErrorMessage: undefined,
                            txHash: undefined
                          })
                        }
                      }}
                      id="place-order-button"
                      disabled={!!inputError || realExecutionPriceAsString === 'never executes'}
                      style={{
                        boxShadow:
                          !inputError &&
                          !(priceImpactSeverity > 3 && !isExpertMode) &&
                          priceImpactSeverity < 2 &&
                          realExecutionPriceAsString !== 'never executes'
                            ? '0px 3px 0px 1px #0090ff'
                            : '',
                        padding: '12px 18px'
                      }}
                    >
                      <Text fontSize={18} fontWeight={500}>
                        {inputError || realExecutionPriceAsString === 'never executes'
                          ? inputError || t("Can't execute this order")
                          : t('Place an Order')}
                      </Text>
                    </ButtonError>
                  )}
                </BottomGrouping>
              </WrapperContainer>
            </AppBody>
          </ContainerContext>
        </ContainerLeft>
      </Wrap>
    </>
  )
}

const WrapperContainer = styled(Wrapper)`
  padding: 25px 1rem;
  padding-bottom: 1.8rem;
  border-top: 1px solid rgb(14, 37, 74, 0.06);
  margin-top: 15px;
  min-height: 430px;
`

const Wrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  @media screen and (max-width: 960px) {
    flex-direction: column-reverse;
    align-items: center;
  }
`

const ContainerLeft = styled.div`
  flex-shrink: 0;
  height: fit-content;
  @media screen and (min-width: 1200px) {
    padding: 0px 40px;
  }
  @media screen and (min-width: 968px) {
    padding: 0px 40px;
  }
`
const ContainerContext = styled.div`
  width: 328px;
  border-radius: 24px;
  max-width: 436px;
  z-index: 1;
`
// const RowFlatEnd = styled(RowFlat)`
//     justify-content:end;
//     margin-top:-6px;
// `

const SwapHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  & > div {
    &:first-of-type {
      width: 60%;
      padding: 1rem 0 0px 1.5rem;
      & > img {
        cursor: pointer;
        &:hover {
          opacity: 0.75;
        }
      }
    }
  }
`
const SwapHeaderText = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  color: #0e254a;
  font-size: 12px;
  padding: 0 26px;
`
const LeftContiner = styled.div`
  width: 50%;
  @media screen and (max-width: 960px) {
    width: 328px;
    max-width: 420px;
    margin-top: 25px;
  }
`
