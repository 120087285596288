import React from 'react'
import styled from 'styled-components'
import CurrencyLogo from '../components/CurrencyLogo'

const Wrapper = styled.div<{ margin: boolean; sizeraw: number; marginTop: number, imgSeft?: boolean }>`
  position: relative;
  display: flex;
  flex-direction: row;
  margin-right:5px;
  margin-top: ${({ marginTop }) => marginTop + 'px'};
  img{
    &:last-of-type{
     margin-left: 5px;
    }
  }
`
const HigherLogo = styled(CurrencyLogo)`
  z-index: 2;
`
const CoveredLogo = styled(CurrencyLogo) <{ sizeraw: number }>`
  position: absolute;
`
export default function CurrencyShowLogo({
    currency,
    size = 30,
    margin = 0,
    marginTop = 0,
    imgSeft = true
}: any) {
    const currency0 = {
        symbol: currency.inputCoin,
        address: currency.inputCoinAddress
    }
    const currency1 = {
        symbol: currency.outCoin,
        address: currency.outCoinAddress
    }
    return (
        <Wrapper sizeraw={size} margin={margin} marginTop={marginTop} imgSeft={imgSeft}>
            {currency0 && <HigherLogo currency={currency0} size={size.toString() + 'px'} />}
            {currency1 && <CoveredLogo currency={currency1} size={size.toString() + 'px'} sizeraw={size} />}
        </Wrapper>
    )
}
