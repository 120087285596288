import { ChainId, JSBI, Percent, Token, WETH } from '@uniswap/sdk'
import { AbstractConnector } from '@web3-react/abstract-connector'

import { injected, tpInjected, walletconnect, walletlink } from '../connectors'

export const ROUTER_ADDRESS = process.env.REACT_APP_AITDSWAP_V2_ROUTER2 || ''

export const ZERO_ADDRESS = '0x0000000000000000000000000000000000000000'

export const REACT_APP_AITDSWAP_MAIN_STA_ADDRESS = process.env.REACT_APP_AITDSWAP_MAIN_STA_ADDRESS || ''
export const REACT_APP_AITDSWAP_WAITD_ADDRESS = process.env.REACT_APP_AITDSWAP_WAITD_ADDRESS || ''
export const REACT_APP_AITDSWAP_USDT_ADDRESS = process.env.REACT_APP_AITDSWAP_USDT_ADDRESS || ''
export const REACT_APP_CRET_ADDRESS = process.env.REACT_APP_CRET_ADDRESS || ''
export const REACT_APP_CAD_ADDRESS = process.env.REACT_APP_CAD_ADDRESS || ''
export const NETWORK_CHAIN_ID: number = parseInt(process.env.REACT_APP_CHAIN_ID ?? '1')
export const REACT_APP_AITDSWAP__MAIN_BTC_ADDRESS = process.env.REACT_APP_AITDSWAP__MAIN_BTC_ADDRESS || ''
export const REACT_APP_AITDSWAP_MAIN_ETH_ADDRESS = process.env.REACT_APP_AITDSWAP_MAIN_ETH_ADDRESS || ''
export const REACT_APP_AITDSWAP_MAIN_UNI_ADDRESS = process.env.REACT_APP_AITDSWAP_MAIN_UNI_ADDRESS || ''
export const REACT_APP_AITDSWAP_MAIN_SAND_ADDRESS = process.env.REACT_APP_AITDSWAP_MAIN_SAND_ADDRESS || ''
export const REACT_APP_AITDSWAP_MAIN_DYDX_ADDRESS = process.env.REACT_APP_AITDSWAP_MAIN_DYDX_ADDRESS || ''
export const REACT_APP_AITDSWAP_MAIN_MASK_ADDRESS = process.env.REACT_APP_AITDSWAP_MAIN_MASK_ADDRESS || ''
export const REACT_APP_AITDSWAP_MAIN_MATIC_ADDRESS = process.env.REACT_APP_AITDSWAP_MAIN_MATIC_ADDRESS || ''
export const REACT_APP_AITDSWAP_MAIN_GTC_ADDRESS = process.env.REACT_APP_AITDSWAP_MAIN_GTC_ADDRESS || ''

export const REACT_APP_AITDSWAP_MAIN_SHIB_ADDRESS = process.env.REACT_APP_AITDSWAP_MAIN_SHIB_ADDRESS || ''

export const REACT_APP_AITDSWAP_MAIN_1INCH_ADDRESS = process.env.REACT_APP_AITDSWAP_MAIN_1INCH_ADDRESS || ''
export const REACT_APP_AITDSWAP_MAIN_MANA_ADDRESS = process.env.REACT_APP_AITDSWAP_MAIN_MANA_ADDRESS || ''

export const REACT_APP_AITDSWAP_MAIN_FIL_ADDRESS = process.env.REACT_APP_AITDSWAP_MAIN_FIL_ADDRESS || ''

export const REACT_APP_F_AITD_ADDRESS = process.env.REACT_APP_F_AITD_ADDRESS || ''
export const REACT_APP_T_AITD_ADDRESS = process.env.REACT_APP_T_AITD_ADDRESS || ''
export const REACT_APP_S_AITD_ADDRESS = process.env.REACT_APP_S_AITD_ADDRESS || ''
export const REACT_APP_K_AITD_ADDRESS = process.env.REACT_APP_K_AITD_ADDRESS || ''
export const REACT_APP_G_AITD_ADDRESS = process.env.REACT_APP_G_AITD_ADDRESS || ''
export const REACT_APP_W_AITD_ADDRESS = process.env.REACT_APP_W_AITD_ADDRESS || ''

export const REACT_APP_Y_YOHO_AITD_ADDRESS = process.env.REACT_APP_Y_YOHO_AITD_ADDRESS || ''
export const REACT_APP_W_YOHO_AITD_ADDRESS = process.env.REACT_APP_W_YOHO_AITD_ADDRESS || ''
export const REACT_APP_BMT_ADDRESS = process.env.REACT_APP_BMT_ADDRESS || ''
export const REACT_APP_OXG_ADDRESS = process.env.REACT_APP_OXG_ADDRESS || ''
export const REACT_APP_OXX_ADDRESS = process.env.REACT_APP_OXX_ADDRESS || ''
export const REACT_APP_CADS_ADDRESS = process.env.REACT_APP_CADS_ADDRESS || ''
export const REACT_APP_YOHO_ADDRESS = process.env.REACT_APP_YOHO_ADDRESS || ''
export const REACT_APP_BNB_ADDRESS = process.env.REACT_APP_BNB_ADDRESS || ''
export const REACT_APP_CAC_ADDRESS = process.env.REACT_APP_CAC_ADDRESS || ''
export const REACT_APP_CACII_ADDRESS = process.env.REACT_APP_CACII_ADDRESS || ''
export const REACT_APP_CADSII_ADDRESS = process.env.REACT_APP_CADSII_ADDRESS || ''
export const REACT_APP_MANEKI_ADDRESS = process.env.REACT_APP_MANEKI_ADDRESS || ''
export const REACT_APP_ATM_ADDRESS = process.env.REACT_APP_ATM_ADDRESS || ''

// 邀请质押 v2
export const REACT_APP_INVITE_ADDRESS_V2 = process.env.REACT_APP_INVITE_ADDRESS_V2

// qiao
export const REACT_APP_BRIDGE_URL = process.env.REACT_APP_BRIDGE_URL
// 赠卡
export const REACT_APP_GIFT_CARDS_API = process.env.REACT_APP_GIFT_CARDS_API

// const REACT_APP_AITDSWAP_MAIN_ADA_ADDRESS = process.env.REACT_APP_AITDSWAP_MAIN_ADA_ADDRESS || ''
export { PRELOADED_PROPOSALS } from './proposals'

export const WUSDT = {
  [ChainId.AITDTESTNET]: new Token(ChainId.AITDTESTNET, REACT_APP_AITDSWAP_USDT_ADDRESS, 6, 'USDT', 'Tether USD'),
  [ChainId.AITDMAINNET]: new Token(ChainId.AITDMAINNET, REACT_APP_AITDSWAP_USDT_ADDRESS, 6, 'USDT', 'Tether USD')
}

// a list of tokens by chain
type ChainTokenList = {
  readonly [chainId in ChainId]: Token[]
}

export enum SWAP_TYPE {
  AIA = 'AIA',
  USDT = 'USDT',
  CRET = 'CRET',
  BTC = 'BTC',
  ETH = 'ETH',
  ADA = 'ADA',
  UNI = 'UNI',
  SAND = 'SAND',
  DYDX = 'DYDX',
  MASK = 'MASK',
  MATIC = 'MATIC',
  GTC = 'GTC',
  STA = 'STA',
  SHIB = 'SHIB',
  INCH = '1INCH',
  MANA = 'MANA',
  FIL = 'FIL',
  // FAIA = "F-AIA",
  // TAIA = "T-AIA",
  // SAIA = "S-AIA",
  // KIAIA = "KiAIA",
  // GAAIA = "GaAIA",
  // WINAIA = "WinAIA",
  WYOHO = 'wYOHO',
  YYOHO = 'yYOHO',
  CAD = 'CAD',
  BMT = 'BMT',
  OXG = 'OXG',
  OXX = 'OXX',
  CADS = 'CADS',
  YOHO = 'YOHO',
  BNB = 'BNB',
  CAC = 'CAC',
  CACII = 'CAC',
  CADSII = 'CADS',
  MANEKI = 'MANEKI',
  ATM = 'ATM'
}

export const AMPL = new Token(ChainId.MAINNET, '0xD46bA6D942050d489DBd938a2C909A5d5039A161', 9, 'AMPL', 'Ampleforth')
export const DAI = new Token(ChainId.MAINNET, '0x6B175474E89094C44Da98b954EedeAC495271d0F', 18, 'DAI', 'Dai Stablecoin')
export const USDC = new Token(ChainId.MAINNET, '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48', 6, 'USDC', 'USD//C')
// export const USDT = new Token(ChainId.MAINNET, '0xdAC17F958D2ee523a2206206994597C13D831ec7', 6, 'USDT', 'Tether USD')
// export const WBTC = new Token(ChainId.MAINNET, '0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599', 8, 'WBTC', 'Wrapped BTC')
export const FEI = new Token(ChainId.MAINNET, '0x956F47F50A910163D8BF957Cf5846D573E7f87CA', 18, 'FEI', 'Fei USD')
export const TRIBE = new Token(ChainId.MAINNET, '0xc7283b66Eb1EB5FB86327f08e1B5816b0720212B', 18, 'TRIBE', 'Tribe')
export const FRAX = new Token(ChainId.MAINNET, '0x853d955aCEf822Db058eb8505911ED77F175b99e', 18, 'FRAX', 'Frax')
export const FXS = new Token(ChainId.MAINNET, '0x3432B6A60D23Ca0dFCa7761B7ab56459D9C964D0', 18, 'FXS', 'Frax Share')
export const renBTC = new Token(ChainId.MAINNET, '0xEB4C2781e4ebA804CE9a9803C67d0893436bB27D', 8, 'renBTC', 'renBTC')

//1、USDT 合约部署
export const USDT = new Token(NETWORK_CHAIN_ID, REACT_APP_AITDSWAP_USDT_ADDRESS, 6, 'USDT', 'Tether USD')
//2、weth9 合约部署
export const WAIA = new Token(NETWORK_CHAIN_ID, REACT_APP_AITDSWAP_WAITD_ADDRESS, 18, 'AIA', 'Wrapped AIA') //1、USDT 合约部署
// cret 合约部署
export const CRET = new Token(NETWORK_CHAIN_ID, REACT_APP_CRET_ADDRESS, 18, 'CRET', 'CRET Coin')
// CAD 代币合约
export const CAD = new Token(NETWORK_CHAIN_ID, REACT_APP_CAD_ADDRESS, 18, 'CAD', 'CAD Coin')
// btc 合约部署
export const BTC = new Token(NETWORK_CHAIN_ID, REACT_APP_AITDSWAP__MAIN_BTC_ADDRESS, 8, 'BTC', 'AIA-Peg ABTC Token')
// eth 合约部署
export const ETH = new Token(NETWORK_CHAIN_ID, REACT_APP_AITDSWAP_MAIN_ETH_ADDRESS, 18, 'ETH', 'AIA-Peg AETH Token')
// UNI 合约部署
export const UNIS = new Token(NETWORK_CHAIN_ID, REACT_APP_AITDSWAP_MAIN_UNI_ADDRESS, 18, 'UNI', 'AIA swapper Uniswap')

// SAND 合约部署
export const SAND = new Token(NETWORK_CHAIN_ID, REACT_APP_AITDSWAP_MAIN_SAND_ADDRESS, 18, 'SAND', 'AIA-Peg SAND Token')
// DYDX 合约部署
export const DYDX = new Token(NETWORK_CHAIN_ID, REACT_APP_AITDSWAP_MAIN_DYDX_ADDRESS, 18, 'DYDX', 'AIA-Peg DYDX Token')
// MASK 合约部署
export const MASK = new Token(NETWORK_CHAIN_ID, REACT_APP_AITDSWAP_MAIN_MASK_ADDRESS, 18, 'MASK', 'AIA-Peg MASK Token')
// MATIC 合约部署
export const MATIC = new Token(
  NETWORK_CHAIN_ID,
  REACT_APP_AITDSWAP_MAIN_MATIC_ADDRESS,
  18,
  'MATIC',
  'AIA-Peg MATIC Token'
)
// GTC 合约部署
export const GTC = new Token(NETWORK_CHAIN_ID, REACT_APP_AITDSWAP_MAIN_GTC_ADDRESS, 18, 'GTC', 'AIA-Peg GTC Token')
// ADA 合约部署
// export const ADA = new Token(NETWORK_CHAIN_ID, REACT_APP_AITDSWAP_MAIN_ADA_ADDRESS, 6, 'ADA', 'AIA-Peg AADA Token')

// SAT 合约部署
export const STA = new Token(NETWORK_CHAIN_ID, REACT_APP_AITDSWAP_MAIN_STA_ADDRESS, 18, 'STA', 'AIA-Peg ASTA Token')

// SHIB 合约部署
export const SHIB = new Token(
  NETWORK_CHAIN_ID,
  REACT_APP_AITDSWAP_MAIN_SHIB_ADDRESS,
  18,
  'SHIB',
  'AIA Swapper SHIBA INU'
)
// 1INCH 合约部署
export const INCH = new Token(
  NETWORK_CHAIN_ID,
  REACT_APP_AITDSWAP_MAIN_1INCH_ADDRESS,
  18,
  '1INCH',
  'AIA Swapper 1INCH INU'
)

// MANA 合约部署
export const MANA = new Token(
  NETWORK_CHAIN_ID,
  REACT_APP_AITDSWAP_MAIN_MANA_ADDRESS,
  18,
  'MANA',
  'AIA Swapper MANA INU'
)

// MANA 合约部署
export const FIL = new Token(NETWORK_CHAIN_ID, REACT_APP_AITDSWAP_MAIN_FIL_ADDRESS, 18, 'FIL', 'AIA Swapper FIL INU')

// BMT 代币合约
export const BMT = new Token(NETWORK_CHAIN_ID, REACT_APP_BMT_ADDRESS, 18, 'BMT', 'BMT Coin')

// OXG 代币合约
export const OXG = new Token(NETWORK_CHAIN_ID, REACT_APP_OXG_ADDRESS, 18, 'OXG', 'OXGame Token')

// OXX 代币合约
export const OXX = new Token(NETWORK_CHAIN_ID, REACT_APP_OXX_ADDRESS, 18, 'OXX', 'OXGame XPoints')

// CADS 代币合约
export const CADS = new Token(NETWORK_CHAIN_ID, REACT_APP_CADS_ADDRESS, 18, 'CADS', 'CADS Coin')

// YOHO 代币合约
export const YOHO = new Token(NETWORK_CHAIN_ID, REACT_APP_YOHO_ADDRESS, 18, 'YOHO', 'AITD Yoho')

// BNB 代币合约
export const BNB = new Token(NETWORK_CHAIN_ID, REACT_APP_BNB_ADDRESS, 18, 'BNB', 'BNB Coin')

// CAC 代币合约
export const CAC = new Token(NETWORK_CHAIN_ID, REACT_APP_CAC_ADDRESS, 18, 'CAC', 'CAC Coin')

// CACII 代币合约
export const CACII = new Token(NETWORK_CHAIN_ID, REACT_APP_CACII_ADDRESS, 18, 'CAC', 'CAC Coin')

// CADSII 代币合约
export const CADSII = new Token(NETWORK_CHAIN_ID, REACT_APP_CADSII_ADDRESS, 18, 'CADS', 'CADS Coin')

// MANEKI 代币合约
export const MANEKI = new Token(NETWORK_CHAIN_ID, REACT_APP_MANEKI_ADDRESS, 18, 'MANEKI', 'MANEKI Coin')

// MANEKI 代币合约
export const ATM = new Token(NETWORK_CHAIN_ID, REACT_APP_ATM_ADDRESS, 18, 'ATM', 'ATM Coin')

// FAIA 合约部署
// export const FAIA = new Token(NETWORK_CHAIN_ID, REACT_APP_F_AITD_ADDRESS, 18, 'F-AIA', 'AIA Swapper FIL INU')
// MANA TAIA
// export const TAIA = new Token(NETWORK_CHAIN_ID, REACT_APP_T_AITD_ADDRESS, 18, 'T-AIA', 'AIA Swapper FIL INU')
// SAIA 合约部署
// export const SAIA = new Token(NETWORK_CHAIN_ID, REACT_APP_S_AITD_ADDRESS, 18, 'S-AIA', 'AIA Swapper FIL INU')

// KiAIA 合约部署
// export const KiAIA = new Token(NETWORK_CHAIN_ID, REACT_APP_K_AITD_ADDRESS, 18, 'KiAIA', 'AIA Swapper FIL INU')
// // GaAIA 合约部署
// export const GaAIA = new Token(NETWORK_CHAIN_ID, REACT_APP_G_AITD_ADDRESS, 18, 'GaAIA', 'AIA Swapper FIL INU')
// // WinAIA 合约部署
// export const WinAIA = new Token(NETWORK_CHAIN_ID, REACT_APP_W_AITD_ADDRESS, 18, 'WinAIA', 'AIA Swapper FIL INU')

// wYOHO 合约部署
export const WYOHO = new Token(NETWORK_CHAIN_ID, REACT_APP_W_YOHO_AITD_ADDRESS, 18, 'wYOHO', 'wYOHO coin')
// yYOHO 合约部署
export const YYOHO = new Token(NETWORK_CHAIN_ID, REACT_APP_Y_YOHO_AITD_ADDRESS, 18, 'yYOHO', 'yYOHO coin ')

// Block time here is slightly higher (~1s) than average in order to avoid ongoing proposals past the displayed time
export const AVERAGE_BLOCK_TIME_IN_SECS = 13
export const PROPOSAL_LENGTH_IN_BLOCKS = 40_320
export const PROPOSAL_LENGTH_IN_SECS = AVERAGE_BLOCK_TIME_IN_SECS * PROPOSAL_LENGTH_IN_BLOCKS

export const GOVERNANCE_ADDRESS = '0xA1e13b4109b8564804a59e19dBbbe24A4111b50b'

export const TIMELOCK_ADDRESS = '0x1a9C8182C09F50C8318d769245beA52c32BE35BC'

const UNI_ADDRESS = REACT_APP_AITDSWAP_WAITD_ADDRESS
// const AITD_ADDRESS = '0x2e8376a5DA20223c151B6947cDef76508CfcC100'
export const UNI: { [chainId in ChainId]: Token } = {
  [ChainId.MAINNET]: new Token(ChainId.MAINNET, UNI_ADDRESS, 18, 'UNI', 'AiaSwap'),
  [ChainId.RINKEBY]: new Token(ChainId.RINKEBY, UNI_ADDRESS, 18, 'UNI', 'Uniswap'),
  [ChainId.ROPSTEN]: new Token(ChainId.ROPSTEN, UNI_ADDRESS, 18, 'UNI', 'Uniswap'),
  [ChainId.GÖRLI]: new Token(ChainId.GÖRLI, UNI_ADDRESS, 18, 'UNI', 'Uniswap'),
  [ChainId.KOVAN]: new Token(ChainId.KOVAN, UNI_ADDRESS, 18, 'UNI', 'Uniswap'),
  [ChainId.AITDTESTNET]: new Token(ChainId.AITDTESTNET, REACT_APP_AITDSWAP_WAITD_ADDRESS, 18, 'WAIA', 'AiaSwap'),
  [ChainId.AITDMAINNET]: new Token(ChainId.AITDMAINNET, REACT_APP_AITDSWAP_WAITD_ADDRESS, 18, 'WAIA', 'AiaSwap')
}

export const COMMON_CONTRACT_NAMES: { [address: string]: string } = {
  [UNI_ADDRESS]: 'UNI',
  [GOVERNANCE_ADDRESS]: 'Governance',
  [TIMELOCK_ADDRESS]: 'Timelock'
}

// TODO: specify merkle distributor for mainnet
export const MERKLE_DISTRIBUTOR_ADDRESS: { [chainId in ChainId]?: string } = {
  [ChainId.MAINNET]: '0x090D4613473dEE047c3f2706764f49E0821D256e'
}

const WETH_ONLY: ChainTokenList = {
  [ChainId.MAINNET]: [WETH[ChainId.MAINNET]],
  [ChainId.ROPSTEN]: [WETH[ChainId.ROPSTEN]],
  [ChainId.RINKEBY]: [WETH[ChainId.RINKEBY]],
  [ChainId.GÖRLI]: [WETH[ChainId.GÖRLI]],
  [ChainId.KOVAN]: [WETH[ChainId.KOVAN]],
  [ChainId.AITDTESTNET]: [WETH[ChainId.AITDTESTNET]],
  [ChainId.AITDMAINNET]: [WETH[ChainId.AITDMAINNET]]
}

// used to construct intermediary pairs for trading
export const BASES_TO_CHECK_TRADES_AGAINST: ChainTokenList = {
  ...WETH_ONLY,
  [ChainId.MAINNET]: [...WETH_ONLY[ChainId.MAINNET], DAI, USDC, USDT],
  [ChainId.AITDTESTNET]: [USDT, WAIA],
  [ChainId.AITDMAINNET]: [USDT, WAIA]
}

export const ADDITIONAL_BASES: { [chainId in ChainId]?: { [tokenAddress: string]: Token[] } } = {
  [ChainId.MAINNET]: {
    '0xA948E86885e12Fb09AfEF8C52142EBDbDf73cD18': [new Token(ChainId.MAINNET, UNI_ADDRESS, 18, 'UNI', 'Uniswap')],
    '0x561a4717537ff4AF5c687328c0f7E90a319705C0': [new Token(ChainId.MAINNET, UNI_ADDRESS, 18, 'UNI', 'Uniswap')],
    [FEI.address]: [TRIBE],
    [TRIBE.address]: [FEI],
    [FRAX.address]: [FXS],
    [FXS.address]: [FRAX]
    // [WBTC.address]: [renBTC],
    // [renBTC.address]: [WBTC]
  }
}

/**
 * Some tokens can only be swapped via certain pairs, so we override the list of bases that are considered for these
 * tokens.
 */
export const CUSTOM_BASES: { [chainId in ChainId]?: { [tokenAddress: string]: Token[] } } = {
  [ChainId.MAINNET]: {
    [AMPL.address]: [DAI, WETH[ChainId.MAINNET]]
  }
}

// used for display in the default list when adding liquidity
export const SUGGESTED_BASES: ChainTokenList = {
  ...WETH_ONLY,
  [ChainId.MAINNET]: [...WETH_ONLY[ChainId.MAINNET], DAI, USDC, USDT]
}

// used to construct the list of all pairs we consider by default in the frontend
export const BASES_TO_TRACK_LIQUIDITY_FOR: ChainTokenList = {
  ...WETH_ONLY,
  [ChainId.MAINNET]: [...WETH_ONLY[ChainId.MAINNET], DAI, USDC, USDT],
  [ChainId.AITDTESTNET]: [USDT, WAIA],
  [ChainId.AITDMAINNET]: [USDT, WAIA]
}

export const PINNED_PAIRS: { readonly [chainId in ChainId]?: [Token, Token][] } = {
  [ChainId.MAINNET]: [
    [
      new Token(ChainId.MAINNET, '0x5d3a536E4D6DbD6114cc1Ead35777bAB948E3643', 8, 'cDAI', 'Compound Dai'),
      new Token(ChainId.MAINNET, '0x39AA39c021dfbaE8faC545936693aC917d5E7563', 8, 'cUSDC', 'Compound USD Coin')
    ],
    [USDC, USDT],
    [DAI, USDT]
  ]
}

export interface WalletInfo {
  connector?: AbstractConnector
  name: string
  iconName: string
  description: string
  href: string | null
  color: string
  primary?: true
  mobile?: true
  mobileOnly?: true
}

export const SUPPORTED_WALLETS: { [key: string]: WalletInfo } = {
  INJECTED: {
    connector: injected,
    name: 'Injected',
    iconName: 'arrow-right.svg',
    description: 'Injected web3 provider.',
    href: null,
    color: '#010101',
    primary: true
  },
  METAMASK: {
    connector: injected,
    name: 'MetaMask',
    iconName: 'metamask.png',
    description: 'Easy-to-use browser extension.',
    href: null,
    color: '#E8831D'
  },
  TOKENPOCKET: {
    connector: tpInjected,
    name: 'TokenPocket',
    iconName: 'tp.svg',
    description: 'Easy-to-use browser extension.',
    href: null,
    color: '#E8831D'
  },
  WALLET_CONNECT: {
    connector: walletconnect,
    name: 'WalletConnect',
    iconName: 'walletConnectIcon.svg',
    description: 'Connect to Trust Wallet, Rainbow Wallet and more...',
    href: null,
    color: '#4196FC',
    mobile: true
  },
  WALLET_LINK: {
    connector: walletlink,
    name: 'Coinbase Wallet',
    iconName: 'coinbaseWalletIcon.svg',
    description: 'Use Coinbase Wallet app on mobile device',
    href: null,
    color: '#315CF5'
  },
  COINBASE_LINK: {
    name: 'Open in Coinbase Wallet',
    iconName: 'coinbaseWalletIcon.svg',
    description: 'Open in Coinbase Wallet app.',
    href: 'https://go.cb-w.com/mtUDhEZPy1',
    color: '#315CF5',
    mobile: true,
    mobileOnly: true
  }
  // FORTMATIC: {
  //   connector: fortmatic,
  //   name: 'Fortmatic',
  //   iconName: 'fortmaticIcon.png',
  //   description: 'Login using Fortmatic hosted wallet',
  //   href: null,
  //   color: '#6748FF',
  //   mobile: true
  // },
  // Portis: {
  //   connector: portis,
  //   name: 'Portis',
  //   iconName: 'portisIcon.png',
  //   description: 'Login using Portis hosted wallet',
  //   href: null,
  //   color: '#4A6C9B',
  //   mobile: true
  // }
}

export const NetworkContextName = 'NETWORK'

// default allowed slippage, in bips
export const INITIAL_ALLOWED_SLIPPAGE = 50
// 20 minutes, denominated in seconds
export const DEFAULT_DEADLINE_FROM_NOW = 60 * 20

// used for rewards deadlines
export const BIG_INT_SECONDS_IN_WEEK = JSBI.BigInt(60 * 60 * 24 * 7)

export const BIG_INT_ZERO = JSBI.BigInt(0)

// one basis point
export const ONE_BIPS = new Percent(JSBI.BigInt(1), JSBI.BigInt(10000))
export const BIPS_BASE = JSBI.BigInt(10000)
// used for warning states
export const ALLOWED_PRICE_IMPACT_LOW: Percent = new Percent(JSBI.BigInt(100), BIPS_BASE) // 1%
export const ALLOWED_PRICE_IMPACT_MEDIUM: Percent = new Percent(JSBI.BigInt(300), BIPS_BASE) // 3%
export const ALLOWED_PRICE_IMPACT_HIGH: Percent = new Percent(JSBI.BigInt(500), BIPS_BASE) // 5%
// if the price slippage exceeds this number, force the user to type 'confirm' to execute
export const PRICE_IMPACT_WITHOUT_FEE_CONFIRM_MIN: Percent = new Percent(JSBI.BigInt(1000), BIPS_BASE) // 10%
// for non expert mode disable swaps above this
export const BLOCKED_PRICE_IMPACT_NON_EXPERT: Percent = new Percent(JSBI.BigInt(1500), BIPS_BASE) // 15%

// used to ensure the user doesn't send so much AIA so they end up with <.01
// export const MIN_ETH: JSBI = JSBI.exponentiate(JSBI.BigInt(10), JSBI.BigInt(16)) // .01 AIA

export const MIN_ETH: JSBI = JSBI.multiply(JSBI.BigInt(Math.pow(10, 16)), JSBI.BigInt(2)) // .01 AIA
export const BETTER_TRADE_LESS_HOPS_THRESHOLD = new Percent(JSBI.BigInt(50), JSBI.BigInt(10000))

export const ZERO_PERCENT = new Percent('0')
export const ONE_HUNDRED_PERCENT = new Percent('1')

// SDN OFAC addresses
export const BLOCKED_ADDRESSES: string[] = [
  '0x7F367cC41522cE07553e823bf3be79A889DEbe1B',
  '0xd882cFc20F52f2599D84b8e8D58C7FB62cfE344b',
  '0x901bb9583b24D97e995513C6778dc6888AB6870e',
  '0xA7e5d5A720f06526557c513402f2e6B5fA20b008',
  '0x8576aCC5C05D6Ce88f4e49bf65BdF0C62F91353C'
]

// 质押赠卡合约地址
export const REACT_APP_GIFT_CARD = process.env.REACT_APP_GIFT_CARD

export const PageSize = 10

// Android返回postMessage 类型对应表

export const AndroidPostMessageType = {
  QRCode: 10
}
export const tryParseAmountInfo = {
  yoho: '1000'
}
