
import { useEffect, useState }
    from 'react'
import axios from "axios"
import { PENDING_ORDERLIST_DATA, FINISHED_ORDERLIST_DATA, ORDER_DETAIL } from 'state/limitOrders/types';
import { useActiveWeb3React } from 'hooks';
import { LimitOrderStatus, ORDER_CATEGORY } from '../types';
import { useOrderList } from 'state/limitOrders/hooks';
import { syncOrderToLocalStorage } from '../hooks/useGelatoLimitOrdersHistory';
import { NETWORK_CHAIN_ID } from 'state/stake/hooks';
import { getPendingOrder, savePendingOrders } from './localStorageOrders';
axios.defaults.timeout = 1000 * 5;
let cancel: any = null
let CancelToken = axios.CancelToken;


export const useTotalAwiat = function (orderCategory: ORDER_CATEGORY, page: number) {
    const { account, chainId } = useActiveWeb3React()
    const { onChangeOrderList, onChangeHistoryTotalOrderList } = useOrderList()
    const [total, setTotal] = useState(0)
    useEffect(() => {
        let timer: any = null
        const url = orderCategory === ORDER_CATEGORY.Open ? PENDING_ORDERLIST_DATA : FINISHED_ORDERLIST_DATA
        const getStatus = (key: string | number): string => {
            let status = LimitOrderStatus.OPEN
            switch (key) {
                case 2:
                    status = LimitOrderStatus.EXECUTED
                    break;
                case 3:
                    status = LimitOrderStatus.CANCELLED
                    break;
                case 4:
                    // LimitOrderStatus.DEALING
                    status = LimitOrderStatus.OPEN
                    break;
                default:
            }
            return status
        }
        const geOrderListFun = async () => {
            const getPendingOrderList = getPendingOrder(chainId,account)

            try {
                const { data } = await axios.get(url + '?userAddress=' + account?.toLocaleLowerCase() + '&pageNum=' + page, {
                    cancelToken: new CancelToken(function executor(c) {
                        cancel = c;
                    })
                })
                if (getPendingOrderList && getPendingOrderList.length > 0) {
                    let newPendingOrderList: any = [];
                    (getPendingOrderList || []).map((i: any) => {
                        newPendingOrderList.push('"' + i.id + '"')
                    })
                    // 查询订单是否已成交
                    const { data: detailOrder } = await axios.get(ORDER_DETAIL + newPendingOrderList.join(","))

                    if (detailOrder && detailOrder?.data.length > 0) {
                        let setPendingOrderList = getPendingOrderList.map((i: any) => {
                            const CANCELLED = detailOrder?.data.some((ii: any) => (ii.id === i.id && Number(ii.status) === 3))
                            const EXECUTED = detailOrder?.data.some((ii: any) => (ii.id === i.id && Number(ii.status) === 2))
                            if (EXECUTED) {
                                return undefined
                            }
                            if (CANCELLED) {
                                return undefined
                            }
                            return i
                        }).filter((i: any) => i)
                        // if (setPendingOrderList.length > 0) {
                        //     setPendingOrderList = setPendingOrderList.map((i: any) => {
                        //         const EXECUTED = detailOrder?.data.some((ii: any) => (ii.id === i.id && Number(ii.status) === 2))
                        //         if (EXECUTED) {
                        //             return undefined
                        //         } else {
                        //             return i
                        //         }
                        //     }).filter((i: any) => i)
                        // }
                        savePendingOrders(chainId, account, setPendingOrderList)
                    }
                }
                if (data && data?.data.length > 0) {
                    const list = data?.data.map((item: any) => {
                        item.status = getStatus(item.status)
                        item.data = item.data
                        return item
                    })
                    if (orderCategory === ORDER_CATEGORY.Open) {

                        await syncOrderToLocalStorage({
                            orders: [...list],
                            chainId,
                            account,
                        })


                    }
                    orderCategory === ORDER_CATEGORY.Open ? onChangeOrderList(list) : onChangeHistoryTotalOrderList(list)
                } else {
                    await syncOrderToLocalStorage({
                        orders: [],
                        chainId,
                        account,
                    })
                    onChangeHistoryTotalOrderList([])
                    onChangeOrderList([])
                    // if (page === 1) {
                    //     saveCancelOrders(chainId, account,[])
                    //     saveTotalOrders(chainId, account, [])
                    // }
                }
                setTotal(data?.total || 0)
            } catch (error) {
                console.info(error)
                const err:any = error
                if(err?.message==="Network Error"){
                    setTotal(0)
                    onChangeHistoryTotalOrderList([])
                    onChangeOrderList([])
                }
            }
        }
        cancel && cancel()
        timer && clearInterval(timer)
        if (NETWORK_CHAIN_ID === chainId&&account) {
           geOrderListFun()
            timer = setInterval(() => { geOrderListFun() }, 6000)
        } else {
            onChangeHistoryTotalOrderList([])
            onChangeOrderList([])
            setTotal(0)
        }

        return () => {
            clearInterval(timer)
        }
    }, [account, chainId, orderCategory, page, NETWORK_CHAIN_ID])


    return total;
};

