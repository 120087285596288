import React from 'react'
import { useTranslation } from 'react-i18next';
import { Text } from 'rebass'

const MobileOrderStatus: React.FC<{
    formattedOrder: any
    showOpenTag?: boolean
}> = ({ formattedOrder, showOpenTag = false }) => {
    const { t } = useTranslation()
    const { isOpen, isSubmissionPending, isCancelled, isPengding,isCancellationPending,isDealing, isExecuted } = formattedOrder
    if (isOpen && !isSubmissionPending && showOpenTag) {
        return <Text fontWeight={"800"} color="#FF9900" >{t('Open')}</Text>
    }
    if (isOpen && isSubmissionPending) {
        return <Text fontWeight={"800"} color="#FF9900" >{t('Pending')}</Text>
    }
    if (isPengding && isSubmissionPending) {
        return <Text fontWeight={"800"} color="#FF9900" >{t('Pending')}</Text>
    }
    if (isPengding) {
        return <Text  fontWeight={"800"} color="#FF9900" >{t('Pending')}</Text>
      }
    if (isCancelled && !isCancellationPending) {
        return <Text fontWeight={"800"} color="#FA8B8B" >{t('Cancelled')}</Text>
    }
    if (isCancellationPending) {
        return <Text fontWeight={"800"} color="#FA8B8B" >{t('Cancelling')}</Text>
    }
    if (isDealing) {
        return <Text fontWeight={"800"} color="#FF9900" >{t('success')}</Text>
    }
   
    if (isExecuted) {
        return <Text fontWeight={"800"} color="#5273EF">{t('Filled')}</Text>
    }
    return null
}

export default MobileOrderStatus
