/**
 * @file 发布订阅的工具实现
 */

type Func = Function & {
  // @ts-ignore
  [key: symbol]: boolean;
};

// type Func = {
//   [key: symbol]: boolean;
//   (...args: any[]): any;
// };

interface PubSubMap {
  [key: string]: Function[];
}

const pubSubMap: PubSubMap = {};
const onceKey = Symbol('pub-sub-onceKey');

// 方法组合 及 链式调用中转
const pubSub = {
  on,
  once,
  off,
  emit,
};

/**
 * 绑定事件 - 订阅事件
 * @param eventName
 * @param func
 */
export function on(eventName: string, func: Function) {
  if (!pubSubMap[eventName]) {
    pubSubMap[eventName] = [];
  }
  pubSubMap[eventName].push(func);
  return pubSub;
}

export function once(eventName: string, func: Func) {
  // @ts-ignore
  func[onceKey] = true;
  return on(eventName, func);
}

/**
 * 解绑事件 - 取消订阅事件
 * @param eventName
 * @param func
 */
export function off(eventName: string, func?: Function) {
  const events = pubSubMap[eventName];
  if (Array.isArray(events)) {
    if (func) {
      const funcIndex = events.findIndex((f) => f === func);
      if (funcIndex > -1) {
        pubSubMap[eventName].splice(funcIndex, 1);
      }
    } else {
      pubSubMap[eventName] = [];
    }
  }
  return pubSub;
}

/**
 * 触发事件 - 事件发布|分发
 * @param eventName
 * @param args
 */
export function emit(eventName: string, ...args: any[]) {
  let events = pubSubMap[eventName];
  if (Array.isArray(events)) {
    events = events.filter((func) => {
      func(...args);
      // @ts-ignore
      return !(func as Func)[onceKey];
    });
    pubSubMap[eventName] = events;
  } else {
  }
  return pubSub;
}

export default pubSub;
