// import React from 'react'
// import styled from 'styled-components'

// import { AlertTriangle, X } from 'react-feather'
// import { useURLWarningToggle, useURLWarningVisible } from '../../state/user/hooks'
// import { isMobile } from 'react-device-detect'

// const PhishAlert = styled.div<{ isActive: any }>`
//   width: 100%;
//   padding: 6px 6px;
//   background-color: ${({ theme }) => theme.blue1};
//   color: white;
//   font-size: 11px;
//   justify-content: space-between;
//   align-items: center;
//   display: ${({ isActive }) => (isActive ? 'flex' : 'none')};
// `

// export const StyledClose = styled(X)`
//   :hover {
//     cursor: pointer;
//   }
// `

export default function URLWarning() {
  // const toggleURLWarning = useURLWarningToggle()
  // const showURLWarning = useURLWarningVisible()
  return null;
  // return isMobile ? (
  //   <PhishAlert isActive={showURLWarning}>
  //     <div style={{ display: 'flex' }}>
  //       <AlertTriangle style={{ marginRight: 6 }} size={12} /> Make sure the URL is
  //       <code style={{ padding: '0 4px', display: 'inline', fontWeight: 'bold' }}>swap-test.aitd.io</code>
  //     </div>
  //     <StyledClose size={12} onClick={toggleURLWarning} />
  //   </PhishAlert>
  // ) : window.location.hostname === 'swap-test.aitd.io' ? (
  //   <PhishAlert isActive={showURLWarning}>
  //     <div style={{ display: 'flex' }}>
  //       <AlertTriangle style={{ marginRight: 6 }} size={12} /> Always make sure the URL is
  //       <code style={{ padding: '0 4px', display: 'inline', fontWeight: 'bold' }}>swap-test.aitd.io</code> - bookmark it
  //       to be safe.
  //     </div>
  //     <StyledClose size={12} onClick={toggleURLWarning} />
  //   </PhishAlert>
  // ) : null
}
