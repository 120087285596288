
import React from "react"
import { Message, MessageText } from 'components/Message'
import { useTranslation } from 'react-i18next'

const LimitOrderDisclaimer = () => {
  const { t } = useTranslation()
  return (
    <Message variant="warning" mt="24px">
      <MessageText sx={{ flex: '1' }}>
        <b>{t('Real Execution Price')}:</b>
        <ol>
          <li>
            {t(
              'Your execution gas fees are paid for by the spread between your specified price and the real execution price.',
            )}
          </li>
          <li>
            {t(
              'Gas fees are volatile and thus the exact market price at which your order will execute is unpredictable.',
            )}
          </li>
          <li>{t('It might take much longer than you expected to reach the price that fills your order + fees.')}</li>
        </ol>
      </MessageText>
    </Message>
  )
}

export default LimitOrderDisclaimer
