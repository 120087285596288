import React, { memo, useState } from 'react'
import styled from 'styled-components'
import CompactRow from './CompactRow'
import  DetailLimitOrderModal  from '../Modal'
import { Table, Td } from './LoadingTable'

const RowStyle = styled.tr`
  cursor: pointer;
`

const CompactLimitOrderTable = ({ orders }: any) => {
    const [isOpen, setIsopen] = useState(false)
    const [cuurentOrder, setCurrentOrder] = useState<any>(undefined)
    const onDismiss = () => setIsopen(false)
    const showClickModalFn = (order: any) => {
      
        setIsopen(true)
        setCurrentOrder(order)
    }
    return (
        <>
            {
                isOpen&&<DetailLimitOrderModal
                    order={cuurentOrder}
                    isOpen={isOpen}
                    onDismiss={onDismiss} />
            }
            <Table>
                <tbody>
                    {orders.map((order: any, key: number) => (
                        <RowStyle key={order.id}>
                            <Td style={{ paddingBottom: 0 }}>
                                <CompactRow order={order} isLast={((orders || []).length - 1) === key} showClickModal={() => showClickModalFn(order)} />
                            </Td>
                        </RowStyle>
                    ))}
                </tbody>
            </Table>
        </>
    )
}





export default memo(CompactLimitOrderTable)
