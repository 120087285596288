import { JSBI, Pair, Percent, TokenAmount } from '@uniswap/sdk'
import { darken } from 'polished'
import React, { useContext, useState } from 'react'
import { ChevronDown, ChevronUp } from 'react-feather'
import { Link } from 'react-router-dom'
import { Text } from 'rebass'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useTotalSupply } from '../../data/TotalSupply'

import { useActiveWeb3React } from '../../hooks'
import { useTokenBalance } from '../../state/wallet/hooks'
import { TYPE } from '../../theme'
import { currencyId } from '../../utils/currencyId'
import { unwrappedToken } from '../../utils/wrappedCurrency'
import { ButtonPrimary, ButtonEmpty } from '../Button'
import { transparentize } from 'polished'
import { CardNoise } from '../earn/styled'

import { useColor } from '../../hooks/useColor'

import Card, { LightCard,WhiteCard } from '../Card'
import { AutoColumn } from '../Column'
// import CurrencyLogo from '../CurrencyLogo'
import DoubleCurrencyLogo from '../DoubleLogo'
import { RowBetween, RowFixed, AutoRow } from '../Row'
import { Dots } from '../swap/styleds'
import { BIG_INT_ZERO } from '../../constants'
import { ThemeContext } from 'styled-components'
export const FixedHeightRow = styled(RowBetween)`
  height: 24px;
`

export const HoverCard = styled(Card)`
  border: 1px solid transparent;
  :hover {
    border: 1px solid ${({ theme }) => darken(0.06, theme.bg2)};
  }
`
const StyledPositionCard = styled(LightCard) <{ bgColor: any }>`
  border: none;
  background-color: ${({ theme }) => theme.white};
  /* background: ${({ theme, bgColor }) =>
    `radial-gradient(91.85% 100% at 1.84% 0%, ${transparentize(0.8, bgColor)} 0%, ${theme.bg3} 100%) `}; */
  position: relative;
  overflow: hidden;
  box-shadow: 0px 3px 0px 0px rgba(14, 37, 74, 0.11);
`

const ButtonPrimaryText = styled(ButtonPrimary)`
  border: none;
  background-color: ${({ theme }) => theme.white};
  display: flex;
  justify-content:space-between;
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 0;
  &:focus {
    box-shadow: none;
    background-color: transparent;
  }
  &:hover {
    background-color: transparent;
  }
  &:active {
    box-shadow:none;
    background-color: transparent;
  }
`

interface PositionCardProps {
  pair: Pair
  showUnwrapped?: boolean
  border?: string
  stakedBalance?: TokenAmount // optional balance to indicate that liquidity is deposited in mining pool
}

export function MinimalPositionCard({ pair, showUnwrapped = false, border }: PositionCardProps) {
  const { account } = useActiveWeb3React()
  const { t } = useTranslation()

  const themes = useContext(ThemeContext)
  const currency0 = showUnwrapped ? pair.token0 : unwrappedToken(pair.token0)
  const currency1 = showUnwrapped ? pair.token1 : unwrappedToken(pair.token1)

  const [showMore, setShowMore] = useState(false)

  const userPoolBalance = useTokenBalance(account ?? undefined, pair.liquidityToken)
  const totalPoolTokens = useTotalSupply(pair.liquidityToken)

  const poolTokenPercentage =
    !!userPoolBalance && !!totalPoolTokens && JSBI.greaterThanOrEqual(totalPoolTokens.raw, userPoolBalance.raw)
      ? new Percent(userPoolBalance.raw, totalPoolTokens.raw)
      : undefined

  const [token0Deposited, token1Deposited] =
    !!pair &&
      !!totalPoolTokens &&
      !!userPoolBalance &&
      // this condition is a short-circuit in the case where useTokenBalance updates sooner than useTotalSupply
      JSBI.greaterThanOrEqual(totalPoolTokens.raw, userPoolBalance.raw)
      ? [
        pair.getLiquidityValue(pair.token0, totalPoolTokens, userPoolBalance, false),
        pair.getLiquidityValue(pair.token1, totalPoolTokens, userPoolBalance, false)
      ]
      : [undefined, undefined]

  return (
    <>
      {userPoolBalance && JSBI.greaterThan(userPoolBalance.raw, JSBI.BigInt(0)) ? (
        <WhiteCard border={border}>
          <AutoColumn gap="12px">
            <FixedHeightRow>
              <RowFixed>
                <Text fontWeight={500} fontSize={16} color={themes.text7}>
                  {t('Your position')}
                </Text>
              </RowFixed>
            </FixedHeightRow>
            <FixedHeightRow onClick={() => setShowMore(!showMore)}>
              <RowFixed>
                <DoubleCurrencyLogo currency0={currency0} currency1={currency1} margin={true} size={26} />
                <Text fontWeight={500} fontSize={18} color={themes.text7}>
                  {currency0.symbol}/{currency1.symbol}
                </Text>
              </RowFixed>
              <RowFixed>
                <Text fontWeight={500} fontSize={20} color={themes.text7}>
                  {userPoolBalance ? userPoolBalance.toSignificant(4) : '-'}
                </Text>
              </RowFixed>
            </FixedHeightRow>
            <AutoColumn gap="4px">
              <FixedHeightRow>
                <Text fontSize={16} fontWeight={500} color={themes.text7}>
                  {t('Your pool share')}:
                </Text>
                <Text fontSize={16} fontWeight={500} color={themes.text7}>
                  {poolTokenPercentage ? poolTokenPercentage.toFixed(6) + '%' : '-'}
                </Text>
              </FixedHeightRow>
              <FixedHeightRow>
                <Text fontSize={16} fontWeight={500} color={themes.text7}>
                  {currency0.symbol}:
                </Text>
                {token0Deposited ? (
                  <RowFixed>
                    <Text fontSize={16} fontWeight={500} marginLeft={'6px'} color={themes.text7}>
                      {token0Deposited?.toSignificant(6)}
                    </Text>
                  </RowFixed>
                ) : (
                  '-'
                )}
              </FixedHeightRow>
              <FixedHeightRow>
                <Text fontSize={16} fontWeight={500} color={themes.text7}>
                  {currency1.symbol}:
                </Text>
                {token1Deposited ? (
                  <RowFixed>
                    <Text fontSize={16} fontWeight={500} marginLeft={'6px'} color={themes.text7}>
                      {token1Deposited?.toSignificant(6)}
                    </Text>
                  </RowFixed>
                ) : (
                  '-'
                )}
              </FixedHeightRow>
            </AutoColumn>
          </AutoColumn>
        </WhiteCard>
      ) : (
        <LightCard>
          <TYPE.subHeader style={{ textAlign: 'center' }}>
            {/* <span role="img" aria-label="wizard-icon">
              ⭐️
            </span>{' '} */}
            {t("By adding liquidity you'll earn 0.3% of all trades on this pair proportional to your share of the pool. Fees are added to the pool, accrue in real time and can be claimed by withdrawing your liquidity.")}
            {/* By adding liquidity you&apos;ll earn 0.3% of all trades on this pair proportional to your share of the pool.
            Fees are added to the pool, accrue in real time and can be claimed by withdrawing your liquidity. */}
          </TYPE.subHeader>
        </LightCard>
      )}
    </>
  )
}

export default function FullPositionCard({ pair, border, stakedBalance }: PositionCardProps) {
  const theme = useContext(ThemeContext)
  const { account } = useActiveWeb3React()

  const currency0 = unwrappedToken(pair.token0)
  const currency1 = unwrappedToken(pair.token1)

  const [showMore, setShowMore] = useState(false)

  const userDefaultPoolBalance = useTokenBalance(account ?? undefined, pair.liquidityToken)
  const totalPoolTokens = useTotalSupply(pair.liquidityToken)

  // if staked balance balance provided, add to standard liquidity amount
  const userPoolBalance = stakedBalance ? userDefaultPoolBalance?.add(stakedBalance) : userDefaultPoolBalance

  const { t } = useTranslation()

  const poolTokenPercentage =
    !!userPoolBalance && !!totalPoolTokens && JSBI.greaterThanOrEqual(totalPoolTokens.raw, userPoolBalance.raw)
      ? new Percent(userPoolBalance.raw, totalPoolTokens.raw)
      : undefined

  const [token0Deposited, token1Deposited] =
    !!pair &&
      !!totalPoolTokens &&
      !!userPoolBalance &&
      // this condition is a short-circuit in the case where useTokenBalance updates sooner than useTotalSupply
      JSBI.greaterThanOrEqual(totalPoolTokens.raw, userPoolBalance.raw)
      ? [
        pair.getLiquidityValue(pair.token0, totalPoolTokens, userPoolBalance, false),
        pair.getLiquidityValue(pair.token1, totalPoolTokens, userPoolBalance, false)
      ]
      : [undefined, undefined]

  const backgroundColor = useColor(pair?.token0)

  return (
    <StyledPositionCard border={border} bgColor={backgroundColor}>
      <CardNoise />
      <AutoColumn gap="12px">
        <FixedHeightRow>
          <AutoRow>
            <DoubleCurrencyLogo currency0={currency0} currency1={currency1} size={26} imgSeft={true} />
            <Text fontWeight={600} fontSize={16} style={{margin:'0 !important'}}>
              {!currency0 || !currency1 ? <Dots>{t('Loading')}</Dots> : `${currency0.symbol}/${currency1.symbol}`}
            </Text>
          </AutoRow>
          <RowFixed gap="8px">
            <ButtonEmpty
              padding="6px 8px"
              width="fit-content"
              minWidth="100px"
              style={{ color: theme.text7, fontWeight: 600, borderRadius: "12px" }}
              onClick={() => setShowMore(!showMore)}
            >
              {showMore ? (
                <>
                  {t('Manage')}
                  <ChevronUp size="20" style={{ marginLeft: '10px', color: theme.text7 }} />
                </>
              ) : (
                <>
                  {t('Manage')}
                  <ChevronDown size="20" style={{ marginLeft: '10px', color: theme.text7 }} />
                </>
              )}
            </ButtonEmpty>
          </RowFixed>
        </FixedHeightRow>

        {showMore && (
          <AutoColumn gap="8px" style={{ paddingTop:"10px",borderTop:'1px solid #F5F5F5',borderRadius:'0' }}>
            <FixedHeightRow>
              <Text fontSize={13} fontWeight={600} color={theme.text7}>
                {t('Your total pool tokens')}:
              </Text>
              <Text fontSize={13} fontWeight={600} color={theme.text7}>
                {userPoolBalance ? userPoolBalance.toSignificant(4) : '-'}
              </Text>
            </FixedHeightRow>
            {stakedBalance && (
              <FixedHeightRow>
                <Text fontSize={13} fontWeight={600} color={theme.text7}>
                  {t('Pool tokens in rewards pool')}:
                </Text>
                <Text fontSize={13} fontWeight={600} color={theme.text7}>
                  {stakedBalance.toSignificant(4)}
                </Text>
              </FixedHeightRow>
            )}
            <FixedHeightRow>
              <RowFixed>
                <Text fontSize={13} fontWeight={600} color={theme.text7}>
                  {t('Pooled')} {currency0.symbol}:
                </Text>
              </RowFixed>
              {token0Deposited ? (
                <RowFixed>
                  <Text fontSize={13} fontWeight={600} color={theme.text7} marginLeft={'6px'}>
                    {token0Deposited?.toSignificant(6)}
                  </Text>
                  {/* <CurrencyLogo size="20px" style={{ marginLeft: '8px' }} currency={currency0} /> */}
                </RowFixed>
              ) : (
                '-'
              )}
            </FixedHeightRow>

            <FixedHeightRow>
              <RowFixed>
                <Text fontSize={13} fontWeight={600} color={theme.text7}>
                {t('Pooled')} {currency1.symbol}:
                </Text>
              </RowFixed>
              {token1Deposited ? (
                <RowFixed>
                  <Text fontSize={13} fontWeight={600} color={theme.text7} marginLeft={'6px'}>
                    {token1Deposited?.toSignificant(6)}
                  </Text>
                  {/* <CurrencyLogo size="20px" style={{ marginLeft: '8px' }} currency={currency1} /> */}
                </RowFixed>
              ) : (
                '-'
              )}
            </FixedHeightRow>

            <FixedHeightRow>
              <Text fontSize={13} fontWeight={600} color={theme.text7}>
                {t('Your pool share')}:
              </Text>
              <Text fontSize={13} fontWeight={600} color={theme.text7}>
                {poolTokenPercentage
                  ? (poolTokenPercentage.toFixed(2) === '0.00' ? '<0.01' : poolTokenPercentage.toFixed(2)) + '%'
                  : '-'}
              </Text>
            </FixedHeightRow>

            {/* <ButtonSecondary padding="8px" borderRadius="8px">
              <ExternalLink
                style={{ width: '100%', textAlign: 'center' }}
                href={`https://swap-test.aitd.io/account/${account}`}
              >
                {t('View accrued fees and analytics')}
                <span style={{ fontSize: '11px' }}>↗</span>
              </ExternalLink>
            </ButtonSecondary> */}
            {userDefaultPoolBalance && JSBI.greaterThan(userDefaultPoolBalance.raw, BIG_INT_ZERO) && (
              <RowBetween marginTop="10px">
                <ButtonPrimary
                  padding="8px"
                  as={Link}
                  to={`/add/${currencyId(currency0)}/${currencyId(currency1)}`}
                  width="48%"
                  style={{ borderRadius: "8px" }}
                >
                  {t('Add')}
                </ButtonPrimary>
                <ButtonPrimary
                  padding="8px"
                  style={{ borderRadius: "8px" }}
                  as={Link}
                  width="48%"
                  to={`/remove/${currencyId(currency0)}/${currencyId(currency1)}`}
                >
                  {t('Remove')}
                </ButtonPrimary>
              </RowBetween>
            )}
            {stakedBalance && JSBI.greaterThan(stakedBalance.raw, BIG_INT_ZERO) && (
              <ButtonPrimaryText
                style={{ marginTop:"10px",borderTop:'1px solid #F5F5F5',borderRadius:'0' }}
                as={Link}
                to={`/add/${currencyId(currency0)}/${currencyId(currency1)}`}>
                <FixedHeightRow>
                  <Text fontSize={13} fontWeight={400} color={theme.text7}>
                    {t('Manage Liquidity in Rewards Pool')}
                  </Text>
                  <ChevronDown size="18" style={{ marginLeft: '10px',transform:'rotateZ(270deg)' }} color={theme.text7} />
                </FixedHeightRow>
              </ButtonPrimaryText>
              // <ButtonPrimary
              //   padding="8px"
              //   borderRadius="8px"
              //   as={Link}
              //   to={`/uni/${currencyId(currency0)}/${currencyId(currency1)}`}
              //   width="100%"
              // >
              //   {t('Manage Liquidity in Rewards Pool')}
              // </ButtonPrimary>
            )}
          </AutoColumn>
        )}
      </AutoColumn>
    </StyledPositionCard>
  )
}
