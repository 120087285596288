import { Trade, TradeType } from '@uniswap/sdk'
import React, { useContext, useMemo } from 'react'
// import {
//    ArrowDown, 
//   AlertTriangle 
// } from 'react-feather'
import { Text } from 'rebass'
import styled,{ ThemeContext } from 'styled-components'
import { Field } from '../../state/swap/actions'
import { TYPE } from '../../theme'
import { ButtonPrimary } from '../Button'
import { isAddress, shortenAddress } from '../../utils'
import { computeSlippageAdjustedAmounts, computeTradePriceBreakdown, warningSeverity } from '../../utils/prices'
import { AutoColumn } from '../Column'
import CurrencyLogo from '../CurrencyLogo'
import { RowBetween, RowFixed } from '../Row'
import { TruncatedText, SwapShowAcceptChanges } from './styleds'
import { useTranslation } from 'react-i18next'
import DownNew from '../../assets/svg/downNew.svg'
import InterrogatSvg from '../../assets/svg/interrogatSvg.svg'

export default function SwapModalHeader({
  trade,
  allowedSlippage,
  recipient,
  showAcceptChanges,
  onAcceptChanges
}: {
  trade: Trade
  allowedSlippage: number
  recipient: string | null
  showAcceptChanges: boolean
  onAcceptChanges: () => void
}) {
  const slippageAdjustedAmounts = useMemo(() => computeSlippageAdjustedAmounts(trade, allowedSlippage), [
    trade,
    allowedSlippage
  ])
  const { priceImpactWithoutFee } = useMemo(() => computeTradePriceBreakdown(trade), [trade])
  const priceImpactSeverity = warningSeverity(priceImpactWithoutFee)

  const theme = useContext(ThemeContext)
  const { t } = useTranslation()

  return (
    <AutoColumn gap={'md'} style={{ marginTop: '20px' }}>

      <Text fontSize={12} fontWeight={600} color={theme.text7}  style={{ marginBottom: '-15px' }}>
        {t('Sold')}
      </Text>

      <RowBetween align="flex-end">
        <RowFixed gap={'0px'}>
          <CurrencyLogo currency={trade.inputAmount.currency} size={'26px'} style={{ marginRight: '12px' }} />
          <TruncatedText
            fontSize={17}
            fontWeight={600}
            fontFamily={'DIN-Bold, DIN'}
            color={showAcceptChanges && trade.tradeType === TradeType.EXACT_OUTPUT ? theme.primary1 : theme.text7}>
            {trade.inputAmount.toSignificant(6)}
          </TruncatedText>
        </RowFixed>
        <RowFixed gap={'0px'}>
          <Text fontSize={20} fontWeight={600} color={theme.text7} style={{ marginLeft: '10px' }}>
            {trade.inputAmount.currency.symbol}
          </Text>
        </RowFixed>
      </RowBetween>
      <RowFixed>
        <img style={{ height: '20px', width: '20px', marginLeft: '4px', minWidth: '16px' }} src={DownNew} alt="AIA"></img>
        {/* <ArrowDown size="16" color={theme.text2} style={{ marginLeft: '4px', minWidth: '16px' }} /> */}
      </RowFixed>
      <RowFixed>
      <Text fontSize={12} fontWeight={600} color={theme.text7} style={{ marginBottom: '-15px' }}>
        {t('Get')}
      </Text>
      </RowFixed>
      <RowBetween align="flex-end">

        <RowFixed gap={'0px'}>
          <CurrencyLogo currency={trade.outputAmount.currency} size={'26px'} style={{ marginRight: '12px' }} />
          <TruncatedText
            fontSize={17}
            fontWeight={500}
            fontFamily={'DIN-Bold, DIN'}
            color={
              priceImpactSeverity > 2
                ? theme.red1
                : showAcceptChanges && trade.tradeType === TradeType.EXACT_INPUT
                  ? theme.primary1
                  : theme.text7
            }>

            {trade.outputAmount.toSignificant(6)}

          </TruncatedText>
        </RowFixed>

        <RowFixed gap={'0px'}>
          <Text fontSize={20} fontWeight={600} color={theme.text7} style={{ marginLeft: '10px' }}>
            {trade.outputAmount.currency.symbol}
          </Text>
        </RowFixed>
      </RowBetween>
      {showAcceptChanges ? (
        <SwapShowAcceptChanges justify="flex-start" gap={'0px'}>
          <RowBetween>
            <RowFixed>
              <img width={20} src={InterrogatSvg} style={{ marginRight: '8px', minWidth: 24 }} />
              <TYPE.main color={theme.text7} fontWeight={600}> {t('Price Updated')}</TYPE.main>
            </RowFixed>
            <ButtonPrimary
              style={{ padding: '.5rem 1rem', width: 'fit-content', fontSize: '0.825rem', borderRadius: '12px' }}
              onClick={onAcceptChanges}
            >
              {t('Accept')}
            </ButtonPrimary>
          </RowBetween>
        </SwapShowAcceptChanges>
      ) : null}
      <AutoColumn justify="flex-start" gap="sm" style={{ padding: '12px 0 0 0px' }}>
        <Line />
        {trade.tradeType === TradeType.EXACT_INPUT ? (
          <TYPE.italic textAlign="left" style={{ width: '100%' }} color={theme.text10}>
            {t('estimateds')}
            <b>
              {slippageAdjustedAmounts[Field.OUTPUT]?.toSignificant(6)} {trade.outputAmount.currency.symbol}
            </b>
            {t('revert')}
          </TYPE.italic>
        ) : (
          <TYPE.italic textAlign="left" style={{ width: '100%' }}>
            {t('sell')}
            <b>
              {slippageAdjustedAmounts[Field.INPUT]?.toSignificant(6)} {trade.inputAmount.currency.symbol}
            </b>
            {t('revert')}
          </TYPE.italic>
        )}
      </AutoColumn>
      {recipient !== null ? (
        <AutoColumn justify="flex-start" gap="sm" style={{ padding: '12px 0 0 0px' }}>
          <TYPE.main>
            Output will be sent to{' '}
            <b title={recipient}>{isAddress(recipient) ? shortenAddress(recipient) : recipient}</b>
          </TYPE.main>
        </AutoColumn>
      ) : null}
    </AutoColumn>
  )
}
const Line = styled.div`
    opacity: 0.06;
    border-bottom: 1px solid #0E254A;
    margin-bottom: 15px;
    margin-top: 0;
    width: 100%;
`