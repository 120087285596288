
import { Currency, CurrencyAmount, JSBI, Token, TokenAmount, Trade, Price, ETHER } from '@uniswap/sdk'
import { useCallback, useMemo } from 'react'
import { useActiveWeb3React } from 'hooks';
import { useCurrency } from 'hooks/Tokens';
import { useTradeExactIn, useTradeExactOut } from 'hooks/Trades';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'state';
import { tryParseAmount } from 'state/swap/hooks';
import { useCurrencyBalances } from 'state/wallet/hooks';
// import { wrappedCurrency } from 'utils/wrappedCurrency';
import { Field, Rate } from './types';
import { selectCurrency, setCancel, setHistoryTotalData, setOrderList, setRateType, setRecipient, setTotalData, switchCurrencies, typeInput } from './actions';
import { wrappedCurrency } from 'utils/wrappedCurrency';
import { useTranslation } from 'react-i18next';
export interface DerivedOrderInfo {
    currencies: {
        [key: string]: Currency | undefined
    }
    currencyBalances: {
        input: CurrencyAmount | undefined
        output: CurrencyAmount | undefined
    }
    inputError?: string
    trade: Trade | undefined
    parsedAmounts: {
        [key: string]: CurrencyAmount | undefined
    }
    formattedAmounts: {
        [key: string]: string
        price: string
    }
    rawAmounts?: {
        [key: string]: string | undefined
    }
    price: Price | undefined
    wrappedCurrencies?: {
        [key: string]: any
    }
    singleTokenPrice?: {
        [key: string]: number
    }
    currencyIds: {
        input: string | undefined
        output: string | undefined
    }
    recipient?: string | null
    typedValue?: string
}
export const useOrderState = (): AppState['limitOrders'] => {
    return useSelector<AppState, AppState['limitOrders']>((state: any) => state.limitOrders)
}
export const useOrderActionHandlers = (): {
    onCurrencySelection: (field: Field, currency: Currency) => void
    onSwitchTokens: () => void
    onUserInput: (field: Field, typedValue: string) => void
    onChangeRateType: (rateType: Rate) => void
    onChangeRecipient: (recipient: string | null) => void
} => {
    const dispatch = useDispatch()
    const onCurrencySelection = useCallback(
        (field: Field, currency: Currency) => {
            dispatch(
                selectCurrency({
                    field,
                    currencyId: currency instanceof Token ? currency.address : currency === ETHER ? 'AIA' : '',
                }),
            )
        },
        [dispatch],
    )

    const onSwitchTokens = useCallback(() => {
        dispatch(switchCurrencies())
    }, [dispatch])

    const onUserInput = useCallback(
        (field: Field, typedValue: string) => {
            dispatch(typeInput({ field, typedValue }))
        },
        [dispatch],
    )

    const onChangeRateType = useCallback(
        (rateType: Rate) => {
            dispatch(setRateType({ rateType }))
        },
        [dispatch],
    )
    const onChangeRecipient = useCallback(
        (recipient: string | null) => {
            dispatch(setRecipient({ recipient }))
        },
        [dispatch]
    )
    return {
        onSwitchTokens,
        onCurrencySelection,
        onUserInput,
        onChangeRateType,
        onChangeRecipient
    }
}
export const useDerivedOrderInfo = (): DerivedOrderInfo => {
    const { account, chainId } = useActiveWeb3React()
    const {
        independentField,
        basisField,
        typedValue,
        [Field.INPUT]: { currencyId: inputCurrencyId },
        [Field.OUTPUT]: { currencyId: outputCurrencyId },
        rateType,
        inputValue,
        outputValue,
        // recipient
    } = useOrderState()

    // Get Currency objects based on currencyId strings
    const inputCurrency: any = useCurrency(inputCurrencyId)
    const outputCurrency: any = useCurrency(outputCurrencyId)
    // Get user balance for selected Currencies
    const relevantTokenBalances = useCurrencyBalances(account ?? undefined, [
        inputCurrency ?? undefined,
        outputCurrency ?? undefined,
    ])

    const inputAmount = useMemo(() => {
        return tryParseAmount(inputValue, inputCurrency ?? undefined)
    }, [inputValue, inputCurrency])

    const outputAmount = useMemo(() => {
        return tryParseAmount(outputValue, outputCurrency ?? undefined)
    }, [outputValue, outputCurrency])



    const isExactIn = independentField === Field.INPUT
    const isOutputBasis = basisField === Field.OUTPUT
    // const dependentField: Field = independentField === Field.INPUT ? Field.OUTPUT : Field.INPUT
    const isDesiredRateUpdate = independentField === Field.PRICE
    const desiredOutputAsCurrencyAmount = isDesiredRateUpdate
        ? getDesiredOutput(inputValue, typedValue, inputCurrency, outputCurrency, rateType === Rate.DIV)
        : undefined
    const desiredInputAsCurrencyAmount = isDesiredRateUpdate
        ? getDesiredInput(outputValue, typedValue, inputCurrency, outputCurrency, rateType === Rate.DIV)
        : undefined
    const desiredOutputAsString =
        isDesiredRateUpdate && desiredOutputAsCurrencyAmount ? desiredOutputAsCurrencyAmount?.toSignificant(6) : typedValue
    const parsedAmount = isDesiredRateUpdate ? isOutputBasis ? outputAmount : tryParseAmount(desiredOutputAsString, outputCurrency)
        : tryParseAmount(typedValue, isExactIn ? inputCurrency : outputCurrency)

    // tryParseAmount(typedValue, (isExactIn ? inputCurrency : outputCurrency) ?? undefined)

    const bestTradeExactIn = useTradeExactIn(isExactIn ? parsedAmount : undefined, outputCurrency ?? undefined)
    const bestTradeExactOut = useTradeExactOut(inputCurrency ?? undefined, !isExactIn || isOutputBasis ? parsedAmount : undefined)

    // console.info(inputCurrency ?? undefined, 'bestTradeExactOut', !isExactIn ? parsedAmount : undefined)
    const trade = isExactIn ? bestTradeExactIn : bestTradeExactOut
    const currencyBalances = {
        input: relevantTokenBalances[0],
        output: relevantTokenBalances[1],
    }
    const currencies = useMemo(
        () => ({
            [Field.INPUT]: inputCurrency ?? undefined,
            [Field.OUTPUT]: outputCurrency ?? undefined,
        }),
        [inputCurrency, outputCurrency]
    )
    const wrappedCurrencies = useMemo(
        () => ({
            [Field.INPUT]: wrappedCurrency(currencies[Field.INPUT], chainId),
            [Field.OUTPUT]: wrappedCurrency(currencies[Field.OUTPUT], chainId),
        }),
        [currencies[Field.INPUT], currencies[Field.OUTPUT], chainId],
    )


    // Get "final" amounts
    // const parsedAmounts: any = {
    //     [Field.INPUT]: independentField === Field.INPUT ? parsedAmount : trade?.inputAmount,
    //     [Field.OUTPUT]: independentField === Field.OUTPUT ? parsedAmount : trade?.outputAmount
    // }

    const parsedAmounts: any = useMemo(() => {
        let input = trade?.inputAmount

        if (!isOutputBasis) {
            input = inputAmount
        } else if (independentField === Field.INPUT) {
            // If user touching input field -> whatever they type currently
            input = parsedAmount
        } else if (isDesiredRateUpdate) {
            // If user modifies the price AND is wishing for specific output amount -> hypothetical input amount at better price
            input = desiredInputAsCurrencyAmount
        }
        let output = trade?.outputAmount
        if (isOutputBasis) {
            output = outputAmount
        } else if (independentField === Field.OUTPUT) {
            // If user touching input field -> whatever they type currently
            output = parsedAmount
        } else if (isDesiredRateUpdate) {
            // console.info(3,'output',independentField,basisField)
            // If user modifies the price AND is wishing for specific input amount -> hypothetical input amount at better price
            output = desiredOutputAsCurrencyAmount
        }
        return {
            [Field.INPUT]: input,
            [Field.OUTPUT]: output,
        }
    }, [
        inputAmount,
        outputAmount,
        isOutputBasis,
        trade,
        independentField,
        desiredInputAsCurrencyAmount,
        desiredOutputAsCurrencyAmount,
        isDesiredRateUpdate,
        parsedAmount])

    // Calculate the price for specified swap
    const price = useMemo(
        () => getPriceForOneToken(parsedAmounts[Field.INPUT], parsedAmounts[Field.OUTPUT]),
        [parsedAmounts[Field.INPUT], parsedAmounts[Field.OUTPUT]],
    )

    // Formatted amounts to use in the UI

    const formattedAmounts = {
        [Field.INPUT]: !isOutputBasis && inputValue && inputValue !== '' ? inputValue : parsedAmounts[Field.INPUT]?.toSignificant(6) ?? '',
        [Field.OUTPUT]: isOutputBasis && outputValue && outputValue !== '' ? outputValue : parsedAmounts[Field.OUTPUT]?.toSignificant(6) ?? '',
        price:
            independentField === Field.PRICE
                ? typedValue
                : rateType === Rate.MUL
                    ? price?.toSignificant(6) ?? ''
                    : price?.invert().toSignificant(6) ?? '',
    }
    const rawAmounts = useMemo(
        () => ({
            [Field.INPUT]: inputCurrency
                ? parsedAmounts[Field.INPUT]?.multiply(JSBI.exponentiate(BIG_INT_TEN, JSBI.BigInt(inputCurrency.decimals))).toFixed(0)
                : undefined,

            [Field.OUTPUT]: outputCurrency
                ? parsedAmounts[Field.OUTPUT]
                    ?.multiply(JSBI.exponentiate(BIG_INT_TEN, JSBI.BigInt(outputCurrency.decimals)))
                    .toFixed(0)
                : undefined,
        }),
        [inputCurrency, outputCurrency, parsedAmounts],
    )
    const { t } = useTranslation()

    return {
        currencies,
        currencyBalances,
        inputError: getErrorMessage(
            account,
            currencies,
            currencyBalances,
            parsedAmounts,
            trade,
            price,
            rateType,
            t,
            // wrappedCurrencies,
        ),
        formattedAmounts,
        trade: trade ?? undefined,
        parsedAmounts,
        price,
        rawAmounts,
        wrappedCurrencies,
        currencyIds: {
            input: inputCurrencyId,
            output: outputCurrencyId,
        },
        // recipient,
        typedValue
    }
}
const getErrorMessage = (
    account: string | null | undefined,
    currencies: { [key: string]: Currency | undefined },
    currencyBalances: { input: CurrencyAmount | undefined; output: CurrencyAmount | undefined },
    parsedAmounts: { [key: string]: CurrencyAmount },
    trade: Trade | null,
    price: Price | undefined,
    rateType: Rate,
    t: any,
    wrappedCurrencies?: {
        input: Token | undefined
        output: Token | undefined
    },

) => {
    if (!account) {
        return 'Connect Wallet'
    }
    // if (
    //     wrappedCurrencies.input &&
    //     wrappedCurrencies.output &&
    //     wrappedCurrencies.input.address.toLowerCase() === wrappedCurrencies.output.address.toLowerCase()
    // ) {
    //     return 'Order not allowed'
    // }
    const hasBothTokensSelected = currencies[Field.INPUT] && currencies[Field.OUTPUT]
    if (!hasBothTokensSelected) {
        return  t('selectAToken') || 'Select a token'
    }
    const hasAtLeastOneParsedAmount = parsedAmounts[Field.INPUT] || parsedAmounts[Field.OUTPUT]

    const tradeIsNotAvailable = !trade || !trade?.route


    // console.log(trade,hasAtLeastOneParsedAmount);

    if (hasAtLeastOneParsedAmount && tradeIsNotAvailable) {
        return t('Insufficient liquidity for this trade')
    }
    const someParsedAmountIsMissing = !parsedAmounts[Field.INPUT] || !parsedAmounts[Field.OUTPUT]
    if (someParsedAmountIsMissing) {
        return t('enterAnAmount') || 'Enter an amount'
    }
    if (currencyBalances.input && currencyBalances.input.lessThan(parsedAmounts[Field.INPUT])) {
        return  t('CurrencyInsufficientBalance', { currency: currencyBalances.input.currency.symbol })
        // return `Insufficient ${currencyBalances.input.currency.symbol} balance`
    }
    let tradeAny: any = trade
    if (price) {
        if (
            rateType === Rate.MUL &&
            (price.lessThan(tradeAny?.executionPrice) || price.equalTo(tradeAny.executionPrice))
        ) {
            return t('Only possible to place sell orders above market rate')
        }
        if (
            rateType === Rate.DIV &&
            (price.invert().greaterThan(tradeAny.executionPrice.invert()) ||
                price.invert().equalTo(tradeAny.executionPrice.invert()))
        ) {
            return t('Only possible to place buy orders below market rate')
        }
    }
    return undefined
}
export const getDesiredOutput = (
    inputValue: string | undefined,
    exchangeRate: string,
    inputCurrency: Currency,
    outputCurrency: Currency,
    isInverted: boolean,
): CurrencyAmount | undefined => {
    if (!inputValue || !inputCurrency || !outputCurrency) {
        return undefined
    }
    const parsedInputAmount: any = tryParseAmount(inputValue, isInverted ? outputCurrency : inputCurrency)
    const parsedExchangeRate: any = tryParseAmount(exchangeRate, isInverted ? inputCurrency : outputCurrency)

    if (!parsedExchangeRate || !parsedInputAmount) {
        return undefined
    }

    if (isInverted) {
        const invertedResultAsFraction = parsedInputAmount
            .multiply(JSBI.exponentiate(BIG_INT_TEN, JSBI.BigInt(outputCurrency.decimals)))
            .divide(parsedExchangeRate)
        const invertedResultAsAmount =
            outputCurrency instanceof Token
                ? new TokenAmount(outputCurrency, invertedResultAsFraction.toFixed(0))
                : CurrencyAmount.ether(invertedResultAsFraction.toFixed(0))

        return invertedResultAsAmount
    }

    const resultAsFraction = parsedInputAmount
        .multiply(parsedExchangeRate)
        .multiply(JSBI.exponentiate(BIG_INT_TEN, JSBI.BigInt(outputCurrency.decimals)))
    const resultAsAmount =
        outputCurrency instanceof Token
            ? new TokenAmount(outputCurrency, resultAsFraction.quotient.toString())
            : CurrencyAmount.ether(resultAsFraction.quotient.toString())
    return resultAsAmount
}
// Get desired input amount in output basis mode
export const getDesiredInput = (
    outputValue: string | undefined,
    exchangeRate: string,
    inputCurrency: Currency,
    outputCurrency: Currency,
    isInverted: boolean,
) => {

    if (!outputValue || !inputCurrency || !outputCurrency) {
        return undefined
    }

    const parsedOutAmount: any = tryParseAmount(outputValue, isInverted ? inputCurrency : outputCurrency)

    const parsedExchangeRate: any = tryParseAmount(exchangeRate, isInverted ? inputCurrency : outputCurrency)

    if (!parsedOutAmount || !parsedExchangeRate) {
        return undefined
    }

    if (isInverted) {
        const invertedResultAsFraction = parsedOutAmount
            .multiply(parsedExchangeRate)
            .multiply(JSBI.exponentiate(BIG_INT_TEN, JSBI.BigInt(inputCurrency.decimals)))
        const invertedResultAsAmount =
            inputCurrency instanceof Token
                ? new TokenAmount(inputCurrency, invertedResultAsFraction.toFixed(0))
                : CurrencyAmount.ether(invertedResultAsFraction.toFixed(0))

        return invertedResultAsAmount
    }

    const resultAsFraction = parsedOutAmount
        .divide(parsedExchangeRate)
        .multiply(JSBI.exponentiate(BIG_INT_TEN, JSBI.BigInt(inputCurrency.decimals)))
    const resultAsAmount =
        inputCurrency instanceof Token
            ? new TokenAmount(inputCurrency, resultAsFraction.quotient.toString())
            : CurrencyAmount.ether(resultAsFraction.quotient.toString())
    return resultAsAmount
}

export const getPriceForOneToken = (inputAmount: CurrencyAmount | any, outputAmount: CurrencyAmount | any) => {
    if (!inputAmount || !outputAmount || inputAmount.equalTo(JSBI.BigInt(0)) || outputAmount.equalTo(JSBI.BigInt(0))) {
        return undefined
    }
    return new Price(inputAmount.currency, outputAmount.currency, inputAmount.raw, outputAmount.raw)
}

export const useOrderList = () => {
    const dispatch = useDispatch()
    const onChangeOrderList = useCallback(
        (orderList: any) => {
            dispatch(setOrderList({ orderList }))
        },
        [dispatch]
    )
    const onChangeCancelAxios = useCallback(
        (cancel: any) => {
            dispatch(setCancel({ cancel }))
        },
        [dispatch]
    )
    const onChangeTotalOrderList = useCallback(
        (totalOrderList: any) => {
            dispatch(setTotalData({ totalOrderList }))
        },
        [dispatch]
    )
    const onChangeHistoryTotalOrderList = useCallback(
        (historyTotalData: any) => {
            dispatch(setHistoryTotalData({ historyTotalData }))
        },
        [dispatch]
    )


    
    return { onChangeOrderList, onChangeCancelAxios,onChangeTotalOrderList,onChangeHistoryTotalOrderList }
}








export const BIG_INT_TEN = JSBI.BigInt(10)