import React from "react"
import { AutoColumn } from "components/Column";
import styled from 'styled-components'
import { Flex, Text } from 'rebass'
import { useTranslation } from "react-i18next";
import { ErrorIcon } from "components/Svg";
import { ButtonError } from "components/Button";
export function TransactionErrorContent({ message, onDismiss }: { message: string; onDismiss: () => void }) {
    const { t } = useTranslation()
    return (
        <Wrapper>
            <AutoColumn justify="center">
                <ErrorIcon color="#ED4B9E" width="64px" />
                <Text color="#ED4B9E" style={{ textAlign: 'center', width: '85%', wordBreak: 'break-word' }}>
                    {message}
                </Text>
            </AutoColumn>
            <Flex justifyContent="center" pt="24px">
                <ButtonError
                    variant="primary"
                    onClick={onDismiss}
                    width="100%"
                    style={{ padding: '12px', margin: '0', boxShadow: '0 3px 0 0 #0090FF' }}
                >
                    {t('Dismiss')}
                </ButtonError>
            </Flex>
        </Wrapper>
    )
}
const Wrapper = styled.div`
  width: 100%;
`