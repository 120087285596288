import React from 'react';
import {Table } from 'antd';
import { useTranslation } from 'react-i18next'
import Modal from '../../../components/Modal';
import styled from 'styled-components'
import { ColumnCenter } from '../../../components/Column';
import { RowBetween} from '../../../components/Row';
// import {Text,} from '../../../components/invitation/styled';
import { CloseOutlined } from '@ant-design/icons'

const ColumnCenterEle = styled(ColumnCenter)`
  width: initial;
  min-width: 140px;
  margin-bottom: 10px;
  color: #333333;
  font-zize: 0.875rem;
`
const SectionEle = styled.section`
width: 100%;
padding:0 1rem;
  margin-bottom: 1.25rem;
`
const TitleEle = styled.h3`
  margin-bottom: 0.625rem;
  padding-left: 0.375rem;
  font-weight: 600;
  color: #275EEB ;
  font-size: 1.2rem;
  position: relative;
  &:before{
    position: absolute;
    left: -0.175rem;
    top: 15%;
    content: '';
    height: 70%;
    border-left: 0.1875rem solid #275EEB;
  }
`
const PEle = styled.p`
  margin-bottom: 0.3125rem;
`
const ListEle = styled.ul`
  padding-left: 1.2rem;
  list-style: disc;
  font-size: 1rem;
  margin: 0;
`
const ListEle1 = styled.ul`
  padding-left: 1.2rem;
  list-style: disc;
  font-size: 1rem;
  color: #666666;
  margin: 0;
`
const ListItemEle = styled.li`
  padding: 0;
`
const ListItemEle1 = styled.li`
  list-style: none;
  position: relative;
  &:before{
    content: '';
    position: absolute;
    left: -1rem;
    top: 0.6rem;
    width: 0.375rem;
    height: 0.375rem;
    border: 0.1rem solid #666666;
    transform-origin: center; 
    transform: rotate(45deg);
    border-width: 0.1rem 0.1rem 0 0 ;
  }
`
const Text = styled.div`
 font-size: 1rem;
`
const Text1 = styled.div`
 font-size:  1rem;
`
const TableEle = styled(Table)`

  thead th,td{
    text-align: center !important;
    font-size: 1rem;
    background: #F3F4F5 !important;
    border-color: #DDDDDD !important;
  }
`



export default function InvitationPlayRule(props:any){
  const { t } = useTranslation()
  // const theme = useContext(ThemeContext);
  const dataSource1 = [
    {title: t('n person',{'amount': 1}),reward: '0.1%', ratio: '5.1%'},
    {title: t('n person',{'amount': 2}),reward: '0.2%', ratio: '5.2%'},
    {title: t('n person',{'amount': 3}),reward: '0.3%', ratio: '5.3%'},
    {title: '...',reward: '...', ratio: '...'},
    {title: t('n person',{'amount': 98}),reward: '9.8%', ratio: '14.8%'},
    {title: t('n person',{'amount': 99}),reward: '9.9%', ratio: '14.9%'},
    {title: t('n person',{'amount': 100}),reward: '10%', ratio: '15%'},
  ];
  const columns1 = [
    {
      title: t('Number of valid invitations'),
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: t('Accumulated increase in reward ratio'),
      dataIndex: 'reward',
      key: 'reward',
    },
    {
      title: t('Increase the reward ratio to'),
      dataIndex: 'ratio',
      key: 'ratio',
    }
  ];
  const dataSource2 = [
    {invitation: '1-100',increase: '0.5%',highest: '50%',total: '50%'},
    {invitation: '101-200',increase: '0.25%',highest: '25%',total: '75%'},
    {invitation: '201-300',increase: '0.125%',highest: '12.5%',total: '87.5%'},
    {invitation: '301-400',increase: '0.0625%',highest: '6.25%',total: '93.75%'},
    {invitation: '401-500',increase: '0.03125%',highest: '3.125%',total: '96.875%'},
    {invitation: '>500',increase: '-',highest: '-',total: '100%'},
  ];
  const columns2 = [
    {
      title: t('Number of valid invitations'),
      dataIndex: 'invitation',
      key: 'invitation',
    },
    {
      title: t('Increase ratio per 1 person'),
      dataIndex: 'increase',
      key: 'increase',
    },
    {
      title: t('The highest proportion that can be increased'),
      dataIndex: 'highest',
      key: 'highest',
    },
    {
      title: t('Total highest proportion'),
      dataIndex: 'total',
      key: 'total',
    },
  ];
  const close = () => {
    props?.onDismiss();
  }
  return (<Modal isOpen={true} onDismiss={close}>
    <ColumnCenterEle style={{ width: '100%',display: 'flex',height: '80vh'}}>
      <RowBetween style={{ padding: '0.9375rem 0.9375rem 1.25rem', }}>
        <Text style={{ fontWeight: 600, color: '#333333', fontSize: '1rem' }}>{t('Play Rules')} </Text>
        <Text style={{ color: '#666666', fontSize: '16px', cursor: 'pointer' }} onClick={close}>
          <CloseOutlined />
        </Text>
      </RowBetween>
      <Text style={{flex: 1,overflow: 'auto',width: '100%' }}>
        <SectionEle>
          <TitleEle>{t('Mining rules title')}</TitleEle>
          <ListEle>
            <ListItemEle><Text>{t('Mining rules item-1')}</Text></ListItemEle>
            <ListItemEle><Text>{t('Mining rules item-2')}</Text></ListItemEle>
            <ListItemEle><Text>{t('Mining rules item-3')}</Text></ListItemEle>
            <ListItemEle><Text>{t('Mining rules item-4')}</Text></ListItemEle>
            <ListItemEle>
              <Text>{t('Mining rules item-5')}</Text>
              <ListEle1 >
                <ListItemEle1><Text1>{t('Mining rules item-5-1')}</Text1></ListItemEle1>
                <ListItemEle1><Text1>{t('Mining rules item-5-2')}</Text1></ListItemEle1>
                <ListItemEle1><Text1>{t('Mining rules item-5-3')}</Text1></ListItemEle1>
              </ListEle1>
            </ListItemEle>
          </ListEle>
        </SectionEle>
        <SectionEle>
          <TitleEle>{t('Invitation Rules title')}</TitleEle>
          <ListEle>
            <ListItemEle><Text>{t('Invitation Rules item-1')}</Text></ListItemEle>
            <ListItemEle><Text>{t('Invitation Rules item-2')}</Text></ListItemEle>
            <ListItemEle><Text>{t('Invitation Rules item-3')}</Text></ListItemEle>
          </ListEle>
        </SectionEle>
        <SectionEle>
          <TitleEle>{t('Reward Rules title')}</TitleEle>
          <ListEle>
            <ListItemEle><Text>{t('Reward Rules item-1')}</Text></ListItemEle>
            <ListItemEle>
              <Text>{t('Reward Rules item-2')}</Text>
              <Text>{t('Reward Rules item-2-1')}</Text>
              <Text>{t('Reward Rules item-2-2')}</Text>
            </ListItemEle>
            <ListItemEle>
              <Text>{t('Reward Rules item-3')}</Text>
              <Text style={{fontWeight: 600,color: '#666666',fontSize: '1rem',margin: '0.625rem 0 0.3125rem'}}>{t('Reward example title')}</Text>
              <PEle style={{fontSize: '1rem'}}>{t('Reward example p-1')}</PEle>
              <PEle style={{fontSize: '1rem'}}>{t('Reward example p-2')}</PEle>
              <TableEle bordered size='small' rowKey={'title'}
              style={{width: '100vw',}} 
              // scroll={{ x: 'max-content' }}
              dataSource={dataSource1} 
              columns={columns1}
              pagination={false}
              > </TableEle>
            </ListItemEle>
          </ListEle>
         
        </SectionEle>
        <SectionEle>
          <TitleEle>{t('Redemption release ratio title')}</TitleEle>
          <ListEle>
            <ListItemEle>{t('Redemption release ratio item-1')}</ListItemEle>
            <ListItemEle>{t('Redemption release ratio item-2')}</ListItemEle>
            <TableEle bordered size='small'  rowKey={'invitation'}
              style={{width: '100vw',marginTop: '0.3125rem'}} 
              // scroll={{ x: 'max-content' }}
              dataSource={dataSource2} 
              columns={columns2}
              pagination={false}
              > </TableEle>
          </ListEle>
        </SectionEle>
      </Text>
    </ColumnCenterEle>
  </Modal>)
}