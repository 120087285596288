import { Currency, ETHER, Token } from '@uniswap/sdk'
import React, { useMemo } from 'react'
import styled from 'styled-components'

import EthereumLogo from '../../assets/svg/logoAia.svg'
import BtcLogo from '../../assets/images/btc.png'
import EthLogo from '../../assets/images/ETH.png'
import AdaLogo from '../../assets/images/ada.png'
import CrctLogo from '../../assets/images/cret.png'
import CADLogo from '../../assets/images/cad.jpg'
import UsdtLogo from '../../assets/images/usdt.png'
import UniLogo from '../../assets/images/uni.png'

import SandLogo from '../../assets/images/sand.png'
import DxdyLogo from '../../assets/images/dydx.png'
import MaskLogo from '../../assets/images/mask.png'
import MaticLogo from '../../assets/images/matic.png'
import GtcLogo from '../../assets/images/gtc.png'
import ShibLogo from '../../assets/images/SHBI.png'
import BMTLogo from '../../assets/images/bmt.jpg'
import OXXLogo from '../../assets/images/OXX.png'
import OXGLogo from '../../assets/images/OXG.png'
// import CADSLogo from '../../assets/images/CADS.png'
import BNBLogo from '../../assets/images/BNB.png'
import YOHOLogo from '../../assets/images/YOHO.png'
// import CACLogo from '../../assets/images/CAC.png'
import CACIILogo from '../../assets/images/CACII.png'
import CADSIILogo from '../../assets/images/CADSII.png'
import MANEKILogo from '../../assets/images/MANEKI.png'
import ATMLogo from '../../assets/images/ATM.png'

// import FAIA from '../../assets/images/FAITD.png'
// import SAIA from '../../assets/images/SAITD.png'
// import TAIA from '../../assets/images/TAITD.png'

// import KIAIA from '../../assets/images/KIAITD.jpg'
// import GAAIA from '../../assets/images/GAAITD.jpg'
// import WINAIA from '../../assets/images/WINAITD.jpg'

import INCHLogo from '../../assets/images/inch.png'
import MANALogo from '../../assets/images/MANA.png'
import FILLogo from '../../assets/images/FIL.png'
import WYOHOLogo from '../../assets/images/WYOHO.png'
import YYOHOLogo from '../../assets/images/YYOHO.png'

import useHttpLocations from '../../hooks/useHttpLocations'
import { WrappedTokenInfo } from '../../state/lists/hooks'
import Logo from '../Logo'
import {
  SWAP_TYPE,
  REACT_APP_CADS_ADDRESS,
  REACT_APP_CADSII_ADDRESS,
  REACT_APP_CACII_ADDRESS,
  REACT_APP_CAC_ADDRESS, REACT_APP_AITDSWAP_USDT_ADDRESS
} from '../../constants'

export const getTokenLogoURL = (address: string) => `/logo/${address}.png`

const StyledEthereumLogo = styled.img<{ size: string }>`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.075);
  border-radius: 24px;
`

const StyledLogo = styled(Logo) <{ size: string }>`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  border-radius: ${({ size }) => size};
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.075);
  background-color: ${({ theme }) => theme.white};
`

export default function CurrencyLogo({
  currency,
  size = '24px',
  style
}: {
  currency?: Currency | null | any
  size?: string
  style?: React.CSSProperties
}) {
  const uriLocations = useHttpLocations(currency instanceof WrappedTokenInfo ? currency.logoURI : undefined)

  const srcs: string[] = useMemo(() => {
    if (currency === ETHER) return []

    if (currency instanceof Token) {
      if (currency instanceof WrappedTokenInfo) {
        return [...uriLocations, getTokenLogoURL(currency.address)]
      }
      return [getTokenLogoURL(currency.address)]
    }
    return []
  }, [currency, uriLocations])

  if (currency === ETHER) {
    return <StyledEthereumLogo src={EthereumLogo} size={size} style={style} />
  }
  if (currency?.symbol === SWAP_TYPE.BTC) {
    return <StyledEthereumLogo src={BtcLogo} size={size} style={style} />
  }
  if (currency?.symbol === SWAP_TYPE.ETH) {
    return <StyledEthereumLogo src={EthLogo} size={size} style={style} />
  }
  if (currency?.symbol === SWAP_TYPE.ADA) {
    return <StyledEthereumLogo src={AdaLogo} size={size} style={style} />
  }
  if (currency?.symbol === SWAP_TYPE.USDT && currency?.address == REACT_APP_AITDSWAP_USDT_ADDRESS) {
    return <StyledEthereumLogo src={UsdtLogo} size={size} style={style} />
  }
  if (currency?.symbol === SWAP_TYPE.CRET) {
    return <StyledEthereumLogo src={CrctLogo} size={size} style={style} />
  }
  if (currency?.symbol === SWAP_TYPE.CAD) {
    return <StyledEthereumLogo src={CADLogo} size={size} style={style} />
  }
  if (currency?.symbol === SWAP_TYPE.BMT) {
    return <StyledEthereumLogo src={BMTLogo} size={size} style={style} />
  }
  if (currency?.symbol === SWAP_TYPE.OXX) {
    return <StyledEthereumLogo src={OXXLogo} size={size} style={style} />
  }
  if (currency?.symbol === SWAP_TYPE.OXG) {
    return <StyledEthereumLogo src={OXGLogo} size={size} style={style} />
  }
  if (currency?.symbol === SWAP_TYPE.CADS && currency?.address == REACT_APP_CADS_ADDRESS) {
    return <StyledLogo size={size} srcs={srcs} alt={`${currency?.symbol ?? 'token'} logo`} style={style} />
  }
  if (currency?.symbol === SWAP_TYPE.CADSII && currency?.address == REACT_APP_CADSII_ADDRESS) {
    return <StyledEthereumLogo src={CADSIILogo} size={size} style={style} />
  }

  if (currency?.symbol === SWAP_TYPE.ATM) {
    return <StyledEthereumLogo src={ATMLogo} size={size} style={style} />
  }

  if (currency?.symbol === SWAP_TYPE.UNI) {
    return <StyledEthereumLogo src={UniLogo} size={size} style={style} />
  }

  if (currency?.symbol === SWAP_TYPE.SAND) {
    return <StyledEthereumLogo src={SandLogo} size={size} style={style} />
  }
  if (currency?.symbol === SWAP_TYPE.DYDX) {
    return <StyledEthereumLogo src={DxdyLogo} size={size} style={style} />
  }
  if (currency?.symbol === SWAP_TYPE.MASK) {
    return <StyledEthereumLogo src={MaskLogo} size={size} style={style} />
  }
  if (currency?.symbol === SWAP_TYPE.MATIC) {
    return <StyledEthereumLogo src={MaticLogo} size={size} style={style} />
  }
  if (currency?.symbol === SWAP_TYPE.GTC) {
    return <StyledEthereumLogo src={GtcLogo} size={size} style={style} />
  }

  if (currency?.symbol === SWAP_TYPE.SHIB) {
    return <StyledEthereumLogo src={ShibLogo} size={size} style={style} />
  }
  if (currency?.symbol === SWAP_TYPE.INCH) {
    return <StyledEthereumLogo src={INCHLogo} size={size} style={style} />
  }
  if (currency?.symbol === SWAP_TYPE.MANA) {
    return <StyledEthereumLogo src={MANALogo} size={size} style={style} />
  }
  if (currency?.symbol === SWAP_TYPE.FIL) {
    return <StyledEthereumLogo src={FILLogo} size={size} style={style} />
  }
  if (currency?.symbol === SWAP_TYPE.AIA || currency?.symbol === 'AIA' || currency?.symbol === 'WAIA') {
    return <StyledEthereumLogo src={EthereumLogo} size={size} style={style} />
  }
  // if (currency?.symbol === SWAP_TYPE.FAIA) {
  //   return <StyledEthereumLogo src={FAIA} size={size} style={style} />
  // }
  // if (currency?.symbol === SWAP_TYPE.TAIA) {
  //   return <StyledEthereumLogo src={TAIA} size={size} style={style} />
  // }
  // if (currency?.symbol === SWAP_TYPE.SAIA) {
  //   return <StyledEthereumLogo src={SAIA} size={size} style={style} />
  // }
  // if (currency?.symbol === SWAP_TYPE.KIAIA) {
  //   return <StyledEthereumLogo src={KIAIA} size={size} style={style} />
  // }
  // if (currency?.symbol === SWAP_TYPE.GAAIA) {
  //   return <StyledEthereumLogo src={GAAIA} size={size} style={style} />
  // }
  // if (currency?.symbol === SWAP_TYPE.WINAIA) {
  //   return <StyledEthereumLogo src={WINAIA} size={size} style={style} />
  // }
  if (currency?.symbol === SWAP_TYPE.WYOHO) {
    return <StyledEthereumLogo src={WYOHOLogo} size={size} style={style} />
  }
  if (currency?.symbol === SWAP_TYPE.YYOHO) {
    return <StyledEthereumLogo src={YYOHOLogo} size={size} style={style} />
  }
  if (currency?.symbol === SWAP_TYPE.YOHO) {
    return <StyledEthereumLogo src={YOHOLogo} size={size} style={style} />
  }
  if (currency?.symbol === SWAP_TYPE.BNB) {
    return <StyledEthereumLogo src={BNBLogo} size={size} style={style} />
  }
  if (currency?.symbol === SWAP_TYPE.CAC && currency?.address == REACT_APP_CAC_ADDRESS) {
    return <StyledLogo size={size} srcs={srcs} alt={`${currency?.symbol ?? 'token'} logo`} style={style} />
  }
  if (currency?.symbol === SWAP_TYPE.CAC && currency?.address == REACT_APP_CACII_ADDRESS) {
    return <StyledEthereumLogo src={CACIILogo} size={size} style={style} />
  }
  if (currency?.symbol === SWAP_TYPE.MANEKI) {
    return <StyledEthereumLogo src={MANEKILogo} size={size} style={style} />
  }
  return <StyledLogo size={size} srcs={srcs} alt={`${currency?.symbol ?? 'token'} logo`} style={style} />
}
