import dayjs from "dayjs"
import utc from 'dayjs/plugin/utc'
import { blockClient } from "./initApollo"
import { GET_BLOCK, GET_BLOCKS } from "./query"
import 'dayjs/locale/zh-cn'

dayjs.extend(utc)

export async function splitQuery(
    query: any,
    localClient: any,
    vars: any,
    list: any,
    skipCount: number) {
    let fetchedData = {}
    let allFound = false
    let skip = 0
    while (!allFound) {
        let end = list.length
        if (skip + skipCount < list.length) {
            end = skip + skipCount
        }
        let sliced = list.slice(skip, end)
        let result = await localClient.query({
            query: query(...vars, sliced),
            fetchPolicy: 'cache-first',
        })
        fetchedData = {
            ...fetchedData,
            ...result.data,
        }
        if (Object.keys(result.data).length < skipCount || skip + skipCount > list.length) {
            allFound = true
        } else {
            skip += skipCount
        }
    }

    return fetchedData
}
export async function getBlocksFromTimestamps(timestamps: any, skipCount = 500) {
    if (timestamps?.length === 0) {
        return []
    }

    let fetchedData: any = await splitQuery(GET_BLOCKS, blockClient, [], timestamps, skipCount)

    let blocks = []
    if (fetchedData) {
        for (var t in fetchedData) {
            if (fetchedData[t].length > 0) {
                blocks.push({
                    timestamp: t.split('t')[1],
                    number: fetchedData[t][0]['number'],
                })
            }
        }
    }
    return blocks
}
/**
 * @notice Fetches first block after a given timestamp
 * @dev Query speed is optimized by limiting to a 600-second period
 * @param {Int} timestamp in seconds
 */
export async function getBlockFromTimestamp(timestamp: number) {
    let result = await blockClient.query({
        query: GET_BLOCK,
        variables: {
            timestampFrom: timestamp,
            timestampTo: timestamp + 600,
        },
        fetchPolicy: 'cache-first',
    })
    return result?.data?.blocks?.[0]?.number
}

export const toNiceDate = (date: any, type?: string) => {

    type = type ? type : '24H'
    const getLang = getLangBool()

    const formatType = `MM${getLang ? '' : 'M'}-DD-HH:mm`
    let x = dayjs.unix(date).format(formatType)
    let [MM, DD, HM] = x.split("-")
    if (getLang) {
        return (type !== '24H' ? `${MM}月${DD}日` : HM)
    } else {
        return (type !== '24H' ? `${MM} ${DD}` : HM)
    }
}
export const toShowTimes = (date: any) => {
    const getLang = getLangBool()
    const formatType = `YYYY-MM${getLang ? '' : 'M'}-DD-HH:mm-A`
    let x = dayjs.unix(date).format(formatType)
    let [YY, MM, DD, HM, AM] = x.split("-")
    if (getLang) {
        return `${YY}年${MM}月${DD}日 ${HM}`
    } else {
        return `${MM} ${DD},${YY},${HM} ${AM}`
    }
}
export const getValue = (val: number) => {
    if (val > 1) {
        return Number(val).toFixed(2)
    } else if (val < 1 && val >= 0.001) {
        return Number(val).toFixed(3)
    } else if (val < 0.001) {
        return `0.001`
    }
    return null
}
export const getValueNew = (val: number) => {
    if (val > 1) {
        return Number(val).toFixed(2)
    } else if (val < 1 && val >= 0.00001) {
        return Number(val).toFixed(6)
    } else if (val < 0.00001) {
        return `0.00001`
    }
    return null
}
const getLangBool = () => {
    const currentLng = ['zh-CN', 'zh-TW', 'CN', 'TW', 'zh']
    const getKey = localStorage.getItem("i18nextLng") || ''
    const getLang = currentLng.includes(getKey)
    getLang?dayjs.locale('zh-cn'):dayjs.locale('en')
    return getLang
}
export function getTimestampsForChanges() {
    const utcCurrentTime = dayjs()
    const t1 = utcCurrentTime.subtract(1, 'day').startOf('minute').unix()
    const t2 = utcCurrentTime.subtract(2, 'day').startOf('minute').unix()
    const tWeek = utcCurrentTime.subtract(1, 'week').startOf('minute').unix()
    return [t1, t2, tWeek]
}

