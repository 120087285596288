/**
 * @name: giftCard
 * @author: mahuanhuan
 * @date: 2024/1/12
 * @description：
 * @update: 2024/1/12
 */


import {useGiftCardContract, useTokenContract} from "../hooks/useContract";
import {useCallback, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useActiveWeb3React} from "../hooks";
import {useTransactionAdder} from "../state/transactions/hooks";
import {dividedByDecimals, lt} from "./bigNumber";
import {message} from "antd";
import {REACT_APP_GIFT_CARD, USDT} from "../constants";
import {useTokenBalance} from "../state/wallet/hooks";
import {ethers} from 'ethers';
import {Token} from "@uniswap/sdk";
import {getAddrByUser} from "../services/card";

const errors = (messages: string) => {
  message.error(messages)
}
/**
 * 获取是否开启赠卡活动
 */

export const useGetIsOpenGiftCard = () => {
  const [isOpenGiftCard, setIsOpenGiftCard] = useState<boolean>(false)

  const tokenContract = useGiftCardContract();

  const getCardFlag = useCallback(async () => {
    try {
      let result = await tokenContract?.getCardFlag();
      setIsOpenGiftCard(result);
    } catch (e) {
      setIsOpenGiftCard(false)
    }
  }, [tokenContract])
  useEffect(() => {
    if (!tokenContract) {
      return
    }
    getCardFlag()
  }, []);
  return {isOpenGiftCard}
}


/**
 * 用户支付邮费
 */
export function PayPostage(
  currentCardInfo: Record<string, any>
) {
  const {t} = useTranslation()
  const {account} = useActiveWeb3React();
  const [loading, SetLoading] = useState<boolean>(false);
  const tokenContract = useGiftCardContract();
  const addTransaction = useTransactionAdder();
  const balanceInfo = useTokenBalance(account as string, USDT)
  const balance = balanceInfo?.toSignificant(4)
  const postage = currentCardInfo.postage;
  const isApproved = ERC20ApproveLimit(USDT, +currentCardInfo.postage, REACT_APP_GIFT_CARD as string)

  const goPay = useCallback(async () => {
    if (!account || !tokenContract) return
    if (!balance) {
      errors(t('CurrencyInsufficientBalance', {currency: USDT.symbol}))
      return
    }
    SetLoading(true);
    await isApproved();
    try {
      const response = await tokenContract.pay(currentCardInfo.transId)
      addTransaction(response, {});
      await response.wait()
      return true;
    } catch (error) {
      errors(error.data ? error.data.message : error.message ?? t('Please try again'))
      SetLoading(false);
      return false;
    }

  }, [account, tokenContract, balance, currentCardInfo, postage, isApproved])
  return {loading, goPay, SetLoading}
}


export const ERC20Approve = (
  coinAddress: string,
  tokenAddress: string,
  amount = ethers.constants.MaxUint256
) => {
  const tokenContract = useTokenContract(coinAddress)
  return useCallback(async () => {
    try {
      const tx = await tokenContract?.approve(tokenAddress, amount)
      await tx.wait()
      return true
    } catch (e) {
      console.debug('Failed to approve token', e)
      return false
    }
  }, [tokenContract, coinAddress, amount])

}

export const ERC20Allowanced = (
  coinInfo: Token,
  tokenAddress: string,
) => {
  const {account} = useActiveWeb3React();
  const tokenContract = useTokenContract(coinInfo.address)
  return useCallback(async () => {
    const res = await tokenContract?.allowance(account, tokenAddress);
    return dividedByDecimals(res.toString(), coinInfo.decimals)
  }, [tokenContract, coinInfo])
}


export const ERC20ApproveLimit = (
  coinInfo: Token,
  value: number,
  tokenAddress: string,
) => {
  const getAllowancedNum = ERC20Allowanced(coinInfo, tokenAddress);
  const approve = ERC20Approve(coinInfo.address, tokenAddress)
  return useCallback(async () => {
    if (coinInfo.symbol && coinInfo.symbol != 'AIA') {
      const amount = await getAllowancedNum()
      if (lt(Number(amount), value)) {
        return await approve()
      }
      return true;
    }
    return true;
  }, [getAllowancedNum, value, coinInfo])
};


export const useAddressInfo = () => {
  const [addressInfo, setAddressInfo] = useState<Record<string, any>>({})
  // 获取收货地址
  const getAccountAddressInfo = async (userAddr: string) => {
    const res = await getAddrByUser({
      userAddr
    })
    setAddressInfo(res);
  }
  return {addressInfo, getAccountAddressInfo}
}

//  获取账户签名

export const useGetSignature = () => {
  const {library, account} = useActiveWeb3React();
  return useCallback(async (message: string) => {
    if(!account) return;
    return library?.getSigner().signMessage(message);
    // return library?.getSigner().request({
    //   method: "personal_sign",
    //   params: [
    //     ethers.utils.toUtf8Bytes(message),
    //     account
    //   ]
    // })
  }, [library, account])
}

/**
 * 查询挖矿赠卡信息
 */

export const useGetTransInfo = () => {
  const tokenContract = useGiftCardContract();
  const getTransInfo = useCallback(async (transId) => {
    return await tokenContract?.getTransInfo(transId);
  }, [tokenContract])
  return {getTransInfo}
}

