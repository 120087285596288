import React,{ useContext, useState,useEffect} from 'react';
import { Spin } from 'antd';
import { useTranslation } from 'react-i18next'
import Modal from '../../../components/Modal';
import styled, { ThemeContext } from 'styled-components'
import { ColumnCenter } from '../../../components/Column';
import { RowBetween} from '../../../components/Row';
import {Text,} from '../../../components/invitation/styled';
import { CloseOutlined,EllipsisOutlined } from '@ant-design/icons'
import {
  GetStakeRecords
} from '../../../utils/invitationCallback_v2'
const ColumnCenterEle = styled(ColumnCenter)`
  width: initial;
  min-width: 140px;
  margin-bottom: 10px;
`
const RecordListEle = styled.div`
width: 100%;
max-height: 90vh;
padding: 0 1rem;
overflow: auto;
`
const RecordItemEle = styled.div`
width: 100%;
padding: 0.5rem 0;

& + .recordItem {
  border-top: 1px solid rgba(14, 37, 74, 0.06);
}
`
const RecordItemFirstRow = styled.div`
display: flex;
justify-content: space-between;
`
const LoadingRow = styled.div`
display: flex;
align-items: center;
justify-content: center;
padding: 1rem;
`
const MoreRow = styled.div`
  width: 100%;
  padding: 1rem;  
  text-align: center;
  cursor: pointer;
  letter-spacing: 0.125rem;
  border-top: 1px solid rgba(14, 37, 74, 0.06);
`
const typeList:any = {
  1: 'Mining',
  2: 'Redemption',
  3: 'Award'
}
export default function PledgeRecordList(props:any){
  const { t } = useTranslation()
  const theme = useContext(ThemeContext);
  const [dataSource, setDataSource] = useState<any>([])
  const [listQuery,setListQuery] = useState<any>({pageNo: 1, pageSize: 10})
  const {getStakeRecords,userStakeLoaing,userStakeRecord } = GetStakeRecords();

  useEffect(() => {
    getList();
  },[listQuery])
  

  const getList = async() => {
    let {pageNo,pageSize } = listQuery;
    let start = (pageNo-1)*pageSize;
    let end = start + pageSize;
    let data:any = await getStakeRecords(start,end);
    setDataSource([...dataSource,...data]);
  }
  const onNext = () => {
    setListQuery({...listQuery, pageNo: listQuery.pageNo+1})
  }
  const close = () => {
    props?.onDismiss();
  }
  return (<Modal isOpen={true} onDismiss={()=>{}} >
    <ColumnCenterEle style={{ width: '100%' }}>
      <RowBetween style={{ padding: '1rem', marginBottom: '1rem', borderBottom: '1px solid rgba(14,37,74,0.06)' }}>
        <Text style={{ fontWeight: 600, color: theme.text7, fontSize: '16px' }}>{t('Pledge asset record')} </Text>
        <Text style={{ color: '#666666', fontSize: '16px' }} onClick={close}>
          <CloseOutlined />
        </Text>
      </RowBetween>
      <RecordListEle>
        {dataSource.length === 0 && !userStakeLoaing && <Text style={{textAlign: 'center'}}>{t('There are currently no records available')}</Text>}
        {
          dataSource.map((item:any,index: number)=>{
            return (<RecordItemEle className='recordItem' key={index}>
              <RecordItemFirstRow>
                <Text style={{ color: theme.text7,  }}>{t(typeList[item.type])}</Text>
                <Text style={{ fontWeight: 600, color: theme.text7, fontSize: '14px',flex: 1,textAlign: 'right' }}>{`${item.type !==2?'+':'-'}${item.amount}`}</Text>
              </RecordItemFirstRow>
              <Text style={{ color: theme.glay, fontSize: '12px' }}>{item.time}</Text>
            </RecordItemEle>
            )
          })
        }
        {(userStakeLoaing ) && <LoadingRow><Spin></Spin></LoadingRow>}
        {!userStakeLoaing && userStakeRecord.length === listQuery.pageSize && <MoreRow onClick={onNext}><EllipsisOutlined style={{fontSize: '25px', fontWeight: 600}} /></MoreRow>}
      </RecordListEle>
    </ColumnCenterEle>
  </Modal>)
}