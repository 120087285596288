import { ChainId } from '@uniswap/sdk'
import { useActiveWeb3React } from 'hooks'
import { useMemo } from 'react'

import  GelatoLimitOrders  from './gelatoLimitOrdersClass'


const useGelatoLimitOrdersLib = (): GelatoLimitOrders | undefined => {
  const { chainId, library } = useActiveWeb3React()

  return useMemo(() => {

    if (!chainId || !library) {

      console.error('Could not instantiate GelatoLimitOrders: missing chainId or library')
      
      return undefined
    }
    try {
      return new GelatoLimitOrders(chainId as ChainId, library?.getSigner())
    } catch (error) {
      const err:any = error
      console.error(`Could not instantiate GelatoLimitOrders: ${err?.message}`)
      return undefined
    }


  }, [chainId, library])
}



export default useGelatoLimitOrdersLib
