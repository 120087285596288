import React, { useState, useMemo, useCallback, ReactElement, memo, useEffect } from 'react'
import styled from 'styled-components'
import NoOrdersMessage from './NoOrdersMessage'
import { ORDER_CATEGORY } from '../../types'
import LoadingTable from './LoadingTable'
import { useTranslation } from 'react-i18next'
import { Box, Flex, Text } from 'rebass'
import { ArrowBackIcon, ArrowForwardIcon } from 'components/Svg'
// import { useActiveWeb3React } from 'hooks'
// import {  getPendingOrder } from 'pages/LimitOrders/utils/localStorageOrders'

const Arrow = styled.div`
  color: ${({ theme }) => theme.text7};
  padding: 0 20px;
  :hover {
    cursor: pointer;
  }
`

interface ExposedProps {
    paginatedData: any[]
}

interface TableNavigationProps {
    data: any[]
    itemsPerPage?: number
    children: (exposedProps: ExposedProps) => ReactElement
    orderCategory: ORDER_CATEGORY
    onPage?: Function,
    total: number
}
const ORDERS_PER_PAGE = 5
const TableNavigation: React.FC<TableNavigationProps> = ({
    data,
    orderCategory,
    itemsPerPage = ORDERS_PER_PAGE,
    children,
    onPage,
    total
}) => {
    // const { account, chainId } = useActiveWeb3React()
    // const pendingOrdersLS = getPendingOrder(chainId, account)
    const { t } = useTranslation()
    const [currentPage, setPage] = useState(1)

    // if(pendingOrdersLS&&pendingOrdersLS.length>0&&orderCategory===ORDER_CATEGORY.Open){
    //     total = total+pendingOrdersLS.length
    // }
    if (data && total === 0) {
        total = data.length
    }
    const maxPage = useMemo(() => {
        if (total) {
            return Math.ceil(total / itemsPerPage)
        }
        return 1
    }, [total, itemsPerPage])

    const onPageNext = useCallback(() => {
        onPage?.(currentPage === maxPage ? currentPage : currentPage + 1)
        setPage((page) => (page === maxPage ? page : page + 1))
    }, [maxPage, currentPage])

    const onPagePrev = useCallback(() => {
        onPage?.(currentPage === 1 ? currentPage : currentPage - 1)
        setPage((page) => (page === 1 ? page : page - 1))
    }, [currentPage])
    const from = useMemo(() => itemsPerPage * (currentPage - 1), [currentPage, itemsPerPage])
    const to = useMemo(() => itemsPerPage * currentPage, [currentPage, itemsPerPage])

    const paginatedData = useMemo(() => {
        return data
        // if(orderCategory!==ORDER_CATEGORY.Open){
        //     return data
        // }
        // return Array.isArray(data) ? data.slice(from, to) : []
    }, [data, from, to, orderCategory])

    useEffect(() => {
        setPage(1)
    }, [orderCategory])

    useEffect(() => {
        if (currentPage > maxPage) {
            onPage?.(maxPage)
            setPage(maxPage)
        } 
        // else {
        //     if (currentPage > 1 && data.length === 0) {
        //         onPage?.(currentPage - 1)
        //         setPage((page) => (page === 1 ? page : page - 1))
        //     }
        // }
    }, [total, data, currentPage, maxPage])


    return (
        <>
            {children({
                paginatedData,
            })}
            {!data && <LoadingTable />}
            {data && !total && <NoOrdersMessage orderCategory={orderCategory} />}
            <PageContainer>
                <Box />
                <Flex width="100%" justifyContent="center" alignItems="center">
                    <Arrow onClick={onPagePrev}>
                        <ArrowBackIcon color={currentPage === 1 ? '#cccccc' : 'primary'} />
                    </Arrow>
                    <Text>{t('PageOfmaxPage', { page: currentPage, maxPage })}</Text>
                    <Arrow onClick={onPageNext}>
                        <ArrowForwardIcon color={currentPage === maxPage ? '#cccccc' : 'primary'} />
                    </Arrow>
                </Flex>
            </PageContainer>
        </>
    )
}

const PageContainer = styled.div`
    width:100%;
    padding:1.05rem 0;

`

export default memo(TableNavigation)
