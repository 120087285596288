const variants = {
  warning: {
    background: "#FFB23719",
    borderColor: "#FFC07B",
  },
  danger: {
    background: "#ED4B9E19",
    borderColor: "failure",
  },
  success: {
    background: "#F1F4FF",
    borderColor: "#1F5DEF",
  },
};

export default variants;
