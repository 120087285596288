import React, { memo, useEffect, useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import Reserve from '../../assets/svg/reserve.svg'
import ChartEmpty from '../../assets/svg/chartEmpty.svg'
import { Area, XAxis, YAxis, ResponsiveContainer, Tooltip, AreaChart } from 'recharts'
import { fetchData, getHourlyRateData } from 'apollo'
import { getValueNew, toNiceDate, toShowTimes } from 'apollo/utils'
import dayjs from 'dayjs'
import CurrencyShowLogo from 'apollo/CoinLogo'
import { Field } from '../../state/swap/actions'
import { getToken, useAspectValue, useShowCoinFun } from 'apollo/hooksUtils'
import LoadChart from './loadIcon'
import useMedia from 'react-use/lib/useMedia'
import { useWindowSize } from 'react-use'
import { CloseIcon } from 'theme/components'


interface Params {
    currencies: any,
    pairList: any,
    onDismiss?: () => void
    sx?:any
}
enum PAIR_AITD_USDT {
    address = '0x1d16adac6be715f21bea6cb67f5e264a43d0b42d',
    USDT = 'USDT',
    AIA = 'AIA',
    WAIA = 'WAIA'
}
const CartChart: React.FC<Params> = (({ currencies, pairList, onDismiss,sx }) => {
    const isPhoneSize = useMedia('(max-width: 960px)')
    const size = useWindowSize()
    const { t } = useTranslation()
    const inputToken = currencies[Field.INPUT]?.symbol
    const outToken = currencies[Field.OUTPUT]?.symbol

    const [pairAddress, setPairAddress] = useState<null | any>(
        {
            tokens: [PAIR_AITD_USDT.AIA, PAIR_AITD_USDT.USDT],
            address: null
        }
    )

    const [showPrice, setShowPrice] = useState<null | string | number>(null)
    const [isReserve, setIsReserve] = useState(false)
    const [selectCurrentTime, setSelectCurrentTime] = useState('1W')
    const [chartChangeData, setChartChangeData] = useState<any>(null)
    const [chartChangeAllData, setChartChangeAllData] = useState<any>(null)
    const [showTime, setShowTime] = useState<string | null>(null)
    const [loading, setLoading] = useState(true)
    const [PGQ, setPGQ] = useState({
        qgqNumber: 0,
        qgqPre: '0%',
        rised: false
    })


    useEffect(() => {
        async function checkForChartData() {
            try {

                if (pairAddress.address) {
                    const getTokenDataFun = (datas: any, getPriceNew: any) => {
                        let newPrice;
                        if (datas[0].symbol.indexOf('AIA') > -1) {
                            newPrice = getPriceNew['WAIA']?.price || getPriceNew['AIA']?.price
                        } else {
                            newPrice = getPriceNew[datas[0].symbol]?.price
                        }
                        // console.info(datas, tokens[0],reserveOne,reserveTwo)
                        datas = [...datas, {
                            open: newPrice,
                            timestamp: dayjs().unix()
                        }]
                        const firstData = datas.filter((i: any) => i.open)
                        const pgq = Number(datas[datas.length - 1].open) - Number(firstData[0].open)
                        const getQgqNumber = Number(pgq.toFixed(3))
                        return {
                            datas,
                            pgqs: {
                                qgqNumber: Math.abs(getQgqNumber),
                                qgqPre: ((Math.abs(pgq) / Number(firstData[0].open)) * 100).toFixed(2) + '%',
                                rised: pgq >= 0
                            }, token: firstData[0].symbol
                        }
                    }
                    setLoading(true)
                    const {
                        reserve0,
                        token0,
                        reserve1,
                        token1
                    } = await fetchData(pairAddress.address)
                    let data = await getHourlyRateData(pairAddress.address, selectCurrentTime);
                    var getPriceNew = {
                        [token0.symbol]: {
                            token: token0.symbol,
                            price: pairAddress.address > 1 ? reserve1 / reserve0 : reserve0 / reserve1
                        },
                        [token1.symbol]: {
                            token: token1.symbol,
                            price: pairAddress.address > 1 ? reserve0 / reserve1 : reserve1 / reserve0
                        },
                    }
                    let { datas: getFirstDataResult, pgqs, token } = getTokenDataFun(data[0], getPriceNew)
                    let { datas: getTwoDataResult, pgqs: pgqsTow, token: tokens1 } = getTokenDataFun(data[1], getPriceNew)
                    const lastResult = {
                        [token]: {
                            datas: getFirstDataResult,
                            pgqs
                        },
                        [tokens1]: {
                            datas: getTwoDataResult,
                            pgqs: pgqsTow
                        },
                    }
                    setLoading(false)
                    setChartChangeAllData({
                        data: lastResult,
                        tokens: {
                            token0: pairAddress.tokens[0],
                            token1: pairAddress.tokens[1],
                        }
                    })
                }
            } catch (error) {
                setLoading(false)
                setChartChangeData(null)
            }
        }
        checkForChartData()
    }, [pairAddress, selectCurrentTime])

    useEffect(() => {
        let result;
        if (chartChangeAllData) {
            const WAIA = PAIR_AITD_USDT.WAIA
            const USDT = PAIR_AITD_USDT.USDT
            const { data, tokens } = chartChangeAllData
            const { token0, token1 } = getToken(tokens.token0, tokens.token1)

            if (token0 && token1 && (token0 !== token1)) {
                result = data[isReserve ? token1 : token0]
            } else if (token0) {
                result = data[isReserve ? USDT : token0]
            } else if (token1) {
                const one = isReserve ? token1 : USDT
                const two = isReserve ? USDT : token1
                result = chartChangeAllData.data[token1 === WAIA ? one : two]
            }
        }
        if (!!result) {
            const datas = result?.datas[result?.datas.length - 1]
            result?.datas.length > 0 && setShowTime(toShowTimes(datas.timestamp))
            result?.datas.length > 0 && setShowPrice(getValueNew(datas.open))
            setPGQ(result.pgqs)
            setChartChangeData(result.datas)
        }
    }, [isReserve, chartChangeAllData])
    useEffect(() => {
        const { token0, token1 } = getToken(inputToken, outToken)
        if (token0 && token1 && (token0 !== token1)) {
            const setPairAddressFun = () => {
                const result = (pairList || []).some((i: any) => {
                    const { PairToken, id } = i
                    const token0Ture = PairToken.includes(token0)
                    const token1Ture = PairToken.includes(token1)
                    if (token0Ture && token1Ture) {
                        setPairAddress({
                            tokens: [token0, token1],
                            address: [id]
                        })
                        // setPairAddress([id])
                        return true
                    }
                    return false
                })
                if (!result) {
                    setChartChangeData(null)
                    setPairAddress({
                        tokens: [token0, token1],
                        address: null
                    })
                }
            }
            const kk = token0.toLocaleUpperCase() === PAIR_AITD_USDT.USDT || token1.toLocaleUpperCase() === PAIR_AITD_USDT.USDT
            const kArriy = [token0.toLocaleUpperCase(), token1.toLocaleUpperCase()]
            const ignore = kk && ['WAIA', 'ETH', 'BTC'].some((i: string) => kArriy.includes(i));
            if (ignore || !kk) {
                setPairAddressFun();
            } else {
                // 特殊处理U
                const resultLast = (pairList || []).filter((i: any) => {
                    const { PairToken } = i
                    const tokenAITDTure = PairToken.includes(PAIR_AITD_USDT.WAIA)
                    const token0Ture = PairToken.includes(token0)
                    const token1Ture = PairToken.includes(token1)
                    if (token0Ture && tokenAITDTure) {
                        return true
                    } else if (token1Ture && tokenAITDTure) {
                        return true
                    }
                    return false
                })
                if (resultLast.length === 2) {
                    const token0Id = resultLast.map((i: any) => i.PairToken.includes(token0) && i.id).filter((i: string) => i)
                    const token1Id = resultLast.map((i: any) => i.PairToken.includes(token1) && i.id).filter((i: string) => i)
                    setPairAddress({
                        tokens: [token0, token1],
                        address: [...token0Id, ...token1Id]
                    })
                }
            }

        } else {
            const PAIR_AITD = PAIR_AITD_USDT.AIA
            const PAIR_USDT = PAIR_AITD_USDT.USDT
            const PAIR_ADDRESS = PAIR_AITD_USDT.address
            if (token0 && (token0 === PAIR_AITD || token0 === 'WAIA')) {
                setPairAddress({
                    tokens: [PAIR_AITD, PAIR_USDT], address: [PAIR_ADDRESS]
                })
            } else if (!token0 && token1 && (token1 === PAIR_AITD || token1 === 'WAIA')) {
                setPairAddress({
                    tokens: [PAIR_USDT, PAIR_AITD], address: [PAIR_ADDRESS]
                })
            } else {
                (token0 && token1 && token0 === token1) && setPairAddress({
                    tokens: [token0, token1], address: null
                })
            }
        }
    }, [inputToken, outToken, pairList])


    const showCoin = useShowCoinFun(currencies, isReserve)
    const textFun = (res: any) => {
        const { isTooltipActive } = res
        if (!isTooltipActive) {
            const datas = chartChangeData[chartChangeData.length - 1]
            setShowPrice(getValueNew(datas.open))
            setShowTime(toShowTimes(datas.timestamp))
        }
    }
    const rexr = (res: any) => {
        const { activeLabel, isTooltipActive, activePayload } = res
        if (isTooltipActive) {
            const datas = activePayload[0].value
            setShowTime(toShowTimes(activeLabel));
            setShowPrice(!!datas ? getValueNew(datas) : '--')
        } else {
            const datas = chartChangeData[chartChangeData.length - 1]
            setShowPrice(!!datas.open ? getValueNew(datas.open) : '--')
            setShowTime(toShowTimes(datas.timestamp))
        }
    }
    // const selectCurrentTimeList = ['24H', '1W', '1M', 'ALL'];
    const selectCurrentTimeList = ['24H', '1W', '1M'];
    const wrappedOnDismiss = () => onDismiss?.()
    const aspect = useAspectValue()
    return <>
        <ChartContainer style={{...sx}}>
            {pairAddress?.address ? <>
                <ShowMoveDot>
                    <ShowRow className='first-coin'>
                        <div><CurrencyShowLogo currency={showCoin(currencies)} />
                            <SpanCoin className='coin'>{showCoin(currencies).context}</SpanCoin>
                            <img className='reserve' src={Reserve} alt="AIA" onClick={() => setIsReserve(!isReserve)} /></div>
                        {isPhoneSize && <CloseIcon onClick={wrappedOnDismiss} />}
                    </ShowRow>
                    <ShowMoveDotContainer>
                        <ShowMoveDotContext>
                            {loading ? <LoadingHide /> : <>
                                <ShowRow className='second-coin'>
                                    {
                                        !!showPrice ? <SpanCoin className='coin wrap-coin'>
                                            {Number(showPrice || 0) === 0.00001 ? <label>v</label> : ''}
                                            {showPrice}
                                        </SpanCoin> : <SpanCoin className='coin wrap-coin'>{showPrice}</SpanCoin>
                                    }

                                    <SpanCoin >{showCoin(currencies).context}</SpanCoin>
                                    <SpanActive col={PGQ.rised ? 'rgb(49, 208, 170)' : 'rgb(237, 75, 158)'}>
                                        {PGQ.rised ? '+' : '-'}
                                        {`${PGQ.qgqNumber < 0.00001 ? 0.00001 : PGQ.qgqNumber}
                                (${PGQ.rised ? '' : '-'}${PGQ.qgqPre})`}</SpanActive>
                                </ShowRow>
                                <ShowRow>
                                    <SpanCoin style={{ fontSize: '16px' }}>{showTime}</SpanCoin>
                                </ShowRow>
                            </>}
                        </ShowMoveDotContext>
                        <ShowMoveDotContextLeft>
                            {selectCurrentTimeList.map((i: string) => <span key={i} className={i === selectCurrentTime ? 'activeTime' : ''} onClick={() => setSelectCurrentTime(i)}>{t(i)}</span>
                            )}
                        </ShowMoveDotContextLeft>
                    </ShowMoveDotContainer>
                </ShowMoveDot>
                <ChartContext style={{ width: '100%' }}>
                    {
                        loading ?
                            <>
                                <LoadingContext>{t('Loading chart data')}</LoadingContext>
                                <LoadChart />
                            </> :
                            chartChangeData &&
                            <ResponsiveContainer aspect={isPhoneSize ? undefined : aspect} width="100%" height='100%'>
                                <AreaChart
                                    data={chartChangeData}
                                    margin={{ top: 15, right: isPhoneSize ? 0 : 2 }}
                                    barCategoryGap={1}
                                    onMouseMove={rexr}
                                    onMouseLeave={textFun}>
                                    <defs>
                                        <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1" strokeWidth={0}>
                                            <stop offset="5%" stopColor={PGQ.rised ? '#D9F2EE' : 'rgb(243,216,236)'} stopOpacity={0.8} />
                                            <stop offset="100%" stopColor={PGQ.rised ? '#2DC193' : 'rgb(237, 75, 158)'} stopOpacity={0} />
                                        </linearGradient>
                                    </defs>
                                    <XAxis
                                        dx={-15}
                                        tickMargin={10}
                                        tickLine={false}
                                        axisLine={false}
                                        tickFormatter={(tick: any) => toNiceDate(tick, selectCurrentTime)}
                                        minTickGap={isPhoneSize ? size.width / 6 / 2 : 64}
                                        dataKey="timestamp"
                                        tick={{ fill: '#6C7A92', fontWeight: 700, fontSize: '13px' }}
                                    />
                                    <YAxis
                                        allowDataOverflow
                                        domain={['dataMin', 'dataMax']}
                                        type="number"
                                        tickLine={false}
                                        axisLine={false}
                                        hide={true}
                                        padding={{ bottom: 2 }}
                                    />
                                    <Area
                                        isAnimationActive
                                        fillOpacity={1}
                                        strokeWidth={2}
                                        label={false}
                                        dot={false}
                                        type="monotone"
                                        dataKey="open"
                                        stroke={PGQ.rised ? '#2DC193' : 'rgb(237, 75, 158)'}
                                        fill="url(#colorUv)"
                                        activeDot={{ stroke: '#ffffff', strokeWidth: 3, r: 5 }}
                                    />
                                    <Tooltip
                                        cursor={{ stroke: PGQ.rised ? '#92DDC8' : 'rgb(255,153,205)', strokeWidth: 2 }}
                                        contentStyle={{
                                            display: 'none'
                                        }}
                                    />
                                </AreaChart>
                            </ResponsiveContainer>
                    }
                </ChartContext></> :
                <EmptyContext>
                    <img src={ChartEmpty} alt="AIA" />
                    <span>You can view USDT for AIA(and vice versa)with no trading fees.</span>
                </EmptyContext>
            }
        </ChartContainer>
    </>

})


const ShowMoveDot = styled.div`

 .first-coin{
   padding:20px 24px;
   border-bottom: 1px solid rgba(14,37,74,0.06);
   display: flex;
   align-items: center;
   justify-content: space-between;
   div{
    display: flex;
   align-items: center;
   }
   .reserve{
     margin-left:20px;
     cursor: pointer;
     &:hover{
       opacity: 0.75;
     }
   }
 }
 .second-coin{
    padding-bottom: 5px;
 }
`
const ShowMoveDotContainer = styled.div`
  display:flex;
  justify-content: space-between;
  padding: 20px 24px 0;

@media screen and (max-width:1200px) {
    flex-wrap: wrap; 
}
  
`
const ShowMoveDotContext = styled.div`
    flex:1;
`
const ShowMoveDotContextLeft = styled.div`
   background: #F2F4F6;
   height: 32px;
   padding:5px 0;
   border-radius: 20px;
   margin-top: 10px;
  span {
    color: #0E254A;
    padding:5px 20px;
    font-weight:bold;
    cursor: pointer;
    &:hover{
          opacity: 0.85;
        }
  }
  .activeTime{
    font-weight: bold;
    color: #FFFFFF;
    background: #2750EB;
    border-radius: 20px;
  }
`
const ShowRow = styled.div`
  .coin{
    color: #0E254A;
    font-family: DIN-Bold, DIN;
    font-weight: bold;
  }
  .wrap-coin{
    margin-right: 10px;
    font-size: 36px;
  }

`


const ChartContainer = styled.div`
    background: rgba(255, 255, 255, 0.5);
    border-radius: 30px;
    /* width: 50%; */
    height: 513px;
    box-shadow: rgb(14,37,74,0.1) 0px 2px 0px 0px;
    @media (max-width: 960px){
        width: 100%;
        height: auto;
        box-shadow:none;
        padding-bottom: 6rem;
    }
`
const SpanActive = styled.span<{ col: string }>`
  font-weight:bold;
  color:${({ col }) => col};
  & > label{
        display: inline-block;
        transform: rotate(90deg);
        margin-left: 10px;
    }
`
const SpanCoin = styled.span`
    font-family: DIN-Bold, DIN;
    color: #6C7B92;
    font-weight: 600;
    font-size: 20px;
    line-height: 1.1;
    & > label{
        display: inline-block;
        transform: rotate(90deg);
    }
`


const ChartContext = styled.div`
  padding:24px 24px 0;
  position: relative;
  height: 315px;
  @media (max-width: 960px){
    padding:24px 0 0;
    
    }
  /* height: 378px; */
`
const LoadingContext = styled.div`
position:absolute;
left:50%;
top:50%;
transform:translate(-50%,-50%);
z-index: 0;
font-size:20px;
font-weight:bold;
font-family: DIN-Bold, DIN;
color: #6C7B92;
`
const LoadingHide = styled.div`
    @keyframes identifier {

         0% {
            background-color: rgb(233, 234, 235,1);
         }
         50%{
            background-color: rgb(233, 234, 235,0.5);
         }
         100%{
            background-color: rgb(233, 234, 235,1);
         }
    }
    height:36px;
    width:128px;
    background-color: rgb(233, 234, 235);
    border-radius: 4px;
    animation: identifier 2s ease-out 0s infinite;
`
const EmptyContext = styled.div`
    display: flex;
    height: 100%;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    span{
        font-size: 14px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #738198;
        padding:2rem 2rem 0;
    }

`
export default memo(CartChart);