import React, { useContext } from 'react'
import { ORDER_CATEGORY } from '../../types'
import styled, { ThemeContext } from 'styled-components'
import { useTranslation } from 'react-i18next'


interface OrderTabProps {
    activeIndex: ORDER_CATEGORY
    onItemClick: (index: ORDER_CATEGORY) => void
}
const OrderTab: React.FC<OrderTabProps> = ({ activeIndex, onItemClick }) => {
    const theme = useContext(ThemeContext)
    const { t } = useTranslation()

    return (
        <Wrapper>
            <ButtonMenu>
                {[t('Open Orders'), t('Order History')].map((content, idx) => (
                    <ButtonMenuItem
                        onClick={() => {
                            onItemClick(idx)
                        }}
                        key={content}
                        style={{
                            color: idx === activeIndex ? 'rgba(14, 37,74 , 0.85)' : '#929FB5',
                            backgroundColor: idx === activeIndex ? theme.white : '#F2F4F6',
                        }}>
                        {content}
                    </ButtonMenuItem>
                ))}
            </ButtonMenu>
        </Wrapper>
    )
}
const Wrapper = styled.div`
 
`
const ButtonMenu = styled.div`
   background-color: #F2F4F6;
    display: flex;
    justify-content: space-between;
`
const ButtonMenuItem = styled.div`
    display: inline-flex;
    align-items:center;
    text-align: center;
    justify-content: center;
    border-radius: 16px;
    height: 48px;
    padding: 0 24px;
    box-sizing: border-box;
    flex: 1;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    font-weight:600;
    cursor: pointer;
    &:hover{
        opacity: 0.65;
    }

`


export default OrderTab