import { useEffect } from "react"
import { useSwapBlockNumber, useSwapBlockNumbers } from "state/block/hooks"
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { client, healthClient } from "./initApollo"
import { HOURLY_PAIR_RATES, SUBGRAPH_HEALTH, TOKEN_CHART } from "./query"
import { getBlocksFromTimestamps, splitQuery } from "./utils"
import { getBulkPairData } from "./getPrice"


export const timeframeOptions = {
    WEEK: 'week',
    MONTH: 'month',
    // THREE_MONTHS: '3 months',
    // YEAR: '1 year',
    HALF_YEAR: '6 months',
    ALL_TIME: 'All time',
}


dayjs.extend(utc)
let aa: any = window
aa.dayjs = dayjs
export const getHourlyRateData = async (pairAddress: any, startTime: string, latestBlock?: number | null) => {
    try {
        const utcEndTime = dayjs.utc()
        let time;
        let timestamps: any = []
        const getTimestamps = (timeType: string) => {
            const unix = utcEndTime.subtract(1, 'hour').startOf('hour').unix();
            const typeData: any = {
                'ALL': { l: 24, k: 7, d: 24 },
                '1W': { l: 6, k: 1, d: 28 },
                '1M': { l: 24, k: 1, d: 30 }
            }
            const { d, l, k } = typeData[timeType]
            const result = [];
            for (let i = 0; i < d; i++) {
                result.push(unix - l * 60 * 60 * k * i)
            }
            return result.reverse();
        }
        if (startTime !== '24H') {
            timestamps = getTimestamps(startTime)
        } else {
            time = utcEndTime.subtract(1, 'day').startOf('hour').unix()
            // create an array of hour start times until we reach current hour
            while (time <= utcEndTime.unix() - 3600) {
                timestamps.push(time)
                time += 3600
            }
        }
        // backout if invalid timestamp format
        if (timestamps.length === 0) {
            return []
        }

        // once you have all the timestamps, get the blocks for each timestamp in a bulk query
        let blocks: any;

        blocks = await getBlocksFromTimestamps(timestamps, 100)
        // catch failing case
        if (!blocks || blocks?.length === 0) {
            return []
        }
        if (latestBlock) {
            blocks = blocks.filter((b: any) => {
                return parseFloat(b.number) <= parseFloat(latestBlock + '')
            })
        }
        let formattedHistoryRate0: any = []
        let formattedHistoryRate1: any = []
        const result: any = await splitQuery(HOURLY_PAIR_RATES, client, [pairAddress[0]], blocks, 100);
        let result1: any;
        if (pairAddress.length === 2) {
            result1 = await splitQuery(HOURLY_PAIR_RATES, client, [pairAddress[1]], blocks, 100)
        }
        let values: any = []
        const getResult = result
        const getTokenPrice = (i: any) => {
            const { token0Price, token1Price } = i
            const symbols = i.token1.symbol
            const isWAITD = (symbols === 'WAIA' || symbols === 'WAIA')
            const reserve0 = isWAITD ? token1Price : token0Price
            return { reserve0, symbol: isWAITD ? i.token0.symbol : symbols }
        }
        for (var row in getResult) {
            let timestamp = row.split('t')[1]
            if (timestamp) {
                if (!!result1) {
                    const tokenOne = getTokenPrice(getResult[row])
                    const tokenTwo = getTokenPrice(result1?.[row])

                    values.push({
                        timestamp,
                        rate0: parseFloat((tokenOne.reserve0 / tokenTwo.reserve0).toString()) || null,
                        rate1: parseFloat((tokenTwo.reserve0 / tokenOne.reserve0).toString()) || null,
                        symbol0: tokenOne.symbol,
                        symbol1: tokenTwo.symbol
                    })
                } else {
                    values.push({
                        timestamp,
                        rate0: parseFloat(getResult[row]?.token0Price) || null,
                        rate1: parseFloat(getResult[row]?.token1Price) || null,
                        symbol0: getResult[row]?.token1.symbol,
                        symbol1: getResult[row]?.token0.symbol
                    })
                }

            }
            // for each hour, construct the open and close price
        }
        for (let i = 0; i < values.length; i++) {
            formattedHistoryRate0.push({
                timestamp: values[i].timestamp,
                open: values[i].rate0 || null,
                symbol: values[values.length - 1].symbol0
            })
            formattedHistoryRate1.push({
                timestamp: values[i].timestamp,
                open: values[i].rate1 || null,
                symbol: values[values.length - 1].symbol1
            })
        }
        return [formattedHistoryRate0, formattedHistoryRate1]
    } catch (e) {
        console.log(e)
        return [[], []]
    }
}

export const fetchData = async (pairAddress: any) => {
    let data: any = await getBulkPairData(pairAddress)

    if (pairAddress && pairAddress.length > 1) {
        if (data) {
            let result: any = []
            data.map((i: any) => {
                const { token0Price, token1Price, token0, token1 } = i
                const symbols = i.token1.symbol
                const reserve0 = (symbols === 'WAIA' || symbols === 'AIA') ? token1Price : token0Price
                const tokens = (symbols === 'WAIA' || symbols === 'AIA') ? token0.symbol : token1.symbol
                result.push({ reserve0, symbol: tokens })
            })


            return {
                reserve0: result[0].reserve0,
                token0: { symbol: result[0].symbol },
                token1: { symbol: result[1].symbol },
                reserve1: result[1].reserve0
            }
        }
    } else {
        // console.log(data?.[0]);
        
        return data?.[0]
    }
}

export const getTokenChartData = async (tokenAddress?: string) => {
    let data: any = []
    try {
        let allFound = false
        let skip = 0
        while (!allFound) {
            let result = await client.query({
                query: TOKEN_CHART,
                variables: {
                    tokenAddr: tokenAddress,
                    skip,
                },
                fetchPolicy: 'cache-first',
            })
            if (result.data.tokenDayDatas.length < 1000) {
                allFound = true
            }
            skip += 1000
            data = data.concat(result.data.tokenDayDatas)
        }

    } catch (e) {
        console.log(e)
    }

}

export function useLatestBlocks() {
    const { latestBlock } = useSwapBlockNumber()
    const { onChangeBlockNumber } = useSwapBlockNumbers()
    useEffect(() => {
        async function fetch() {
            healthClient
                .query({
                    query: SUBGRAPH_HEALTH,
                })
                .then((res: any) => {
                    const syncedBlock = res.data.indexingStatusForCurrentVersion.chains[0].latestBlock.number
                    if (syncedBlock) {
                        onChangeBlockNumber(syncedBlock)
                    }
                })
                .catch((e: any) => {
                    console.log(e)
                })
        }
        if (!latestBlock) {
            fetch()
        }
    }, [latestBlock])

    return latestBlock
}