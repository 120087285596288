import React, {useContext, useMemo, useState} from 'react'
import {AutoColumn} from '../Column'
import {RowBetween} from '../Row'
import styled, {ThemeContext} from 'styled-components'
import {isMobile} from 'react-device-detect'
import {
  TYPE,
  //  StyledInternalLink

} from '../../theme'
import DoubleCurrencyLogo from '../DoubleLogo'
import {
  Fraction,
  // ETHER, 
  JSBI, TokenAmount
} from '@uniswap/sdk'
import {ButtonPrimaryNew, ButtonPrimaryBlack} from '../Button'
import {StakingInfo} from '../../state/stake/hooks'
// import { useColor } from '../../hooks/useColor'
import {currencyId} from '../../utils/currencyId'
import {
  Break
  // , CardBGImage, CardNoise 

} from './styled'
import {unwrappedToken} from '../../utils/wrappedCurrency'
import {useTotalSupply} from '../../data/TotalSupply'
import {usePair} from '../../data/Reserves'
import useUSDCPrice from '../../utils/useUSDCPrice'
import {BIG_INT_SECONDS_IN_WEEK, REACT_APP_YOHO_ADDRESS, tryParseAmountInfo} from '../../constants'
import {useTranslation} from 'react-i18next'
import ManageModal from './ManageModal'
import LinkSvg from '../../assets/svg/link.svg'
import {handleShareClick, SetBngFunCommon, TypeFun} from './utils'
import {QuestionHelpers} from 'components/QuestionHelper'
import {dividedBy, multipliedBy, multipliedByDecimals} from '../../utils/bigNumber';
import {useDerivedSwapTokenUsdt} from "../../state/swap/hooks";

const StatContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 1rem;
  margin-right: 20px;
  margin-left: 20px;
  ${({theme}) => theme.mediaWidth.upToSmall`
  display: none;
`};
`
const MobileContainer = styled.div`
  gap: 12px;
  margin-right: 20px;
  margin-left: 20px;
  padding-bottom: 5px;
`

const Wrapper = styled(AutoColumn) <{ showBackground: boolean; bgColor: any }>`
  border-radius: 12px;
  width: 100%;
  overflow: hidden;
  position: relative;
  background: ${({theme, bgColor = '#ffffff', showBackground}) => bgColor};
  color: ${({theme, showBackground}) => (theme.text7)} !important;
  box-shadow: 0px 2px 0px 1px rgba(14, 37, 74, 0.1);
`

// const TopSection = styled.div`
//   display: grid;
//   grid-template-columns: 48px 1fr 120px;
//   grid-gap: 0px;
//   align-items: center;
//   padding: 1rem;
//   z-index: 1;
//   ${({ theme }) => theme.mediaWidth.upToSmall`
//     grid-template-columns: 48px 1fr 96px;
//   `};
// `
const RowSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
`
const ColumnSection = styled.div`
  display: flex;
  flex-direction: column;
  width: auto;
`

const BottomSection = styled.div<{ showBackground: boolean }>`
  padding: 12px 20px;
  opacity: ${({showBackground}) => (showBackground ? '1' : '0.4')};
  border-radius: 0 0 12px 12px;
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: flex-start;
  z-index: 1;
  border: 1px solid #F5F5F5;
`


interface PoolCardArg {
  isEnd?: boolean;
  stakingInfo: StakingInfo;
  apy?: number;
  apr?: number;
  USDTNUM: string | number | null
}

export default function PoolCard({stakingInfo, isEnd = false, apy = 0, apr = 0, USDTNUM = 0}: PoolCardArg) {
  const theme = useContext(ThemeContext)
  const token0 = stakingInfo.tokens[0]
  const token1 = stakingInfo.tokens[1]
  const currency0 = unwrappedToken(token0)
  const currency1 = unwrappedToken(token1)
  const {t} = useTranslation()

  const isStaking = Boolean(stakingInfo.stakedAmount.greaterThan('0'))

  // get the color of the token
  // const token = currency0 === ETHER ? token1 : token0
  // const WETH = currency0 === ETHER ? token0 : token1
  const WETH = token0
  // const backgroundColor = useColor(token)

  const totalSupplyOfStakingToken = useTotalSupply(stakingInfo.stakedAmount.token)
  const [, stakingTokenPair] = usePair(...stakingInfo.tokens)

  // let returnOverMonth: Percent = new Percent('0')
  let valueOfTotalStakedAmountInWETH: TokenAmount | undefined
  if (totalSupplyOfStakingToken && stakingTokenPair && stakingInfo && WETH) {
    // take the total amount of LP tokens staked, multiply by AIA value of all LP tokens, divide by all LP tokens
    valueOfTotalStakedAmountInWETH = new TokenAmount(
      WETH,
      JSBI.divide(
        JSBI.multiply(
          JSBI.multiply(stakingInfo.totalStakedAmount.raw, stakingTokenPair.reserveOf(WETH).raw),
          JSBI.BigInt(2) // this is b/c the value of LP shares are ~double the value of the WETH they entitle owner to
        ),
        JSBI.greaterThan(totalSupplyOfStakingToken.raw, JSBI.BigInt(0)) ? totalSupplyOfStakingToken.raw : JSBI.BigInt(1)
      )
    )
  }

  // get the USD value of staked WETH
  const USDPrice = useUSDCPrice(WETH)
  const valueOfTotalStakedAmountInUSDC = valueOfTotalStakedAmountInWETH && USDPrice?.quote(valueOfTotalStakedAmountInWETH)


  // 获取 yoho 价格
  const yohoUsdt1000 = useDerivedSwapTokenUsdt(REACT_APP_YOHO_ADDRESS, tryParseAmountInfo.yoho);
  const yohoUsdt = dividedBy(yohoUsdt1000, tryParseAmountInfo.yoho);

  const switchusdt = useMemo(() => {

    const getTokenAmount = (token: any) => {
      if (stakingTokenPair && totalSupplyOfStakingToken) {

        return new TokenAmount(
          token,
          JSBI.divide(
            JSBI.multiply(
              JSBI.multiply(stakingInfo.totalStakedAmount.raw, stakingTokenPair.reserveOf(token).raw),
              JSBI.BigInt(2) // this is b/c the value of LP shares are ~double the value of the WETH they entitle owner to
            ),
            JSBI.greaterThan(totalSupplyOfStakingToken.raw, JSBI.BigInt(0)) ? totalSupplyOfStakingToken.raw : JSBI.BigInt(1)
          )
        )
      }
      return null;
    }
    let result;
    if (token1?.symbol === 'USDT') {

      result = getTokenAmount(token1)
      // const ZERO_FRACTION = new Fraction(JSBI.BigInt(10),JSBI.BigInt(10) )
      return result?.toSignificant(6, {groupSeparator: ','}) ?? '-'
    } else if (token1?.symbol === 'BNB' && token0?.symbol === 'YOHO') {
      const token = token0;
      result = getTokenAmount(token0)
      let yohoNum;
      result = getTokenAmount(token)
      yohoNum = result?.toFixed(4) || 0
      const divide = JSBI.BigInt(Math.pow(10, 18))
      const c1 = multipliedByDecimals(multipliedBy(yohoNum, yohoUsdt || 0, 18), 18)
      const numbers = new Fraction(JSBI.BigInt(c1), JSBI.BigInt(1)).divide(divide).toSignificant(6, {groupSeparator: ','})
      return numbers ?? '-'
    } else {
      const token = token0?.symbol === ('AIA' || 'WAIA') ? token0 : token1
      let aitdNum;
      result = getTokenAmount(token)
      aitdNum = result?.toFixed(4) || 0
      // 原来的逻辑
      // const numbers = new Fraction(JSBI.BigInt((Number(aitdNum) * Number(USDTNUM || 0)) * Math.pow(10, 18)), JSBI.BigInt(1)).divide(JSBI.BigInt(Math.pow(10, 18))).toSignificant(6, { groupSeparator: ',' })
      const divide = JSBI.BigInt(Math.pow(10, 18))
      const c1 = multipliedByDecimals(multipliedBy(aitdNum, USDTNUM || 0, 18), 18)
      const numbers = new Fraction(JSBI.BigInt(c1), JSBI.BigInt(1)).divide(divide).toSignificant(6, {groupSeparator: ','})
      return numbers ?? '-'
    }

  }, [stakingInfo, stakingTokenPair, totalSupplyOfStakingToken, yohoUsdt])

  // const type = stakingInfo?.type === 'one' ? t('Phase 1') : stakingInfo?.type === 'two' ? t('Phase 2') : ''
  const rewordCoin = useMemo(() => (infos: StakingInfo) => {
    return infos?.reward ?? 'AIA'
  }, [stakingInfo])
  const [showManageRewardModal, setShowManageRewardModal] = useState(false)

  const setBngFun = SetBngFunCommon(stakingInfo)

  const type = TypeFun(stakingInfo, t);
  return (
    <Wrapper showBackground={isStaking} bgColor={setBngFun.bng}>
      {/* <CardBGImage desaturate />
      <CardNoise /> */}
      <RowSection style={{paddingBottom: '20px'}}>
        <ColumnSection>
          <TYPE.white fontWeight={600} fontSize={18} color={stakingInfo?.active ? theme.text7 : theme.text11}>
            {type} {currency0.symbol}-{currency1.symbol}
          </TYPE.white>
          <div style={{"opacity": stakingInfo?.active ? 1 : 0.6}}>
            <DoubleCurrencyLogo currency0={currency0} currency1={currency1} size={20}/>
          </div>
        </ColumnSection>

        {
          !isEnd ? <ButtonPrimaryNew padding="8px" borderRadius="13px" onClick={() => setShowManageRewardModal(true)}>
              {isStaking ? t('Manage') : t('Deposit')}
            </ButtonPrimaryNew> :
            <ButtonPrimaryBlack padding="8px" borderRadius="13px" onClick={() => setShowManageRewardModal(true)}>
              {isStaking ? t('Manage') : t('Details')}
            </ButtonPrimaryBlack>
        }

        {/* <StyledInternalLink
          to={`/aia/${currencyId(currency0)}/${currencyId(currency1)}/${stakingInfo.stakingRewardAddress}`}>
          <ButtonPrimaryNew padding="8px" borderRadius="13px">
            {isStaking ? t('Manage') : t('Deposit')}
          </ButtonPrimaryNew>
        </StyledInternalLink> */}
      </RowSection>
      {(!isEnd && apy >= 0) && <MobileContainer>
        <RowBetween>
          <TYPE.white color={setBngFun.col} fontSize={28}>
            <label style={{fontFamily: 'DIN-Bold, DIN'}}>{apy}% </label>
            <label style={{fontSize: '12px', fontWeight: 400}}>APY</label>
            <QuestionHelpers
              apr={`${apr}%`}
              props={{
                padding: 0
              }}
              text={t('The LP transaction')}
            />
          </TYPE.white>
          {
            isMobile && <img src={LinkSvg} width={13} onClick={() => {
              handleShareClick(apy || 0, `${type ? type + ' ' : ''}${currency0.symbol}-${currency1.symbol}`)
            }}/>
          }
        </RowBetween>
      </MobileContainer>}
      <StatContainer>
        <RowBetween>
          <TYPE.white color={theme.text7} fontWeight={400}> {t('Liquidity')}</TYPE.white>
          <TYPE.white color={theme.text7} fontWeight={400}>
            {valueOfTotalStakedAmountInUSDC
              ? `$${valueOfTotalStakedAmountInUSDC.toFixed(0, {groupSeparator: ','})}`
              : `$${switchusdt}`}
            {/* ---{valueOfTotalStakedAmountInWETH?.toExact()} */}
          </TYPE.white>
        </RowBetween>
        <RowBetween>
          <TYPE.white color={theme.text7} fontWeight={400}> {t('Weekly Output')} </TYPE.white>
          <TYPE.white color={theme.text7} fontWeight={400}>
            {stakingInfo
              ? stakingInfo.active
                ? `${stakingInfo.totalRewardRate
                  ?.multiply(BIG_INT_SECONDS_IN_WEEK)
                  ?.toFixed(0, {groupSeparator: ','})} ${stakingInfo?.type === token0?.symbol ? `CRET / ${t('week')}` : `${rewordCoin(stakingInfo)} / ${t('week')}`}`
                : `0 ${stakingInfo?.type === token0?.symbol ? `CRET / ${t('week')}` : `${rewordCoin(stakingInfo)} / ${t('week')}`}`
              : '-'}
          </TYPE.white>
        </RowBetween>
      </StatContainer>

      {isStaking && (
        <>
          <Break/>
          <BottomSection showBackground={true}>
            <TYPE.black color={theme.text7} fontWeight={400}>
              <span style={{marginRight: '5px'}}>{t('Your rate')} : </span>
            </TYPE.black>

            <TYPE.black style={{textAlign: 'right'}} color={theme.text7} fontWeight={500}>
              {/* <span role="img" aria-label="wizard-icon" style={{ marginRight: '0.5rem' }}>
                ⚡
              </span> */}
              {stakingInfo
                ? stakingInfo.active
                  ? `${stakingInfo.rewardRate
                    ?.multiply(BIG_INT_SECONDS_IN_WEEK)
                    ?.toSignificant(4, {groupSeparator: ','})} ${stakingInfo?.type === token0?.symbol ? `CRET / ${t('week')}` : `${rewordCoin(stakingInfo)} / ${t('week')}`}`
                  : `0 ${stakingInfo?.type === token0?.symbol ? `CRET / ${t('week')}` : `${rewordCoin(stakingInfo)} / ${t('week')}`}`
                : '-'}
            </TYPE.black>
          </BottomSection>
        </>
      )}

      <ManageModal
        showUsdt={`$${switchusdt}`}
        apy={!isEnd ? apy : undefined}
        currencyIdA={currencyId(currency0)}
        currencyIdB={currencyId(currency1)}
        stakingRewardAddress={stakingInfo.stakingRewardAddress}
        rewardCoin={rewordCoin(stakingInfo)}
        isOpen={showManageRewardModal}
        onDismiss={() => setShowManageRewardModal(false)}
        stakingInfo={stakingInfo}
      />
    </Wrapper>
  )
}
