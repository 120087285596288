import Modal from "components/Modal"
import { RowBetween } from "components/Row"
import styled, { ThemeContext } from 'styled-components'
import { Box, FlexProps, Text } from 'rebass'
import { flexbox } from "styled-system";
import { CloseIcon } from "theme/components"
import { AutoColumn } from "components/Column"
import React, { memo, useContext } from "react"
// import CellFormat from "./LimitOrderTable/CellFormat";
import DownNew from '../../../assets/svg/downNew.svg'
import { useTranslation } from "react-i18next";
import { Currency } from "@uniswap/sdk";
import CurrencyLogo from "components/CurrencyLogo";
import { Message, MessageText } from "components/Message";
import { Field } from "state/limitOrders/types";
import LimitOrderDisclaimer from "./LimitOrderDisclaimer";
import { ButtonError } from "components/Button";
import { TransactionErrorContent } from "./TransactionConfirmationModal";
import { wrappedCurrency } from "utils/wrappedCurrency";
import { useActiveWeb3React } from "hooks";
import { TransactionSubmittedContent } from "./TransactionSubmittedContent";

interface ConfirmLimitOrderModalProps {
    currencies: {
        [key: string]: Currency | any
    }
    formattedAmounts: {
        [key: string]: string
        price: string
    }
    currentMarketRate: any
    currentMarketRateInverted: string
    limitPrice?: string
    limitPriceInverted: string
    percentageRateDifference: any
    onConfirm?: () => void
    attemptingTxn: boolean
    txHash: string | undefined
    onDismiss: () => void
    swapErrorMessage?: string
    isOpen: boolean
    title: string
}


export const ConfirmLimitOrderModal: React.FC<ConfirmLimitOrderModalProps> = ({
    onDismiss,
    isOpen,
    currencies,
    formattedAmounts,
    currentMarketRate,
    currentMarketRateInverted,
    limitPrice,
    limitPriceInverted,
    percentageRateDifference,
    onConfirm,
    attemptingTxn,
    txHash,
    swapErrorMessage,
    title
}) => {
    const { chainId } = useActiveWeb3React()
    const theme = useContext(ThemeContext)
    const { t } = useTranslation()
    const wrappedOutput = wrappedCurrency(currencies.output, chainId)
    return (
        <Modal onDismiss={onDismiss} isOpen={isOpen} >
            <Wrapper>
                <Section>
                    <RowBetween>
                        <Text fontWeight={600} fontSize={18} color={theme.text7}>
                            {title}
                        </Text>
                        <CloseIcon onClick={onDismiss} />
                    </RowBetween>
                </Section>

                <Container>
                    {
                        attemptingTxn ? <LoadingContent /> :
                            txHash ? (
                                <TransactionSubmittedContent
                                    hash={txHash}
                                    currencyToAdd={wrappedOutput}
                                    onDismiss={onDismiss}
                                />
                            ) :
                                swapErrorMessage ? <TransactionErrorContent onDismiss={onDismiss} message={swapErrorMessage} /> :
                                    <OrderContent
                                        currencies={currencies}
                                        formattedAmounts={formattedAmounts}
                                        currentMarketRate={currentMarketRate}
                                        currentMarketRateInverted={currentMarketRateInverted}
                                        limitPrice={limitPrice}
                                        limitPriceInverted={limitPriceInverted}
                                        percentageRateDifference={percentageRateDifference}
                                    />
                    }

                </Container>
                {!txHash && !swapErrorMessage && (
                    <Flex justifyContent="center" mt="12px" mb="12px" sx={{ padding: '0 16px' }}>
                        <ButtonError
                            variant="primary"
                            onClick={onConfirm}
                            width="100%"
                            style={{ padding: '12px', margin: '0', boxShadow: attemptingTxn ? 'none' : '0 3px 0 0 #0090FF' }}
                            disabled={attemptingTxn}>
                            {attemptingTxn ? t(`Confirming...`) : t(`Confirm`)}
                        </ButtonError>
                    </Flex>
                )}


            </Wrapper>
        </Modal>
    )
}


const OrderContent: React.FC<OrderContentProps> = ({
    currencies,
    formattedAmounts,
    currentMarketRate,
    currentMarketRateInverted,
    limitPrice,
    limitPriceInverted,
    percentageRateDifference,
}) => {

    const { t } = useTranslation()
    const theme = useContext(ThemeContext)
    const currentPriceExchangeRateText = `1 ${currencies[Field.INPUT]?.symbol} = ${currentMarketRate} ${currencies[Field.OUTPUT]?.symbol}`
    const currentPriceExchangeRateTextReversed = `1 ${currencies[Field.OUTPUT]?.symbol} = ${currentMarketRateInverted} ${currencies[Field.INPUT]?.symbol}`
    const limitPriceExchangeRateText = `1 ${currencies[Field.INPUT]?.symbol} = ${limitPrice} ${currencies[Field.OUTPUT]?.symbol}`
    const limitPriceExchangeRateTextReversed = `1 ${currencies[Field.OUTPUT]?.symbol} = ${limitPriceInverted} ${currencies[Field.INPUT]?.symbol}`
    return (
        <>
            <Flex flexDirection="column" width="100%">
                <Flex alignItems="center" justifyContent="space-between">
                    <Flex alignItems="center">
                        <CurrencyLogo currency={currencies[Field.INPUT]} size={'19px'} />
                        <Text color={theme.text6} ml="8px" fontWeight={600}>
                            {currencies[Field.INPUT]?.symbol}
                        </Text>

                    </Flex>
                    <Text fontSize="16px" color={theme.text6} fontWeight={600}>
                        {formattedAmounts[Field.INPUT]}
                    </Text>
                </Flex>
                <Flex justifyContent="start" sx={{ padding: '15px 0' }}>
                    <img style={{ height: '20px', width: '20px' }} src={DownNew} alt="AIA" />
                </Flex>
                <Flex alignItems="center" justifyContent="space-between">
                    <Flex alignItems="center">
                        <CurrencyLogo currency={currencies[Field.OUTPUT]} size={'19px'} />
                        <Text color={theme.text6} ml="8px" fontWeight={600}>
                            {currencies[Field.OUTPUT]?.symbol}
                        </Text>
                    </Flex>
                    <Text fontSize="16px" color={theme.text6} fontWeight={600}>
                        {formattedAmounts[Field.OUTPUT]}
                    </Text>
                </Flex>
            </Flex>
            <LimitTradeInfoCard
                currentPriceExchangeRateText={currentPriceExchangeRateText}
                currentPriceExchangeRateTextReversed={currentPriceExchangeRateTextReversed}
                limitPriceExchangeRateText={limitPriceExchangeRateText}
                limitPriceExchangeRateTextReversed={limitPriceExchangeRateTextReversed}
            />
            <Message variant="success" mt="16px">
                <MessageText sx={{ flex: '1' }}>
                    {t(
                        'Limit price is percentage above the current market rate. The order will be executed when the market price reaches high enough above your limit price (to also pay for limit order execution gas fees).',
                        { percentage: percentageRateDifference },
                    )}
                </MessageText>
            </Message>
            <Message variant="success" mt="16px">
                <MessageText sx={{ flex: '1' }}>
                    {t(
                      'Tokens with transfer fee mechanisms cannot use the limit order feature. If used, you do so at your own risk.'
                    )}
                </MessageText>
            </Message>

            <LimitOrderDisclaimer />
        </>
    )
}
const LimitTradeInfoCard: React.FC<any> = memo(
    ({
        currentPriceExchangeRateText,
        currentPriceExchangeRateTextReversed,
        limitPriceExchangeRateText,
        limitPriceExchangeRateTextReversed,
    }) => {
        const { t } = useTranslation()
        const theme = useContext(ThemeContext)
        return (
            <InfoCardWrapper>
                <Flex justifyContent="space-between">
                    <Text fontSize="14px" color={theme.text6} fontWeight={600}>
                        {t('Current Market Price')}
                    </Text>
                    <Flex flexDirection="column">
                        <Text color={theme.text6} fontSize="14px" fontWeight={600}>
                            {currentPriceExchangeRateText}
                        </Text>
                        <Text color={theme.text6} fontSize="14px" fontWeight={600}>
                            {currentPriceExchangeRateTextReversed}
                        </Text>
                    </Flex>
                </Flex>
                <Flex justifyContent="space-between" mt="16px">
                    <Text fontSize="14px" color={theme.text6} fontWeight={600}>
                        {t('Limit Price')}
                    </Text>
                    <Flex flexDirection="column">
                        <Text color="#2750EB" fontSize="14px" fontWeight={600}>
                            {limitPriceExchangeRateText}
                        </Text>
                        <Text color="#2750EB" fontSize="14px" fontWeight={600}>
                            {limitPriceExchangeRateTextReversed}
                        </Text>
                    </Flex>
                </Flex>
            </InfoCardWrapper>
        )
    },
)
const LoadingContent: React.FC = memo(() => {
    const { t } = useTranslation()
    const theme = useContext(ThemeContext)
    return (
        <Flex>
            <Flex flexDirection="column">
                <Text fontWeight={600} fontSize={18} color={theme.text7}>
                    {t('Confirm')}
                </Text>
                <Text fontWeight={600} fontSize={15} color={theme.text7} mt="8px">
                    {t('Please confirm the transaction in your wallet')}
                </Text>
            </Flex>
        </Flex>
    )
})
interface OrderContentProps {
    currencies: {
        [key: string]: any
    }
    formattedAmounts: {
        [key: string]: string
        price: string
    }
    currentMarketRate: string
    currentMarketRateInverted: string
    limitPrice?: string
    limitPriceInverted: string
    percentageRateDifference: string
}

const Wrapper = styled.div`
  width: 100%;
  height: 100%;  
`
const Section = styled(AutoColumn)`
  padding: 16px;
  border-bottom: 1px solid #F5F6F7;
`
const Flex = styled(Box) <FlexProps>`
  display: flex;
  ${flexbox}
`;
const InfoCardWrapper = styled.div`
  border-radius: 16px;
  padding: 16px;
  margin-top: 16px;
  border: 1px solid ${({ theme }) => '#E7E3EB'};
  background-color: ${({ theme }) => '#FAF9FA'};
`
const Container = styled.div`
padding:16px;
`