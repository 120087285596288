import React, {  useRef, useContext, useMemo } from 'react'
import 'whatwg-fetch'
import styled, { ThemeContext } from 'styled-components'
import { 
  // AutoRow, 
  RowCenter } from '../../../components/Row'
import { truncateAddressString } from '../../../utils/invitationCallback'
import { useTranslation } from 'react-i18next'
import EmptyNew from '../../../assets/images/emptyNew.png'
import { TYPE } from 'theme'
import { AutoColumn } from '../../../components/Column'

import { toBigNumber } from 'utils/bigNumber'
export default function TableCom(props: any) {
  const theme = useContext(ThemeContext)
  const node = useRef<HTMLDivElement>()
  const { invitationList,
    // handlePre, handleNext, start, endDisabled,total 
  } = props;

  const { t } = useTranslation()
  // const IndexPage = styled.div`
  //   background-color: #FAFAFA;
  //   padding: 2px 8px;
  //   border-radius: 6px;
  //   margin: 0 10px;
  //   cursor: pointer;
  //   &.disabled {
  //     opacity: 0.5;
  //     cursor: not-allowed;
  //   }
  // `

  // const DataRow = styled(AutoRow)`
  //   justify-items: flex-start;
  //   margin: 20px 0 0;
  // `
  const Ths = styled.th`
    text-align: center;
    color: #999999;
    font-weight: bold;
  `
//   const Text = styled.span`
//   text-align: center;
//   color: #666666;
//   margin: 0 10px;
// `
const TextDiv = styled.div`
  color: #666666;
`
  const EmptyProposalsNew = styled(AutoColumn)`
  padding: 3rem 12px;
  border-radius: 15px;
`
const SearchBtn = styled.div`
  display: inline-block;
  padding:0 0.5rem;
  margin-left: 0.6rem;
  color: rgb(39, 80, 235);
  border: 0.1rem solid rgb(39, 80, 235);
  border-radius: 0.75rem;
  font-size: 0.625rem;
  cursor: pointer;
  line-height: 1rem;
`

  const Row = (props: any) => {
    const { item, index } = props
    // const [status, setStatus] = useState<boolean>(false)
    // GetStatusByAddress(item).then((status: any) => {
    //   setStatus(status)
    // })
    // useEffect(() => {
    //   function fetchData() {
    //     fetch((process.env.REACT_APP_INVITE_STATUS as RequestInfo) || '/invite/', {
    //       method: 'POST',
    //       // headers: {
    //       //   Accept: 'application/json',
    //       //   'Content-Type': 'application/json'
    //       // },
    //       body: JSON.stringify({
    //         jsonrpc: '2.0',
    //         method: 'getDeposit',
    //         params: [item],
    //         id: 1
    //       })
    //     })
    //       .then(res => {
    //         return res.json()
    //       })
    //       .then(data => {
    //         console.log('data',data)
    //         // const { status } = data.result
    //         // setStatus(status === 'success')
    //       }).catch((e: any) => { })
    //   }
    //   fetchData()
    // }, [])
    return (
      <tr style={{ position: 'relative' }} ref={node as any} key={index}>
        <td style={{ color: '#333333', textAlign: 'left', padding: '10px 0' }}>{ index+1}</td>
        <td style={{ textAlign: 'left', padding: '10px 0' }}>
          <TextDiv style={{ color: '#333333', fontWeight: 700 }} data-text={item.address}>{truncateAddressString(item.address,4,4)}</TextDiv>
          <TextDiv style={{ color: theme.glay, fontSize: '0.75rem' }}>{item.time}</TextDiv>
        </td>
        <td style={{ color: '#333333', textAlign: 'right', padding: '10px 0',wordBreak: 'break-all',fontWeight: 600 }}>
          {/* {status ? t('Pledged') : t('Not Pledged')} */}
          {toBigNumber(item.amount).gt(0)?item.amount:'--'}
        </td>
      </tr>
    )
  }
  const Rows = React.memo(Row, (preprop: any, nextprop: any) => {
    return preprop.item !== nextprop.item
  })
  const Empty = useMemo(() => {
    return (<EmptyProposalsNew>
      <RowCenter justify='center'>
        <img src={EmptyNew} alt="AIA" style={{ width: '84px', marginBottom: '0.3rem' }} />
      </RowCenter>
      <TYPE.body color={theme.glay} textAlign="center" fontSize={14}>
        {t('NoMember')}
      </TYPE.body>
    </EmptyProposalsNew>)
  }, [t,theme])

  const setSearchAddressOpen = () =>{
    props?.showSearch()
  }
  return (
    <section>
      <table style={{ width: '100%', marginTop: '20px' }}>
        <thead>
          <tr >
            <Ths style={{textAlign: 'left', width: '35px' }}>{t('Serial Number')}</Ths>
            <Ths style={{textAlign: 'left',display: 'flex',alignItems: 'center'}}>
              {t('Member Addresses')}
              <SearchBtn onClick={setSearchAddressOpen}>{t('Search for')}</SearchBtn>
            </Ths>
            <Ths style={{textAlign: 'right'}}>{t('Reward quantity')}</Ths>
          </tr>
        </thead>
        <tbody>
          {invitationList?.map((item: any, index: number) => (
            <Rows item={item} key={item.address} index={index}></Rows>
          ))}
        </tbody>
      </table>
      {/* {(invitationList.length !== 0) &&
        <DataRow style={{ justifyContent: 'flex-end' }}>
          <IndexPage onClick={handlePre} className={Number(start) === 0 ? 'disabled' : ''}>
            {'<'}
          </IndexPage>
          {total > 0 && <Text>{`${(size+1)} / ${Math.ceil(total / 10)}`}</Text>}
          <IndexPage onClick={handleNext} className={endDisabled ? 'disabled' : ''}>
            {'>'}
          </IndexPage>
        </DataRow>} */}

      {(invitationList.length === 0) && Empty}
    </section>
  )
}
