import React, { useCallback, useContext, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import Modal from '../Modal'
import { AutoColumn } from '../Column'

import styled, { ThemeContext } from 'styled-components'
import { useTranslation } from 'react-i18next'
import { RowBetween, RowFlat } from '../Row'
import { CloseIcon, TYPE } from '../../theme'
import {
    StakingInfo,
    // useStakingInfo 

} from '../../state/stake/hooks'
import {
    JSBI, TokenAmount
    // , ETHER 
} from '@uniswap/sdk'
import { useActiveWeb3React } from '../../hooks'
import { useCurrency } from 'hooks/Tokens'
import { wrappedCurrency } from 'utils/wrappedCurrency'
import { usePair } from 'data/Reserves'
import { useTokenBalance } from 'state/wallet/hooks'
// import { useColor } from 'hooks/useColor'
import { useTotalSupply } from 'data/TotalSupply'
import usePrevious from 'hooks/usePrevious'
import useUSDCPrice from 'utils/useUSDCPrice'
import { useWalletModalToggle } from 'state/application/hooks'
import DoubleCurrencyLogo from 'components/DoubleLogo'
import { Countdown } from 'pages/Earn/Countdown'
import { BIG_INT_ZERO, BIG_INT_SECONDS_IN_WEEK } from '../../constants'
// import { 
//     DataCard
//     ,CardSection, CardNoise, CardBGImage 
// } from './styled'
import { ButtonPrimary, ButtonPrimaryNew } from 'components/Button'
import { currencyId } from 'utils/currencyId'
import StakingModal from './StakingModal'
import UnstakingModal from './UnstakingModal'
import ClaimRewardModal from './ClaimRewardModal'
import { CountUp } from 'use-count-up'

import InterrogatGraySvg from '../../assets/svg/interrogatGraySvg.svg'
import { TypeFun } from './utils'




interface ManageModalProps {
    isOpen: boolean
    onDismiss: () => void
    stakingInfo: StakingInfo
    rewardCoin?: string,
    currencyIdA?: string,
    currencyIdB?: string,
    stakingRewardAddress?: string
    apy?: number;
    showUsdt?: any
}

export default function ManageModal({
    isOpen,
    onDismiss,
    apy,
    currencyIdA,
    currencyIdB,
    stakingInfo,
    showUsdt

}: ManageModalProps) {
    const theme = useContext(ThemeContext)
    const { account, chainId } = useActiveWeb3React()
    // get currencies and pair
    const [currencyA, currencyB] = [useCurrency(currencyIdA), useCurrency(currencyIdB)]
    const tokenA = wrappedCurrency(currencyA ?? undefined, chainId)
    const tokenB = wrappedCurrency(currencyB ?? undefined, chainId)

    const [, stakingTokenPair] = usePair(tokenA, tokenB)
    // const info = useStakingInfo(stakingTokenPair)
    // const stakingInfo = info && info?.find(item => item.stakingRewardAddress === stakingRewardAddress)
    // detect existing unstaked LP position to show add button if none found
    const userLiquidityUnstaked = useTokenBalance(account ?? undefined, stakingInfo?.stakedAmount?.token)
    const showAddLiquidityButton = Boolean(stakingInfo?.stakedAmount?.equalTo('0') && userLiquidityUnstaked?.equalTo('0'))

    // toggle for staking modal and unstaking modal
    const [showStakingModal, setShowStakingModal] = useState(false)
    const [showUnstakingModal, setShowUnstakingModal] = useState(false)
    const [showClaimRewardModal, setShowClaimRewardModal] = useState(false)

    // fade cards if nothing staked or nothing earned yet
    // const disableTop = !stakingInfo?.stakedAmount || stakingInfo.stakedAmount.equalTo(JSBI.BigInt(0))
    // const backgroundColor = useColor(token)
    // const token = currencyA === ETHER ? tokenB : tokenA
    const WETH = tokenA


    // get WETH value of staked LP tokens
    const totalSupplyOfStakingToken = useTotalSupply(stakingInfo?.stakedAmount?.token)
    let valueOfTotalStakedAmountInWETH: TokenAmount | undefined
    if (totalSupplyOfStakingToken && stakingTokenPair && stakingInfo && WETH) {
        // take the total amount of LP tokens staked, multiply by AIA value of all LP tokens, divide by all LP tokens
        valueOfTotalStakedAmountInWETH = new TokenAmount(
            WETH,
            JSBI.divide(
                JSBI.multiply(
                    JSBI.multiply(stakingInfo.totalStakedAmount.raw, stakingTokenPair.reserveOf(WETH).raw),
                    JSBI.BigInt(2) // this is b/c the value of LP shares are ~double the value of the WETH they entitle owner to
                ),
                JSBI.greaterThan(totalSupplyOfStakingToken.raw, JSBI.BigInt(0)) ? totalSupplyOfStakingToken.raw : JSBI.BigInt(1)
            )
        )
    }

    const countUpAmount = stakingInfo?.earnedAmount?.toFixed(6) ?? '0'
    const countUpAmountPrevious = usePrevious(countUpAmount) ?? '0'

    // get the USD value of staked WETH
    const USDPrice = useUSDCPrice(WETH)
    const valueOfTotalStakedAmountInUSDC =
        valueOfTotalStakedAmountInWETH && USDPrice?.quote(valueOfTotalStakedAmountInWETH)
    const toggleWalletModal = useWalletModalToggle()

    const handleDepositClick = useCallback(() => {
        if (account) {
            setShowStakingModal(true)
        } else {
            toggleWalletModal()
        }
    }, [account, toggleWalletModal])

    const { t } = useTranslation()

    const rewordCoin = useMemo(() => (infos: any) => {
        return infos?.reward ?? 'AIA'
    }, [stakingInfo])
    /**
        {valueOfTotalStakedAmountInUSDC
            ? `$${valueOfTotalStakedAmountInUSDC.toFixed(0, { groupSeparator: ',' })}`
            : `${valueOfTotalStakedAmountInWETH?.toSignificant(4, { groupSeparator: ',' }) ?? '-'} ${currencyA?.symbol
            }`}*/
   
    const type = TypeFun(stakingInfo,t);
    const wrappedOnDismiss = () => onDismiss()
    return (
        <Modal isOpen={isOpen} onDismiss={wrappedOnDismiss}>
            <PageWrapper gap="20px" justify="center">
                <RowBetween>
                    <TYPE.mediumHeader>
                        {type} {currencyA?.symbol}-{currencyB?.symbol}
                        {/* {t('Liquidity Mining')} */}
                    </TYPE.mediumHeader>
                    <CloseIcon onClick={wrappedOnDismiss} />
                </RowBetween>
                <PageScroller gap="20px" justify="center">
                    <AutoColumn style={{ width: '100%' }}>
                        <RowFlat>
                            <DoubleCurrencyLogo currency0={currencyA ?? undefined} currency1={currencyB ?? undefined} size={24} />
                            <Countdown exactEnd={stakingInfo?.periodFinish} stakingInfo={stakingInfo} />
                        </RowFlat>
                    </AutoColumn>

                    <AutoColumnData gap={'10px'}>
                        <RowBetween>
                            <TYPE.body fontWeight={700} color={theme.text7} fontSize={14}>{t('Liquidity')}</TYPE.body>
                            <TYPE.body fontSize={20} fontWeight={700} color={theme.text7} fontFamily={'DIN-Bold, DIN'}>
                                {valueOfTotalStakedAmountInUSDC
                                    ? `$${valueOfTotalStakedAmountInUSDC.toFixed(0, { groupSeparator: ',' })}`
                                    : `${showUsdt}`}
                            </TYPE.body>
                        </RowBetween>
                        <RowBetween style={{ borderTop: '1px solid #F5F5F5', paddingTop: '10px' }}>
                            <TYPE.body style={{ margin: 0 }} color={theme.text7} fontWeight={700} fontSize={14}>{t('Weekly Output')}</TYPE.body>
                            <TYPE.body fontSize={20} fontWeight={700} color={theme.text7} fontFamily={'DIN-Bold, DIN'}>
                                {stakingInfo?.active
                                    ? stakingInfo?.totalRewardRate
                                        ?.multiply(BIG_INT_SECONDS_IN_WEEK)
                                        ?.toFixed(0, { groupSeparator: ',' }) ?? '-'
                                    : '0'}
                                {rewordCoin(stakingInfo)}
                                <Text>/ {t('week')}</Text>
                            </TYPE.body>
                        </RowBetween>
                        {(apy || apy === 0) && <RowBetween style={{ borderTop: '1px solid #F5F5F5', paddingTop: '10px' }}>
                            <TYPE.body style={{ margin: 0 }} color={theme.text7} fontWeight={700} fontSize={14}>{t('Annual Percentage Yield')}: </TYPE.body>
                            <TYPE.body fontSize={20} fontWeight={700} color={theme.text7} fontFamily={'DIN-Bold, DIN'}>
                                <Text>{apy}% </Text>
                            </TYPE.body>
                        </RowBetween>}
                    </AutoColumnData>

                    {showAddLiquidityButton && (<AutoColumnData color={theme.text7} gap={'10px'} style={{ padding: '16px 20px' }}>
                        <RowBetween>
                            <TYPE.white fontWeight={700} color={theme.text7}>
                                {t('Step', { period: stakingInfo?.type === 'three' ? 'AIA-V3' : `${rewordCoin(stakingInfo)}-V2` })}
                                {/* Step 1. Get {stakingInfo?.type === 'three' ? 'AIA-V3' : `${rewordCoin(stakingInfo)}-V2`} Liquidity tokens */}
                            </TYPE.white>
                        </RowBetween>
                        <RowBetween style={{ marginBottom: '1rem' }} color={theme.text7}>
                            <TYPE.white fontSize={14} color={theme.text7}>
                                {t('AIA-V2 LP tokens are required', { SymbolAIA: rewordCoin(stakingInfo) })}
                                {t("Once you've added liquidity", { currencySymbol: `${currencyA?.symbol}-${currencyB?.symbol}` })}
                                {/* {`${rewordCoin(stakingInfo)}-V2 LP tokens are required. Once you've added liquidity to the ${currencyA?.symbol}-${currencyB?.symbol} pool you can stake your liquidity tokens on this page.`} */}
                            </TYPE.white>
                        </RowBetween>
                        {
                            stakingInfo?.active && <RowFlat style={{ justifyContent: 'flex-end' }}>
                                <ButtonBottom
                                    width={'fit-content'}
                                    as={Link}
                                    to={`/add/${currencyA && currencyId(currencyA)}/${currencyB && currencyId(currencyB)}`}>
                                    {t('Add Current Liquidity', { currency: `${currencyA?.symbol}-${currencyB?.symbol}` })}
                                    {/* {`Add ${currencyA?.symbol}-${currencyB?.symbol} liquidity`} */}
                                </ButtonBottom>
                            </RowFlat>
                        }

                    </AutoColumnData>)}

                    <AutoColumnData gap={'10px'}>
                        <RowBetween>
                            <TYPE.body fontWeight={700} color={theme.text7} fontSize={14}>
                                {t('Your liquidity deposits')}
                            </TYPE.body>
                            <TYPE.body fontSize={20} fontWeight={700} color={theme.text7} fontFamily={'DIN-Bold, DIN'}>
                                {stakingInfo?.stakedAmount?.toSignificant(6) ?? '-'}
                            </TYPE.body>
                        </RowBetween>
                        <RowBetween style={{ borderTop: '1px solid #F5F5F5', paddingTop: '10px' }}>
                            <TYPE.body style={{ margin: 0 }} color={theme.text7} fontWeight={400} fontSize={14}>
                                {stakingInfo?.type === 'three' ? 'AIA-V3' : `${rewordCoin(stakingInfo)}-V2`} {currencyA?.symbol}-{currencyB?.symbol}
                            </TYPE.body>
                        </RowBetween>
                    </AutoColumnData>
                    <AutoColumnData gap={'10px'}>
                        <RowBetween>
                            <TYPE.body fontWeight={700} color={theme.text7} fontSize={14}>
                                {t('Unclaimed')} {stakingInfo?.type === currencyA?.symbol ? 'CRET' : `${rewordCoin(stakingInfo)}`}
                            </TYPE.body>
                            <TYPE.body fontSize={20} fontWeight={700} color={theme.text7} fontFamily={'DIN-Bold, DIN'}>
                                <CountUp
                                    key={countUpAmount}
                                    isCounting
                                    decimalPlaces={4}
                                    start={parseFloat(countUpAmountPrevious)}
                                    end={parseFloat(countUpAmount)}
                                    thousandsSeparator={','}
                                    duration={1}
                                />
                            </TYPE.body>
                        </RowBetween>
                        <RowBetween style={{ borderTop: '1px solid #F5F5F5', paddingTop: '10px' }}>
                            <TYPE.body style={{ margin: 0 }} color={theme.text7} fontWeight={400} fontSize={14}>{stakingInfo?.active
                                ? stakingInfo?.rewardRate
                                    ?.multiply(BIG_INT_SECONDS_IN_WEEK)
                                    ?.toSignificant(4, { groupSeparator: ',' }) ?? '-'
                                : '0'}
                                {stakingInfo?.type === currencyA?.symbol ? `CRET / ${t('week')}` : ` ${rewordCoin(stakingInfo)} / ${t('week')}`}</TYPE.body>
                            {stakingInfo?.earnedAmount && JSBI.notEqual(BIG_INT_ZERO, stakingInfo?.earnedAmount?.raw) && (
                                <ButtonPrimaryNew onClick={() => setShowClaimRewardModal(true)}>
                                    {t('Claim')}
                                </ButtonPrimaryNew>
                            )}
                        </RowBetween>
                    </AutoColumnData>

                    <AutoColumnData color={theme.text7} gap={'10px'}>
                        <RowFlat style={{ alignItems: 'center' }}>
                            <img width={18} src={InterrogatGraySvg} alt="AIA" />
                            <TYPE.main fontWeight={600} fontSize={13} style={{ marginLeft: '5px' }}>
                                {t('When you withdraw, the contract will automagically claim AIA on your behalf!', {
                                    currency: `${currencyA?.symbol}`
                                })}
                            </TYPE.main>
                        </RowFlat>
                    </AutoColumnData>
                    {!userLiquidityUnstaked ? null : userLiquidityUnstaked.equalTo('0') ? null : !stakingInfo?.active ? null : (<AutoColumnData color={theme.text7} gap={'10px'}>
                        <RowBetween style={{ alignItems: 'center' }}>
                            <TYPE.body fontWeight={700} color={theme.text7} fontSize={14}>LP tokens {t('available')}</TYPE.body>
                            <TYPE.body fontSize={20} fontWeight={700} color={theme.text7} fontFamily={'DIN-Bold, DIN'}>
                                {userLiquidityUnstaked.toSignificant(6)} {stakingInfo?.type === currencyA?.symbol ? 'CRET' : rewordCoin(stakingInfo)}
                            </TYPE.body>
                        </RowBetween>
                    </AutoColumnData>)}

                    <PositionInfo gap="lg" justify="center" dim={showAddLiquidityButton}>
                        {!showAddLiquidityButton && (
                            <DataRow style={{ margin: '1rem 0', justifyContent: (stakingInfo && stakingInfo.active && stakingInfo?.stakedAmount?.greaterThan(JSBI.BigInt(0))) ? 'space-between' : 'center' }}>
                                {stakingInfo && stakingInfo.active && (
                                    <ButtonBottom
                                        onClick={handleDepositClick}>
                                        {stakingInfo?.stakedAmount?.greaterThan(JSBI.BigInt(0))
                                            ? t('Deposit')
                                            : t('Deposit') + `${stakingInfo?.type === 'three' ? 'AIA-V3' : `${rewordCoin(stakingInfo)}-V2`} LP Tokens`}
                                    </ButtonBottom>
                                )}
                                {
                                    stakingInfo?.stakedAmount?.greaterThan(JSBI.BigInt(0)) && (
                                        <ButtonBottom onClick={() => setShowUnstakingModal(true)}>
                                            {t('Withdraw')}
                                        </ButtonBottom>
                                    )
                                }
                            </DataRow>
                        )}

                    </PositionInfo>

                    {stakingInfo && (
                        <>
                            <StakingModal
                                rewardCoin={rewordCoin(stakingInfo)}
                                isOpen={showStakingModal}
                                onDismiss={() => setShowStakingModal(false)}
                                stakingInfo={stakingInfo}
                                userLiquidityUnstaked={userLiquidityUnstaked}
                            />
                            <UnstakingModal
                                rewardCoin={rewordCoin(stakingInfo)}
                                isOpen={showUnstakingModal}
                                onDismiss={() => setShowUnstakingModal(false)}
                                stakingInfo={stakingInfo}
                            />
                            <ClaimRewardModal
                                rewardCoin={rewordCoin(stakingInfo)}
                                isOpen={showClaimRewardModal}
                                onDismiss={() => setShowClaimRewardModal(false)}
                                stakingInfo={stakingInfo}
                            />
                        </>
                    )}
                </PageScroller>
            </PageWrapper>
        </Modal>
    )
}
const PageWrapper = styled(AutoColumn)`
  max-width: 420px;
  width: 100%;
  padding: 1rem;
  background-color: ${({ theme }) => theme.bg9};
`
const PageScroller = styled(AutoColumn)`
  max-height: 80vh;
  overflow-y:auto;
  width: 100%;
`
const PositionInfo = styled(AutoColumn) <{ dim: any }>`
  position: relative;
  max-width: 640px;
  width: 100%;
  opacity: ${({ dim }) => (dim ? 0.6 : 1)};
`



// const StyledDataCard = styled(DataCard) <{ bgColor?: any; showBackground?: any }>`
//   background: radial-gradient(76.02% 75.41% at 1.84% 0%, #1e1a31 0%, #3d51a5 100%);
//   z-index: 2;
//   box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
//   background: ${({ theme, bgColor, showBackground }) =>
//         `radial-gradient(91.85% 100% at 1.84% 0%, ${bgColor} 0%,  ${showBackground ? theme.black : theme.bg5} 100%) `};
// `






const DataRow = styled(RowBetween)`
  justify-content: space-between;
  gap: 20px;

  /* ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-direction: column;
    gap: 12px;
  `}; */
`
const AutoColumnData = styled(AutoColumn)`
   background-color: ${({ theme }) => theme.white};
   padding: 10px 20px;
   box-shadow: 0px 5px 2px 1px rgba(14, 37, 74, 0.01);
   border-radius: 15px;
   width: 100%;
`

const Text = styled.span`
    font-size: 15px;
    font-weight: bold;
    color: ${({ theme }) => theme.text7};
`

const ButtonBottom = styled(ButtonPrimary)`
    min-width: 45%;
    width:auto;
    padding:8px 15px;
    border-radius: 12px;
    box-shadow: 0 3px 0 0px #0090ff;
    box-sizing: border-box;
    &:focus {
        box-shadow: 0 3px 0 0px #0090ff;
    }
    &:active {
        box-shadow: 0 3px 0 0px #0090ff;
   }
`