import React, { useContext, useMemo, useState } from 'react'
import { AutoColumn } from '../../components/Column'
import styled, { ThemeContext } from 'styled-components'
import { STAKING_REWARDS_INFO, useStakingInfoEarn, useCretStakingInfo } from '../../state/stake/hooks'
import { TYPE } from '../../theme'
import PoolCard from '../../components/earn/PoolCard'
import PoolCardCret from '../../components/earn/PoolCardCret'
import { RowBetween, RowCenter } from '../../components/Row'
import { DataCardImg } from '../../components/earn/styled'
// import { Countdown } from './Countdown'
import Loader from '../../components/Loader'
import { useActiveWeb3React } from '../../hooks'
import { JSBI } from '@uniswap/sdk'
import { BIG_INT_ZERO } from '../../constants'
import { OutlineCard } from '../../components/Card'
import { useTranslation } from 'react-i18next'
import EmptyNew from '../../assets/images/emptyNew.png'
import bannerImg from '../../assets/images/banner.png'
// import CanvasImgShare from '../../components/earn/CanvasImgShare'
import { GetApyFun } from 'components/earn/utils'
import { useDerivedSwapAitdUsdt } from 'state/swap/hooks'

export default function Earn() {
  const theme = useContext(ThemeContext)
  const { chainId } = useActiveWeb3React()
  const [isActive, setIsActive] = useState(true)
  const [getProgress, setProgress] = useState(0)
  const getAPYData = GetApyFun()
  // const [stakingInfosList, setStakingInfoList] = useState<any>([])
  const getUsdt = useDerivedSwapAitdUsdt()
  // useFetchMasterChefFarmPoolLength()
  // staking info for connected account
  let stakingInfos = useStakingInfoEarn(getProgress, getAPYData)
  const stakingCartInfos = useCretStakingInfo()
  const { t } = useTranslation()

  // console.log(stakingCartInfos);
  
  /**
   * only show staking cards with balance
   * @todo only account for this if rewards are inactive
   */
  const stakingInfosWithBalance = stakingInfos?.filter(s => JSBI.greaterThanOrEqual(s.stakedAmount.raw, BIG_INT_ZERO))
  // toggle copy if rewards are inactive
  const stakingRewardsExist = Boolean(typeof chainId === 'number' && (STAKING_REWARDS_INFO[chainId]?.length ?? 0) > 0)
 
  const stakingInfoList = useMemo(() => [
    ...[stakingInfosWithBalance[0]],
    ...[stakingInfosWithBalance[2]],
    ...[stakingInfosWithBalance[1]],
    ...stakingInfosWithBalance?.filter((item, index) => index > 2),
    ...stakingCartInfos
  ], [stakingInfosWithBalance, stakingCartInfos])

  const isActiving = useMemo(() => {
    let having = false
    if (stakingRewardsExist&&stakingInfoList.length > 0) {
      having = stakingInfoList?.some((i: any) => {
        const isStaking = Boolean(i?.stakedAmount.greaterThan('0'))
        if (getProgress === 0) {
          return isActive ? i?.active : (i?.active && isStaking)
        } else {
          return isActive ? !i?.active : (!i?.active && isStaking)
        }
      })
    }
    return having
  }, [stakingRewardsExist, stakingInfoList, isActive, getProgress])

  // useEffect(()=>{
  //   setTimeout(()=>{
  //     setStakingInfoList(stakingInfos)
  //   }, 100)
  // },[stakingInfos])


  const Empty = useMemo(() => {
    return (<EmptyProposalsNew>
      <RowCenter justify='center'>
        <img src={EmptyNew} alt="AIA" style={{ width: '84px', marginBottom: '0.3rem' }} />
      </RowCenter>
      <TYPE.body color={theme.glay} textAlign="center" fontSize={14}>
        {t('No Active Pools')}
      </TYPE.body>
    </EmptyProposalsNew>)
  }, [])

  const handleSwitch = (num: number) => {
    setProgress(num);
    setIsActive(true)
  }

  return (
    <PageWrapper gap="lg" justify="center">
      <TopSection gap="md">
        <DataCardImg>
          <img width="100%" src={bannerImg} alt='AIA'></img>
        </DataCardImg>
      </TopSection>

      <AutoColumn gap="lg" style={{ width: '100%', maxWidth: '720px' }}>
        {/* {false && <CanvasImgShare />} */}
        <RowBetween>
          <SwitchTypeLeft>
            <TYPE.mediumHeader className={getProgress === 0 ? 'active' : ''} onClick={() => handleSwitch(0)}>{t('In Progress')}</TYPE.mediumHeader>
            <TYPE.mediumHeader className={getProgress !== 0 ? 'active' : ''} onClick={() => handleSwitch(1)}>{t('Ended')}</TYPE.mediumHeader>
          </SwitchTypeLeft>
          <SwitchType>
            <SwitchTypeSpan isActive={isActive} onClick={() => setIsActive(true)}>{t('ALL')}</SwitchTypeSpan>
            <SwitchTypeSpan isActive={!isActive} onClick={() => setIsActive(false)}>{t('Participated')}</SwitchTypeSpan>
          </SwitchType>
        </RowBetween>
        <PoolSection>
          {stakingInfoList?.length === 0 ? (
            <Loader style={{ margin: '8vh auto' }} />
          ) : !stakingRewardsExist ? (
            <OutlineCard>{t('No Active Pools')}</OutlineCard>
          ) :  !isActiving ? Empty : (stakingInfoList?.map((stakingInfo, keys) => {
            if (!stakingInfo) return null
            const isStaking = Boolean(stakingInfo?.stakedAmount.greaterThan('0'))
            const address = stakingInfo?.stakingRewardAddress.toLocaleLowerCase()
            const addressKeys = Object.keys(getAPYData || {}) || []
            const addressResult = addressKeys.includes(address)
            const { APR, APY } = addressResult ? getAPYData[address] : { APR: undefined, APY: undefined }
            const isGoing = !stakingInfo?.active
            const keyComponent = stakingInfo?.stakingRewardAddress + keys
            const getCardComponetFun = (PoolCardComponent:any, argObject: Object) => {
              return <PoolCardComponent {...argObject} key={keyComponent} stakingInfo={stakingInfo} />
            }
            let result: any = null
            if (stakingInfo?.tokens?.length === 1) {
              let elment = getCardComponetFun(PoolCardCret, { apy: APY, apr: APR })
              if (getProgress === 0) {
                result = isActive ? stakingInfo?.active && elment : (stakingInfo?.active && isStaking) && elment
              } else {
                elment = getCardComponetFun(PoolCardCret, { isEnd: true })
                result = isActive ? isGoing && elment : (isGoing && isStaking) && elment
              }
              return result
            }
            let poolCardElem = getCardComponetFun(PoolCard, { apy: APY, apr: APR,USDTNUM:getUsdt})
            if (getProgress === 0) {
              result = isActive ? stakingInfo?.active && poolCardElem : (stakingInfo?.active && isStaking) && poolCardElem
            } else {
              poolCardElem = getCardComponetFun(PoolCard, { isEnd: true,USDTNUM:getUsdt})
              result = isActive ? isGoing && poolCardElem : (isGoing && isStaking) && poolCardElem
            }
            return result;
          })
          )}
        </PoolSection>

      </AutoColumn>
    </PageWrapper>
  )
}
const PageWrapper = styled(AutoColumn)`
  max-width: 640px;
  width: 100%;
`

const TopSection = styled(AutoColumn)`
  max-width: 720px;
  width: 100%;
`

const PoolSection = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  column-gap: 10px;
  row-gap: 15px;
  width: 100%;
  justify-self: center;
`

// const DataRow = styled(RowBetween)`
//   ${({ theme }) => theme.mediaWidth.upToSmall`
//     flex-direction: column;
// `};
// `
const SwitchType = styled(RowBetween)`
  background-color: ${({ theme }) => theme.white};
  color: ${({ theme }) => theme.glay};
  border-radius: 22px;
  align-items: center;
  width: auto;
  cursor: pointer;
`
const SwitchTypeLeft = styled(RowBetween)`
  border-radius: 22px;
  align-items: center;
  width: auto;
  cursor: pointer;
  div{
    margin-right:1rem;
    color: ${({ theme }) => theme.glay};
    font-weight: 600;
    font-size: 16px;
  }
  .active {
    position: relative;
    color: ${({ theme }) => theme.text7};
    &::after{
      content: "";
      position: absolute;
      width: 45%;
      height:3px;
      background-color: ${({ theme }) => theme.text7};
      transform:translateX(-50%);
      left:50%;
      bottom:-7px;
      border-radius:2px;
    }
  }
`

const SwitchTypeSpan = styled.span<{ isActive: Boolean }>`
  display: inline-block;
  background-color: ${({ theme, isActive }) => isActive ? theme.text8 : theme.white};
  color: ${({ theme, isActive }) => isActive ? theme.white : '#CCCCCC'};
  font-size: 13px;
  border-radius: 22px;
  padding: 2px 25px;
`
const EmptyProposalsNew = styled(AutoColumn)`
  padding: 3rem 12px;
  border-radius: 15px;
  background: #FFFFFF;
  box-shadow: 0px 2px 1px 1px rgba(14, 37, 74, 0.1);
`