/**
 * @name: addMyInfoModal
 * @author: mahuanhuan
 * @date: 2024/1/15
 * @description：
 * @update: 2024/1/15
 */
import React, {useContext, useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {ThemeContext} from "styled-components";
import {Button, Form, Input, message} from "antd";
import Modal from '../../../components/Modal';
import {RowBetween} from "../../../components/Row";
import {Text} from "../../../components/invitation/styled";
import {CloseOutlined} from "@ant-design/icons";
import {FormStyle, AddInfo} from "../styleds";
import {useActiveWeb3React} from "../../../hooks";
import {addrSaveOrUpdate} from "../../../services/card";
import pubSub from "../../../utils/pub-sub";

const {TextArea} = Input;

const initObj = {
  name: '',
  phone: '',
  area: '',
  address: '',
}

const AddMyInfoModal = ({isOpen, setOpen, info, orderId}: {
  isOpen: boolean;
  setOpen: Function;
  info: Record<string, any>;
  orderId?: number;
}) => {
  const {t} = useTranslation();
  const theme = useContext(ThemeContext);
  const {account} = useActiveWeb3React()
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [initialValues, setInitialValues] = useState<Record<string, any>>(initObj)
  const close = () => {
    setOpen(false)
    form.resetFields(); // 重置表单的值为初始值
  }

  useEffect(() => {
    if (info?.name && isOpen) {
      setInitialValues({...info})
    }
  }, [info, isOpen])

  const onFinish = (values: any) => {
    if (loading) return;
    setLoading(true);
    let param = {
      ...values,
      userAddr: account,
    }
    if (orderId) {
      param.orderId = orderId
    }
    addrSaveOrUpdate(param).then(() => {
      setOpen(false);
      pubSub.emit('getMyInfo');
      message.success(t('informationSucceeded'))
    }).catch(() => {
    }).finally(() => {
      setLoading(false);
    })
  };

  return (
    <Modal isOpen={isOpen} onDismiss={close}>
      <AddInfo>
        <RowBetween style={{padding: '1rem 0', marginBottom: '1rem', borderBottom: '1px solid rgba(14,37,74,0.06)'}}>
          <Text style={{fontWeight: 600, color: theme.text7, fontSize: '16px'}}>
            {t('HarvestInformation')}
          </Text>
          <Text style={{color: '#666666', fontSize: '16px'}} onClick={close}>
            <CloseOutlined/>
          </Text>
        </RowBetween>
        <FormStyle form={form} initialValues={initialValues} onFinish={onFinish}>
          <Form.Item required name="name" label={t('consignee')} rules={[
            {
              required: true, message: t('pasteName')
            }
          ]}>
            <Input allowClear placeholder={t('pasteName')}/>
          </Form.Item>
          <Form.Item required name="phone" label={t('phoneNum')} rules={[
            {
              required: true, message: t('pastePhone')
            }
          ]}>
            <Input allowClear placeholder={t('pastePhone')}/>
          </Form.Item>
          <Form.Item required name="area" label={t('detailedArea')} rules={[
            {
              required: true, message: t('pasteArea')
            }
          ]}>
            <Input allowClear placeholder={t('pasteArea')}/>
          </Form.Item>
          <Form.Item required name="address" label={t('detailedAddress')} rules={[
            {
              required: true, message: t('pasteDetailedAddress')
            }
          ]}>
            <TextArea placeholder={t('pasteDetailedAddress')} autoSize={{minRows: 3, maxRows: 5}}/>
          </Form.Item>
          <Form.Item style={{marginBottom: '0', paddingBottom: '40px'}}>
            <Button loading={loading} type="primary" htmlType="submit">{t('save')}</Button>
          </Form.Item>
        </FormStyle>
      </AddInfo>
    </Modal>
  );
}

export default AddMyInfoModal;