/**
 * @name: verifyVoucherCode
 * @author: mahuanhuan
 * @date: 2024/1/10
 * @description：核销凭证码弹窗
 * @update: 2024/1/10
 */
import React, {useContext, useEffect, useState} from 'react';
import Modal from '../../../components/Modal';
import {VerifyVoucherCodeDiv, WriteOff, WriteOffSuc, CardInfo, ConfirmButtonDiv, WriteOffBefore} from "../styleds";
import {useTranslation} from "react-i18next";
import {Button, Input} from 'antd'

import {CloseOutlined} from '@ant-design/icons';
import {Text, TextBox, TextButton} from "../../../components/invitation/styled";
import {RowBetween} from "../../../components/Row";
import {ThemeContext} from "styled-components";
import {chargeOff, chargeOffBefore} from "../../../services/card";
import {useActiveWeb3React} from "../../../hooks";
import writeOffSuc from '../../../assets/images/write-offSuc.png';
import sweep from '../../../assets/svg/sweep.svg';
import {minus} from "../../../utils/bigNumber";
import {RoleEnum} from "../types";

import {judgePhoneType} from "../../../components/earn/utils";
import {AndroidPostMessageType} from "../../../constants";
const { isAndroid } = judgePhoneType();
const VerifyVoucherCode = (
  {open, setOpen, onSucHandle, role}: {
    open: boolean;
    setOpen: Function;
    onSucHandle: Function;
    role: RoleEnum;
  }) => {
  const {t} = useTranslation();
  const {account} = useActiveWeb3React()
  const theme = useContext(ThemeContext)
  const [isSuc, setIsSuc] = useState(false);
  const [isChargeOff, setChargeOff] = useState(false);
  const [inputValue, setValue] = useState<string>('')
  const [info, setInfo] = useState<Record<string, any>>({})
  const [isShowAddress, setIsShowAddress] = useState(false);
  const [loading, setLoading] = useState(false);
  const [chargeOffBeforeInfo, setChargeOffBeforeInfo] = useState<Record<string, any>>({});


  useEffect(() => {
    if (open) {
      setInfo({});
      setChargeOffBeforeInfo({});
      setIsSuc(false);
      setChargeOff(false);
      window.addEventListener('message', (event) => {
        const res = event.data || {};
        if(res?.type == AndroidPostMessageType.QRCode) {
          const data = res?.data || '';
          setValue(data);
        }
      });
    }
    return ()=>{
      setValue('');
      window.removeEventListener('message', ()=>{})
    }
  }, [open])
  const handleChange = (event: any) => {
    setValue(event.target.value)
  }


  const onClickHandle = () => {

    if (role === RoleEnum.cardIssuingCenter) {
      // 发卡中心
      chargeOffBeforeHandle();
    } else {
      // 领卡队长
      chargeOffHandle();
    }
  }

  const chargeOffBeforeHandle = () => {
    if (loading) return;
    if (!inputValue) return;
    setLoading(true);
    chargeOffBefore({
      qr: inputValue,
      address: account,
    }).then((res) => {
      setChargeOffBeforeInfo(res);
      // 部分发卡
      if(res.sendState == 3) {
        setChargeOff(true);
      } else {
        chargeOffHandle();
      }
    }).finally(() => {
      setLoading(false);
    })
  }

  const chargeOffHandle = () => {
    if (loading) return;
    if (!inputValue) return;
    setLoading(true);
    chargeOff({
      qr: inputValue,
      address: account,
    }).then((res) => {
      setInfo(res)
      setIsSuc(true);
      setValue('')
      onSucHandle();
    }).finally(() => {
      setLoading(false);
    })
  }

  const close = () => {
    setIsSuc(false);
    setOpen(false)
  }

  const sweepHandle = () => {
    if(isAndroid) {
      (window as any).flutter_inappwebview && (window as any).flutter_inappwebview.callHandler('scanQRCode',{imageUrl:""})
    }
  }

  return (
    <Modal isOpen={open} onDismiss={close}>
      <VerifyVoucherCodeDiv>
        <RowBetween style={{padding: '1rem 0', marginBottom: '1rem', borderBottom: '1px solid rgba(14,37,74,0.06)'}}>
          <Text style={{fontWeight: 600, color: theme.text7, fontSize: '16px'}}>
            {
              isSuc
                ? t('Write-offSuccessful')
                : isChargeOff
                  ? t('cancellationNum')
                  : t('Write-offAgentVoucherCode')
            }
          </Text>
          <Text style={{color: '#666666', fontSize: '16px'}} onClick={close}>
            <CloseOutlined/>
          </Text>
        </RowBetween>

        {
          isSuc ? (
              <WriteOffSuc>
                <div className='banK'>
                  <div className='title'>
                    <img src={writeOffSuc} alt="writeOffSuc"/>
                    {t('Write-offSuccessful')}
                  </div>
                  <Text style={{fontWeight: 600, color: theme.text7, fontSize: '15px'}}>
                    {t('IssueBankCards', {num: info.alreReceivedNum})}
                  </Text>
                  <CardInfo style={{
                    background: '#F7F7F7',
                    borderRadius: '15px',
                    padding: '16px 10px',
                    marginTop: '16px',
                  }}>
                    <div className='cardInfoList'>
                      <label>{t('orderReference')}</label>
                      <span>{info?.orderId}</span>
                    </div>
                    <div className='cardInfoList'>
                      <label>{t('userAddress')}</label>
                      <span>{info?.address}</span>
                    </div>
                    <div className='cardInfoList'>
                      <label>{t('HarvestInformation')}</label>
                      {
                        info?.shippingAddress?.name && <span className='clickToView' onClick={() => {
                          setIsShowAddress((pre) => {
                            return !pre
                          })
                        }}>{t('clickToView')}</span>
                      }

                    </div>
                    {
                      isShowAddress && (
                        <div className='addressInfo'>
                          <span>{info?.shippingAddress?.name}</span>
                          <span>{info?.shippingAddress?.phone}</span>
                          <span>{info?.shippingAddress?.area}{info?.shippingAddress?.address}</span>
                        </div>
                      )
                    }
                    <div className='cardInfoList'>
                      <label>{t('Write-offTime')}</label>
                      <span>{info?.destroyDate}</span>
                    </div>
                  </CardInfo>
                </div>
              </WriteOffSuc>
            ) :
            isChargeOff ? (
                <WriteOffBefore>
                   <div className='cardInfo'>
                       <div className='num'>{chargeOffBeforeInfo?.thisReceivedNum}</div>
                       <div className='tips'>{t('maximumWrittenOff', {num: minus(chargeOffBeforeInfo?.cardNum, chargeOffBeforeInfo?.alreReceivedNum)})}</div>
                   </div>
                  <TextBox style={{width: '100%', justifyContent: 'center', marginTop: '20px'}}>
                    <TextButton
                      style={{
                        color: '#878A93',
                        background: '#edeef2',
                        width: '40%',
                        margin: '10px',
                        textAlign: 'center',
                        boxShadow: '0px 2px 0px 1px #edeef2'
                      }}
                      onClick={close}
                    >
                      {t('Cancel')}
                    </TextButton>
                    <ConfirmButtonDiv style={{width: '40%', padding: '0', margin: '10px'}}>
                      <Button loading={loading}
                              type="primary"
                              disabled={!inputValue}
                              onClick={chargeOffHandle}
                      >{t('ConfirmWrite-off')}</Button>
                    </ConfirmButtonDiv>
                  </TextBox>
                </WriteOffBefore>
              )
              : (<WriteOff>
                  <Input
                    placeholder={t('PleaseEnterUserCredentialCode')}
                    value={inputValue}
                    allowClear
                    addonAfter={<img style={{width: '24px', height: '24px'}} onClick={sweepHandle} src={sweep}
                                     alt='sweep'></img>}
                    onChange={event => {
                      handleChange(event)
                    }}
                  ></Input>
                  <TextBox style={{width: '100%', justifyContent: 'space-between', marginTop: '42px'}}>
                    <TextButton
                      style={{
                        color: '#878A93',
                        background: '#edeef2',
                        width: '40%',
                        textAlign: 'center',
                        boxShadow: '0px 2px 0px 1px #edeef2'
                      }}
                      onClick={close}
                    >
                      {t('Cancel')}
                    </TextButton>
                    <ConfirmButtonDiv style={{width: '40%', padding: '0'}}>
                      <Button loading={loading}
                              type="primary"
                              disabled={!inputValue}
                              onClick={onClickHandle}
                      >{t('ConfirmWrite-off')}</Button>
                    </ConfirmButtonDiv>
                  </TextBox>
                </WriteOff>
              )
        }
      </VerifyVoucherCodeDiv>
    </Modal>
  )
}

export default VerifyVoucherCode;