import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import Popover, { PopoverProps } from '../Popover'

const TooltipContainer = styled.div`
  width: 228px;
  padding: 0.6rem 1rem;
  line-height: 150%;
  font-weight: 400;
`
const Text = styled.span`
 font-size:12px;
`
const Strong = styled.strong`
font-size:12px;
`
const AprContainer = styled.div`
  display: flex;
  justify-content: space-between;
  font-size:12px;
  margin-bottom: 5px;
  span{
    word-wrap: break-word;
  }
`

interface TooltipProps extends Omit<PopoverProps, 'content'> {
  text: string
  apr?: any
}

export default function Tooltip({ text, ...rest }: TooltipProps) {
  return <Popover content={<TooltipContainer>{text}</TooltipContainer>} {...rest} />
}
export function TooltipNew({ text, apr, ...rest }: TooltipProps) {
  const { t } = useTranslation()
  return <Popover content={<TooltipContainer style={{ width: '100%', padding: "0.6rem 0.8rem" }}>
    <AprContainer><span>{t('Annual Percentage Rate')}:</span>
      <Strong
        style={{
          paddingLeft: '5px',
          maxWidth: '80px'
        }}>{apr}</Strong>
    </AprContainer>
    <AprContainer>
      <Text>{t('Annual Percentage Yield')}:</Text>
      <Text style={{ paddingLeft: '5px',wordBreak: "break-all" }}>{text}</Text>
    </AprContainer>
    
  </ TooltipContainer>} {...rest} />
}
export function MouseoverTooltip({ children, ...rest }: Omit<TooltipProps, 'show'>) {

  const [show, setShow] = useState(false)
  const open = useCallback(() => setShow(true), [setShow])
  const close = useCallback(() => setShow(false), [setShow])
  return (
    <Tooltip {...rest} show={show}>
      <div onMouseEnter={open} onMouseLeave={close} style={{ color: '#fff' }}>
        {children}
      </div>
    </Tooltip>
  )
}
