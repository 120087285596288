/**
 * @name: index
 * @author: mahuanhuan
 * @date: 2024/1/19
 * @description：
 * @update: 2024/1/19
 */
import React, {useContext} from 'react';
import {RowCenter} from "../Row";
import EmptyNew from "../../assets/images/emptyNew.png";
import {TYPE} from "../../theme";
import styled, {ThemeContext} from "styled-components";
import {AutoColumn} from "../Column";


const EmptyProposalsNew = styled(AutoColumn)`
  padding: 3rem 12px;
  border-radius: 15px;
  background: #FFFFFF;
  //box-shadow: 0px 2px 1px 1px rgba(14, 37, 74, 0.1);
`

const Empty = ({title}: {title: string}) => {
  const theme = useContext(ThemeContext)

  return (<EmptyProposalsNew>
    <RowCenter justify='center'>
      <img src={EmptyNew} alt="AIA" style={{ width: '84px', marginBottom: '0.3rem' }} />
    </RowCenter>
    <TYPE.body color={theme.glay} textAlign="center" fontSize={14}>
      {title}
    </TYPE.body>
  </EmptyProposalsNew>)
}

export default Empty;