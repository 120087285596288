import { useState,useCallback,useEffect } from 'react';
import { message } from 'antd'
import { TransactionResponse } from '@ethersproject/providers'
import { useInvitationV2Contract } from '../hooks/useContract'
import { useActiveWeb3React } from '../hooks/index'
// import { useMultipleContractSingleData } from '../state/multicall/hooks'
import { toBigNumber,dividedByDecimals,multipliedByDecimals,numToFixed } from 'utils/bigNumber';
import { ZERO_ADDRESS} from 'constants/index'
import { useTransactionAdder } from '../state/transactions/hooks'
import { useTranslation } from 'react-i18next'
// import { calculateGasMargin } from '.';
import { useBlockNumber } from '../state/application/hooks'
// import INVITE_STAKE_V2_ABI from '../constants/abis/invite-stake-v2.json';
// import { Interface } from '@ethersproject/abi'
import moment_tz from 'moment-timezone';
import pubSub from "./pub-sub";
export const REACT_APP_INVITE_ADDRESS_V2 = process.env.REACT_APP_INVITE_ADDRESS_V2 || '';

const errors = (messages: string) => {
  message.error(messages)
}

// function useMultipleData(functionName:string,params:any){
//   return useMultipleContractSingleData([REACT_APP_INVITE_ADDRESS_V2], new Interface(INVITE_STAKE_V2_ABI),functionName,params)
// }

//gas 费查询
export function useGas(){
  const {library} = useActiveWeb3React()
  const tokenContract = useInvitationV2Contract();

  useEffect(()=>{
    getGasPrice();
  },[])
  const getGasPrice = useCallback(async()=>{
    // @ts-ignore
    let {gasPrice } = await library.getFeeData();
    return gasPrice?.toString();
  },[library]);
  const getGasLimit = useCallback(async(eventName: string, arg:any,option?:any)=>{
    let gasLimit;
    try{
      let estimateGas;
      if(arg.length == 0){
        estimateGas = await tokenContract?.estimateGas[eventName]({...(option || {})});
      }else{
        estimateGas = await tokenContract?.estimateGas[eventName](arg.toString(),{...(option || {})});
      }
      
      gasLimit = estimateGas?.toString();
    }catch{
      gasLimit = '0'
    }
    return gasLimit
  },[tokenContract])
  /** 名称，[参数],比例 */
  const getGas = useCallback(async({eventName, arg,option,rate}:{
    eventName: string, 
    arg:any,
    option?:any,
    rate?:number
  })=>{
    let gasLimit,gasLimitMul,gasPrice,gas,rateVal = rate??1.3;
    try{
      gasLimit = await getGasLimit(eventName,arg,option);
      gasLimitMul = toBigNumber(gasLimit?.toString() || '0').multipliedBy(rateVal).toFixed(0)

      gasPrice = await getGasPrice();
      gas = toBigNumber(gasLimit?.toString() || '0')
      .multipliedBy(gasPrice?.toString() || '0')
      .multipliedBy(rateVal)
      .div(1e18).toFixed()
    }catch{
      gasLimit = '0';
      gasPrice = '0';
      gas = '0';
    }
    return {gasLimit,gasLimitMul,gasPrice,gas}
  },[])
  return {getGasLimit,getGasPrice,getGas}
}

/**
 * 查询用户上级
 */
export function useUserUpper(){
  const [userUpper, setUpper ] = useState<string>('')
  const { account } = useActiveWeb3React()
  const tokenContract = useInvitationV2Contract();
  // console.log(tokenContract)
  const latestBlockNumber = useBlockNumber();
  const getUserUpper = useCallback(async() => {
    let address = '';
    try{
      let result = await tokenContract?.getUserUpper(account);
      address = result === ZERO_ADDRESS?'':result
      setUpper(address)
    }catch{
      setUpper('')
    }
    return address
  }, [account, tokenContract])
  useEffect(() => {
    if(!account){
      return
    }
    getUserUpper()
  },[account,getUserUpper,latestBlockNumber]);
  return {userUpper,getUserUpper}
}

const userInviteInfoDefault = {
  parentAddress: '',//上级地址
  validInvite: '0',//有效邀请数
  totalInvite: '0',//总邀请数
  totalReward: '0',//总邀请奖励
}
/**
 * 查询用户邀请信息
 * @return userInviteInfoDefault
 */
export function useUserInviteInfo(){
  const { account } = useActiveWeb3React();
  const [userInviteInfo, setUserInviteInfo ] = useState<any>({...userInviteInfoDefault})
  const tokenContract = useInvitationV2Contract();
  const latestBlockNumber = useBlockNumber();
  const getUserInviteInfo = useCallback(async() => {
    try{
      let result = await tokenContract?.getUserInviteInfo(account);
      // console.log('查询用户邀请信息 result',result)
      result = result.map((it:any)=>it.toString())
      let [parentAddress,validInvite,totalInvite,totalReward]:any = result;
      setUserInviteInfo({parentAddress,validInvite,totalInvite,
        totalReward:numToFixed(dividedByDecimals(totalReward?.toString(),18))
      });
    }catch{
      setUserInviteInfo({...userInviteInfoDefault})
    }
  }, [account, tokenContract])
  useEffect(() => {
    if(!account){
      return
    }
    getUserInviteInfo()
  },[account,getUserInviteInfo,latestBlockNumber]);
  return userInviteInfo
}
/**
 * 邀请前检测
 */
export function useCheckUpper(){
  const { account } = useActiveWeb3React();
  const tokenContract = useInvitationV2Contract();
  const checkUpper = useCallback(async(address:string)=>{
    let  result = await tokenContract?.check(account,address);
    return result.toString()
  },[tokenContract,account])
  return {checkUpper}
}

/**
 * 添加邀请人
 */
export function SetAddUpper(){
  const { t } = useTranslation()
  const [addUpperLoaing,SetaddUpperLoaing ] = useState<boolean>(false);
  const {account,library } = useActiveWeb3React();
  const tokenContract = useInvitationV2Contract();
  const addTransaction = useTransactionAdder();
  const {getGas } = useGas();
  const addUpper = useCallback(async(address) => {
    if(!account || !tokenContract) return
      SetaddUpperLoaing(true);
      const {gasLimitMul,gas} =await getGas({eventName: 'addUpper', arg:[address]});
      let balance = await library?.getBalance(account);
      if(toBigNumber(dividedByDecimals(balance?.toString() || 0,18)).lt(gas)){
        errors(t('If the balance is insufficient, at least reserve a balance of xxxAIA',{amount: gas}))
        SetaddUpperLoaing(false);
        return 
      }
      // const estimatedGas = await tokenContract.estimateGas.addUpper(address).catch((error: any) => {
      //   console.log(error)
      //   errors(error.data ? error.data.message : error.message ?? t('Please try again'))
      //   SetaddUpperLoaing(false);
      //   return tokenContract.estimateGas.addUpper(address)
      // })
      // console.log('estimatedGas',estimatedGas)
      tokenContract.addUpper(address,{
        gasLimit: gasLimitMul,
      }).then(async(response: TransactionResponse)=>{
        addTransaction(response, {});
        SetaddUpperLoaing(false);
      }).catch((error:any)=>{
        console.log(error)
        errors(error.data ? error.data.message : error.message ?? t('Please try again'))
      }).finally(()=>{
        SetaddUpperLoaing(false);
      });
  }, [account, tokenContract,addTransaction,t,library])
  return { addUpperLoaing,addUpper }
}

/**
 * 获取用户质押信息
 * @return Aarry [当前质押总量,质押结束时间text,质押结束时间戳] 
 * @
 */
export function useStakeInfo(){
  const { account } = useActiveWeb3React();
  const [stakeInfo, setStakeInfo ] = useState<string[]>(['0','0','0'])
  const tokenContract = useInvitationV2Contract();
  const latestBlockNumber = useBlockNumber();
  const getStakeInfo = useCallback(async() => {
    try{
      let result = await tokenContract?.getStakeInfo(account);
      let list = result.map((it:any,index:number) => {
        return index === 0 ? 
        dividedByDecimals(it.toString(),18) :
        it.toString() === '0'?'0':
        moment_tz(new Date(it.toString() * 1000)).format('YYYY-MM-DD HH:mm:ss')
      })
      setStakeInfo([...list,result[1].toString()]);
    }catch{
      setStakeInfo(['0','0','0'])
    }
  }, [account, tokenContract])
  useEffect(() => {
    if(!account){
      return
    }
    getStakeInfo()
  },[account,getStakeInfo,latestBlockNumber]);
  return stakeInfo
}

// 分页查询用户下级
export function GetUserChilds(){
  const { t } = useTranslation()
  const [userChildsLoaing,setUserChildsLoaing ] = useState<boolean>(false);
  const { account } = useActiveWeb3React();
  const tokenContract = useInvitationV2Contract();
  /** 开始索引startIndex, 结束索引endIndex*/
  const getUserChilds = useCallback(async({pageNo, pageSize}) => {
    return new Promise((resolve, reject)=>{
      if(!account || !tokenContract) {
          resolve([]);
          return
        }
        let start = (pageNo - 1) * pageSize;
        let end = start + pageSize;
        setUserChildsLoaing(true);
        tokenContract.getUserChilds(account,start,end).then(async(response:any)=>{
          // console.log('response',response)
          setUserChildsLoaing(false);
          let list:any = [];
          response[0]?.map((item:any, index:number) =>{
            list.push({
              address: item, 
              time:  moment_tz(new Date(response[1][index] * 1000)).format('YYYY-MM-DD HH:mm:ss'),
              amount: numToFixed(dividedByDecimals(response[2][index].toString(),18))
            })
          })
          // console.log('list',list)
          resolve(list) 
        }).catch((error:any)=>{
          errors(error.data ? error.data.message : error.message ?? t('Please try again'))
          reject([]);
        }).finally(()=>{
          setUserChildsLoaing(false);
        });
    })
  }, [account, tokenContract])
  return { userChildsLoaing,getUserChilds }
}

// 搜索下级
export function GetChildInfo(){
  const { t } = useTranslation()
  const [childInfoLoading,setLoading ] = useState<boolean>(false);
  const { account } = useActiveWeb3React();
  const tokenContract = useInvitationV2Contract();
  /** 开始索引startIndex, 结束索引endIndex*/
  const getChildInfo = useCallback(async(address: string) => {
    return new Promise(async(resolve, reject)=>{
      if(!account || !tokenContract) return
        setLoading(true);
        let data = await tokenContract.getUserUpper(address);
        if(data.toLocaleLowerCase() !== account.toLocaleLowerCase()){//非该账号下级
          resolve({})
        }
        tokenContract.getChildInfo(address).then(async(response:any)=>{
          // console.log('response',response)
          setLoading(false);
          if(response[0] === ZERO_ADDRESS)resolve({}); 
          let data:any = {
            address: response[0], 
            time:  moment_tz(new Date(response[1] * 1000)).format('YYYY-MM-DD HH:mm:ss'),
            amount: numToFixed(dividedByDecimals(response[2].toString(),18))
          }
          resolve(data) 
        }).catch((error:any)=>{
          errors(error.data ? error.data.message : error.message ?? t('Please try again'))
          reject({});
        }).finally(()=>{
          setLoading(false);
        });
    })
  }, [account, tokenContract])
  return { childInfoLoading,getChildInfo }
}

/**
 * 获取最小质押数量
 */
export function useMinStakeAmount(){
  const [minStakeNum, setMinStakeNum ] = useState<string>('0');
  const tokenContract = useInvitationV2Contract();
  const latestBlockNumber = useBlockNumber();
  const getMinStakeAmount = useCallback(async() => {
    try{
      let result = await tokenContract?.getMinStakeAmount();
      setMinStakeNum(dividedByDecimals(result.toString(),18))
    }catch{
      setMinStakeNum('0')
    }
  }, [tokenContract])
  useEffect(() => {
    if(!tokenContract){
      return
    }
    getMinStakeAmount()
  },[tokenContract,getMinStakeAmount,latestBlockNumber]);
  return minStakeNum
}
/**
 * 质押
 * @returns 
 */
export function GetStake(){
  const { t } = useTranslation()
  const [stakeLoaing,setStakeLoaing ] = useState<boolean>(false);
  const { account,library } = useActiveWeb3React();
  const {getGas } = useGas();
  const tokenContract = useInvitationV2Contract();
  const addTransaction = useTransactionAdder()
  const goStake = useCallback(async(amount) => {
    if(!account || !tokenContract) return
      let num = multipliedByDecimals(amount,18);
      setStakeLoaing(true);
      const {gasLimitMul,gasPrice,gas} =await getGas({eventName: 'stake', arg:[],option:{value:num}});
      let balance = await library?.getBalance(account);
      let needBalance = toBigNumber(gas).plus(amount);
      // console.log('质押费用判断',needBalance.toString(),gasPrice,gasLimitMul,dividedByDecimals(balance?.toString() || 0,18))
      if(needBalance.gt(dividedByDecimals(balance?.toString() || 0,18))){
        errors(t('If the balance is insufficient, at least reserve a balance of xxxAIA',{amount: needBalance.toString()}))
        setStakeLoaing(false);
        return 
      }
      // const estimatedGas = await tokenContract.estimateGas.stake({value: num}).catch((error: any) => {
      //   console.log(error)
      //   errors(error.data ? error.data.message : error.message ?? t('Please try again'))
      //   setStakeLoaing(false);
      //   return tokenContract.estimateGas.stake({
      //     value: num
      //   })
      // })
      // console.log('estimatedGas',estimatedGas.toString(),calculateGasMargin(estimatedGas))
      tokenContract.stake({
        value: num,
        gasPrice,
        gasLimit:gasLimitMul,// calculateGasMargin(estimatedGas),
      }).then(async(response: TransactionResponse)=>{
        addTransaction(response, {});
        setStakeLoaing(false);
        // 质押成功后，发起列表请求
        pubSub.emit('stakeSucHandle');
      }).catch((error:any)=>{
        console.log(error)
        errors(error.data ? error.data.message : error.message ?? t('Please try again'))
      }).finally(()=>{
        setStakeLoaing(false);
      });
  }, [account, tokenContract])
  return { stakeLoaing,goStake }
}


/**
 * 分页查询用户质押资产记录
 * @returns 
 */
export function GetStakeRecords(){
  const [userStakeRecord, setUserStakeRecord ] = useState<any[]>([]);
  const [userStakeLoaing, setUserStakeLoaing ] = useState<boolean>(false);
  const { account } = useActiveWeb3React();
  const tokenContract = useInvitationV2Contract();
  /** 开始索引startIndex, 结束索引endIndex*/
  const getStakeRecords = useCallback(async(startIndex, endIndex) => {
    return new Promise(async(resolve, reject) => {
      if(!account) {
        setUserStakeRecord([]);
        reject([]);
      }
      try{
        setUserStakeLoaing(true);
        let result = await tokenContract?.getStakeRecords(account,startIndex,endIndex);
        let list:any = [];
        if(result[0].length>0){
          result[0].forEach((it:any,index:number) => {
            list.push({
              type: +it, 
              time: moment_tz(new Date(result[1][index] * 1000)).format('YYYY-MM-DD HH:mm:ss'), 
              amount: numToFixed(dividedByDecimals(result[2][index].toString(),18))
            })
          })
        }
        setUserStakeLoaing(false);
        setUserStakeRecord([...list]);
        resolve([...list]);
      }catch{
        setUserStakeLoaing(false);
        setUserStakeRecord([]);
        reject([]);
      }
    })
  }, [account, tokenContract])
  return {userStakeRecord,userStakeLoaing,getStakeRecords }
}

const profitInfoDefault = ['0','0','0','0','0','0']
/** 
 * 获取用户收益信息
 * @returns Array [质押池总量,每个区块奖励,预估到期总收益,当前质押收益,年利率（APR）,年溢率（APY）] 
 * 
 * 注：年利率和年溢率由前端进行计算，以目前3秒出一个区块为例，计算公式如下
 * 每日奖励 = (24*60*60 / 3) * 每个区块奖励；
 * 年利率（APR）= 365*每日奖励 / 质押池总量;
 * 年溢率（APY）= (1 + APR / 365)^365 - 1;
 * 页面以百分比进行显示时，对应的APR和APY都乘以100，但计算时不用乘以100，特别注意计算APR时要做好质押池总量为0的处理，免得报错
 * */ 
export function useProfitInfo(){
  const [profitInfo, setProfitInfo ] = useState<string[]>([...profitInfoDefault])
  const { account } = useActiveWeb3React()
  const tokenContract = useInvitationV2Contract();
  const latestBlockNumber = useBlockNumber();
  const getProfitInfo = useCallback(async() => {
    try{
      // console.log('获取用户收益信息-----start')
      // response [质押池总量,每个区块奖励,预估到期总收益,当前质押收益]
      let result = await tokenContract?.getProfitInfo(account);
      // console.log('获取用户收益信息',result)
      result = result.map((it:any) => dividedByDecimals(it.toString(),18))
      // console.log('result',result)
      let dayReward = toBigNumber(24*60*60).dividedBy(3).multipliedBy(result[1]);
      let totalStakeIsZore = toBigNumber(result[0]).isZero();
      let APR = totalStakeIsZore? toBigNumber(0) : toBigNumber(365).multipliedBy(dayReward).dividedBy(result[0]);
      let APY = totalStakeIsZore? toBigNumber(0) : (toBigNumber(1).plus(APR.dividedBy(365))).exponentiatedBy(365).minus(1);
      // console.log('计算',dayReward.toString(),APR.toFixed(1,1),APY.toFixed(1,1))
      setProfitInfo([...result,APR.multipliedBy(100).toFixed(2),APY.multipliedBy(100).toFixed(2)]);
    }catch{
      setProfitInfo([...profitInfoDefault]);
    }
  }, [account, tokenContract])
  useEffect(() => {
    if(!account){
      return
    }
    getProfitInfo()
  },[account,getProfitInfo, latestBlockNumber]);
  return profitInfo
}

/**
 * 获取用户赎回信息
 * @return Array [赎回释放比例,总可赎回数量,已赎回数量,可赎回数量]
 */
export function useUnstakeInfo(){
  // const { account } = useActiveWeb3React()
  // const UnstakeInfoData = useMultipleData('getUnstakeInfo',[account]);
  // let result:any = UnstakeInfoData[0].result; 
  // console.log('获取用户赎回信息',result)
  // if(result?.length>0){
  //   // 赎回释放比例要除以10000000，页面以百分比显示时，再乘以100，也可直接一步到位除以100000
  //   return result.map((it:any,index:number) => {
  //     return index === 0 ? 
  //     toBigNumber(it.toString()).div(100000).toFixed(2,1) :
  //     dividedByDecimals(it.toString(),18)
  //   })
  // }else{
  //   return ['0','0','0','0']
  // }
  const { account } = useActiveWeb3React()
  const [unstakeInfo, setUnstakeInfo ] = useState<string[]>(['0','0','0','0']);
  const tokenContract = useInvitationV2Contract();
  const latestBlockNumber = useBlockNumber();
  const getUnstakeInfo = useCallback(async() => {
    try{
      let result = await tokenContract?.getUnstakeInfo(account);
      // console.log('获取用户赎回信息',result)
      // 赎回释放比例要除以10000000，页面以百分比显示时，再乘以100，也可直接一步到位除以100000
      let list = result.map((it:any,index:number) => {
        return index === 0 ? 
        toBigNumber(it.toString()).div(100000).toFixed(2,1) :
        dividedByDecimals(it.toString(),18)
      })
      setUnstakeInfo([...list])
    }catch{
      setUnstakeInfo(['0','0','0','0'])
    }
  }, [tokenContract,account])
  useEffect(() => {
    if(!account || !tokenContract){
      return
    }
    getUnstakeInfo()
  },[account,tokenContract,getUnstakeInfo, latestBlockNumber]);
  return unstakeInfo
}

/**
 * 赎回
 * @returns 
 */
export function GetUnStake(){
  const { t } = useTranslation()
  const [unStakeLoaing,setUnStakeLoaing ] = useState<boolean>(false);
  const { account,library } = useActiveWeb3React();
  const tokenContract = useInvitationV2Contract();
  const addTransaction = useTransactionAdder()
  const { getGas } =useGas();
  /** 开始索引startIndex, 结束索引endIndex*/
  const unStake = useCallback(async(amount) => {
    if(!account || !tokenContract) return
      let num = multipliedByDecimals(amount,18);
      setUnStakeLoaing(true);
      const {gasLimitMul,gas} =await getGas({eventName: 'unstake', arg:[num]});
      let balance = await library?.getBalance(account);
      // console.log('费用判断',gasLimitMul,gas,dividedByDecimals(balance?.toString() || 0,18))
      if(toBigNumber(dividedByDecimals(balance?.toString() || 0,18)).lt(gas)){
        errors(t('If the balance is insufficient, at least reserve a balance of xxxAIA',{amount: gas}))
        setUnStakeLoaing(false);
        return 
      }
      // const estimatedGas = await tokenContract.estimateGas.unstake(num).catch((error: any) => {
      //   errors(error.data ? error.data.message : error.message ?? t('Please try again'))
      //   setUnStakeLoaing(false);
      //   return tokenContract.estimateGas.unstake(num)
      // })
      // console.log('estimatedGas',estimatedGas)
      tokenContract.unstake(num,{
        gasLimit: gasLimitMul,//calculateGasMargin(estimatedGas),
      }).then(async(response: TransactionResponse)=>{
        addTransaction(response, {});
        setUnStakeLoaing(false);
      }).catch((error:any)=>{
        errors(error.data ? error.data.message : error.message ?? t('Please try again'))
      }).finally(()=>{
        setUnStakeLoaing(false);
      });
  }, [account, tokenContract,library])
  return { unStakeLoaing,unStake }
}

/**
 * 赎回收益
 */
export function GetReceiveProfit(){
  const { t } = useTranslation()
  const [receiveProfitLoaing,SetReceiveProfitLoaing ] = useState<boolean>(false);
  const {account,library } = useActiveWeb3React();
  const tokenContract = useInvitationV2Contract();
  const addTransaction = useTransactionAdder()
  const { getGas} = useGas();
  const receiveProfit = useCallback(async() => {
    // console.log('点击赎回收益:调用方法receiveProfit:')
    if(!account || !tokenContract) return
      SetReceiveProfitLoaing(true);
      const {gasLimitMul,gas} =await getGas({eventName: 'receiveProfit', arg:[]});
      let balance = await library?.getBalance(account);
      // console.log('费用判断',gasLimitMul,gas,dividedByDecimals(balance?.toString() || 0,18))
      if(toBigNumber(dividedByDecimals(balance?.toString() || 0,18)).lt(gas)){
        errors(t('If the balance is insufficient, at least reserve a balance of xxxAIA',{amount: gas}))
        SetReceiveProfitLoaing(false);
        return 
      }
      // const estimatedGas = await tokenContract.estimateGas.receiveProfit().catch((error: any) => {
      //   errors(error.data ? error.data.message : error.message ?? t('Please try again'))
      //   SetReceiveProfitLoaing(false);
      //   return tokenContract.estimateGas.receiveProfit()
      // })
      tokenContract.receiveProfit({
        gasLimit:gasLimitMul,// calculateGasMargin(estimatedGas),
      }).then(async(response: TransactionResponse)=>{
        addTransaction(response, {});
        SetReceiveProfitLoaing(false);
      }).catch((error:any)=>{
        errors(error.data ? error.data.message : error.message ?? t('Please try again'))
      }).finally(()=>{
        SetReceiveProfitLoaing(false);
      });
  }, [account, tokenContract,library])
  return { receiveProfitLoaing,receiveProfit }
}


