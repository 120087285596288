import { useEffect, useMemo, useState } from "react"
import { REACT_APP_APY_URL, REACT_APP_POOL_URL } from 'utils'
import axios from "axios"
import { useActiveWeb3React } from "hooks";
import { BigNumber } from "ethers"
import { useEarnState } from "state/earn/hooks";
import { STAKING_REWARDS_INFO } from "state/stake/hooks";
import { STAKING_REWARDS_INTERFACE } from "constants/abis/staking-rewards";
import { useMulticallContract } from "hooks/useContract";
import { JSBI } from "@uniswap/sdk";
axios.defaults.timeout = 1000 * 5;
// is Android
export const judgePhoneType = () => {
    let isAndroid = false;
    let isIOS = false;
    let isIOS9 = false;
    let version: any;
    let u = navigator.userAgent;
    let ua = u.toLowerCase();
    //Android系统
    if (u.indexOf("Android") > -1 || u.indexOf("Linux") > -1) {
        //android终端或者uc浏览器
        isAndroid = true;
    }

    //ios
    if (ua.indexOf("like mac os x") > 0) {
        let regStr_saf = /os [\d._]*/gi;
        let verinfo = ua.match(regStr_saf);
        version = (verinfo + "").replace(/[^0-9|_.]/gi, "").replace(/_/gi, ".");
    }
    let version_str = version + "";
    // ios9以上
    if (version_str !== "undefined" && version_str.length > 0) {
        version = parseInt(version);
        if (version >= 8) {
            isIOS9 = true;
        } else {
            isIOS = true;
        }
    }
    return { isAndroid, isIOS, isIOS9 };
}
// click button to share app
export const handleShareClick = (apy: number, phase: string) => {
    const { isIOS, isIOS9 } = judgePhoneType();
    let win: any = window
    if (apy >= 10000) {
        apy = parseInt(apy + '')
    }
    const param = { apy: apy + '%', phase }
    if (win.flutter_inappwebview) {
        win?.flutter_inappwebview.callHandler('shareImage', param).then(function (result: any) {
            console.info(apy)
        });
    } else {
        if (isIOS || isIOS9) {
            win.webkit?.messageHandlers.shareImage.postMessage(JSON.stringify(param));
        }
    }
}
// get device ratio
export const getPixelRatio = function (context: any) {
    var backingStore = context.backingStorePixelRatio ||
        context.webkitBackingStorePixelRatio ||
        context.mozBackingStorePixelRatio ||
        context.msBackingStorePixelRatio ||
        context.oBackingStorePixelRatio ||
        context.backingStorePixelRatio || 1;
    return (window.devicePixelRatio || 1) / backingStore;
};

export const usePoolDatas = () => {
    const { account } = useActiveWeb3React()
    const [getAPYData, setAPYData] = useState<any>(null)
    const { numberAuto } = useEarnState()
    useEffect(() => {
        const getDataFun = async () => {
            const userAddress = (account && account !== 'null') ? ('?userAddress=' + account) : ''
            try {
                let { data } = await axios.get(REACT_APP_POOL_URL + userAddress);
                let keysDataAddress = Object.keys(data)
                let newItem: any = {}
                keysDataAddress.map((item: any) => {
                    newItem[item] = ''
                    let wrapSecond: any = {}
                    const itemKeys = Object.keys(data[item])
                    itemKeys.map((keyds: any) => {
                        let arr = new Array()
                        if (data[item][keyds][0]) {
                            arr[0] = BigNumber.from(data[item][keyds][0])
                        } else {
                            arr[0] = BigNumber.from(data[item][keyds])
                        }
                        const result = {
                            result: arr
                        }
                        wrapSecond[keyds] = result
                    })
                    newItem[item] = wrapSecond
                    // console.info(newItem,'newItemnewItem')
                    return newItem
                })

                setAPYData(newItem)
            } catch (error) {
                console.info(error)
                setAPYData(undefined)
            }
        }
        getDataFun();
    }, [account, numberAuto])
    return getAPYData
}
// get APY data
export const GetApyFun = function () {
    const [getAPYData, setAPYData] = useState<any>([])
    useEffect(() => {
        let timer: any;
        const getDataFun = async () => {
            try {
                const { data } = await axios.get(REACT_APP_APY_URL)
                setAPYData(JSON.parse(data))
            } catch (error) {
                setAPYData(undefined)
            }
        }
        getDataFun();
        timer = setInterval(() => { getDataFun() }, 35000)
        return () => {
            clearInterval(timer)
            setAPYData([])
        }
    }, [])
    return getAPYData;
};




// const type = stakingInfo?.type === 'one' ? t('Phase 1') : (stakingInfo?.type === 'two' ? t('Phase 2') : (stakingInfo?.type === 'three' ? t('Phase 3') : ''))
export const TypeFun = (stakingInfo: any, t: any) => {
    const setBngFun = useMemo(() => (stakingInfo: any, t: any) => {
        const p = stakingInfo?.type
        switch (p) {
            case 'one':
                return t('Phase 1')
            case 'two':
                return t('Phase 2')
            case 'three':
                return t('Phase 3')
            case 'four':
                return t('Phase 4')
            default:
                if (!p) {
                    return ''
                } else {
                    return t(p)
                }
        }
    }, [stakingInfo?.type])
    return setBngFun(stakingInfo, t)
}

export const SetBngFunCommon = (stakingInfo: any) => {
    const reslut = useMemo(() => (info: any) => {
        if (info?.active) {
            switch (info?.type) {
                case 'one':
                    return { bng: 'linear-gradient(184deg, #FFFFFF  10%, #FFF3CF 100%)', col: '#FFA70E' };
                case 'two':
                    return { bng: 'linear-gradient(184deg, #FFFFFF 10%, #DBF6FF 100%)', col: '#26B6E6' };
                case 'three':
                    return { bng: 'linear-gradient(184deg, #FFFFFF 10%, #DDF9E6 100%)', col: '#39C465' };
                default:
                    // if (info?.type) {
                    //     return { bng: 'linear-gradient(184deg, #FFFFFF 10%, #DDF9E6 100%)', col: '#39C465' };
                    // }
                    return { bng: '#ffffff', col: '#000000' };
            }
        } else {
            return { bng: '#ffffff', col: '#000000' };
        }
    }, [stakingInfo])
    return reslut(stakingInfo)
}

export const useFetchMasterChefFarmPoolLength = () => {
    const { account, chainId } = useActiveWeb3React()
    const farmsCanFetch = STAKING_REWARDS_INFO[chainId || 1320]
    const multi: any = useMulticallContract()
    useEffect(() => {
        const fetchedData = async () => {
            const [
                userFarmAllowances,
                userFarmTokenBalances,
                userTotalSupply,
                userFarmRewardRate,
                userFarmPeriodFinish
            ] = await Promise.all([
                fetchFarmUserAllowances(farmsCanFetch, multi, account || undefined),
                fetchFarmUserTokenBalances(farmsCanFetch, multi, account || undefined),
                fetchFarmUserTotalSupply(farmsCanFetch, multi),
                fetchFarmUserRewardRate(farmsCanFetch, multi),
                fetchFarmUserPeriodFinishes(farmsCanFetch, multi)
            ])
            return userFarmAllowances.map((farmAllowance: any, index: number) => {
                // console.info(userFarmRewardRate[index].toString(), 'farmsfarms')
                return {
                    allowance: userFarmAllowances[index],
                    tokenBalance: userFarmTokenBalances[index],
                    stakedBalance: userTotalSupply[index],
                    earnings: userFarmRewardRate[index],
                    eee: userFarmPeriodFinish[index]
                }
            })
        }
        fetchedData()
    }, [])

}

export const fetchFarmUserAllowances = async (farmsToFetch: any, multi: any, account: string | undefined) => {
    return await fetchCommon(farmsToFetch, multi, 'balanceOf', account)
}
export const fetchFarmUserTokenBalances = async (farmsToFetch: any, multi: any, account: string | undefined) => {

    return await fetchCommon(farmsToFetch, multi, 'earned', account)
}
export const fetchFarmUserTotalSupply = async (farmsToFetch: any, multi: any) => {
    return await fetchCommon(farmsToFetch, multi, 'totalSupply')
}
export const fetchFarmUserRewardRate = async (farmsToFetch: any, multi: any) => {
    return await fetchCommon(farmsToFetch, multi, 'rewardRate')
}
export const fetchFarmUserPeriodFinishes = async (farmsToFetch: any, multi: any) => {
    const calls = farmsToFetch.map((farm: any) => {
        const lpContractAddress = farm.stakingRewardAddress
        const parms: any = {
            address: lpContractAddress,
            name: 'rewardRate'
        }
        return parms
    })
    const rawLpAllowances = await multicall(STAKING_REWARDS_INTERFACE, calls, multi)
    const parsedLpAllowances = rawLpAllowances.map((lpBalance: any) => {
        return JSBI.divide(JSBI.BigInt(lpBalance.toString()), JSBI.BigInt(Math.pow(10, 18)))
    })
    return parsedLpAllowances
}
export const fetchCommon = async (farmsToFetch: any, multi: any, name: string, account?: string | undefined) => {

    const calls = farmsToFetch.map((farm: any) => {
        const lpContractAddress = farm.stakingRewardAddress
        const parms: any = {
            address: lpContractAddress,
            name
        }
        if (account) {
            parms.params = [account]
        }
        return parms
    })
    const rawLpAllowances = await multicall(STAKING_REWARDS_INTERFACE, calls, multi)
    const parsedLpAllowances = rawLpAllowances.map((lpBalance: any) => {
        return JSBI.divide(JSBI.BigInt(lpBalance.toString()), JSBI.BigInt(Math.pow(10, 18)))
    })
    return parsedLpAllowances
}
const multicall = async <T = any>(abi: any, calls: any[], multi: any): Promise<T> => {
    const itf: any = abi
    const calldata = calls.map((call: any) => ({
        target: call.address.toLowerCase(),
        callData: itf.encodeFunctionData(call.name, call.params),
    }))
    const { returnData } = await multi.aggregate(calldata)
    const res = returnData.map((call: any, i: number) => itf.decodeFunctionResult(calls[i].name, call))
    return res as any
}