import React from 'react'
import styled from 'styled-components'
import CurrencyFormat from './CurrencyFormat'
import CellFormat from './CellFormat'
import TextIcon from './TextIcon'
// import { DetailLimitOrderModal } from './DetailLimitOrderModal'



import OrderStatus, { StatusElementType } from './OrderStatus'
import { Td } from './LoadingTable'
import useFormattedOrderData from 'pages/LimitOrders/hooks/useFormattedOrderData'
import { MoreHorizontalIcon, SyncAltIcon } from 'components/Svg'
import dayjs from 'dayjs'

const FullRow: React.FC<{ order: any, showClickModal: any }> = ({ order, showClickModal }) => {
    const formattedOrder = useFormattedOrderData(order)
    const { inputToken, outputToken, inputAmount, outputAmount, executionPrice } = formattedOrder
    const { orderTime, execTime } = order

    return (
        <>

            <tr>
                <Td>
                    <CellFormat firstRow={inputAmount} secondRow={<CurrencyFormat bold currency={inputToken} />} />
                </Td>
                <Td>
                    <CellFormat firstRow={outputAmount} secondRow={<CurrencyFormat bold currency={outputToken} />} />
                </Td>
                <Td>
                    <CellFormat
                        firstRow={executionPrice}
                        secondRow={<TextIcon
                            bold
                            text={`${outputToken?.symbol}/${inputToken?.symbol}`}
                            icon={<SyncAltIcon />} />}
                    />
                </Td>
                <Td>
                    <OrderStatus formattedOrder={formattedOrder} showOpenTag element={StatusElementType.TEXT} />
                </Td>
                <Td>

                    <CellFormat
                        firstRow={
                            !orderTime ? '/' :
                                dayjs((execTime ? execTime : orderTime) * 1000).format('YYYY-MM-DD')
                        }
                        secondRow={<TextIcon
                            bold
                            text={
                                !orderTime ? '/' :
                                    dayjs((execTime ? execTime : orderTime) * 1000).locale('en').format('HH:mm:ss')
                            }
                        />} />
                </Td>
                <Td>
                    {/* <DetailLimitOrderModal
                        order={order}
                        isOpen={isOpen}
                        onDismiss={onDismiss}
                        formattedOrder={formattedOrder} /> */}
                    <ToOrderContainer onClick={showClickModal}>
                        <MoreHorizontalIcon />
                    </ToOrderContainer>
                </Td>
            </tr>
        </>
    )
}

const ToOrderContainer = styled.div`
    cursor: pointer;
`

export default FullRow
