import { ChainId, TokenAmount } from '@uniswap/sdk'
import React, { useState, useCallback, useMemo } from 'react'
import { Dropdown, Menu as AntMenu } from 'antd';
import { Text } from 'rebass'
import { NavLink } from 'react-router-dom'
import { darken } from 'polished'
import { useTranslation } from 'react-i18next'

import styled from 'styled-components'
import Logo from '../../assets/svg/logoAia.svg'
// import LogoDark from '../../assets/svg/logo_white.svg'
import SwapSvg from '../../assets/images/swap1.svg'
import SwapActiveSvg from '../../assets/images/swap.svg'
import CapitalSvg from '../../assets/images/capital1.svg'
import CapitalActiveSvg from '../../assets/images/capital.svg'

import PoolSvg from '../../assets/images/pool1.svg'
import PoolActiveSvg from '../../assets/images/pool.svg'

// import InvitationSvg from '../../assets/images/invitation_active.svg'
// import InvitationActiveSvg from '../../assets/images/invitation1.svg'
import BridgeImg from '../../assets/images/bridge.png'
import BridgeActiveImg from '../../assets/images/bridge1.png'

import GlobalSvg from '../../assets/svg/global.svg'
import InfoSvg from '../../assets/svg/info.svg'

import { useActiveWeb3React } from '../../hooks'
import { useDarkModeManager } from '../../state/user/hooks'
import { useETHBalances, useAggregateUniBalance } from '../../state/wallet/hooks'
import { CardNoise } from '../earn/styled'
import { CountUp } from 'use-count-up'
import { TYPE } from '../../theme'

import { YellowCard } from '../Card'
import { Moon, Sun } from 'react-feather'
import Menu from '../Menu'

import Row, { RowFixed } from '../Row'
import Web3Status from '../Web3Status'
import ClaimModal from '../claim/ClaimModal'
import { useToggleSelfClaimModal, useShowClaimPopup, useWalletModalToggle } from '../../state/application/hooks'
import { useUserHasAvailableClaim } from '../../state/claim/hooks'
import { useUserHasSubmittedClaim } from '../../state/transactions/hooks'
import { Dots } from '../swap/styleds'
import Modal from '../Modal'
import UniBalanceContent from './UniBalanceContent'
import usePrevious from '../../hooks/usePrevious'
import { REACT_APP_INFO } from 'utils';
import { useLocation } from 'react-use';
import { REACT_APP_BRIDGE_URL } from '../../constants/index';




const NETWORK_LABELS: { [chainId in ChainId]?: string } = {
  [ChainId.RINKEBY]: 'Rinkeby',
  [ChainId.ROPSTEN]: 'Ropsten',
  [ChainId.GÖRLI]: 'Görli',
  [ChainId.KOVAN]: 'Kovan',
  [ChainId.AITDTESTNET]: 'AIA',
  [ChainId.AITDMAINNET]: 'AIA'
}

export default function Header() {
  const { hash } = useLocation()
  const getLimitRouter = useMemo(() => {
    return (hash || '').indexOf('/limit') > -1
  }, [hash])
  const isInvitation = useMemo(() => {
    return (hash || '').indexOf('/invitation') > -1
  }, [hash])
  const { account, chainId } = useActiveWeb3React()
  const { t, i18n } = useTranslation()
  const i18nAny: any = i18n
  const userEthBalance = useETHBalances(account ? [account] : [])?.[account ?? '']
  // const [isDark] = useDarkModeManager()
  const [darkMode, toggleDarkMode] = useDarkModeManager()

  const toggleClaimModal = useToggleSelfClaimModal()

  const availableClaim: boolean = useUserHasAvailableClaim(account)

  const { claimTxn } = useUserHasSubmittedClaim(account ?? undefined)

  const aggregateBalance: TokenAmount | undefined = useAggregateUniBalance()

  const [showUniBalanceModal, setShowUniBalanceModal] = useState(false)
  const showClaimPopup = useShowClaimPopup()

  const countUpValue = aggregateBalance?.toFixed(0) ?? '0'
  const countUpValuePrevious = usePrevious(countUpValue) ?? '0'
  const toggleWalletModal = useWalletModalToggle()
  const handleEnterInvitation = useCallback(() => {
    if (!account) {
      toggleWalletModal()
    }
  }, [account, toggleWalletModal])

  const menu = (
    <AntMenu>
      {
        [
          {
            key: 'en',
            name: 'English'
          },
          {
            key: 'zh',
            name: '简体中文'
          },
          {
            key: 'tw',
            name: '繁體中文'
          },
          {
            key: 'jp',
            name: '日本語'
          },
          {
            key: 'turkey',
            name: 'Türkçe'
          }
        ].map(i => <MenuItem isActive={i18nAny.language === i.key} onClick={() => {
            i18nAny?.changeLanguage(i.key)
            localStorage.setItem("i18nextLng", i.key)
          }} key={i.key}>{i.name}</MenuItem>)
        }

    </AntMenu>
  );
  const [getVisible, setVisible] = useState(false)
  const linkToBridge = ()=>{
    let lang = '';
    switch(i18nAny.language){
      case 'zh': lang = 'zh-CN';break;
      case 'tw': lang = 'zh-TW';break;
      default: lang = i18nAny.language;
    }
    window.open(REACT_APP_BRIDGE_URL+'/#/?lng='+lang,'__blank');
  }
  return (
    <HeaderFrame>
      <ClaimModal />
      <Modal isOpen={showUniBalanceModal} onDismiss={() => setShowUniBalanceModal(false)}>
        <UniBalanceContent setShowUniBalanceModal={setShowUniBalanceModal} />
      </Modal>
      {
        !isInvitation && (
          <MobileHeaderRowContainer>
            <MobileHeaderRow>
              <MobileHeaderItem>
                <StyledNavLink className={getLimitRouter ? activeClassName : ''} id={`swap-nav-link`} to={'/swap'}>
                  <img width={'28px'} className="hide" src={SwapActiveSvg} alt="swap" />
                  <img width={'28px'} className="show" src={SwapSvg} alt="swap" />
                  <span>{t('swap')}</span>
                </StyledNavLink>
              </MobileHeaderItem>
              <MobileHeaderItem>
                <StyledNavLink id={`pool-nav-link`}
                  to={'/pool'}
                  isActive={(match, { pathname }) => {
                    const matchResult = Boolean(match) ||
                      pathname.startsWith('/add') ||
                      pathname.startsWith('/remove') ||
                      pathname.startsWith('/create') ||
                      pathname.startsWith('/find')
                    return matchResult
                  }
                  }>
                  <img width={'28px'} className="hide" src={CapitalSvg} alt="swap" />
                  <img width={'28px'} className="show" src={CapitalActiveSvg} alt="swap" />
                  <span>{t('pool')}</span>
                </StyledNavLink>
              </MobileHeaderItem>
              <MobileHeaderItem>
                <StyledNavLink id={`stake-nav-link`} to={'/aia'}>
                  <img width={'28px'} className="hide" src={PoolSvg} alt="swap" />
                  <img width={'28px'} className="show" src={PoolActiveSvg} alt="swap" />
                  <span>{t('POOL')}</span>
                </StyledNavLink>
              </MobileHeaderItem>
              <MobileHeaderItem>
                {/* {
                  account ?
                    <StyledNavLink id={`invitation`} to={'/invitation'}>
                      <img width={'28px'} className="hide" src={InvitationActiveSvg} alt="swap" />
                      <img width={'28px'} className="show" src={InvitationSvg} alt="swap" />
                      <span>{t('Mining')}</span>
                    </StyledNavLink> : <StyleNavNoAccount onClick={handleEnterInvitation}>
                      <img width={'28px'} src={InvitationSvg} alt="swap" />
                      <span>{t('Mining')}</span>
                    </StyleNavNoAccount>
                } */}
                {
                  account ?
                    <StyledNavLink id={`invitation`} to={'/bridge'} onClick={(e)=>{
                      e.preventDefault();
                      linkToBridge();
                    }}>
                      <img width={'28px'} className="hide" src={BridgeActiveImg} alt="swap" />
                      <img width={'28px'} className="show" src={BridgeImg} alt="swap" />
                      <span>{t('Bridge')}</span>
                    </StyledNavLink> : <StyleNavNoAccount onClick={handleEnterInvitation}>
                      <img width={'28px'} src={BridgeImg} alt="swap" />
                      {/* <img width={'28px'} src={getCurrentActive === 3 ? InvitationActiveSvg : InvitationSvg} alt="swap" /> */}
                      <span>{t('Bridge')}</span>
                    </StyleNavNoAccount>
                }
              </MobileHeaderItem>
            </MobileHeaderRow>
          </MobileHeaderRowContainer>
        )

      }

      <HeaderRow>
        <Title href="." onClick={(e)=>{
          if(isInvitation){
            e.preventDefault();
          }
          }}>
          <UniIcon>
            {/*<img width={darkMode ? '64px' : '32px'} src={darkMode ? LogoDark : Logo} alt="logo" />*/}
            <img width={ '32px'} src={Logo} alt="logo" />
          </UniIcon>
        </Title>
          {
            !isInvitation && (

            <HeaderLinks>
              <StyledNavLink className={getLimitRouter ? activeClassName : ''} id={`swap-nav-link`} to={'/swap'}>
                {t('swap')}
              </StyledNavLink>
              <StyledNavLink
                id={`pool-nav-link`}
                to={'/pool'}
                isActive={(match, { pathname }) =>
                  Boolean(match) ||
                  pathname.startsWith('/add') ||
                  pathname.startsWith('/remove') ||
                  pathname.startsWith('/create') ||
                  pathname.startsWith('/find')
                }
              >
                {t('pool')}
              </StyledNavLink>
              <StyledNavLink id={`stake-nav-link`} to={'/aia'}>
                POOL
              </StyledNavLink>

              {!account && (
                <div
                  id={`invitation`}
                  onClick={handleEnterInvitation}
                  style={{ color: 'rgba(27,54,87,0.5)', fontWeight: 600, fontSize: '1rem', margin: '0 12px' }}
                >
                  {t('Bridge')}
                </div>
              )}

              {account && (
                <StyledNavLink id={`invitation`} to={'/bridge'} onClick={(e)=>{
                  e.preventDefault();
                  linkToBridge()
                }}>
                  {t('Bridge')}
                </StyledNavLink>
              )}

              {/* <StyledNavLink id={`stake-nav-link`} to={'/vote'}>
                Vote
              </StyledNavLink> */}
            </HeaderLinks>
            )
          }

        <HeaderElementWrap>
          {!isInvitation && (
            <a href={REACT_APP_INFO} target="_blank"><img src={InfoSvg} style={{ marginRight: '15px', transform: 'scale(0.8)' }}  alt={''}/></a>
          )}
          <Dropdown
            visible={getVisible}
            onVisibleChange={(visible: boolean) => setVisible(visible)} overlay={menu} placement="bottomCenter">
            <img onClick={() => setVisible(true)} src={GlobalSvg} className={'imgSvg'} />
          </Dropdown>

          <AccountElement active={!!account} style={{ pointerEvents: 'auto', marginLeft: '15px' }}>
            <Web3Status />
          </AccountElement>
        </HeaderElementWrap>
      </HeaderRow>

      {
        false && <HeaderControls>
          <HeaderElement>
            <HideSmall>
              {chainId && NETWORK_LABELS[chainId || 1320] && (
                <NetworkCard title={NETWORK_LABELS[chainId || 1320]}>{NETWORK_LABELS[chainId || 1320]}</NetworkCard>
              )}
            </HideSmall>

            {false && (availableClaim && !showClaimPopup && (
              <UNIWrapper onClick={toggleClaimModal}>
                <UNIAmount active={!!account && !availableClaim} style={{ pointerEvents: 'auto' }}>
                  <TYPE.white padding="0 2px">
                    {claimTxn && !claimTxn?.receipt ? <Dots>{t('Claiming')} AIA</Dots> : `${t('Claim')} AIA`}
                  </TYPE.white>
                </UNIAmount>
                <CardNoise />
              </UNIWrapper>
            ))}

            {!availableClaim && aggregateBalance && (
              <UNIWrapper onClick={() => setShowUniBalanceModal(true)}>
                {false && <UNIAmount active={!!account && !availableClaim} style={{ pointerEvents: 'auto' }}>
                  {account && (
                    <HideSmall>
                      <TYPE.white
                        style={{
                          paddingRight: '.4rem'
                        }}
                      >
                        <CountUp
                          key={countUpValue}
                          isCounting
                          start={parseFloat(countUpValuePrevious)}
                          end={parseFloat(countUpValue)}
                          thousandsSeparator={','}
                          duration={1}
                        />
                      </TYPE.white>
                    </HideSmall>
                  )}
                  AIA
                </UNIAmount>}
                <CardNoise />
              </UNIWrapper>
            )}
            <AccountElement active={!!account} style={{ pointerEvents: 'auto' }}>
              {account && userEthBalance ? (
                <BalanceText style={{ flexShrink: 0 }} pl="0.75rem" pr="0.5rem" fontWeight={500}>
                  {userEthBalance?.toSignificant(4)} AIA
                </BalanceText>
              ) : null}
              <Web3Status />
            </AccountElement>
          </HeaderElement>
          {
            false && <HeaderElementWrap>
              <StyledMenuButton onClick={() => toggleDarkMode()}>
                {darkMode ? <Moon size={20} /> : <Sun size={20} />}
              </StyledMenuButton>
              <Menu />
            </HeaderElementWrap>
          }
        </HeaderControls>
      }
    </HeaderFrame >
  )
}
const HeaderFrame = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  width: 100%;
  top: 0;
  position: relative;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding: 1rem;
  z-index: 2;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    grid-template-columns: 1fr;
    padding: 0.5rem 1rem;
    width: calc(100%);
    position: relative;
  `};

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
        padding: 0.5rem 1rem;
        
  `}
  background-color: ${({ theme }) => theme.white};

`

const HeaderControls = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-self: flex-end;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    flex-direction: row;
    justify-content: space-between;
    justify-self: center;
    width: 100%;
    max-width: 960px;
    padding: 1rem;
    position: fixed;
    bottom: 0px;
    left: 0px;
    width: 100%;
    z-index: 99;
    height: 72px;
    border-radius: 12px 12px 0 0;
    background-color: ${({ theme }) => theme.bg1};
  `};
`

const HeaderElement = styled.div`
  display: flex;
  align-items: center;

  /* addresses safari's lack of support for "gap" */
  & > *:not(:first-child) {
    margin-left: 8px;
  }

  ${({ theme }) => theme.mediaWidth.upToMedium`
   flex-direction: row-reverse;
    align-items: center;
  `};
`

const HeaderElementWrap = styled.div`
  display: flex;
  align-items: center;
  .imgSvg{
      width: 24px;
      height:24px;
      cursor: pointer;
  }
`

const HeaderRow = styled(RowFixed)`
  justify-content: space-around;
  width: 100%;
  ${({ theme }) => theme.mediaWidth.upToMedium`
   
   display: flex;
   justify-content: space-between;
  `};
  
`

const HeaderLinks = styled(Row)`
  justify-content: center;
 & > a {
   margin: 0 40px;
 }
  ${({ theme }) => theme.mediaWidth.upToMedium`
    display: none;
    padding: 1rem 0 1rem 1rem;
    justify-content: flex-end;
`};
`

const AccountElement = styled.div<{ active: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${({ theme, active }) => (!active ? theme.bg1 : theme.bg3)};
  border-radius: 12px;
  white-space: nowrap;
  width: auto;
  cursor: pointer;
  :focus {
    border: 1px solid blue;
  }
  ${({ theme }) => theme.mediaWidth.upToMedium`
    justify-content: flex-end;
`};
`

const UNIAmount = styled(AccountElement)`
  color: white;
  padding: 4px 8px;
  height: 36px;
  font-weight: 500;
  background-color: ${({ theme }) => theme.bg3};
  background: radial-gradient(174.47% 188.91% at 1.84% 0%, #814ed9 0%, #2172e5 100%), #edeef2;
`

const UNIWrapper = styled.span`
  width: fit-content;
  position: relative;
  cursor: pointer;

  :hover {
    opacity: 0.8;
  }

  :active {
    opacity: 0.9;
  }
`

const HideSmall = styled.span`
  ${({ theme }) => theme.mediaWidth.upToSmall`
    display: none;
  `};
`

const NetworkCard = styled(YellowCard)`
  border-radius: 12px;
  padding: 8px 12px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin: 0;
    margin-right: 0.5rem;
    width: initial;
    overflow: hidden;
    text-overflow: ellipsis;
    flex-shrink: 1;
  `};
`

const BalanceText = styled(Text)`
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    display: none;
  `};
`

const Title = styled.a`
  display: flex;
  align-items: center;
  pointer-events: auto;
  justify-self: flex-start;
  margin-right: 12px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    justify-self: center;
  `};
  :hover {
    cursor: pointer;
  }
`

const UniIcon = styled.div`
  transition: transform 0.3s ease;
  :hover {
    transform: rotate(-5deg);
  }
`

const activeClassName = 'ACTIVE'

const MobileHeaderRow = styled.div`
    display: flex;
    max-width: 960px;
    padding: 0.5rem 0;
    width: 100%;
    z-index: 99;
    // height: 72px;
    border-radius: 12px;
    background-color: #FFFFFF;
    justify-content: space-around;
    box-shadow: 0px 5px 2px 1px rgba(14, 37, 74, 0.1);
`


const MobileHeaderRowContainer = styled.div`
  display: none;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    display: block;
    padding: 0.5rem 1rem;
    max-width: 960px;
    position: fixed;
    bottom: 0.5rem;
    left: 0px;
    width: 100%;
    z-index: 99;
  `}
  ${({ theme }) => theme.mediaWidth.upToMedium`
    display: block;
`}
`

const MenuItem = styled.li<{ isActive: boolean }>`
    text-align: center;
    padding: 5px 0;
    font-weight: 600;
    color:${({ theme, isActive = false }) => isActive ? theme.text13 : 'rgba(27,54,87,0.5)'} ;
    cursor: pointer;
    min-width: 100px;
    &:hover{
      color: ${({ theme }) => theme.text13};
    }
`
const MobileHeaderItem = styled.div`
  & > a,div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    & > span {
      font-size: 14px;
      font-family: PingFang SC-Bold, PingFang SC;
      font-weight: bold;
      margin-top:0.1rem;
      color:#333333;
    }
  }
`

const StyledNavLink = styled(NavLink).attrs({
  activeClassName
})`
  ${({ theme }) => theme.flexRowNoWrap}
  align-items: left;
  border-radius: 3rem;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  color: rgba(27,54,87,0.5);
  font-size: 1rem;
  width: fit-content;
  margin: 0 12px;
  font-weight: 600;
  .hide{
    display: none;
  }
  .show{
    display: block;
  }
  &.${activeClassName} {
 
    border-radius: 12px;
    font-weight: 600;
    color: ${({ theme }) => theme.text12} !important;

    .hide{
    display:  block;
    }
    .show{
      display: none;
    }
    position: relative;
    & > span{
      color: ${({ theme }) => theme.text8};
      opacity: 1;
    }
    &::after{
      content: '';
      position: absolute;
      bottom:  calc(-1.1rem - 4px);
      height: 2px;
      width:25px;
      background-color:  ${({ theme }) => theme.text8};
      left:50%;
      transform: translateX(-50%);
      border-radius: 1px;
    }
    ${({ theme }) => theme.mediaWidth.upToMedium`
      &::after{
      content: '';
      position: absolute;
      height: 0;
      width:0;
    }
    `}

  }

  :hover,
  :focus {
    color: ${({ theme }) => darken(0.1, theme.text1)};
  }
  @media (max-width: 500px) {
    /* font-size: 12px; */
    margin: 0 5px;
  }
  & > span{
    opacity: 0.5;
  }
`

const StyleNavNoAccount = styled.div<{ isActived?: boolean }>`
  cursor: pointer;
  font-weight: 500;
    :hover,
    :focus {
      color: ${({ theme }) => darken(0.1, theme.text1)};
    }
    @media (max-width: 500px) {
      /* font-size: 12px; */
      margin: 0 5px;
    }
    & > span{
    color: ${({ theme }) => theme.text8};
    opacity: 0.5;
  }
`

export const StyledMenuButton = styled.button`
  position: relative;
  width: 100%;
  height: 100%;
  border: none;
  background-color: transparent;
  margin: 0;
  padding: 0;
  height: 35px;
  background-color: ${({ theme }) => theme.bg3};
  margin-left: 8px;
  padding: 0.15rem 0.5rem;
  border-radius: 0.5rem;

  :hover,
  :focus {
    cursor: pointer;
    outline: none;
    background-color: ${({ theme }) => theme.bg4};
  }

  svg {
    margin-top: 2px;
  }
  > * {
    stroke: ${({ theme }) => theme.text1};
  }
`