import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-use'
import { AppDispatch, AppState } from 'state'
import { updatePoolResults } from './actions'

export function useEarnState(): AppState['earn'] {
    return useSelector<AppState, AppState['earn']>(state => state.earn)
}
export function useUpdatePoolResults(): any {
    const dispatch = useDispatch<AppDispatch>()
    const { hash } = useLocation()
    const onUpdatePoolResults = useCallback(
        () => {
            if (hash && hash.indexOf('/aia') > -1) {
                setTimeout(() => {
                    dispatch(updatePoolResults({ numberAuto: 1 }))
                }, 1500)
            }
        },
        [dispatch]
    )
    return { onUpdatePoolResults }
}
