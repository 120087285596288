import i18next from 'i18next'
import {initReactI18next} from 'react-i18next'
import translation_zh from './locales/zh-CN.json';
import translation_tw from './locales/zh-TW.json';
import translation_en from './locales/en.json';
import translation_jp from './locales/jp.json';
import translation_turkey from './locales/turkey.json';


import XHR from 'i18next-xhr-backend'
// import LanguageDetector from 'i18next-browser-languagedetector'


const getPageQuery = () => {
  const lng = window.location.href.split('?lng=')[1]
  let setLng;
  const currentLng = ['en', 'zh-CN', 'jp', 'zh-TW', 'CN', 'TW', 'zh','tr','turkey']
  const isInClude = (str: any, type: any) => {
    return (str || '').indexOf(type) > -1
  }
  const getCurrentLng = (langs: string | null | undefined) => {
    let lngsFunVal;
// const isInClude = (str:any,type:any)=>{
//         return (str || '').indexOf(type) > -1
//       }
    currentLng.some((i: string) => {
      i = i.toLocaleLowerCase()
      langs = (langs || '').toLocaleLowerCase()

      if ((langs || '').indexOf(i) > -1) {
        const zhCN = 'zh'
        if (langs === 'cn' || isInClude(langs, 'cn')) {
          lngsFunVal = zhCN
        } else if (langs === 'tw' || isInClude(langs, 'tw')) {
          lngsFunVal = 'tw'
        } else {
          switch (langs) {
            case 'zh-cn':
              i = 'zh'
              break;
            case 'zh-CN':
              i = 'zh'
              break;
            case 'zh-tw':
              i = 'tw'
              break;
            case 'zh-TW':
              i = 'tw'
              break;
            default:
              i = i;
          }
          lngsFunVal = i
        }

        return true
      }
      return false
    })
    return lngsFunVal || null
  }
  if (!!lng) {
    setLng = getCurrentLng(lng)
  } else {
    const getKey = localStorage.getItem("i18nextLng")
    if (!!getKey) {
      setLng = getCurrentLng(getKey)
    } else {
      setLng = getCurrentLng(navigator.language)
    }
  }
  const alllngs = ['en', 'zh', 'tw', 'jp','tr','turkey']
  const resultLng = setLng || 'en'
  const lstResultLng = alllngs.includes(resultLng) ? resultLng : 'en'
  localStorage.setItem("i18nextLng", lstResultLng)
  return lstResultLng
};
const getLng = getPageQuery()

const resources = {
  en: {
    translation: translation_en
  },
  zh: {
    translation: translation_zh
  },
  tw: {
    translation: translation_tw
  },
  jp: {
    translation: translation_jp
  },
  tr: {
    translation: translation_turkey
  },
  turkey:{
    translation: translation_turkey
  }
};

i18next
  .use(XHR)
  // .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    lng: getLng,
    // backend: {
    //   loadPath: `./locales/{{lng}}.json`
    // },
    react: {
      useSuspense: true
    },
    fallbackLng: 'en',
    preload: ['en'],
    keySeparator: false,
    interpolation: {escapeValue: false}
  })

export default i18next
