/**
 * @name: cardCancellationPage
 * @author: mahuanhuan
 * @date: 2024/1/9
 * @description：核销卡片页面
 * @update: 2024/1/9
 */
import React, {useEffect, useMemo, useState} from 'react';
import {OrderTypeEnum, RoleEnum, SendStateEnum} from "./types";
import {AntdList, CardCancellationPageDiv, CardInfo, CardLabel, InputInfo, ParagraphInfo} from "./styleds";
import {useTranslation} from "react-i18next";
import {TextButton1} from "../../components/invitation/styled";
import VerifyVoucherCode from "./components/verifyVoucherCode";
import {getCardOne, getMyOrder} from "../../services/card";
import {PageSize} from "../../constants";
import {useActiveWeb3React} from "../../hooks";
import Empty from "../../components/Empty";
import {Avatar, Dropdown, Input, List, Menu, Space} from "antd";
import Card2Svg from "../../assets/svg/card2.svg";
import Card1Svg from "../../assets/svg/card1.svg";
import CardCancellation from '../../assets/images/CardCancellation.png';
import {stringLengthChange} from "../../utils";
import {minus} from "../../utils/bigNumber";
import {CaretDownOutlined} from '@ant-design/icons';
import SearchClose from "../../assets/svg/searchClose.svg";

const CardCancellationPage = ({role}: { role: RoleEnum }) => {
  const [open, setOpen] = useState(false);
  const {account} = useActiveWeb3React()
  const {t, i18n} = useTranslation();
  const i18nAny: any = i18n
  const [giftCardOrderListAll, setGiftCardOrderListAll] = useState<Record<string, any>[]>([]);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [inputValue, setValue] = useState<string>('')
  const [selected, setSelected] = useState<string>('')
  const [cardNumInfo, setCardNumInfo] = useState<Record<string, any>>({})
  const [dropdownName, setDropdownName] = useState('CardStatus');
  const [isMenuVisible, setIsMenuVisible] = useState(false);

  const selectOptions = [
    {
      key: '',
      label: 'all',
    },
    {
      key: String(SendStateEnum.cardNotIssued),
      label: 'cardNotIssued',
    },
    {
      key: String(SendStateEnum.cardHasBeenIssued),
      label: 'cardHasBeenIssued',
    },
    {
      key: String(SendStateEnum.noCardIssued),
      label: 'noCardIssued',
    },
    {
      key: String(SendStateEnum.partiallyIssuedCards),
      label: 'partialHairClip',
    }
  ]

  useEffect(() => {
    const handleScroll = () => {
      const {scrollTop, clientHeight, scrollHeight} = document.documentElement;
      if (scrollTop + clientHeight === scrollHeight) {
        if (PageSize * page < total) {
          getGiftCardOrder(+page + 1);
        }
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [page, total]);

  // 获取卡数量信息
  const getCardNumInfo = async () => {
    const res = await getCardOne({
      powerAddr: account
    })
    setCardNumInfo(res)
  }

  const getData = () => {
    getCardNumInfo().then();
    getGiftCardOrder().then();
  }

  useEffect(() => {
    getData()
  }, [])

  // 核销成功后的操作
  const onSucHandle = () => {
    getData()
  }

  const getGiftCardOrder = async (page: number = 1, sendState: string = '', address: string = '') => {
    setPage(page);
    setSelected(sendState);
    setValue(address);
    if (page == 1) {
      setGiftCardOrderListAll([]);
    }
    const res = await getMyOrder({
      pageNo: page,
      pageSize: PageSize,
      orderType: role === RoleEnum.cardCollectionAgent ? OrderTypeEnum.cardCollectionAgent : OrderTypeEnum.cardIssuingCenter,
      sendState: sendState ? sendState : null,
      address: address ? address : null,
    })
    const giftCardOrder: Record<string, any>[] = res.records;
    res.total > 0 && setTotal(res.total);
    giftCardOrder.forEach((item) => {
      item.isShowAddress = false;
    })
    setGiftCardOrderListAll((pre) => {
      return [...pre, ...giftCardOrder]
    })
  }

  const switchSvg = (type: number) => {
    switch (type) {
      case OrderTypeEnum.ordinary:
        return Card2Svg;
      default:
        return Card1Svg;
    }
  }

  const switchSendState = (sendState: number) => {
    switch (sendState) {
      case SendStateEnum.cardNotIssued:
        return t('cardNotIssued');
      case SendStateEnum.cardHasBeenIssued:
        return t('cardHasBeenIssued');
      case SendStateEnum.noCardIssued:
        return t('noCardIssued');
      case SendStateEnum.partiallyIssuedCards:
        return t('partialHairClip');
    }
  }

  const clickHandle = async (info: Record<string, any>) => {
    setGiftCardOrderListAll((pre) => {
      return pre.map((item) => {
        if (item.id === info.id) {
          return {
            ...item,
            isShowAddress: !item.isShowAddress,
          };
        }
        return item;
      });
    });
  }

  const language = useMemo(() => {
    return i18nAny.language == 'turkey' ? 'tr' : i18nAny.language;
  }, [i18nAny.language])


  const cardsHeld = useMemo(() => {
    if (cardNumInfo?.alreReceivedNum > 0) {
      return minus(+cardNumInfo?.alreReceivedNum, +cardNumInfo?.sendCardNum) ?? 0
    } else {
      return 0
    }
  }, [cardNumInfo])

  const handleMenuClick = (key: any) => {
    getGiftCardOrder(1, key, inputValue)
    const options = selectOptions.find((item) => item.key == key)
    options?.label && setDropdownName(options.label);
    setIsMenuVisible(false);
  }

  const searchHandle = () => {
    getGiftCardOrder(1, selected, inputValue)
  }


  const menu = (
    <Menu onClick={({key}) => handleMenuClick(key)}>
      {selectOptions.map((option) => (
        <Menu.Item key={option.key}>{t(option.label)}</Menu.Item>
      ))}
    </Menu>
  );

  return (
    <CardCancellationPageDiv>
      <div className='title'>
        <div className={`titleInfo ${cardNumInfo?.state == 1 ? 'titleInfoGray' : ''}`}>
          <img src={CardCancellation} alt={'miningBonusCard'}/>
          {
            cardNumInfo?.state == 0 && <span>{role === RoleEnum.cardCollectionAgent ? t('cardCollectionAgentName', {
              bank: cardNumInfo?.parentNameAddress?.[language]?.name,
              name: cardNumInfo?.nameAddress?.[language]?.name
            }) : t('youHaveBecome', {bank: cardNumInfo?.nameAddress?.[language]?.name})}</span>
          }
          {
            cardNumInfo?.state == 1 &&
            <span>{role === RoleEnum.cardCollectionAgent ? t('cardCollectionAgentNameDisabled', {
              bank: cardNumInfo?.parentNameAddress?.[language]?.name,
              name: cardNumInfo?.nameAddress?.[language]?.name
            }) : t('youHaveBecomeDisabled', {bank: cardNumInfo?.nameAddress?.[language]?.name})}</span>
          }
        </div>
        <div className='cardNum'>
          <div className='total cardNumList'>
             <span>
               <div>{t('totalNumberGiftCardsReceived')}</div>
               <div className='num'>{cardNumInfo?.cardSum ?? 0}</div>
             </span>
            <span>
               <div>{t('cumulativeNumberIssued')}</div>
               <div className='num'>{cardNumInfo?.shouldSum ?? 0}</div>
             </span>
          </div>
          <div className='cardNumList'>
            <span>{t('received')}：{cardNumInfo?.alreReceivedNum ?? 0} {' '} {t('unclaimedCard')}：{cardNumInfo?.unReceivedNum ?? 0}</span>
            <span>{t('cardHasBeenIssued')}：{cardNumInfo?.sendCardNum ?? 0} {' '} {t('cardNotIssued')}：{cardNumInfo?.unCardNum ?? 0}</span>
          </div>
          <div className='writeOffButton'>
            {/*className={transactionStatus || !Number(ethBalance) ? 'disabled' : ''}*/}
            <TextButton1
              onClick={() => {
                setOpen(true)
              }}
              style={{
                background: '#2750EB',
                color: '#ffffff',
                // marginRight: '10px',
                minWidth: '45%',
                textAlign: 'center',
                backgroundColor: '#2750EB'
              }}
            >
              {t('Write-offAgentVoucherCode')}
            </TextButton1>
          </div>
          <div
            className='cardsCurrently'>{t('cardsCurrently')}：{cardsHeld}</div>
        </div>
      </div>
      <VerifyVoucherCode open={open} setOpen={setOpen} onSucHandle={onSucHandle}
                         role={role}></VerifyVoucherCode>

      <div className='orderListTitle' style={{marginTop: '20px', fontSize: '14px', color: '#333', fontWeight: 'bold'}}>{t('lowerOrder')}</div>

      <div className='selectionInfo'>
        <InputInfo>
          <Input placeholder={t('searchAddress')} value={inputValue} onChange={(e) => {
            setValue(e.target.value);
          }}/>
          {
            inputValue && <img className='SearchClose' onClick={()=>{
              setValue('')
              getGiftCardOrder(1, selected, '')
            }} src={SearchClose} alt="SearchClose"/>
          }
          <span className='search' onClick={searchHandle}>{t('Search for')}</span>
        </InputInfo>
        <div className='select'>
          <Dropdown
            visible={isMenuVisible}
            overlay={menu}
            onVisibleChange={setIsMenuVisible}
          >
            <a onClick={(e) =>{
              e.preventDefault()
              setIsMenuVisible(!isMenuVisible);
            }}>
              <Space style={{textAlign: "right"}}>
                {t(dropdownName)}
                <CaretDownOutlined/>
              </Space>
            </a>
          </Dropdown>
        </div>
      </div>

      {
        giftCardOrderListAll.length == 0 ?
          <Empty title={t('noContent')}></Empty>
          : (<AntdList
            dataSource={giftCardOrderListAll}
            renderItem={(item: any) => (
              <List.Item
                key={item.id}
              >
                <List.Item.Meta
                  avatar={<Avatar src={switchSvg(item.orderType)}/>}
                  title={`${t('ReceiveMultipleBankCards')}x${item.cardNum}`}
                  description={item.createTime}
                >
                </List.Item.Meta>
                <div className={`OrderStata`}>
                  <div> {switchSendState(item.sendState)}</div>
                  <div
                    className='alreReceivedNum'>{item.alreReceivedNum > 0 ? `${t('cancelledCard')} ${item.alreReceivedNum}` : ''}</div>
                </div>
                <CardInfo>
                  <div className='cardInfoList'>
                    <CardLabel>{t('orderReference')}</CardLabel>
                    <ParagraphInfo copyable={{
                      tooltips: [t('Copy'), t('Copy Successfully')]
                    }} style={{marginBottom: 0}}>{item.orderId}</ParagraphInfo>
                  </div>
                  <div className='cardInfoList'>
                    <CardLabel>{role == RoleEnum.cardIssuingCenter ? t('CaptainAddress') : t('userAddress')}</CardLabel>
                    <ParagraphInfo copyable={{
                      tooltips: [t('Copy'), t('Copy Successfully')],
                      text: item.address
                    }} style={{marginBottom: 0}}>{stringLengthChange(item.address)}</ParagraphInfo>
                  </div>
                  {
                    role == RoleEnum.cardIssuingCenter && <div className='cardInfoList'>
                      <CardLabel>{t('CaptainName')}</CardLabel>
                      <span>{item.pledgeNum}</span>
                    </div>
                  }

                  <div className='cardInfoList'>
                    <CardLabel>{t('HarvestInformation')}</CardLabel>
                    <span className='clickToView' onClick={() => {
                      clickHandle(item)
                    }}>{item.isShowAddress ? t('packUp') : t('clickToView')}</span>
                  </div>
                  {
                    item.isShowAddress && (
                      <div className='addressInfo'>
                        <span>{item?.shippingAddress?.name}</span>
                        <span>{item?.shippingAddress?.phone}</span>
                        <span>{item?.shippingAddress?.area}{item?.shippingAddress?.address}</span>
                      </div>
                    )
                  }

                  {
                    item.sendState == SendStateEnum.noCardIssued && (
                      <div className='cardInfoList'>
                        <CardLabel>{t('noCardIssued')}</CardLabel>
                        <span>{item.desc[language]}</span>
                      </div>
                    )
                  }


                  {
                    (item.sendState == SendStateEnum.cardHasBeenIssued || item.sendState == SendStateEnum.partiallyIssuedCards) &&
                    <div className='cardInfoList'>
                      <CardLabel>{t('Write-offTime')}</CardLabel>
                      <span>{item.destroyDate}</span>
                    </div>
                  }

                </CardInfo>
              </List.Item>
            )}
          >
          </AntdList>)
      }
    </CardCancellationPageDiv>
  )
}

export default CardCancellationPage;