import { createAction } from '@reduxjs/toolkit'
import { Field, Rate, OrderState } from './types'


export const replaceLimitOrdersState = createAction<OrderState>('limitOrders/replaceLimitOrdersState')
export const selectCurrency = createAction<{
    field: Field
    currencyId: string
  }>('limitOrders/selectCurrency')


export const switchCurrencies = createAction<void>('limitOrders/switchCurrencies')
export const typeInput = createAction<{ field: Field; typedValue: string }>('limitOrders/typeInput')

export const replaceSwapState = createAction<{
  field: Field
  typedValue: string
  inputCurrencyId?: string
  outputCurrencyId?: string
  recipient: string | null,
  rateType:Rate
  orderList:any
  historyTotalData:any
}>('limitOrders/replaceSwapState')

export const setRecipient = createAction<{ recipient: string | null }>('limitOrders/setRecipient')
export const setRateType = createAction<{ rateType: Rate }>('limitOrders/setRateType')

export const setOrderList = createAction<{ orderList: any }>('limitOrders/setOrderList')
export const setCancel = createAction<{ cancel: any }>('limitOrders/setCancel')

export const setTotalData = createAction<{ totalOrderList: any }>('limitOrders/setTotalData')

export const setHistoryTotalData = createAction<{ historyTotalData: any }>('limitOrders/setHistoryTotalData')

