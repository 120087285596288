/**
 * @name: card
 * @author: mahuanhuan
 * @date: 2024/1/19
 * @description：赠卡相关接口
 * @update: 2024/1/19
 */
import request from './request';

// 订单数据(必传userAddr,选填orderType)
export async function getMyOrder(body = {}) {
  return request('POST', '/cardCore/getMyOrder', {
    body,
  });
}

// 发卡中心-领卡队长-分页
export async function getCardPage(body = {}) {
  return request('POST', '/cardCore/getCardPage', {
    body,
  });
}

// 发卡中心-领卡队长-单个()
export async function getCardOne(body = {}) {
  return request('POST', '/cardCore/getCardOne', {
    body,
  });
}

// 根据用户查询卡授权地址(必传userAddr)
export async function getCardAddrByUser(body = {}) {
  return request('POST', '/cardCore/getCardAddrByUser', {
    body,
  });
}


// 根据用户地址查询收货地址(必传userAddr)
export async function getAddrByUser(body = {}) {
  return request('POST', '/cardCore/getAddrByUser', {
    body,
  });
}

// 核销(必传id,qr,address)
export async function chargeOff(body = {}) {
  return request('POST', '/cardCore/chargeOff', {
    body,
  });
}

// 新增-修改-卡授权地址
export async function cardAddrSaveOrUpdate(body = {}) {
  return request('POST', '/cardCore/cardAddrSaveOrUpdate', {
    body,
  });
}

// 新增-修改-收货地址
export async function addrSaveOrUpdate(body = {}) {
  return request('POST', '/cardCore/addrSaveOrUpdate', {
    body,
  });
}

// 修改订单领卡队长(订单id,getCardId-领卡队长id)
export async function updateOrder(body = {}) {
  return request('POST', '/cardCore/updateOrder', {
    body,
  });
}

// 登录
export async function login(body = {}) {
  return request('POST', '/user/login', {
    body,
  });
}

// 计算核销卡数,不核销(必传qr,address)
export async function chargeOffBefore(body = {}) {
  return request('POST', '/cardCore/chargeOffBefore', {
    body,
  });
}