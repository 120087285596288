import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { updateNameData } from "./data";
import { client } from "./initApollo";
import { ETH_PRICE, PAIRS_BULK } from "./query";
import { getBlockFromTimestamp, getBlocksFromTimestamps, getTimestampsForChanges } from "./utils";

// tokens that should be tracked but arent due to lag in subgraph
const TRACKED_OVERRIDES = [
    '0x9928e4046d7c6513326ccea028cd3e7a91c7590a',
    '0x87da823b6fc8eb8575a235a824690fda94674c88',
    '0xcd7989894bc033581532d2cd88da5db0a4b12859',
    '0xe1573b9d29e2183b1af0e743dc2754979a40d237',
    '0x45804880de22913dafe09f4980848ece6ecbaf78',
    '0x709f7b10f22eb62b05913b59b92ddd372d4e2152',
]


export function useEthPrice() {

    const [ethPrice, setEthPrice] = useState<any>(null)
    // const [state, { updateEthPrice }] = useGlobalDataContext()

    useEffect(() => {
        async function checkForEthPrice() {
            if (!ethPrice) {
                let [newPrice] = await getEthPrice()
                setEthPrice(newPrice)
                //   updateEthPrice(newPrice, oneDayPrice, priceChange)
            }
        }
        checkForEthPrice()
    }, [ethPrice
        // , updateEthPrice
    ])

    return [ethPrice]
}


/**
* Gets the current price  of ETH, 24 hour price, and % change between them
*/
const getEthPrice = async () => {
    const utcCurrentTime = dayjs()
    const utcOneDayBack = utcCurrentTime.subtract(1, 'day').startOf('minute').unix()

    let ethPrice = 0
    let ethPriceOneDay = 0
    let priceChangeETH = 0

    try {
        let oneDayBlock = await getBlockFromTimestamp(utcOneDayBack)
        let result = await client.query({
            query: ETH_PRICE(),
            fetchPolicy: 'cache-first',
        })
        let resultOneDay = await client.query({
            query: ETH_PRICE(oneDayBlock),
            fetchPolicy: 'cache-first',
        })
        const currentPrice = result?.data?.bundles[0]?.ethPrice
        const oneDayBackPrice = resultOneDay?.data?.bundles[0]?.ethPrice
        priceChangeETH = getPercentChange(currentPrice, oneDayBackPrice)
        ethPrice = currentPrice
        ethPriceOneDay = oneDayBackPrice
    } catch (e) {
        console.log(e)
    }

    return [ethPrice, ethPriceOneDay, priceChangeETH]
}

/**
* get standard percent change between two values
* @param {*} valueNow
* @param {*} value24HoursAgo
*/
export const getPercentChange = (valueNow?: any, value24HoursAgo?: any) => {
    const adjustedPercentChange =
        ((parseFloat(valueNow) - parseFloat(value24HoursAgo)) / parseFloat(value24HoursAgo)) * 100
    if (isNaN(adjustedPercentChange) || !isFinite(adjustedPercentChange)) {
        return 0
    }
    return adjustedPercentChange
}
export const getBulkPairData = async (pairList:any, ethPrice?:any)=> {
    const [t1, t2, tWeek] = getTimestampsForChanges()
    const result = await getBlocksFromTimestamps([t1, t2, tWeek])
    if (!result.length) {
        return []
    }
    let [{ number: b1 }] = result
    try {
        let current = await client.query({
            query: PAIRS_BULK,
            variables: {
                allPairs: pairList,
            },
            fetchPolicy: 'cache-first',
        })
       
        
        // let [oneDayResult] = await Promise.all(
        //     [b1].map(async (block) => {
        //         let result = client.query({
        //             query: PAIRS_HISTORICAL_BULK(block, pairList),
        //             fetchPolicy: 'cache-first',
        //         })
        //         return result
        //     })
        // )

        // let oneDayData = oneDayResult?.data?.pairs.reduce((obj:any, cur:any, i:any) => {
        //     return { ...obj, [cur.id]: cur }
        // }, {})

        // let twoDayData = twoDayResult?.data?.pairs.reduce((obj:any, cur:any, i:any) => {
        //     return { ...obj, [cur.id]: cur }
        // }, {})

        // let oneWeekData = oneWeekResult?.data?.pairs.reduce((obj:any, cur:any, i:any) => {
        //     return { ...obj, [cur.id]: cur }
        // }, {})
        
        let pairData = await Promise.all(
            current &&
            current.data.pairs.map(async (pair:any) => {
                let data = pair
                // let oneDayHistory = oneDayData?.[pair.id]
                // if (!oneDayHistory) {
                //     let newData = await client.query({
                //         query: PAIR_DATA(pair.id, b1),
                //         fetchPolicy: 'cache-first',
                //     })
                //     oneDayHistory = newData.data.pairs[0]
                // }
                // console.log(oneDayHistory);
                // let twoDayHistory = twoDayData?.[pair.id]
                // if (!twoDayHistory) {
                //     let newData = await client.query({
                //         query: PAIR_DATA(pair.id, b2),
                //         fetchPolicy: 'cache-first',
                //     })
                //     twoDayHistory = newData.data.pairs[0]
                // }
                // let oneWeekHistory = oneWeekData?.[pair.id]
                // if (!oneWeekHistory) {
                //     let newData = await client.query({
                //         query: PAIR_DATA(pair.id, bWeek),
                //         fetchPolicy: 'cache-first',
                //     })
                //     oneWeekHistory = newData.data.pairs[0]
                // }
                data = parseData(data, undefined, undefined, undefined, undefined, b1)
                return data
            })
        )
        return pairData
    } catch (e) {
        console.log(e)
        return null
    }
}
function parseData(
    data?:any, 
    oneDayData?:any, 
    twoDayData?:any,
     oneWeekData?:any, 
     ethPrice?:any, 
     oneDayBlock?:any
    ) {
    const pairAddress = data.id
  
    // get volume changes
    const [oneDayVolumeUSD, volumeChangeUSD] = get2DayPercentChange(
      data?.volumeUSD,
      oneDayData?.volumeUSD ? oneDayData.volumeUSD : 0,
      twoDayData?.volumeUSD ? twoDayData.volumeUSD : 0
    )
    const [oneDayVolumeUntracked, volumeChangeUntracked] = get2DayPercentChange(
      data?.untrackedVolumeUSD,
      oneDayData?.untrackedVolumeUSD ? parseFloat(oneDayData?.untrackedVolumeUSD) : 0,
      twoDayData?.untrackedVolumeUSD ? twoDayData?.untrackedVolumeUSD : 0
    )
  
    const oneWeekVolumeUSD = parseFloat(oneWeekData ? data?.volumeUSD - oneWeekData?.volumeUSD : data.volumeUSD)
  
    const oneWeekVolumeUntracked = parseFloat(
      oneWeekData ? data?.untrackedVolumeUSD - oneWeekData?.untrackedVolumeUSD : data.untrackedVolumeUSD
    )
  
    // set volume properties
    data.oneDayVolumeUSD = parseFloat(oneDayVolumeUSD.toString())
    data.oneWeekVolumeUSD = oneWeekVolumeUSD
    data.volumeChangeUSD = volumeChangeUSD
    data.oneDayVolumeUntracked = oneDayVolumeUntracked
    data.oneWeekVolumeUntracked = oneWeekVolumeUntracked
    data.volumeChangeUntracked = volumeChangeUntracked
  
    // set liquidity properties
    data.trackedReserveUSD = data.trackedReserveETH * ethPrice
    data.liquidityChangeUSD = getPercentChange(data.reserveUSD, oneDayData?.reserveUSD)
  
    // format if pair hasnt existed for a day or a week
    if (!oneDayData && data && data.createdAtBlockNumber > oneDayBlock) {
      data.oneDayVolumeUSD = parseFloat(data.volumeUSD)
    }
    if (!oneDayData && data) {
      data.oneDayVolumeUSD = parseFloat(data.volumeUSD)
    }
    if (!oneWeekData && data) {
      data.oneWeekVolumeUSD = parseFloat(data.volumeUSD)
    }
  
    if (TRACKED_OVERRIDES.includes(pairAddress)) {
   
        
      data.oneDayVolumeUSD = oneDayVolumeUntracked
      data.oneWeekVolumeUSD = oneWeekVolumeUntracked
      data.volumeChangeUSD = volumeChangeUntracked
      data.trackedReserveUSD = data.reserveUSD
    }
  
    // format incorrect names
    updateNameData(data)
  
    return data
  }

  /**
 * gets the amoutn difference plus the % change in change itself (second order change)
 * @param {*} valueNow
 * @param {*} value24HoursAgo
 * @param {*} value48HoursAgo
 */
export const get2DayPercentChange = (
    valueNow?:any, 
    value24HoursAgo?:any,
     value48HoursAgo?:any) => {
    // get volume info for both 24 hour periods
    let currentChange = parseFloat(valueNow) - parseFloat(value24HoursAgo)
    let previousChange = parseFloat(value24HoursAgo) - parseFloat(value48HoursAgo)
  
    const adjustedPercentChange = (parseFloat((currentChange - previousChange).toString()) / parseFloat(previousChange.toString())) * 100
  
    if (isNaN(adjustedPercentChange) || !isFinite(adjustedPercentChange)) {
      return [currentChange, 0]
    }
    return [currentChange, adjustedPercentChange]
  }