import React, { useContext } from 'react'
import { Currency } from '@uniswap/sdk'
import CurrencyLogo from 'components/CurrencyLogo'
import TextIcon from './TextIcon'
import { ThemeContext } from 'styled-components'

interface CurrencyFormatProps {
  currency: Currency
  bold?: boolean
  size?:string
}

const CurrencyFormat: React.FC<CurrencyFormatProps> = ({ currency, bold,size }) => {
  const theme = useContext(ThemeContext)
  return <TextIcon color={theme.text7} bold={bold} text={currency?.symbol} icon={<CurrencyLogo size={size} currency={currency} />} />
}

export default CurrencyFormat
