

import { ChainId } from '@uniswap/sdk';
import { ContractTransaction, ethers, Overrides, utils } from 'ethers'

import GelatoLimitOrders__factory from './gelatoLimitOrders'
import ERC20OrderRouter__factory from './orderrouterfactory'
import ERC20__factory from './erc20factory'
// import { Order } from 'hooks/limitOrders/useGelatoLimitOrdersHandlers';
const abstract_provider_1 = require("@ethersproject/abstract-provider");
const abstract_signer_1 = require("@ethersproject/abstract-signer");

type Handler = "spookyswap" | "spookyswap_stoplimit" | "uniswap" | "uniswap_stoplimit" | "quickswap" | "quickswap_stoplimit" | "spiritswap" | "spiritswap_stoplimit" | "bombswap" | "polydex" | "cafeswap" | "pancakeswap" | "pancakeswap_stoplimit" | "traderjoe" | "traderjoe_stoplimit" | "defyswap" | "pangolin" | "pangolin_stoplimit";
interface orderInfo {
    inputAmount: number
    minReturnToBeParsed: number
    owner: string
    inputToken: string
    outputToken: string
    checkAllowance?: boolean
    witness?: string
    amount?: number
    secret?: string
}

interface orderInfos {
    inputAmount: number
    owner: string
    inputToken: string
    outputToken: string
    checkAllowance?: boolean
    witness?: string
    amount?: number
    secret?: string
    minReturn: string
}
const _gelatoFeeBPS = 10
const _slippageBPS = 40
// const isFlashbotsProtected = false 
// const _handlerAddress = "0x608dfd4c32efecefcbd1fb15b9b8e9931d5c4faa"
const _handlerAddress = process.env.REACT_APP_AITDSWAPHANDLER || ''
// p  0x88f8ccc064ba2d39cf08d57b6e7504a7b6be8e4e -AiaSwapHandler

// const _moduleAddress = "0xb04e92ad85f8c02ca43b28632f4ebc00f7c9f400" 
const _moduleAddress = process.env.REACT_APP_LIMITORDERS || ''
// p  0xb7499a92fc36e9053a4324aFfae59d333635D9c3- LimitOrders

// const GELATO_LIMIT_ORDERS_ADDRESS = "0x069d22853cbe7acb1debffd441aa6651444d70c4"
const GELATO_LIMIT_ORDERS_ADDRESS = process.env.REACT_APP_GELATOPINECORE || ''
// p  0x0c30D3d66bc7C73A83fdA929888c34dcb24FD599 - GelatoPineCore

// const GELATO_LIMIT_ORDERS_ERC20_ORDER_ROUTER = "0x2c91774b603b10f68542a0967ff66650699ed190"
const GELATO_LIMIT_ORDERS_ERC20_ORDER_ROUTER = process.env.REACT_APP_ERC20OrderRouter||''
// p  0x64c7f3c2C19B41a6aD67bb5f4edc8EdbB3284F34 - ERC20OrderRouter

const G_0XEE = "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee"
const ETH_ADDRESS = "0x00eeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee"

const switchTokenAdress = (token: string) => {
    if (token.toLowerCase() === ETH_ADDRESS.toLowerCase()) {
        return G_0XEE
    }
    return token
}
const isNetworkGasToken = (token: string) => {
    if (token.toLowerCase() === ETH_ADDRESS.toLowerCase()) {
        return true;
    }
    else {
        return false;
    }
};
export const getOriginToken = (token: string) => {
    const e = ETH_ADDRESS
    const e1 = G_0XEE
    return token?.toLowerCase() === e ? e1 : token
}
class GelatoLimitOrders {
    private _chainId: any;
    private _signer: any;
    private _provider: any;
    private _erc20OrderRouter: any;
    private _gelatoLimitOrders: any;
    _signerOrProvider: any;
    _abiEncoder = new utils.AbiCoder();
    constructor(chainId: ChainId, signerOrProvider?: any, handler?: Handler, isFlashbotsProtected = false) {
        this._chainId = chainId;
        this._signerOrProvider = signerOrProvider;
        this._provider = abstract_provider_1.Provider.isProvider(signerOrProvider)
            ? signerOrProvider : abstract_signer_1.Signer.isSigner(signerOrProvider) ? signerOrProvider?.provider : undefined;
        this._signer = abstract_signer_1.Signer.isSigner(signerOrProvider)
            ? signerOrProvider
            : undefined;

        this._erc20OrderRouter = this._signer
            ? ERC20OrderRouter__factory.connect(GELATO_LIMIT_ORDERS_ERC20_ORDER_ROUTER, this._signer)
            : this._provider
                ? ERC20OrderRouter__factory.connect(GELATO_LIMIT_ORDERS_ERC20_ORDER_ROUTER, this._provider)
                : new ethers.Contract(GELATO_LIMIT_ORDERS_ERC20_ORDER_ROUTER, ERC20OrderRouter__factory.createInterface());
        this._gelatoLimitOrders = this._signer
            ? GelatoLimitOrders__factory.connect(GELATO_LIMIT_ORDERS_ADDRESS, this._signer)
            : this._provider
                ? GelatoLimitOrders__factory.connect(GELATO_LIMIT_ORDERS_ADDRESS, this._provider)
                : new ethers.Contract(GELATO_LIMIT_ORDERS_ADDRESS, GelatoLimitOrders__factory.createInterface());
    }
    _getKey = (order: any) => {
        if (order.inputToken.toLowerCase() === ETH_ADDRESS.toLowerCase()) {
            order.inputToken = G_0XEE
        }
        return utils.keccak256(this._abiEncoder.encode(["address", "address", "address", "address", "bytes"],
            [order.module, order.inputToken, order.owner, order.witness, order.data]));
    }
    encodeLimitOrderSubmissionWithSecret = async ({
        inputToken,
        outputToken,
        inputAmount,
        minReturnToBeParsed,
        owner,
        checkAllowance = true }: orderInfo) => {

        const randomSecret = utils.hexlify(utils.randomBytes(19)).replace("0x", "");
        // 0x67656c61746f6e6574776f726b = gelatonetwork in hex
        const fullSecret = `0x67656c61746f6e6574776f726b${randomSecret}`;
        const { privateKey: secret, address: witness } = new ethers.Wallet(fullSecret);
        const { minReturn } = this.getFeeAndSlippageAdjustedMinReturn(minReturnToBeParsed);
        try {
            const payload = await this._encodeSubmitData({
                inputToken, outputToken, owner, witness,
                inputAmount, minReturn, secret, checkAllowance
            });
            const encodedData = _handlerAddress
                ? this._abiEncoder.encode(["address", "uint256", "address"], [switchTokenAdress(outputToken), minReturn, _handlerAddress])
                : this._abiEncoder.encode(["address", "uint256"], [switchTokenAdress(outputToken), minReturn]);
            return {
                payload,
                secret,
                witness,
                order: {
                    id: this._getKey({
                        module: _moduleAddress,
                        inputToken,
                        owner,
                        witness,
                        data: encodedData,
                    }),
                    module: _moduleAddress.toLowerCase(),
                    data: encodedData,
                    inputToken: inputToken.toLowerCase(),
                    outputToken: outputToken.toLowerCase(),
                    owner: owner.toLowerCase(),
                    witness: witness.toLowerCase(),
                    inputAmount: inputAmount.toString(),
                    minReturn: minReturn.toString(),
                    adjustedMinReturn: minReturnToBeParsed.toString(),
                    inputData: payload.data.toString(),
                    secret: secret.toLowerCase(),
                    handler: _handlerAddress ? _handlerAddress : null,
                },
            };
        } catch (error) {
            console.info(error)
            throw Error('error')
        }

    }
    getFeeAndSlippageAdjustedMinReturn = (outputAmount: number, extraSlippageBPS?: string) => {
        if (extraSlippageBPS) {
            if (!Number.isInteger(extraSlippageBPS))
                throw new Error("Extra Slippage BPS must an unsigned integer");
        }
        const gelatoFee = ethers.BigNumber.from(outputAmount)
            .mul(_gelatoFeeBPS)
            .div(10000)
            .gte(1)
            ? ethers.BigNumber.from(outputAmount).mul(_gelatoFeeBPS).div(10000)
            : ethers.BigNumber.from(1);
        const slippageBPS = extraSlippageBPS
            ? _slippageBPS + extraSlippageBPS
            : _slippageBPS;
        const slippage = ethers.BigNumber.from(outputAmount).mul(slippageBPS).div(10000);
        const minReturn = ethers.BigNumber.from(outputAmount).sub(gelatoFee).sub(slippage);
        return {
            minReturn: minReturn.toString(),
            slippage: slippage.toString(),
            gelatoFee: gelatoFee.toString(),
        };
    }
    _encodeSubmitData = async ({ inputToken, outputToken, owner, witness, inputAmount, minReturn, secret, checkAllowance }: orderInfos) => {
        const _provider = this._provider;
        const _gelatoLimitOrders: any = this._gelatoLimitOrders
        const _erc20OrderRouter = this._erc20OrderRouter
        if (!_provider)
            throw new Error("No provider");
        if (inputToken.toLowerCase() === outputToken.toLowerCase())
            throw new Error("Input token and output token can not be equal");


        const encodedData = _handlerAddress
            ? this._abiEncoder.encode(["address", "uint256", "address"], [switchTokenAdress(outputToken), minReturn, _handlerAddress])
            : this._abiEncoder.encode(["address", "uint256"], [switchTokenAdress(outputToken), minReturn]);
        let data, value, to;

        if (isNetworkGasToken(inputToken)) {
            try {
                const ETH_ADDRESSSTR = "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE"
                const encodedEthOrder = await _gelatoLimitOrders?.encodeEthOrder(_moduleAddress, ETH_ADDRESSSTR, // we also use ETH_ADDRESS if it's MATIC
                    owner, witness, encodedData, secret);
                data = _gelatoLimitOrders.interface.encodeFunctionData("depositEth", [encodedEthOrder]);
                value = inputAmount;
                to = _gelatoLimitOrders.address;
                return { data, value, to }
            } catch (error) {

                throw new Error("Insufficient token depositEth");
            }
        } else {
            if (checkAllowance) {
                try {
                    const allowance = await ERC20__factory.connect(inputToken, _provider).allowance(owner, _erc20OrderRouter.address);
                    if (allowance.lt(inputAmount))
                        throw new Error("Insufficient token allowance for placing order");
                } catch (error) {
                    throw new Error("Insufficient token allowance for placing order");
                }
            }
            data = _erc20OrderRouter.interface.encodeFunctionData("depositToken", [
                inputAmount,
                _moduleAddress,
                inputToken,
                owner,
                witness,
                encodedData,
                secret,
            ]);
            value = ethers.constants.Zero;
            to = _erc20OrderRouter.address;
            return { data, value, to }
        }

        // return { data, value, to };
    }

    cancelLimitOrder = async (order: any, checkIsActiveOrder?: boolean, overrides?: Overrides): Promise<ContractTransaction> => {
        if (!this._signer)
            throw new Error("No signer");
        if (!this._gelatoLimitOrders)
            throw new Error("No gelato limit orders contract");
        let _order: any = order;

        // _order.inputToken = getOriginToken(_order.inputToken)
        // debugger
        if (!order.id) {
            throw new Error("No id in order");
            // try {
            //     const subgraphOrder = await Promise.race([
            //         this.getOrder(order.id),
            //         new Promise((resolve) => setTimeout(resolve, 5000)).then(() => {
            //             throw new Error("Timeout");
            //         }),
            //     ]);
            //     if (subgraphOrder) {
            //         if (subgraphOrder.status === "cancelled") {
            //             throw new Error(`Order status is not open. Current order status: ${subgraphOrder.status}. Cancellation transaction hash: ${subgraphOrder.cancelledTxHash}`);
            //         }
            //         if (subgraphOrder.status === "executed") {
            //             throw new Error(`Order status is not open. Current order status: ${subgraphOrder.status}. Execution transaction hash: ${subgraphOrder.executedTxHash}`);
            //         }
            //         _order = Object.assign(Object.assign({}, order), subgraphOrder);
            //     }
            // } catch (error) {
            // }
        }

        if (!_order.inputToken)
            throw new Error("No input token in order");
        if (!_order.witness)
            throw new Error("No witness in order");
        if (!_order.outputToken)
            throw new Error("No output token in order");
        if (!_order.minReturn)
            throw new Error("No minReturn in order");
        if (!_order.data)
            throw new Error("No data in order");
        if (!_order.module)
            throw new Error("No module in order");

        if (checkIsActiveOrder) {
            const isActiveOrder = await this.isActiveOrder(_order);
            if (!isActiveOrder)
                throw new Error("Order not found. Please review your order data.");
        }
        const owner = await this._signer.getAddress();
        if (owner.toLowerCase() !== order.owner.toLowerCase())
            throw new Error("Owner and signer mismatch");
        return this._gelatoLimitOrders.cancelOrder(_order.module,
            getOriginToken(_order.inputToken),
            _order.owner,
            _order.witness,
            _order.data,
            overrides !== null && overrides !== void 0 ? overrides : {
                gasLimit: 2000000
            });
    }
    isActiveOrder = async (order: any) => {
        if (!this._provider)
            throw new Error("No provider");
        if (!this._gelatoLimitOrders)
            throw new Error("No gelato limit orders contract");
        return await this._gelatoLimitOrders.existOrder(order.module, order.inputToken, order.owner, order.witness, order.data);
    }
    getAdjustedMinReturn(minReturn: any, extraSlippageBPS?: any) {
        const gelatoFee = ethers.BigNumber.from(_gelatoFeeBPS);
        const slippage = extraSlippageBPS
            ? ethers.BigNumber.from(_slippageBPS + extraSlippageBPS)
            : ethers.BigNumber.from(_slippageBPS);
        const fees = gelatoFee.add(slippage);
        const adjustedMinReturn = ethers.BigNumber.from(minReturn)
            .mul(10000)
            .div(ethers.BigNumber.from(10000).sub(fees));
        return adjustedMinReturn.toString();
    }
    getOpenOrders = async (account: any) => {
        return {
            data: {}
        }
    }
    get signer() {
        return this._signer;
    }
    get chainId() {
        return this._chainId;
    }
    get erc20OrderRouter() {
        return this._erc20OrderRouter;
    }
}
export default GelatoLimitOrders