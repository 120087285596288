import React, { useCallback, useContext } from 'react'
import { useDispatch } from 'react-redux'
import styled, { ThemeContext } from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useActiveWeb3React } from '../../hooks'
import { AppDispatch } from '../../state'
import { clearAllTransactions } from '../../state/transactions/actions'
import { shortenAddress } from '../../utils'
import { AutoRow } from '../Row'
import Copy from './Copy'
import Transaction from './Transaction'
import { isMobile } from 'react-device-detect'

import { SUPPORTED_WALLETS } from '../../constants'
import { ReactComponent as Close } from '../../assets/images/x.svg'
import { getEtherscanLink } from '../../utils'
import { injected, walletconnect, walletlink, fortmatic, portis } from '../../connectors'
import CoinbaseWalletIcon from '../../assets/images/coinbaseWalletIcon.svg'
import WalletConnectIcon from '../../assets/images/walletConnectIcon.svg'
import FortmaticIcon from '../../assets/images/fortmaticIcon.png'
import PortisIcon from '../../assets/images/portisIcon.png'
import WalletIcon from '../../assets/svg/deepWalletSvg.svg'
import DeleteSvg from '../../assets/svg/deleteSvg.svg'
// import Identicon from '../Identicon'
import { ButtonSecondary } from '../Button'
import { ExternalLink as LinkIcon } from 'react-feather'
import { ExternalLink, TYPE } from '../../theme'
import { useWeb3React } from '@web3-react/core'

const HeaderRow = styled.div`
  ${({ theme }) => theme.flexRowNoWrap};
  padding: 1rem 1rem;
  font-weight: 500;
  color: ${props => (props.color === 'blue' ? ({ theme }) => theme.primary1 : 'inherit')};
  ${({ theme }) => theme.mediaWidth.upToMedium`
    padding: 1rem;
  `};
`

const UpperSection = styled.div`
  position: relative;
  h5 {
    margin: 0;
    margin-bottom: 0.5rem;
    font-size: 1rem;
    font-weight: 400;
  }

  h5:last-child {
    margin-bottom: 0px;
  }

  h4 {
    margin-top: 0;
    font-weight: 500;
  }
`

const InfoCard = styled.div`
  /* padding: 1rem; */
  /* border: 1px solid ${({ theme }) => theme.bg3}; */
  /* border-radius: 20px; */
  position: relative;
  display: grid;
  grid-row-gap: 12px;
  /* margin-bottom: 20px; */
`

const AccountGroupingRow = styled.div`
  ${({ theme }) => theme.flexRowNoWrap};
  justify-content: space-between;
  align-items: center;
  font-weight: 400;
  color: ${({ theme }) => theme.text1};

  div {
    ${({ theme }) => theme.flexRowNoWrap}
    align-items: center;
  }
`

const AccountSection = styled.div`
  background-color: ${({ theme }) => theme.bg1};
  padding: 1rem 0 0 0;
  box-shadow: 0px 3px 0 1px rgba(14, 37, 74, 0.1);
  border-radius:15px;
  /* ${({ theme }) => theme.mediaWidth.upToMedium`padding:1rem 0 0 0;`}; */
`

const YourAccount = styled.div`
  h5 {
    margin: 0 0 1rem 0;
    font-weight: 400;
  }

  h4 {
    margin: 0;
    font-weight: 500;
  }
`

const LowerSection = styled.div`
  ${({ theme }) => theme.flexColumnNoWrap}
  padding: 1rem;
  padding-top:50px;
  margin-top: -20px;
  flex-grow: 1;
  overflow: auto;
  background-color: ${({ theme }) => theme.white};
  /* border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px; */
  box-shadow: 0px 3px 0 1px rgba(14, 37, 74, 0.1);
  border-radius:15px;
  h5 {
    margin: 0;
    font-weight: 400;
    color: ${({ theme }) => theme.text3};
  }
  @media screen and (min-width:960px){
    ::-webkit-scrollbar{
      width:3px;
    }
    ::-webkit-scrollbar-thumb {
    border-radius:15px;
      background: rgba(0,0,0,0.2);
    }
    ::-webkit-scrollbar-track {
      border-radius: 15px;
      background: transparent;

    }
  }
 
`

const AccountControl = styled.div`
  display: flex;
  justify-content: space-between;
  min-width: 0;
  width: 100%;

  font-weight: 500;
  font-size: 1.25rem;

  a:hover {
    text-decoration: underline;
  }

  p {
    min-width: 0;
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`
const AccountControlNew = styled.div`
  display: flex;
  justify-content: space-between;
  min-width: 0;
  width: 100%;
  font-weight: 500;
  font-size: 1.25rem;
  border-top: 1px solid #F5F5F5;
  padding: 5px 0;
  a:hover {
    text-decoration: underline;
  }
  a,button{
    width:50%;
    justify-content: center;
    padding: 5px 0;
    span{
      color: ${({ theme }) => theme.text7};
      font-weight: 500;
    }
  }
  p {
    min-width: 0;
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`


const AddressLink = styled(ExternalLink) <{ hasENS: boolean; isENS: boolean }>`
  color: ${({ theme }) => theme.text7};
  /* margin-left: 1rem; */
  font-size: 0.825rem;
  display: flex;
  border-left:1px solid #F5F5F5;
  :hover {
    color: ${({ theme }) => theme.text2};
  }
`

const CloseIcon = styled.div`
  position: absolute;
  right: 1rem;
  top: 14px;
  &:hover {
    cursor: pointer;
    opacity: 0.6;
  }
`

const CloseColor = styled(Close)`
  path {
    stroke: ${({ theme }) => theme.text4};
  }
`

const WalletName = styled.div`
  width: initial;
  font-size: 0.825rem;
  font-weight: 500;
  color: ${({ theme }) => theme.text7};
`

const IconWrapper = styled.div<{ size?: number }>`
  ${({ theme }) => theme.flexColumnNoWrap};
  align-items: center;
  justify-content: center;
  margin-right: 8px;
  & > img,
  span {
    height: ${({ size }) => (size ? size + 'px' : '32px')};
    width: ${({ size }) => (size ? size + 'px' : '32px')};
  }
  ${({ theme }) => theme.mediaWidth.upToMedium`
    align-items: flex-end;
  `};
`

const TransactionListWrapper = styled.div`
  ${({ theme }) => theme.flexColumnNoWrap};

  
`

const WalletAction = styled(ButtonSecondary)`
  width: fit-content;
  font-weight: 400;
  margin-left: 8px;
  font-size: 0.825rem;
  padding: 4px 6px;
  :hover {
    cursor: pointer;
    text-decoration: underline;
  }
`
const WalletActionNew = styled.div`
  width: fit-content;
  font-weight: 400;
  margin-left: 8px;
  font-size: 0.825rem;
  padding: 4px 6px;
  color: ${({ theme }) => theme.primary1};
  :hover {
    cursor: pointer;
    text-decoration: underline;
  }
`

const ContainerWrap = styled.div`
  padding: 10px;
  padding-bottom: 40px;
  background-color: #F8FAFF;
`

const MainWalletAction = styled(WalletAction)`
  color: ${({ theme }) => theme.primary1};
`

function renderTransactions(transactions: string[]) {
  return (
    <TransactionListWrapper>
      {transactions.map((hash, i) => {
        return <Transaction key={hash} hash={hash} />
      })}
    </TransactionListWrapper>
  )
}

interface AccountDetailsProps {
  toggleWalletModal: () => void
  pendingTransactions: string[]
  confirmedTransactions: string[]
  ENSName?: string
  openOptions: () => void
}

export default function AccountDetails({
  toggleWalletModal,
  pendingTransactions,
  confirmedTransactions,
  ENSName,
  openOptions
}: AccountDetailsProps) {
  const {deactivate} = useWeb3React();
  const { chainId, account, connector } = useActiveWeb3React()
  const theme = useContext(ThemeContext)
  const dispatch = useDispatch<AppDispatch>()

  const { t } = useTranslation()

  function formatConnectorName() {
    const { ethereum } = window
    const isMetaMask = !!(ethereum && ethereum.isMetaMask)
    const name = Object.keys(SUPPORTED_WALLETS)
      .filter(
        k =>
          SUPPORTED_WALLETS[k].connector === connector && (connector !== injected || isMetaMask === (k === 'METAMASK'))
      )
      .map(k => SUPPORTED_WALLETS[k].name)[0]
    return (
      <WalletName>
        {t('Connected with')} {name}
      </WalletName>
    )
  }

  function getStatusIcon() {
    if (connector === injected) {
      return (
        <IconWrapper size={20}>
          {/* <Identicon /> */}
          <img src={WalletIcon} alt={''} />
        </IconWrapper>
      )
    } else if (connector === walletconnect) {
      return (
        <IconWrapper size={16}>
          <img src={WalletConnectIcon} alt={'wallet connect logo'} />
        </IconWrapper>
      )
    } else if (connector === walletlink) {
      return (
        <IconWrapper size={16}>
          <img src={CoinbaseWalletIcon} alt={'coinbase wallet logo'} />
        </IconWrapper>
      )
    } else if (connector === fortmatic) {
      return (
        <IconWrapper size={16}>
          <img src={FortmaticIcon} alt={'fortmatic logo'} />
        </IconWrapper>
      )
    } else if (connector === portis) {
      return (
        <>
          <IconWrapper size={16}>
            <img src={PortisIcon} alt={'portis logo'} />
            <MainWalletAction
              onClick={() => {
                portis.portis.showPortis()
              }}
            >
              Show Portis
            </MainWalletAction>
          </IconWrapper>
        </>
      )
    }
    return null
  }

  const clearAllTransactionsCallback = useCallback(() => {
    if (chainId) dispatch(clearAllTransactions({ chainId }))
  }, [dispatch, chainId])

  return (
    <>
      <ContainerWrap>
        <UpperSection>
          <CloseIcon onClick={toggleWalletModal}>
            <CloseColor />
          </CloseIcon>
          <HeaderRow>{t('Account')}</HeaderRow>
          <AccountSection>
            <YourAccount>
              <InfoCard>
                <AccountGroupingRow style={{ justifyContent: 'center' }}>
                  {formatConnectorName()}
                  <div>
                    {connector == injected && (
                      <WalletActionNew
                        style={{ fontSize: '.825rem', fontWeight: 400, marginRight: '8px' }}
                        onClick={() => {
                          deactivate()
                        }}
                      >
                        Disconnect
                      </WalletActionNew>
                    )}
                    <WalletActionNew
                      style={{ fontSize: '.825rem', fontWeight: 400 }}
                      onClick={() => {
                        openOptions()
                      }}
                    >
                      {t('Change')}
                    </WalletActionNew>
                  </div>
                </AccountGroupingRow>
                <AccountGroupingRow id="web3-account-identifier-row" >
                  <AccountControl style={{ justifyContent: 'center' }}>
                    {ENSName ? (
                      <>
                        <div>
                          {getStatusIcon()}
                          <p> {ENSName}</p>
                        </div>
                      </>
                    ) : (
                      <>
                        <div>
                          {getStatusIcon()}
                          <p> {account && shortenAddress(account)}</p>
                        </div>
                      </>
                    )}
                  </AccountControl>
                </AccountGroupingRow>
                <AccountGroupingRow style={{ padding: 0 }}>
                  {ENSName ? (
                    <>
                      <AccountControlNew>
                        <>
                          {account && (
                            <Copy toCopy={account}>
                              <span style={{ marginLeft: '4px' }}>{t('Copy Address')}</span>
                            </Copy>
                          )}
                          {chainId && account && (
                            <AddressLink
                              hasENS={!!ENSName}
                              isENS={true}
                              href={chainId && getEtherscanLink(chainId, ENSName, 'address')}
                            >
                              <LinkIcon size={16} />
                              <span style={{ marginLeft: '4px' }}>{t('View on Aiascan')}</span>
                            </AddressLink>
                          )}
                        </>
                      </AccountControlNew>
                    </>
                  ) : (
                    <>
                      <AccountControlNew>
                        <>
                          {account && (
                            <Copy toCopy={account}>
                              <span style={{ marginLeft: '4px' }}>{t('Copy Address')}</span>
                            </Copy>
                          )}
                          {chainId && account && (
                            <AddressLink
                              hasENS={!!ENSName}
                              isENS={false}
                              href={getEtherscanLink(chainId, account, 'address')}
                            >
                              <LinkIcon size={16} />
                              <span style={{ marginLeft: '4px' }}>{t('View on Aiascan')}</span>
                            </AddressLink>
                          )}
                        </>
                      </AccountControlNew>
                    </>
                  )}
                </AccountGroupingRow>
              </InfoCard>
            </YourAccount>
          </AccountSection>
        </UpperSection>
        {!!pendingTransactions.length || !!confirmedTransactions.length ? (
          <LowerSection style={{ maxHeight: isMobile ? 'auto' : '65vh' }}>
            <AutoRow mb={'1rem'} style={{ justifyContent: 'space-between' }}>

              <TYPE.body color={theme.text7} fontWeight={600} fontSize={13}>{t('Recent Transactions')}</TYPE.body>
              <div style={{ width: '20px' }}><img onClick={clearAllTransactionsCallback} src={DeleteSvg} alt="AIA" width={15} /></div>
            </AutoRow>

            {renderTransactions(pendingTransactions)}
            {renderTransactions(confirmedTransactions)}
          </LowerSection>
        ) : (
          <LowerSection>
            <TYPE.body color={theme.text7}>{t('Your transactions will appear here')}...</TYPE.body>
          </LowerSection>
        )}
      </ContainerWrap>
    </>
  )
}
