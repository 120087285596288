import { JSBI, Pair, Token, TokenAmount } from "@uniswap/sdk"

import { BigNumber } from "ethers"


export const getHypotheticalRewardRateFun = (
    stakedAmount: TokenAmount,
    totalStakedAmount: TokenAmount,
    totalRewardRate: TokenAmount,
    uni: Token
): any => {
    return new TokenAmount(
        uni,
        JSBI.greaterThan(totalStakedAmount.raw, JSBI.BigInt(0))
            ? JSBI.divide(JSBI.multiply(totalRewardRate.raw, stakedAmount.raw), totalStakedAmount.raw)
            : JSBI.BigInt(0)
    )
}

interface commonFunDataProp {
    balanceState: any
    earnedAmountState: any
    totalSupplyState: any
    rewardRateState: any
    periodFinishState: any
    info: any
    uni: Token
    currentBlockTimestamp: BigNumber | undefined
}

export const commonFunData = ({
    balanceState,
    earnedAmountState,
    totalSupplyState,
    rewardRateState,
    periodFinishState,
    info,
    currentBlockTimestamp,
    uni,
}: commonFunDataProp) => {
    const getHypotheticalRewardRate = (
        stakedAmount: any,
        totalStakedAmount: any,
        totalRewardRate: any) => {
        return getHypotheticalRewardRateFun(stakedAmount, totalStakedAmount, totalRewardRate, uni)
    }
    const tokens = info?.tokens
    const dummyPair = new Pair(new TokenAmount(tokens[0], '0'), new TokenAmount(tokens[1], '0'))
    const stakedAmount = new TokenAmount(dummyPair.liquidityToken, JSBI.BigInt(balanceState?.result?.[0] ?? 0))
    const totalStakedAmount = new TokenAmount(dummyPair.liquidityToken, JSBI.BigInt(totalSupplyState?.result?.[0] ?? 0))
    const totalRewardRate = new TokenAmount(uni, JSBI.BigInt(rewardRateState?.result?.[0] ?? 0))
    const individualRewardRate = getHypotheticalRewardRate(stakedAmount, totalStakedAmount, totalRewardRate)
    const periodFinishSeconds = periodFinishState?.result?.[0]?.toNumber()||0

    const periodFinishMs = periodFinishSeconds * 1000
    // compare period end timestamp vs current block timestamp (in seconds)
    const active =
        periodFinishSeconds && currentBlockTimestamp ? periodFinishSeconds > currentBlockTimestamp.toNumber() : true

    let result: any = {
        periodFinish: periodFinishMs > 0 ? new Date(periodFinishMs) : undefined,
        earnedAmount: new TokenAmount(uni, JSBI.BigInt(earnedAmountState?.result?.[0] ?? 0)),
        rewardRate: individualRewardRate,
        totalRewardRate: totalRewardRate,
        stakedAmount: stakedAmount,
        totalStakedAmount: totalStakedAmount,
        getHypotheticalRewardRate,
        active,
        tokens,
        type: info.type,
    }
    if (info?.reward) { result.reward = info.reward }
    return result
}
export const isLoading = ({
    balanceState,
    earnedAmountState,
    totalSupplyState,
    rewardRateState,
    periodFinishState,
}: any): boolean => {
    return !balanceState?.loading &&
        !earnedAmountState?.loading &&
        totalSupplyState &&
        !totalSupplyState.loading &&
        rewardRateState &&
        !rewardRateState.loading &&
        periodFinishState &&
        !periodFinishState.loading
}
export const isError = ({
    balanceState,
    earnedAmountState,
    totalSupplyState,
    rewardRateState,
    periodFinishState,
}: any): boolean => {
    return balanceState?.error ||
        earnedAmountState?.error ||
        totalSupplyState.error ||
        rewardRateState.error ||
        periodFinishState.error
}

export const stateDatas = (data: any, index?: number) => {
    const {
        balances,
        earnedAmounts,
        totalSupplies,
        rewardRates,
        periodFinishes
    } = data
    if(!index){
        return {
            balanceState: balances,
            earnedAmountState: earnedAmounts,
            totalSupplyState: totalSupplies,
            rewardRateState: rewardRates,
            periodFinishState: periodFinishes
        }
    }
    return ({
        balanceState: balances[index],
        earnedAmountState: earnedAmounts[index],
        totalSupplyState: totalSupplies[index],
        rewardRateState: rewardRates[index],
        periodFinishState: periodFinishes[index]
    })
}