import React from 'react'
import CartChart from './index'
import Modal from '../../components/Modal'

interface Params {
    isOpen: boolean,
    currencies: any,
    onDismiss: () => void,
    pairList:any
}
const ChartModal: React.FC<Params> = ({
    pairList,
    isOpen,
    currencies,
    onDismiss
}) => {
    return (
        <Modal isOpen={isOpen} onDismiss={onDismiss} minHeight={60} maxHeight={90}>
            <CartChart onDismiss={onDismiss} pairList={pairList} currencies={currencies} />
        </Modal>
    )
}
export default ChartModal