import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import Settings from '../Settings'
import { RowBetween } from '../Row'
import { TYPE } from '../../theme'

const StyledSwapHeader = styled.div`
  padding: 1rem 1rem 0px 1.5rem;
  margin-bottom: -4px;
  width: 100%;
  max-width: 420px;
  color: ${({ theme }) => theme.text6};
`
interface SwapHeaderProps {
  show?: boolean,
  showSetting?: boolean
}
const SwapHeader: React.FC<SwapHeaderProps> = ({ show = false, showSetting = true }) => {
  const { t } = useTranslation()

  return (<>
    <StyledSwapHeader>
      <RowBetween>
        <TYPE.black fontWeight={900} sx={{
          fontSize: '18px'
        }}>{show ? t('Limit') : t('swap')}</TYPE.black>
        {showSetting && <Settings />}
      </RowBetween>
    </StyledSwapHeader>
  </>)
}

export default SwapHeader;