import { useMemo } from "react"
import { LimitOrderStatus, ORDER_CATEGORY } from "../types"
import orderBy from 'lodash/orderBy'
import { useActiveWeb3React } from "hooks"
import useGelatoLimitOrdersLib from "../constants/useGelatoLimitOrdersLib"
import { Order } from "hooks/limitOrders/useGelatoLimitOrdersHandlers"
import {
  getLSOrders, getUniqueOrders,
  hashOrder,
  hashOrderSet,
  saveOrder,
  // getTotalOrders,
  // getCancelOrders,
  // savePendingOrders,
  // saveTotalOrders,
  saveOrders,
  savePendingOrders
} from "../utils/localStorageOrders"
import { useOrderState } from "state/limitOrders/hooks"
import { useAllTransactions } from "state/transactions/hooks"


export default function useGelatoLimitOrdersHistory(orderCategory: ORDER_CATEGORY, page = 1) {
  const historyOrders = useHistoryOrders(orderCategory === ORDER_CATEGORY.History)
  const openOrders = useOpenOrders(orderCategory === ORDER_CATEGORY.Open, page)

  const orders = useMemo(
    () => (orderCategory === ORDER_CATEGORY.Open ? openOrders : historyOrders),
    [orderCategory, openOrders, historyOrders, page],
  )

  return useMemo(
    () => (Array.isArray(orders) ? orderBy(orders, (order) => parseInt(order.createdAt), 'desc') : orders),
    [orders],
  )
}

const useOpenOrders = (turnOn: boolean, page = 1): any => {
  let data: any = []
  const { account, chainId } = useActiveWeb3React()
  const startFetch = turnOn && account && chainId
  const transactions = useAllTransactions()
  const { orderList: orders } = useOrderState()
  // const [data,setData] = useState<any>([])
  /***/
  const transactionPending = (transactionHash?: string) => {
    if (!transactionHash || !transactions[transactionHash]) return false
    return !transactions[transactionHash].receipt
  }
  const getOrderData = () => {
    try {

      // syncOrderToLocalStorage({
      //   orders: [...orders],
      //   chainId,
      //   account,
      // })

      // const openOrdersLS = getLSOrders(chainId, account).filter((order:any) => order.status === LimitOrderStatus.OPEN)
      const pendingOrdersLS = getLSOrders(chainId, account, true);
      const pendingOrder = pendingOrdersLS.filter((i: any) => i.status === 'pengding')
      const cancelingOrder = pendingOrdersLS.filter((i: any) => i.status === 'cancelled')

      let ordersfilter = orders.filter((i: any) => {
        const hash = transactions[i.createdTxHash]
        let isCancelled = false
        if (hash) {
          isCancelled = hash.order?.status === 'cancelled'
        }
        return !isCancelled
      })
      try {
        ordersfilter = ordersfilter.map((i: any) => {
          let item = { ...i }
          cancelingOrder.some((ii: any) => {
            if (i.id === ii.id) {
              item.status = ii.status
              item.cancelledTxHash = ii.cancelledTxHash
              return true;
            }
            return false
          })
          return item
        })
      } catch (error) {
        console.info(error)
      }

      // console.info(orders)
      let listData: any = getUniqueOrders([...pendingOrder, ...ordersfilter], 'asc');

      listData = listData.filter((i: any) => {
        //过滤掉已取消的..
        // const isCancellationPending = transactionPending(i?.cancelledTxHash ?? undefined)
        // const isSubmissionPending = transactionPending(i?.createdTxHash ?? undefined)
        const pengdingStatus = i.status === 'pengding'
        let isCancelled = true
        // const opendingStatus = i.status === 'open'
        // const status = i.status === 'cancelled'
        // let isCancelled = !(status && !isCancellationPending);
        // if (!isCancelled) {
        //   if (pendingOrdersLS.length > 0) {
        //     const newPenging = pendingOrdersLS.filter((ii: any) => {
        //       if (ii.status === 'pengding' && page === 1) {
        //         return true
        //       } else {
        //         return ii.status === 'cancelled' && !((i?.id || '').toLowerCase() === (ii.id || '').toLowerCase())
        //       }
        //     }) || []
        //     chainId && account && savePendingOrders(chainId, account, newPenging)
        //   }
        // } else {
          // if (pengdingStatus && !isSubmissionPending) {
          //   isCancelled = orders.some((ii:any)=>ii.id===i.id)
          // }
        //   if (pengdingStatus && page !== 1) {
        //     isCancelled = false
        //   }
        // }
        // if (pengdingStatus && !isSubmissionPending) {
        //   isCancelled = false
        // }
        if (pengdingStatus && page !== 1) {
          isCancelled = false
        }
        return isCancelled
      })

      if (pendingOrdersLS.length > 0) {
        const newPenging = pendingOrdersLS.filter((ii: any) => {
          const isCancellationPending = transactionPending(ii?.cancelledTxHash ?? undefined)
          const status = ii?.status === 'cancelled'
          let isCancelled = !(status && !isCancellationPending);
          if (ii.status === 'pengding') {
            return true
          } else {

            return isCancelled
          }
        }) || []
        chainId && account && savePendingOrders(chainId, account, newPenging)
      }
      // console.info(listData,3)
      data = [...listData].sort(newOrdersFirst)
    } catch (error) {
    }
    return [...data]
  }

  data = getOrderData()
  // saveTotalOrders(chainId, account, data)
  return startFetch ? data : []
}

const useHistoryOrders = (turnOn: boolean): Order[] => {
  const { account, chainId } = useActiveWeb3React()
  const gelatoLimitOrders = useGelatoLimitOrdersLib()
  const { historyTotalData: orders } = useOrderState()
  const startFetch = turnOn && gelatoLimitOrders && account && chainId

  /***
    const { data } = useSWR(
      startFetch ? EXECUTED_CANCELLED_ORDERS_SWR_KEY : null,
      async () => {
        try {
          const acc = account.toLowerCase()
  
          const [canOrders, exeOrders] = await Promise.all([
            gelatoLimitOrders.getCancelledOrders(acc, false),
            gelatoLimitOrders.getExecutedOrders(acc, false),
          ])
  
          await syncOrderToLocalStorage({
            orders: [...canOrders, ...exeOrders],
            chainId,
            account,
          })
        } catch (e) {
          console.error('Error fetching history orders from subgraph', e)
        }
  
        const executedOrdersLS = getLSOrders(chainId, account).filter(
          (order:any) => order.status === LimitOrderStatus.EXECUTED,
        )
  
        const cancelledOrdersLS = getLSOrders(chainId, account).filter(
          (order:any) => order.status === LimitOrderStatus.CANCELLED,
        )
  
        const pendingCancelledOrdersLS = getLSOrders(chainId, account, true).filter(
          (order:any) => order.status === LimitOrderStatus.CANCELLED,
        )
  
        return [...pendingCancelledOrdersLS, ...cancelledOrdersLS, ...executedOrdersLS].sort(newOrdersFirst)
      },
      {
        refreshInterval: SLOW_INTERVAL,
      },
    )
  */
  return startFetch ? [...orders].sort(newOrdersFirst) : []
}

export function newOrdersFirst(a: Order, b: Order) {
  return Number(a.updatedAt) - Number(b.updatedAt)
}
export function newOrdersFirstdesc(a: Order, b: Order) {
  return Number(b.updatedAt) - Number(a.updatedAt)
}
export async function syncOrderToLocalStorage({
  chainId,
  account,
  orders,
  syncStatuses,
}: {
  chainId: any
  account: any
  orders: Order[] | any
  syncStatuses?: LimitOrderStatus[]
}) {

  const allOrdersLS = getLSOrders(chainId, account)
  const lsOrdersHashSet = hashOrderSet(allOrdersLS)
  const newOrders = orders.filter((order: Order) => !lsOrdersHashSet.has(hashOrder(order)))

  saveOrders(chainId, account, newOrders)

  const typeOrdersLS = syncStatuses
    ? allOrdersLS.filter((order: any) => syncStatuses.some((type) => type === order.status))
    : [];
  for (let i = 0; i < typeOrdersLS.length; i++) {
    const confOrder = typeOrdersLS[i]
    try {
      const graphOrder =
        orders.find((order: any) => confOrder.id.toLowerCase() === order.id.toLowerCase()) ?? null;
      if (isOrderUpdated(confOrder, graphOrder)) {
        saveOrder(chainId, account, graphOrder)
      }
    } catch (e) {
      console.error('Error fetching order from subgraph', e)
    }
  }
}

const isOrderUpdated = (oldOrder: Order, newOrder: Order | null): boolean => {
  return newOrder ? Number(oldOrder.updatedAt) < Number(newOrder.updatedAt) : false
}