import { useMemo } from "react";
import useWindowSize from "react-use/lib/useWindowSize";
import { Field } from "state/swap/actions";
import { REACT_APP_AITDSWAP_USDT_ADDRESS, REACT_APP_AITDSWAP_WAITD_ADDRESS } from "../constants";


export function useShowCoinFun(currencies: any, isReserve: boolean) {
    return useMemo(() => (currencc: any) => {
        const coinA = currencc[Field.INPUT]?.symbol
        const coinB = currencc[Field.OUTPUT]?.symbol
        const addressA = currencc[Field.INPUT]?.address
        const addressB = currencc[Field.OUTPUT]?.address
        let inputCoin, outCoin, context, inputCoinAddress, outCoinAddress;
        if (!coinA && coinB) {
            inputCoin = isReserve ? 'AIA' : 'USDT'
            inputCoinAddress = isReserve ? REACT_APP_AITDSWAP_WAITD_ADDRESS : REACT_APP_AITDSWAP_USDT_ADDRESS
            outCoin = isReserve ? 'USDT' : 'AIA'
            outCoinAddress = isReserve ? REACT_APP_AITDSWAP_USDT_ADDRESS : REACT_APP_AITDSWAP_WAITD_ADDRESS
            context = isReserve ? `AIA / USDT ` : `USDT / AIA `
        } else if (coinA && !coinB) {
            inputCoin = isReserve ? 'USDT' : 'AIA'
            inputCoinAddress = isReserve ? REACT_APP_AITDSWAP_USDT_ADDRESS : REACT_APP_AITDSWAP_WAITD_ADDRESS
            outCoin = isReserve ? 'AIA' : 'USDT'
            outCoinAddress = isReserve ? REACT_APP_AITDSWAP_WAITD_ADDRESS : REACT_APP_AITDSWAP_USDT_ADDRESS
            context = isReserve ? `USDT / AIA ` : `AIA / USDT `
        } else {
            inputCoin = isReserve ? coinB : coinA
            inputCoinAddress =isReserve ? addressB : addressA
            outCoin = isReserve ? coinA : coinB
            outCoinAddress = isReserve ? addressA : addressB
            context = isReserve ? `${coinB}/${coinA}` : `${coinA} / ${coinB}`
        }
        return {
            inputCoin,
            inputCoinAddress,
            outCoin,
            outCoinAddress,
            context
        }
    }, [currencies[Field.OUTPUT], currencies[Field.INPUT], isReserve])
}

export function useAspectValue() {
    const belowSize = useWindowSize()
    return useMemo(() => {
        let getDiffer = 0;
        if (belowSize.width > 1800) {
            getDiffer = 0.02;
        } else if (belowSize.width > 1600) {
            getDiffer = 0.06;
        } else if (belowSize.width > 1400) {
            getDiffer = 0.08;
        } else if (belowSize.width > 1200) {
            getDiffer = 0.1;
        } else if (belowSize.width > 1000) {
            getDiffer = 0.12;
        } else {
            getDiffer = 0.83
        }
        return belowSize.width / (516 / 0.83 - getDiffer)
    }, [belowSize])
}


export const getToken = (token0: string | undefined, token1: string | undefined) => {
    return {
        token0: token0 === 'AIA' ? 'WAIA' : token0,
        token1: token1 === 'AIA' ? 'WAIA' : token1
    }
}