import { ChainId, CurrencyAmount, JSBI, Token, TokenAmount, WETH, Pair } from '@uniswap/sdk'
import { useMemo } from 'react'
import { DAI, UNI, USDC, USDT, WAIA, CRET, BTC, ETH, DYDX, SAND, MASK, MATIC, GTC, SHIB, INCH, MANA, FIL, WYOHO, CAD, BMT, OXG, OXX, CADS, YOHO, BNB, CAC, CADSII, CACII } from '../../constants'
import { STAKING_REWARDS_INTERFACE } from '../../constants/abis/staking-rewards'
import { useActiveWeb3React } from '../../hooks'
import { NEVER_RELOAD, useMultipleContractSingleData } from '../multicall/hooks'
import { tryParseAmount } from '../swap/hooks'
import useCurrentBlockTimestamp from 'hooks/useCurrentBlockTimestamp'
import { useTranslation } from 'react-i18next'
import { usePoolDatas } from 'components/earn/utils'
import { commonFunData, isError, isLoading, stateDatas } from './utils'
// import { useUpdatePoolResults } from 'state/earn/hooks'

const REACT_APP_AITDSWAP_V2_STAKE_REWARD = process.env.REACT_APP_AITDSWAP_V2_STAKE_REWARD || ''
const REACT_APP_AITDSWAP_V2_STAKE_REWARD_TWO = process.env.REACT_APP_AITDSWAP_V2_STAKE_REWARD_TWO || ''

const REACT_APP_AITDSWAP_V2_STAKE_REWARD_FOUR = process.env.REACT_APP_AITDSWAP_V2_STAKE_REWARD_FOUR || ''
const REACT_APP_AITDSWAP_V2_STAKE_REWARD_FIVE = process.env.REACT_APP_AITDSWAP_V2_STAKE_REWARD_FIVE || ''
const REACT_APP_AITDSWAP_V2_STAKE_REWARD_SIX = process.env.REACT_APP_AITDSWAP_V2_STAKE_REWARD_SIX || ''

const REACT_APP_AITDSWAP_V2_STAKE_REWARD_MAIN_BTC_TWO =
  process.env.REACT_APP_AITDSWAP_V2_STAKE_REWARD_MAIN_BTC_TWO || ''
const REACT_APP_AITDSWAP_V2_STAKE_REWARD_THREE = process.env.REACT_APP_AITDSWAP_V2_STAKE_REWARD_THREE || ''

const REACT_APP_AITDSWAP_V2_STAKE_REWARD_MAIN_BTC = process.env.REACT_APP_AITDSWAP_V2_STAKE_REWARD_MAIN_BTC || ''
const REACT_APP_AITDSWAP_V2_STAKE_REWARD_MAIN_ETH = process.env.REACT_APP_AITDSWAP_V2_STAKE_REWARD_MAIN_ETH || ''
const REACT_APP_AITDSWAP_V2_STAKE_REWARD_MAIN_ETH_TWO =
  process.env.REACT_APP_AITDSWAP_V2_STAKE_REWARD_MAIN_ETH_TWO || ''
// const REACT_APP_AITDSWAP_V2_STAKE_REWARD_MAIN_UNI = process.env.REACT_APP_AITDSWAP_V2_STAKE_REWARD_MAIN_UNI || ''

// const REACT_APP_AITDSWAP_V2_STAKE_REWARD_MAIN_SAND = process.env.REACT_APP_AITDSWAP_V2_STAKE_REWARD_MAIN_SAND || ''
const REACT_APP_AITDSWAP_STAKE_REWARD_MAIN_SAND = process.env.REACT_APP_AITDSWAP_STAKE_REWARD_MAIN_SAND || ''
const REACT_APP_AITDSWAP_V2_STAKE_REWARD_MAIN_DYDX = process.env.REACT_APP_AITDSWAP_V2_STAKE_REWARD_MAIN_DYDX || ''
// const REACT_APP_AITDSWAP_V2_STAKE_REWARD_MAIN_MASK = process.env.REACT_APP_AITDSWAP_V2_STAKE_REWARD_MAIN_MASK || ''
// const REACT_APP_AITDSWAP_V2_STAKE_REWARD_MAIN_MATIC = process.env.REACT_APP_AITDSWAP_V2_STAKE_REWARD_MAIN_MATIC || ''
const REACT_APP_AITDSWAP_V2_STAKE_REWARD_MAIN_GTC = process.env.REACT_APP_AITDSWAP_V2_STAKE_REWARD_MAIN_GTC || ''
// const REACT_APP_AITDSWAP_V2_STAKE_REWARD_MAIN_CRET = process.env.REACT_APP_AITDSWAP_V2_STAKE_REWARD_MAIN_CRET || ''

const REACT_APP_CRET_REWARD_ADDRESS = process.env.REACT_APP_CRET_REWARD_ADDRESS || ''

const REACT_APP_AITDSWAP_V3_STAKE_REWARD_MAIN_BTC = process.env.REACT_APP_AITDSWAP_V3_STAKE_REWARD_MAIN_BTC || ''
const REACT_APP_AITDSWAP_V3_STAKE_REWARD_MAIN_ETH = process.env.REACT_APP_AITDSWAP_V3_STAKE_REWARD_MAIN_ETH || ''
const REACT_APP_AITDSWAP_STAKE_REWARD_MAIN_SHIB = process.env.REACT_APP_AITDSWAP_STAKE_REWARD_MAIN_SHIB || ''
const REACT_APP_AITDSWAP_V1_STAKE_REWARD_MAIN_1INCH = process.env.REACT_APP_AITDSWAP_V1_STAKE_REWARD_MAIN_1INCH || ''
const REACT_APP_AITDSWAP_V2_STAKE_REWARD_MAIN_SAND = process.env.REACT_APP_AITDSWAP_V2_STAKE_REWARD_MAIN_SAND || ''

const REACT_APP_AITDSWAP_V2_STAKE_REWARD_MAIN_DYDX_TWO =
  process.env.REACT_APP_AITDSWAP_V2_STAKE_REWARD_MAIN_DYDX_TWO || ''
const REACT_APP_AITDSWAP_V2_STAKE_REWARD_MAIN_MASK_ONE =
  process.env.REACT_APP_AITDSWAP_V2_STAKE_REWARD_MAIN_MASK_ONE || ''
const REACT_APP_AITDSWAP_V2_STAKE_REWARD_MAIN_MATIC_ONE =
  process.env.REACT_APP_AITDSWAP_V2_STAKE_REWARD_MAIN_MATIC_ONE || ''
const REACT_APP_AITDSWAP_V2_STAKE_REWARD_MAIN_GTC_TWO =
  process.env.REACT_APP_AITDSWAP_V2_STAKE_REWARD_MAIN_GTC_TWO || ''

const REACT_APP_AITDSWAP_STAKE_REWARD_MAIN_MANA = process.env.REACT_APP_AITDSWAP_STAKE_REWARD_MAIN_MANA || ''
const REACT_APP_AITDSWAP_V2_STAKE_REWARD_MAIN_SHIB = process.env.REACT_APP_AITDSWAP_V2_STAKE_REWARD_MAIN_SHIB || ''
const REACT_APP_AITDSWAP_STAKE_REWARD_MANA_WAITD = process.env.REACT_APP_AITDSWAP_STAKE_REWARD_MANA_WAITD || ''
const REACT_APP_AITDSWAP_STAKE_REWARD_MATIC_WAITD = process.env.REACT_APP_AITDSWAP_STAKE_REWARD_MATIC_WAITD || ''
const REACT_APP_AITDSWAP_V5_STAKE_REWARD_MAIN_BTC = process.env.REACT_APP_AITDSWAP_V5_STAKE_REWARD_MAIN_BTC || ''
const REACT_APP_AITDSWAP_V5_STAKE_REWARD_MAIN_ETH = process.env.REACT_APP_AITDSWAP_V5_STAKE_REWARD_MAIN_ETH || ''
const REACT_APP_AITDSWAP_V6_STAKE_REWARD_MAIN_BTC = process.env.REACT_APP_AITDSWAP_V6_STAKE_REWARD_MAIN_BTC || ''
const REACT_APP_AITDSWAP_V6_STAKE_REWARD_MAIN_ETH = process.env.REACT_APP_AITDSWAP_V6_STAKE_REWARD_MAIN_ETH || ''

const REACT_APP_AITDSWAP_STAKE_REWARD_FILWAITD = process.env.REACT_APP_AITDSWAP_STAKE_REWARD_FILWAITD || ''
// const REACT_APP_F_AITD_STAKE_REWARD_ADDRESS = process.env.REACT_APP_F_AITD_STAKE_REWARD_ADDRESS || ''
// const REACT_APP_T_AITD_STAKE_REWARD_ADDRESS = process.env.REACT_APP_T_AITD_STAKE_REWARD_ADDRESS || ''
// const REACT_APP_S_AITD_STAKE_REWARD_ADDRESS = process.env.REACT_APP_S_AITD_STAKE_REWARD_ADDRESS || ''

// const REACT_APP_K_AITD_STAKE_REWARD_ADDRESS = process.env.REACT_APP_K_AITD_STAKE_REWARD_ADDRESS || ''
// const REACT_APP_G_AITD_STAKE_REWARD_ADDRESS = process.env.REACT_APP_G_AITD_STAKE_REWARD_ADDRESS || ''
// const REACT_APP_W_AITD_STAKE_REWARD_ADDRESS = process.env.REACT_APP_W_AITD_STAKE_REWARD_ADDRESS || ''
const REACT_APP_W_YOHO_STAKE_REWARD_ADDRESS = process.env.REACT_APP_W_YOHO_STAKE_REWARD_ADDRESS || ''

const REACT_APP_AITDSWAP_V2_STAKE_REWARD_MAIN_DYDX_THREE =
  process.env.REACT_APP_AITDSWAP_V2_STAKE_REWARD_MAIN_DYDX_THREE || ''
const REACT_APP_AITDSWAP_V2_STAKE_REWARD_MAIN_DYDX_FOUR =
  process.env.REACT_APP_AITDSWAP_V2_STAKE_REWARD_MAIN_DYDX_FOUR || ''
const REACT_APP_AITDSWAP_V2_STAKE_REWARD_MAIN_DYDX_FIVE =
  process.env.REACT_APP_AITDSWAP_V2_STAKE_REWARD_MAIN_DYDX_FIVE || ''

const REACT_APP_AITDSWAP_V2_STAKE_REWARD_MAIN_GTC_THREE =
  process.env.REACT_APP_AITDSWAP_V2_STAKE_REWARD_MAIN_GTC_THREE || ''
const REACT_APP_AITDSWAP_V2_STAKE_REWARD_MAIN_GTC_FOUR =
  process.env.REACT_APP_AITDSWAP_V2_STAKE_REWARD_MAIN_GTC_FOUR || ''
const REACT_APP_AITDSWAP_V2_STAKE_REWARD_MAIN_GTC_FIVE =
  process.env.REACT_APP_AITDSWAP_V2_STAKE_REWARD_MAIN_GTC_FIVE || ''

const REACT_APP_AITDSWAP_STAKE_REWARD_MATIC_WAITD_V2 = process.env.REACT_APP_AITDSWAP_STAKE_REWARD_MATIC_WAITD_V2 || ''
const REACT_APP_AITDSWAP_STAKE_REWARD_MATIC_WAITD_V3 = process.env.REACT_APP_AITDSWAP_STAKE_REWARD_MATIC_WAITD_V3 || ''
const REACT_APP_AITDSWAP_STAKE_REWARD_MANA_WAITD_V2 = process.env.REACT_APP_AITDSWAP_STAKE_REWARD_MANA_WAITD_V2 || ''
const REACT_APP_AITDSWAP_STAKE_REWARD_MANA_WAITD_V3 = process.env.REACT_APP_AITDSWAP_STAKE_REWARD_MANA_WAITD_V3 || ''

export const NETWORK_CHAIN_ID: number = parseInt(process.env.REACT_APP_CHAIN_ID ?? '1')

export const STAKING_GENESIS = 1600387200

export const REWARDS_DURATION_DAYS = NETWORK_CHAIN_ID === 1319 ? 400 : 400
export const REWARDS_DURATION_DAYS_CRET = 400

// TODO add staking rewards addresses here
// 双币池
export const STAKING_REWARDS_INFO: {
  [chainId in ChainId]?: {
    tokens: [Token, Token]
    stakingRewardAddress: string
    type?: string
    reward?: string
  }[]
} = {
  [ChainId.MAINNET]: [
    {
      tokens: [WETH[ChainId.MAINNET], DAI],
      stakingRewardAddress: '0xa1484C3aa22a66C62b77E0AE78E15258bd0cB711'
    },
    {
      tokens: [WETH[ChainId.MAINNET], USDC],
      stakingRewardAddress: '0x7FBa4B8Dc5E7616e59622806932DBea72537A56b'
    }
    // {
    //   tokens: [WETH[ChainId.MAINNET], USDT],
    //   stakingRewardAddress: '0x6C3e4cb2E96B01F4b866965A91ed4437839A121a'
    // }
  ],
  [ChainId.AITDTESTNET]: [
    {
      tokens: [CRET, WAIA],
      stakingRewardAddress: '0x215Df14d674bBA8E33C9205ffE69570825C3f795',
      type: 'Phase 1',
      reward: 'CRET'
    },
    {
      tokens: [WAIA, USDT],
      stakingRewardAddress: REACT_APP_AITDSWAP_V2_STAKE_REWARD,
      type: 'one'
    },
    {
      tokens: [WAIA, USDT],
      stakingRewardAddress: REACT_APP_AITDSWAP_V2_STAKE_REWARD_TWO,
      type: 'two'
    },
    {
      tokens: [WAIA, USDT],
      stakingRewardAddress: REACT_APP_AITDSWAP_V2_STAKE_REWARD_THREE,
      type: 'three'
    },
    {
      tokens: [BTC, USDT],
      stakingRewardAddress: REACT_APP_AITDSWAP_V2_STAKE_REWARD_MAIN_BTC
    },
    {
      tokens: [BTC, USDT],
      stakingRewardAddress: REACT_APP_AITDSWAP_V2_STAKE_REWARD_MAIN_BTC_TWO,
      type: 'two'
    },
    {
      tokens: [ETH, USDT],
      stakingRewardAddress: REACT_APP_AITDSWAP_V2_STAKE_REWARD_MAIN_ETH
    },
    {
      tokens: [ETH, USDT],
      stakingRewardAddress: REACT_APP_AITDSWAP_V2_STAKE_REWARD_MAIN_ETH_TWO,
      type: 'two'
    },
    // {
    //   tokens: [STA, USDT],
    //   stakingRewardAddress: REACT_APP_AITDSWAP_V2_STAKE_REWARD_MAIN_CRET,
    //   type: 'two',
    //   reward: 'CRET'
    // },
    // {
    //   tokens: [UNIS, USDT],
    //   stakingRewardAddress: REACT_APP_AITDSWAP_V2_STAKE_REWARD_MAIN_UNI,
    //   type: UNIS.symbol
    // },
    // {
    //   tokens: [SAND, WAIA],
    //   stakingRewardAddress: REACT_APP_AITDSWAP_STAKE_REWARD_MAIN_SAND,
    // },
    {
      tokens: [SAND, WAIA],
      stakingRewardAddress: REACT_APP_AITDSWAP_V2_STAKE_REWARD_MAIN_SAND
    },
    {
      tokens: [DYDX, WAIA],
      stakingRewardAddress: REACT_APP_AITDSWAP_V2_STAKE_REWARD_MAIN_DYDX
    },
    {
      tokens: [DYDX, WAIA],
      stakingRewardAddress: REACT_APP_AITDSWAP_V2_STAKE_REWARD_MAIN_DYDX_TWO,
      type: 'two'
    },
    {
      tokens: [SHIB, WAIA],
      stakingRewardAddress: REACT_APP_AITDSWAP_V2_STAKE_REWARD_MAIN_SHIB
    },
    {
      tokens: [MASK, USDT],
      stakingRewardAddress: REACT_APP_AITDSWAP_V2_STAKE_REWARD_MAIN_MASK_ONE
    },
    {
      tokens: [MATIC, USDT],
      stakingRewardAddress: REACT_APP_AITDSWAP_V2_STAKE_REWARD_MAIN_MATIC_ONE
    },
    {
      tokens: [GTC, WAIA],
      stakingRewardAddress: REACT_APP_AITDSWAP_V2_STAKE_REWARD_MAIN_GTC
    },
    {
      tokens: [GTC, WAIA],
      stakingRewardAddress: REACT_APP_AITDSWAP_V2_STAKE_REWARD_MAIN_GTC_TWO,
      type: 'two'
    },
    {
      tokens: [INCH, USDT],
      stakingRewardAddress: REACT_APP_AITDSWAP_V1_STAKE_REWARD_MAIN_1INCH
    },
    {
      tokens: [MANA, USDT],
      stakingRewardAddress: REACT_APP_AITDSWAP_STAKE_REWARD_MAIN_MANA
    },
    {
      tokens: [WAIA, USDT],
      stakingRewardAddress: '0x5637ce6D1E4518E76E6ff95d7112Cc304d1Fa4f5',
      type: 'Phase 1'
    },
    {
      tokens: [OXG, WAIA],
      stakingRewardAddress: '0x78FD1D718E886EC6785f9064465010CDbc390fB0',
      type: 'Phase 1',
      reward: 'OXX'
    },
    {
      tokens: [OXX, WAIA],
      stakingRewardAddress: '0x52d72f194C532CB66D7872B06b8810d30EA59181',
      type: 'Phase 1',
      reward: 'OXG'
    }
  ],
  [ChainId.AITDMAINNET]: [
    {
      tokens: [CACII, WAIA],
      stakingRewardAddress: '0x55BAc86d6d7F112543784Fcdf7d2E94bC2EE46a6',
      type: 'Phase 2',
      reward: 'CADS'
    },
    {
      tokens: [WAIA, USDT],
      stakingRewardAddress: '0x573d649368FC24EEaF0eEEbEeDFF3D28F57D32F8',
      type: 'Phase 14',
      reward: 'CADS'
    },
    {
      tokens: [CADSII, WAIA],
      stakingRewardAddress: '0x81D6E23566F49d6F4bd16889f134161D6F51777F',
      type: 'Phase 1',
      reward: 'CADS'
    },
    {
      tokens: [WAIA, USDT],
      stakingRewardAddress: '0x41c12F09f603AB2d03764080964B6103D724d13b',
      type: 'Phase 13'
    },
    {
      tokens: [WAIA, USDT],
      stakingRewardAddress: '0x8c47caB8da6e86e5e8057abD4cc7b57F91224c6E',
      type: 'Phase 12'
    },
    {
      tokens: [CRET, WAIA],
      stakingRewardAddress: '0xeDbdCB4b2be8e629Ff2D9248401b4A80CA4E7112',
      type: 'Phase 1',
      reward: 'CRET'
    },
    {
      tokens: [WAIA, USDT],
      stakingRewardAddress: '0x1468650689d7D2e12eB00eb92277DF83377DB273',
      type: 'Phase 11'
    },
    {
      tokens: [WAIA, USDT],
      stakingRewardAddress: '0xDf694c02BE92248BBe535CBEdbDA4aDD3F6c370f',
      type: 'Phase 10'
    },
    {
      tokens: [WAIA, USDT],
      stakingRewardAddress: '0xB29d5321c8f434f84Cb12b2d4236902940f495cF',
      type: 'Phase 9'
    },
    {
      tokens: [WAIA, USDT],
      stakingRewardAddress: REACT_APP_AITDSWAP_V2_STAKE_REWARD_FOUR,
      type: 'Phase 4'
    },
    {
      tokens: [WAIA, USDT],
      stakingRewardAddress: REACT_APP_AITDSWAP_V2_STAKE_REWARD_FIVE,
      type: 'Phase 5'
    },
    {
      tokens: [WAIA, USDT],
      stakingRewardAddress: REACT_APP_AITDSWAP_V2_STAKE_REWARD_SIX,
      type: 'Phase 6'
    },
    {
      tokens: [WAIA, USDT],
      stakingRewardAddress: REACT_APP_AITDSWAP_V2_STAKE_REWARD_THREE,
      type: 'three'
    },
    {
      tokens: [WAIA, USDT],
      stakingRewardAddress: REACT_APP_AITDSWAP_V2_STAKE_REWARD,
      type: 'one'
    },
    {
      tokens: [WAIA, USDT],
      stakingRewardAddress: REACT_APP_AITDSWAP_V2_STAKE_REWARD_TWO,
      type: 'two'
    },
    {
      tokens: [BTC, USDT],
      stakingRewardAddress: REACT_APP_AITDSWAP_V6_STAKE_REWARD_MAIN_BTC,
      type: 'Phase 4'
    },
    {
      tokens: [BTC, USDT],
      stakingRewardAddress: REACT_APP_AITDSWAP_V5_STAKE_REWARD_MAIN_BTC,
      type: 'three'
    },
    {
      tokens: [ETH, USDT],
      stakingRewardAddress: REACT_APP_AITDSWAP_V5_STAKE_REWARD_MAIN_ETH,
      type: 'three'
    },
    {
      tokens: [ETH, USDT],
      stakingRewardAddress: REACT_APP_AITDSWAP_V6_STAKE_REWARD_MAIN_ETH,
      type: 'Phase 4'
    },
    {
      tokens: [BTC, USDT],
      stakingRewardAddress: REACT_APP_AITDSWAP_V3_STAKE_REWARD_MAIN_BTC,
      type: 'two'
    },
    {
      tokens: [ETH, USDT],
      stakingRewardAddress: REACT_APP_AITDSWAP_V3_STAKE_REWARD_MAIN_ETH,
      type: 'two'
    },
    {
      tokens: [BTC, USDT],
      stakingRewardAddress: REACT_APP_AITDSWAP_V2_STAKE_REWARD_MAIN_BTC
    },
    {
      tokens: [ETH, USDT],
      stakingRewardAddress: REACT_APP_AITDSWAP_V2_STAKE_REWARD_MAIN_ETH
    },
    {
      tokens: [FIL, WAIA],
      stakingRewardAddress: REACT_APP_AITDSWAP_STAKE_REWARD_FILWAITD
    },
    {
      tokens: [SAND, WAIA],
      stakingRewardAddress: REACT_APP_AITDSWAP_STAKE_REWARD_MAIN_SAND
    },
    {
      tokens: [SHIB, WAIA],
      stakingRewardAddress: REACT_APP_AITDSWAP_STAKE_REWARD_MAIN_SHIB
    },
    // {
    //   tokens: [STA, USDT],
    //   stakingRewardAddress: REACT_APP_AITDSWAP_V2_STAKE_REWARD_MAIN_CRET,
    //   type: 'two',
    //   reward: 'CRET'
    // },
    {
      tokens: [DYDX, WAIA],
      stakingRewardAddress: REACT_APP_AITDSWAP_V2_STAKE_REWARD_MAIN_DYDX
    },
    {
      tokens: [DYDX, WAIA],
      stakingRewardAddress: REACT_APP_AITDSWAP_V2_STAKE_REWARD_MAIN_DYDX_TWO,
      type: 'two'
    },
    {
      tokens: [DYDX, WAIA],
      stakingRewardAddress: REACT_APP_AITDSWAP_V2_STAKE_REWARD_MAIN_DYDX_THREE,
      type: 'three'
    },
    {
      tokens: [DYDX, WAIA],
      stakingRewardAddress: REACT_APP_AITDSWAP_V2_STAKE_REWARD_MAIN_DYDX_FOUR,
      type: 'Phase 4'
    },
    {
      tokens: [DYDX, WAIA],
      stakingRewardAddress: REACT_APP_AITDSWAP_V2_STAKE_REWARD_MAIN_DYDX_FIVE,
      type: 'Phase 5'
    },
    {
      tokens: [GTC, WAIA],
      stakingRewardAddress: REACT_APP_AITDSWAP_V2_STAKE_REWARD_MAIN_GTC
    },
    {
      tokens: [GTC, WAIA],
      stakingRewardAddress: REACT_APP_AITDSWAP_V2_STAKE_REWARD_MAIN_GTC_TWO,
      type: 'two'
    },
    {
      tokens: [GTC, WAIA],
      stakingRewardAddress: REACT_APP_AITDSWAP_V2_STAKE_REWARD_MAIN_GTC_THREE,
      type: 'three'
    },
    {
      tokens: [GTC, WAIA],
      stakingRewardAddress: REACT_APP_AITDSWAP_V2_STAKE_REWARD_MAIN_GTC_FOUR,
      type: 'Phase 4'
    },
    {
      tokens: [GTC, WAIA],
      stakingRewardAddress: REACT_APP_AITDSWAP_V2_STAKE_REWARD_MAIN_GTC_FIVE,
      type: 'Phase 5'
    },
    {
      tokens: [MANA, WAIA],
      stakingRewardAddress: REACT_APP_AITDSWAP_STAKE_REWARD_MANA_WAITD
    },

    {
      tokens: [MANA, WAIA],
      stakingRewardAddress: REACT_APP_AITDSWAP_STAKE_REWARD_MANA_WAITD_V2,
      type: 'two'
    },
    {
      tokens: [MANA, WAIA],
      stakingRewardAddress: REACT_APP_AITDSWAP_STAKE_REWARD_MANA_WAITD_V3,
      type: 'three'
    },
    {
      tokens: [MATIC, WAIA],
      stakingRewardAddress: REACT_APP_AITDSWAP_STAKE_REWARD_MATIC_WAITD
    },
    {
      tokens: [MATIC, WAIA],
      stakingRewardAddress: REACT_APP_AITDSWAP_STAKE_REWARD_MATIC_WAITD_V2,
      type: 'two'
    },
    {
      tokens: [MATIC, WAIA],
      stakingRewardAddress: REACT_APP_AITDSWAP_STAKE_REWARD_MATIC_WAITD_V3,
      type: 'three'
    },
    // {
    //   tokens: [ADA, USDT],
    //   stakingRewardAddress: REACT_APP_AITDSWAP_V2_STAKE_REWARD_MAIN_ADA
    // }
    {
      tokens: [WAIA, USDT],
      stakingRewardAddress: '0xE41c39F21290A926FC68b4c2d1D39df1dA51D0B5',
      type: 'Phase 7'
    },
    {
      tokens: [MANA, WAIA],
      stakingRewardAddress: '0xEF36F7fD44F7CDBf8de8Fa3856369c532F2670Ae',
      type: 'Phase 4'
    },
    {
      tokens: [MATIC, WAIA],
      stakingRewardAddress: '0x6E3994B29c21b26357823A0F9F77E7EC341068ad',
      type: 'Phase 4'
    },
    {
      tokens: [WAIA, USDT],
      stakingRewardAddress: '0xd765f9C2F51Ef5C68950a98435756e9E134B2206',
      type: 'Phase 8'
    },
    {
      tokens: [MANA, WAIA],
      stakingRewardAddress: '0x187FeE3C0577C1458825F35eb8dF6b063BCfF564',
      type: 'Phase 5'
    },
    {
      tokens: [MATIC, WAIA],
      stakingRewardAddress: '0x222b66720aDf9f005427ac0ffb5253366506f8E5',
      type: 'Phase 5'
    },
    {
      tokens: [MANA, WAIA],
      stakingRewardAddress: '0x14143eDBf67857C31d263D370097Aa3511dfa1E3',
      type: 'Phase 6'
    },
    {
      tokens: [MATIC, WAIA],
      stakingRewardAddress: '0xf06b2c2b18A49010188A1e98f4A50a09ef181811',
      type: 'Phase 6'
    },
    {
      tokens: [CRET, WAIA],
      stakingRewardAddress: '0x7D7dCF0950fAC83482b06BA0b41494ACE681c6f0',
      type: 'Phase 2'
    },
    {
      tokens: [OXG, WAIA],
      stakingRewardAddress: '0x9CB0838e75AF96444c21176E74F63a77ef3490Ed',
      type: 'Phase 1',
      reward: 'OXX'
    },
    {
      tokens: [OXX, WAIA],
      stakingRewardAddress: '0xc3DD4Bf120Cc06D4f29416304E233c98e34d3403',
      type: 'Phase 1',
      reward: 'OXG'
    },
    {
      tokens: [CADS, WAIA],
      stakingRewardAddress: '0x3BC0ce21DC65205fb6DaF44991F46B074417113d',
      type: 'Phase 1',
      reward: 'CADS'
    },
    {
      tokens: [YOHO, WAIA],
      stakingRewardAddress: '0x5C454DFc3D1f5B727347EA10AF7d7F6B6bb4bdbF',
      type: 'Phase 1',
      reward: 'YOHO'
    },
    {
      tokens: [YOHO, BNB],
      stakingRewardAddress: '0xA850a00F17CC80BBf2EB30232A73E332039c6C7B',
      type: 'Phase 1',
      reward: 'YOHO'
    },
    {
      tokens: [CAC, WAIA],
      stakingRewardAddress: '0xAf2DBD78F3521B234F8b5d4183D02AFFA6ea0013',
      type: 'Phase 1',
      reward: 'CADS'
    }
  ]
}

export const STAKING_REWARDS_CRET_INFO: {
  [chainId in ChainId]?: {
    tokens: [Token]
    stakingRewardAddress: string
    type?: string
    reward?: string
    unlock?: boolean // 用于配置不锁仓
    rewardToken?: Token // 奖励计算
  }[]
} = {
  // 单币池
  [ChainId.AITDTESTNET]: [
    {
      tokens: [CAD],
      stakingRewardAddress: '0x5ce6daa2FdaD1E1e58c3524AA0F54A9E406d9B3E',
      reward: 'USDT',
      type: '全球分红二期-4',
      rewardToken: USDT
    },
    {
      tokens: [CAD],
      stakingRewardAddress: '0x4C6763c15b038aAE59bAb7DBEf00b05a97B5Fbad',
      reward: 'USDT',
      type: '全球分红二期-5',
      rewardToken: USDT
    },
    {
      tokens: [CAD],
      stakingRewardAddress: '0xFF01Ac18e2e1CB11f14A2a92d6e7D82787469268',
      reward: 'USDT',
      type: '全球分红二期-1',
      rewardToken: USDT
    },
    {
      tokens: [CAD],
      stakingRewardAddress: '0xF183fF12D4d807CDf720eE457FD40356704940c0',
      reward: 'USDT',
      type: '全球分红二期-2',
      rewardToken: USDT
    },
    {
      tokens: [CAD],
      stakingRewardAddress: '0x7F3397202546119DEf60ed2A8C33245258D4a1B5',
      reward: 'USDT',
      type: '全球分红二期-3',
      rewardToken: USDT
    },
    {
      tokens: [CAD],
      stakingRewardAddress: '0xF73f574c6f0D6A0df4Ff8bbD333BEa26F08C82ce',
      reward: 'USDT',
      type: '全球分红二期',
      rewardToken: USDT
    },
    {
      tokens: [CAD],
      stakingRewardAddress: '0x63A093FBfAe84cDFf8fcD3a28c3f64fCa9cE04DD',
      type: '全球分红一期',
      reward: 'USDT',
      rewardToken: USDT,
      unlock: true
    },
    {
      tokens: [CAD],
      stakingRewardAddress: '0x9D6d8AcAD0a923fF7b3aa7CCAd550E11B28A8EA0',
      reward: 'USDT',
      unlock: true
    },
    {
      tokens: [CAD],
      stakingRewardAddress: '0xf257835F8e396Da0529541a91DAf64ac5AF1eB85',
      reward: 'USDT',
      unlock: true
    },
    {
      tokens: [CRET],
      stakingRewardAddress: REACT_APP_CRET_REWARD_ADDRESS
    }
    // {
    //   tokens: [FAIA],
    //   stakingRewardAddress: REACT_APP_F_AITD_STAKE_REWARD_ADDRESS
    // },
    // {
    //   tokens: [TAIA],
    //   stakingRewardAddress: REACT_APP_T_AITD_STAKE_REWARD_ADDRESS
    // },
    // {
    //   tokens: [SAIA],
    //   stakingRewardAddress: REACT_APP_S_AITD_STAKE_REWARD_ADDRESS
    // },
  ],

  [ChainId.AITDMAINNET]: [
    {
      tokens: [BMT],
      stakingRewardAddress: '0x3c58e1e7B842BfC182a2C0d24fc8638aB3F998d8',
      type: 'Launchpad首发奖池',
      reward: 'AIA',
      rewardToken: WAIA,
      unlock: true
    },
    {
      tokens: [CAD],
      stakingRewardAddress: '0xA7Db3C93dd7F110fB50fcf503c9301b0319453B5',
      reward: 'USDT',
      type: '全球分红三期-3',
      rewardToken: USDT
    },
    {
      tokens: [CAD],
      stakingRewardAddress: '0xAf46334841adc260410673aC59f6db305165E0A5',
      reward: 'USDT',
      type: '全球分红三期-1',
      rewardToken: USDT
    },
    {
      tokens: [CAD],
      stakingRewardAddress: '0x10fb9fCF39D56282Ea015c24455e3D40948E45Cc',
      reward: 'USDT',
      type: '全球分红三期-2',
      rewardToken: USDT
    },
    {
      tokens: [CAD],
      stakingRewardAddress: '0xB956e4Bc8Dce2bF1DFdD5e6809662daD7C9D8e45',
      reward: 'USDT',
      type: '全球分红二期-4',
      rewardToken: USDT
    },
    {
      tokens: [CAD],
      stakingRewardAddress: '0xd58574A7cb7a1411522F2A95DaDa3FE8e115f213',
      reward: 'USDT',
      type: '全球分红二期-5',
      rewardToken: USDT
    },
    {
      tokens: [CAD],
      stakingRewardAddress: '0xa2f9adc0047C6821886E7dC5191eAC104e2ED6Cd',
      reward: 'USDT',
      type: '全球分红二期-1',
      rewardToken: USDT
    },
    {
      tokens: [CAD],
      stakingRewardAddress: '0xD76BAc6e8EE70e7Ec643430F667caacE45fb5a1E',
      reward: 'USDT',
      type: '全球分红二期-2',
      rewardToken: USDT
    },
    {
      tokens: [CAD],
      stakingRewardAddress: '0x2d4d0E57a63e3aBFA46C7cc8E21D4176666EC810',
      reward: 'USDT',
      type: '全球分红二期-3',
      rewardToken: USDT
    },
    {
      tokens: [CAD],
      stakingRewardAddress: '0x3929Cc310Dd44207A97b8e150D05282060c8642C',
      type: '全球分红一期',
      reward: 'USDT',
      rewardToken: USDT,
      unlock: true
    },
    {
      tokens: [CRET],
      stakingRewardAddress: REACT_APP_CRET_REWARD_ADDRESS
    },

    {
      tokens: [WYOHO],
      stakingRewardAddress: REACT_APP_W_YOHO_STAKE_REWARD_ADDRESS
    }
    // {
    //   tokens: [KiAIA],
    //   stakingRewardAddress: REACT_APP_K_AITD_STAKE_REWARD_ADDRESS
    // },
    // {
    //   tokens: [GaAIA],
    //   stakingRewardAddress: REACT_APP_G_AITD_STAKE_REWARD_ADDRESS
    // },
    // {
    //   tokens: [WinAIA],
    //   stakingRewardAddress: REACT_APP_W_AITD_STAKE_REWARD_ADDRESS
    // },
    // {
    //   tokens: [FAIA],
    //   stakingRewardAddress: REACT_APP_F_AITD_STAKE_REWARD_ADDRESS
    // },
    // {
    //   tokens: [TAIA],
    //   stakingRewardAddress: REACT_APP_T_AITD_STAKE_REWARD_ADDRESS
    // },
    // {
    //   tokens: [SAIA],
    //   stakingRewardAddress: REACT_APP_S_AITD_STAKE_REWARD_ADDRESS
    // },
  ]
}

export interface StakingInfo {
  // the address of the reward contract
  stakingRewardAddress: string
  // the tokens involved in this pair
  tokens: [Token, Token]
  // the amount of token currently staked, or undefined if no account
  stakedAmount: TokenAmount
  // the amount of reward token earned by the active account, or undefined if no account
  earnedAmount: TokenAmount
  // the total amount of token staked in the contract
  totalStakedAmount: TokenAmount
  // the amount of token distributed per second to all LPs, constant
  totalRewardRate: TokenAmount
  // the current amount of token distributed to the active account per second.

  // equivalent to percent of total supply * reward rate
  rewardRate: TokenAmount
  // when the period ends
  periodFinish: Date | undefined
  // if pool is active
  active: boolean
  type?: string
  reward?: string

  // calculates a hypothetical amount of token distributed to the active account per second.
  getHypotheticalRewardRate: (
    stakedAmount: TokenAmount,
    totalStakedAmount: TokenAmount,
    totalRewardRate: TokenAmount
  ) => TokenAmount
}

export function useStakingTime(stakingEndTime: any) {
  const MINUTE = 60
  const HOUR = MINUTE * 60
  const DAY = HOUR * 24
  const REWARDS_DURATION = DAY * REWARDS_DURATION_DAYS
  const now = Math.floor(Date.now() / 1000)
  const end = useMemo(
    () => (stakingEndTime ? Math.floor(stakingEndTime.getTime() / 1000) : STAKING_GENESIS + REWARDS_DURATION),
    [REWARDS_DURATION, stakingEndTime]
  )
  return end - now < 0
}

// gets the staking info from the network for the active chain id
export function useStakingInfoEarn(finished: number, rewardAddress?: any): StakingInfo[] {
  const { chainId, account } = useActiveWeb3React()

  const poolDatas = usePoolDatas()
  // rewardAddress = rewardAddress?.filter((i: any) => i.stakingToken !== 'CRET') || []
  const currentBlockTimestamp = useCurrentBlockTimestamp()
  const info = useMemo(() => {
    if (NETWORK_CHAIN_ID !== chainId) {
      return []
    }
    // console.log(!rewardAddress)
    return chainId
      ? STAKING_REWARDS_INFO[chainId]?.filter(stakingRewardInfo => {
          if (!rewardAddress) return true
          return (
            (rewardAddress?.[stakingRewardInfo.stakingRewardAddress.toLocaleLowerCase()]?.finished || 0) === finished
          )
        }) ?? []
      : []
  }, [chainId, rewardAddress, finished])

  const uni = chainId ? UNI[chainId] : undefined

  const rewardsAddresses = useMemo(() => info.map(({ stakingRewardAddress }) => stakingRewardAddress), [info])
  const accountArg = useMemo(() => [account ?? undefined], [account])
  // get all the info from the staking rewards contracts
  const balances = useMultipleContractSingleData(rewardsAddresses, STAKING_REWARDS_INTERFACE, 'balanceOf', accountArg)
  const earnedAmounts = useMultipleContractSingleData(rewardsAddresses, STAKING_REWARDS_INTERFACE, 'earned', accountArg)
  const totalSupplies = useMultipleContractSingleData(rewardsAddresses, STAKING_REWARDS_INTERFACE, 'totalSupply')

  // tokens per second, constants
  const rewardRates = useMultipleContractSingleData(
    rewardsAddresses,
    STAKING_REWARDS_INTERFACE,
    'rewardRate',
    undefined
  )
  const periodFinishes = useMultipleContractSingleData(
    rewardsAddresses,
    STAKING_REWARDS_INTERFACE,
    'periodFinish',
    undefined
  )

  const contractData = useMemo(() => {
    if (!chainId || !uni) return []

    return rewardsAddresses.reduce<StakingInfo[]>((memo, rewardsAddress, index) => {
      const status = {
        balanceState: balances[index],
        earnedAmountState: earnedAmounts[index],
        totalSupplyState: totalSupplies[index],
        rewardRateState: rewardRates[index],
        periodFinishState: periodFinishes[index]
      }

      if (isLoading(status)) {
        if (isError(status)) {
          // console.error('Failed to load staking rewards info')
          return memo
        }
        const datas = commonFunData({ ...status, info: info[index], uni, currentBlockTimestamp })
        const result: any = { stakingRewardAddress: rewardsAddress, ...datas }
        memo.push(result)
      }
      return memo
    }, [])
  }, [
    balances,
    chainId,
    currentBlockTimestamp,
    earnedAmounts,
    info,
    periodFinishes,
    rewardRates,
    rewardsAddresses,
    totalSupplies,
    uni
  ])

  const getPoolDatas = useMemo(() => {
    if (!chainId || !uni || !poolDatas || poolDatas === null) return []
    const lastAddress = rewardsAddresses.filter((keys: string) => {
      return !!poolDatas[keys]
    })
    return lastAddress.reduce<any>((memo, rewardsAddress, index) => {
      const current = poolDatas[rewardsAddress]
      const status = stateDatas(current)
      const datas = commonFunData({ ...status, info: info[index], uni, currentBlockTimestamp })
      const result: any = { stakingRewardAddress: rewardsAddress, ...datas }
      memo.push(result)
      return memo
    }, [])
  }, [
    balances,
    chainId,
    currentBlockTimestamp,
    earnedAmounts,
    info,
    periodFinishes,
    rewardRates,
    rewardsAddresses,
    totalSupplies,
    uni,
    poolDatas
  ])

  if (poolDatas === null || poolDatas !== undefined) {
    if (poolDatas === null || !uni) return []
    return getPoolDatas
  } else {
    // console.info(contractData,'contractDatacontractData')
    return contractData
  }
}

// gets the staking info from the network for the active chain id
export function useStakingInfo(pairToFilterBy?: Pair | null): StakingInfo[] {
  const { chainId, account } = useActiveWeb3React()
  // detect if staking is ended
  const currentBlockTimestamp = useCurrentBlockTimestamp()
  const info = useMemo(
    () =>
      chainId
        ? STAKING_REWARDS_INFO[chainId]?.filter(stakingRewardInfo =>
            pairToFilterBy === undefined
              ? true
              : pairToFilterBy === null
              ? false
              : pairToFilterBy.involvesToken(stakingRewardInfo.tokens[0]) &&
                pairToFilterBy.involvesToken(stakingRewardInfo.tokens[1])
          ) ?? []
        : [],
    [chainId, pairToFilterBy]
  )
  const uni = chainId ? UNI[chainId] : undefined
  const rewardsAddresses = useMemo(() => info.map(({ stakingRewardAddress }) => stakingRewardAddress), [info])
  const accountArg = useMemo(() => [account ?? undefined], [account])

  // get all the info from the staking rewards contracts
  const balances = useMultipleContractSingleData(rewardsAddresses, STAKING_REWARDS_INTERFACE, 'balanceOf', accountArg)
  const earnedAmounts = useMultipleContractSingleData(rewardsAddresses, STAKING_REWARDS_INTERFACE, 'earned', accountArg)
  const totalSupplies = useMultipleContractSingleData(rewardsAddresses, STAKING_REWARDS_INTERFACE, 'totalSupply')
  // tokens per second, constants
  const rewardRates = useMultipleContractSingleData(
    rewardsAddresses,
    STAKING_REWARDS_INTERFACE,
    'rewardRate',
    undefined,
    NEVER_RELOAD
  )
  const periodFinishes = useMultipleContractSingleData(
    rewardsAddresses,
    STAKING_REWARDS_INTERFACE,
    'periodFinish',
    undefined,
    NEVER_RELOAD
  )

  return useMemo(() => {
    if (!chainId || !uni) return []
    return rewardsAddresses.reduce<StakingInfo[]>((memo, rewardsAddress, index) => {
      // these two are dependent on accoun
      const status = {
        balanceState: balances[index],
        earnedAmountState: earnedAmounts[index],
        totalSupplyState: totalSupplies[index],
        rewardRateState: rewardRates[index],
        periodFinishState: periodFinishes[index]
      }
      if (isLoading(status)) {
        if (isError(status)) {
          console.error('Failed to load staking rewards info')
          return memo
        }
        const datas = commonFunData({ ...status, info: info[index], uni, currentBlockTimestamp })
        const result: any = { stakingRewardAddress: rewardsAddress, ...datas }
        memo.push(result)
      }
      return memo
    }, [])
  }, [
    balances,
    chainId,
    currentBlockTimestamp,
    earnedAmounts,
    info,
    periodFinishes,
    rewardRates,
    rewardsAddresses,
    totalSupplies,
    uni
  ])
}

export function useCretStakingInfo(pairToFilterBy?: Pair | null) {
  const { chainId, account } = useActiveWeb3React()
  // detect if staking is ended
  const currentBlockTimestamp = useCurrentBlockTimestamp()
  const info = useMemo(
    () =>
      chainId
        ? STAKING_REWARDS_CRET_INFO[chainId]?.filter(stakingRewardInfo =>
            pairToFilterBy === undefined
              ? true
              : pairToFilterBy === null
              ? false
              : pairToFilterBy.involvesToken(stakingRewardInfo.tokens[0])
          ) ?? []
        : [],
    [chainId, pairToFilterBy]
  )
  const uni = chainId ? UNI[chainId] : undefined

  const rewardsAddresses = useMemo(() => info.map(({ stakingRewardAddress }) => stakingRewardAddress), [info])

  const accountArg = useMemo(() => [account ?? undefined], [account])

  // get all the info from the staking rewards contracts
  const balances = useMultipleContractSingleData(rewardsAddresses, STAKING_REWARDS_INTERFACE, 'balanceOf', accountArg)
  const earnedAmounts = useMultipleContractSingleData(rewardsAddresses, STAKING_REWARDS_INTERFACE, 'earned', accountArg)
  const totalSupplies = useMultipleContractSingleData(rewardsAddresses, STAKING_REWARDS_INTERFACE, 'totalSupply')

  // tokens per second, constants
  const rewardRates = useMultipleContractSingleData(
    rewardsAddresses,
    STAKING_REWARDS_INTERFACE,
    'rewardRate',
    undefined,
    NEVER_RELOAD
  )
  const periodFinishes = useMultipleContractSingleData(
    rewardsAddresses,
    STAKING_REWARDS_INTERFACE,
    'periodFinish',
    undefined,
    NEVER_RELOAD
  )

  return useMemo(() => {
    if (!chainId || !uni) return []

    return rewardsAddresses.reduce<any[]>((memo, rewardsAddress, index) => {
      // these two are dependent on account
      const balanceState = balances[index]
      const earnedAmountState = earnedAmounts[index]

      // these get fetched regardless of account
      const totalSupplyState = totalSupplies[index]
      const rewardRateState = rewardRates[index]
      const periodFinishState = periodFinishes[index]

      if (
        // these may be undefined if not logged in
        !balanceState?.loading &&
        !earnedAmountState?.loading &&
        // always need these
        totalSupplyState &&
        !totalSupplyState.loading &&
        rewardRateState &&
        !rewardRateState.loading &&
        periodFinishState &&
        !periodFinishState.loading
      ) {
        if (
          balanceState?.error ||
          earnedAmountState?.error ||
          totalSupplyState.error ||
          rewardRateState.error ||
          periodFinishState.error
        ) {
          console.error('Failed to load staking rewards info')
          return memo
        }

        // get the LP token
        const tokens = info[index].tokens
        // const dummyPair = new Pair(new TokenAmount(tokens[0], '0'), new TokenAmount(tokens[0], '0'))
        // // check for account, if no account set to 0
        // console.log(tokens,CRET,'tokenstokenstokens');

        // 兼容当奖励代币精度非18位时，如奖励代币是USDT
        const currentUni = info[index].rewardToken || uni
        const stakedAmount = new TokenAmount(tokens[0], JSBI.BigInt(balanceState?.result?.[0] ?? 0))
        const totalStakedAmount = new TokenAmount(tokens[0], JSBI.BigInt(totalSupplyState?.result?.[0] ?? 0))
        const totalRewardRate = new TokenAmount(
          info[index].rewardToken || tokens[0],
          JSBI.BigInt(rewardRateState?.result?.[0] ?? 0)
        )
        const getHypotheticalRewardRate = (
          stakedAmount: TokenAmount,
          totalStakedAmount: TokenAmount,
          totalRewardRate: TokenAmount
        ): TokenAmount => {
          // mainnet
          // const list = ['0xa2f9adc0047C6821886E7dC5191eAC104e2ED6Cd', '0xD76BAc6e8EE70e7Ec643430F667caacE45fb5a1E', '0x2d4d0E57a63e3aBFA46C7cc8E21D4176666EC810'];
          // testnet
          // const list = ['0xFF01Ac18e2e1CB11f14A2a92d6e7D82787469268', '0xF183fF12D4d807CDf720eE457FD40356704940c0', '0x7F3397202546119DEf60ed2A8C33245258D4a1B5']
          // if (list.includes(info[index].stakingRewardAddress)) {
          //   console.log('currentUni: ', currentUni, info[index].stakingRewardAddress)
          // }
          return new TokenAmount(
            currentUni,
            JSBI.greaterThan(totalStakedAmount.raw, JSBI.BigInt(0))
              ? JSBI.divide(JSBI.multiply(totalRewardRate.raw, stakedAmount.raw), totalStakedAmount.raw)
              : JSBI.BigInt(0)
          )
        }
        const individualStakedAmount = new TokenAmount(
          info[index].rewardToken || tokens[0],
          JSBI.BigInt(balanceState?.result?.[0] ?? 0)
        )
        const individualRewardRate = getHypotheticalRewardRate(
          individualStakedAmount,
          totalStakedAmount,
          totalRewardRate
        )
        const periodFinishSeconds = periodFinishState?.result?.[0]?.toNumber() || 0
        const periodFinishMs = periodFinishSeconds * 1000
        // compare period end timestamp vs current block timestamp (in seconds)
        const active =
          periodFinishSeconds && currentBlockTimestamp ? periodFinishSeconds > currentBlockTimestamp.toNumber() : true

        memo.push({
          stakingRewardAddress: rewardsAddress,
          tokens: info[index].tokens,
          reward: info[index]?.reward, // 配置奖励代币
          unlock: info[index]?.unlock, // 设置不锁仓
          periodFinish: periodFinishMs > 0 ? new Date(periodFinishMs) : undefined,
          earnedAmount: new TokenAmount(currentUni, JSBI.BigInt(earnedAmountState?.result?.[0] ?? 0)),
          rewardRate: individualRewardRate,
          stakedAmount: stakedAmount,
          totalStakedAmount: totalStakedAmount,
          totalRewardRate: totalRewardRate,
          getHypotheticalRewardRate,
          active,
          type: info[index].type
        })
      }
      return memo
    }, [])
  }, [
    balances,
    chainId,
    currentBlockTimestamp,
    earnedAmounts,
    info,
    periodFinishes,
    rewardRates,
    rewardsAddresses,
    totalSupplies,
    uni
  ])
}

export function useTotalUniEarned(): TokenAmount | undefined {
  const { chainId } = useActiveWeb3React()
  const uni = chainId ? UNI[chainId] : undefined
  const stakingInfos = useStakingInfo()
  return useMemo(() => {
    if (!uni) return undefined
    return (
      stakingInfos?.reduce((accumulator, stakingInfo) => {
        const [token0] = stakingInfo.tokens
        if (stakingInfo.type === token0.symbol) {
          return accumulator
        }
        return accumulator.add(stakingInfo.earnedAmount)
      }, new TokenAmount(uni, '0')) ?? new TokenAmount(uni, '0')
    )
  }, [stakingInfos, uni])
}

// based on typed value
export function useDerivedStakeInfo(
  typedValue: string,
  stakingToken: Token,
  userLiquidityUnstaked: TokenAmount | undefined
): {
  parsedAmount?: CurrencyAmount
  error?: string
} {
  const { account } = useActiveWeb3React()

  const parsedInput: CurrencyAmount | undefined = tryParseAmount(typedValue, stakingToken)

  const parsedAmount =
    parsedInput && userLiquidityUnstaked && JSBI.lessThanOrEqual(parsedInput.raw, userLiquidityUnstaked.raw)
      ? parsedInput
      : undefined

  const { t } = useTranslation()

  let error: string | undefined
  if (!account) {
    error = 'Connect Wallet'
  }
  if (!parsedAmount) {
    error = error ?? t('enterAnAmount')
  }

  return {
    parsedAmount,
    error
  }
}

// based on typed value
export function useDerivedUnstakeInfo(
  typedValue: string,
  stakingAmount: TokenAmount
): {
  parsedAmount?: CurrencyAmount
  error?: string
} {
  const { account } = useActiveWeb3React()

  const parsedInput: CurrencyAmount | undefined = tryParseAmount(typedValue, stakingAmount.token)

  const parsedAmount = parsedInput && JSBI.lessThanOrEqual(parsedInput.raw, stakingAmount.raw) ? parsedInput : undefined

  const { t } = useTranslation()

  let error: string | undefined
  if (!account) {
    error = 'Connect Wallet'
  }
  if (!parsedAmount) {
    error = error ?? t('enterAnAmount')
  }

  return {
    parsedAmount,
    error
  }
}
