
import React from 'react'
import { useTranslation } from 'react-i18next'
import { ORDER_CATEGORY } from '../../types'
import ChartEmpty from '../../../../assets/svg/chartEmpty.svg'
import { Flex, Text } from 'rebass'
const NoOrdersMessage: React.FC<{ orderCategory: ORDER_CATEGORY }> = ({ orderCategory }) => {
    const { t } = useTranslation()

    return (
        <Flex p="24px" justifyContent="center" alignItems="center" flexDirection="column">
            <img src={ChartEmpty} alt="AIA" width={64} />
            <Text color="#9DAFCC" fontWeight={400}>
                {orderCategory === ORDER_CATEGORY.Open ? t('No Open Orders') : t('No Order History')}
            </Text>
        </Flex>
    )
}

export default NoOrdersMessage
