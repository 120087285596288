import { ApolloClient } from 'apollo-client'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { HttpLink } from 'apollo-link-http'

export const client = new ApolloClient({
  link: new HttpLink({
    uri: 'https://graph.aiaswap.com/subgraphs/name/aitd/aitdswap',
    // uri: 'http://18.138.168.135:8000/subgraphs/name/aitd/aitdswap',
  }),
  cache: new InMemoryCache()
  // ,shouldBatch: true
})
export const healthClient = new ApolloClient({
  link: new HttpLink({
    uri: 'https://api.thegraph.com/index-node/graphql',
  }),
  cache: new InMemoryCache(),
  // shouldBatch: true,
})
export const blockClient = new ApolloClient({
  link: new HttpLink({
    uri: 'https://graph.aiaswap.com/subgraphs/name/aitd/aitdchain-blocks',
    // uri: 'http://18.138.168.135:8000/subgraphs/name/aitd/aitdchain-blocks',
  }),
  cache: new InMemoryCache(),
})