import axios, {AxiosRequestConfig, AxiosResponse} from 'axios';
import {REACT_APP_GIFT_CARDS_API} from "../constants";
import {notification} from 'antd';
import i18next from '../i18n';
import storage from "../utils/storage";

interface RequestUtil {
  (
    method: 'GET' | 'POST' | 'PUT' | 'DELETE',
    url: string,
    params?: {
      query?: object;
      body?: object;
    },
    headers?: any,
    filter?: boolean
  ): Promise<any>;
}


const clean = () => {
  storage.localRemove('loginInfo');
  window.location.reload();
};

const service = axios.create({
  baseURL: REACT_APP_GIFT_CARDS_API,
  timeout: 30000,
  headers: {
    Accept: '*/*',
    'Content-Type': 'application/json',
  },
});

service.interceptors.request.use(
  // 统一请求前处理
  (config: AxiosRequestConfig | any) => {
    const loginInfo: any = storage.localGet('loginInfo');
    if (loginInfo?.loginToken) {
      config.headers['loginToken'] = loginInfo?.loginToken;
    }
    return config;
  },
  (error: any) => {
    // notification.error({
    //   message: 'Server request exception',
    // })
    // return Promise.reject(new Error(error));
  },
);

const codeArray = ['1008', '52002', '52003', '52004', '52005', '52006',
  '52007', '52008', '52009', '52010', '52011', '52012', '52013', '52014', '52015', '52016',
  '52017', '52018', '52019', '52020', '52021', '52022', '52023', '52024', '500', '52025', '52026',
  '65001', '213','216','217','52027', '52028', '52029']

service.interceptors.response.use(
  // 统一返回数据处理
  (response: AxiosResponse) => {
    const {data} = response;
    const {code} = data;
    if (code == 1008) {
      clean();
    }
    return response.data;
  },
  (error: any) => {
  },
);

const request: RequestUtil = async (
  method,
  url,
  params = {
    query: undefined,
    body: undefined,
  },
) => {
  const {query, body, ...otherParams} = params;
  const response: any = await service({
    url,
    method,
    params: query,
    data: body,
    ...otherParams,
  });
  if (!response) return Promise.reject('');
  const {code, msg, data} = response;
  if (code !== 0) {
    msg && notification.error({
      message: codeArray.includes(String(code)) ? i18next.t(String(code)) : msg,
    })
    return Promise.reject(msg);
  }
  return data || {};
};

export default request;
