import React from 'react'
import styled from 'styled-components'
import { CheckCircle, Triangle } from 'react-feather'

import { useActiveWeb3React } from '../../hooks'
import { getEtherscanLink } from '../../utils'
import { ExternalLink } from '../../theme'
import { useAllTransactions } from '../../state/transactions/hooks'
import { RowBetween } from '../Row'
import LinkSvg from '../../assets/svg/link.svg'
import Loader from '../Loader'

const TransactionWrapper = styled.div`
&:first-of-type{
  margin-top: 0 !important;
}

`

const TransactionStatusText = styled.div`
  margin-right: 0.5rem;
  width: 75%;
  /* display: flex;
  align-items: center; */
  word-wrap:break-word;
  margin-left: 15px;
  color: ${({ theme }) => theme.text7};
  font-weight: bold;
  font-size: 13px;
  :hover {
    text-decoration: underline;
  }
`

const TransactionState = styled(ExternalLink) <{ pending: boolean; success?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-decoration: none !important;
  border-radius: 0.5rem;
  padding: 0.25rem 0rem;
  font-weight: 500;
  font-size: 0.825rem;
  color: ${({ theme }) => theme.primary1};
`

const IconWrapper = styled.div<{ pending: boolean; success?: boolean }>`
  color: ${({ pending, success, theme }) => (pending ? theme.primary1 : success ? theme.green1 : theme.red1)};
`

export default function Transaction({ hash }: { hash: string}) {
  const { chainId } = useActiveWeb3React()
  const allTransactions = useAllTransactions()

  const tx = allTransactions?.[hash]
  const summary = tx?.summary
  const pending = !tx?.receipt
  const success = !pending && tx && (tx.receipt?.status === 1 || typeof tx.receipt?.status === 'undefined')

  if (!chainId) return null

  return (
    <TransactionWrapper style={{ marginTop: '20px' }} >
      <TransactionState href={getEtherscanLink(chainId, hash, 'transaction')} pending={pending} success={success}>
        <RowBetween style={{flex:1,justifyContent:'flex-start',alignItems:'center'}}>
          <IconWrapper pending={pending} success={success} style={{display:'flex'}}>
            {pending ? <Loader /> : success ? <CheckCircle size="22" /> : <Triangle size="22" />}
          </IconWrapper>
          <TransactionStatusText>{summary ?? hash}</TransactionStatusText>
        </RowBetween>
        <RowBetween  style={{width:'20px'}}>
          <img src={LinkSvg} width={13} />
        </RowBetween>
        {/* <IconWrapper pending={pending} success={success}>
          {pending ? <Loader /> : success ? <CheckCircle size="16" /> : <Triangle size="16" />}
        </IconWrapper> */}
      </TransactionState>
    </TransactionWrapper>
  )
}
