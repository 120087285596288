
import { createReducer } from '@reduxjs/toolkit'
import { updatePoolResults } from './actions'

export interface EarnState {
    numberAuto: number
}

const initialState: EarnState = {
    numberAuto: 1
}

export default createReducer(initialState, builder =>
    builder.addCase(updatePoolResults, (state, { payload: { numberAuto } }) => {
        numberAuto += state.numberAuto + numberAuto
        return {
            ...state,
            numberAuto
        }
    })
)