import useFormattedOrderData from 'pages/LimitOrders/hooks/useFormattedOrderData'
import React, { useContext } from 'react'
import styled, { ThemeContext } from 'styled-components'
import { useTranslation } from 'react-i18next'
import { Box, Flex, Text } from 'rebass'
import CurrencyFormat from './CurrencyFormat'
// import { DetailLimitOrderModal } from './DetailLimitOrderModal'
import MobileOrderStatus from './MobileOrderStatus'
import DownNew from '../../../../assets/svg/downNew.svg'
import dayjs from 'dayjs'
interface CompactRowProps {
    order: any
    isLast: boolean
    showClickModal: any
}

const CompactRow: React.FC<CompactRowProps> = ({ order, isLast, showClickModal }) => {
    const { t } = useTranslation()
    const theme = useContext(ThemeContext)
    const formattedOrder = useFormattedOrderData(order)
    const { inputToken, outputToken, inputAmount, outputAmount, executionPrice } = formattedOrder
    const { orderTime, execTime } = order
    // const [isOpen, setIsopen] = useState(false)
    // const onDismiss = () => setIsopen(false)
    // useEffect(() => {
    //     return () => setIsopen(false)
    // }, [])

    return (
        <>
            {/* <DetailLimitOrderModal
                order={order}
                isOpen={isOpen}
                onDismiss={onDismiss} formattedOrder={formattedOrder} /> */}
            <FlexDiv
                isLast={isLast}
                width="100%"
                justifyContent="center"
                alignItems="center"
                onClick={showClickModal}>
                <Box width="100%">
                    <Flex mb="16px" justifyContent="space-between">
                        <Flex alignItems="center">
                            <CurrencyFormat size='15px' bold currency={inputToken} />
                            <Img src={DownNew} alt="AIA" />
                            <CurrencyFormat size='15px' bold currency={outputToken} />
                        </Flex>
                        <MobileOrderStatus formattedOrder={formattedOrder} showOpenTag={true} />
                    </Flex>
                    <Flex justifyContent="space-between">
                        <Text fontSize="13px" fontWeight={400} color={theme.text7}>
                            {t('From')}
                        </Text>
                        <Text fontSize="13px" fontWeight={400} color={theme.text7}>{`${inputAmount} ${inputToken?.symbol}`}</Text>
                    </Flex>
                    <Flex justifyContent="space-between">
                        <Text fontSize="13px"
                            fontWeight={400}
                            color={theme.text7}>
                            {t('To')}
                        </Text>
                        <Text fontSize="13px" fontWeight={400} color={theme.text7}>{`${outputAmount} ${outputToken?.symbol}`}</Text>
                    </Flex>
                    <Flex justifyContent="space-between">
                        <Text fontSize="13px"
                            fontWeight={400}
                            color={theme.text7}>
                            {t('Price')}
                        </Text>
                        <Text fontSize="13px" fontWeight={400}
                            color={theme.text7}>{`${executionPrice} ${outputToken?.symbol} per ${inputToken?.symbol}`}</Text>
                    </Flex>
                    <Flex justifyContent="space-between">
                        <Text fontSize="13px"
                            fontWeight={400}
                            color={theme.text7}>
                            {t('Time')}
                        </Text>
                        <Text fontSize="13px" fontWeight={400}
                            color={theme.text7}>{!orderTime ? '/' :
                                dayjs((execTime ? execTime : orderTime) * 1000).format('YYYY-MM-DD HH:mm:ss')}</Text>
                    </Flex>
                </Box>
            </FlexDiv>
        </>

    )
}

const Img = styled.img`

height: 12px;
width: 12px;
margin:0 8px;
transform: rotate(-90deg);
`
const FlexDiv = styled(Flex) <{ isLast: boolean }>`
    border-bottom:${({ isLast }) => `1px solid  ${isLast ? '#ffffff' : '#FAF7FA'}`} ;
    padding-top: 0 !important;
    padding-bottom:16px;
`
export default CompactRow
