import { Modal } from 'antd';
import React, { memo, useCallback, useContext, useState } from 'react';
import { RowBetween } from "components/Row"
import styled, { ThemeContext } from 'styled-components'
import { isMobile } from 'react-device-detect'
import { Box, FlexProps, Text } from 'rebass'
import { flexbox } from "styled-system";
import { CloseIcon } from "theme/components"
import { AutoColumn } from "components/Column"
import { ButtonError } from "components/Button";
import { TransactionErrorContent } from "../TransactionConfirmationModal";
import { TransactionSubmittedContent } from "../TransactionSubmittedContent";
import { useMedia } from 'react-use';
import { useTranslation } from 'react-i18next';
import useFormattedOrderData from 'pages/LimitOrders/hooks/useFormattedOrderData';
import useGelatoLimitOrdersHandlers from 'hooks/limitOrders/useGelatoLimitOrdersHandlers';
import CellFormat from '../LimitOrderTable/CellFormat';
import CurrencyFormat from '../LimitOrderTable/CurrencyFormat';
import LimitOrderDisclaimer from "../LimitOrderDisclaimer";
import DownNew from '../../../../assets/svg/downNew.svg'
interface DetailLimitOrderModalProps {
    isOpen: boolean,
    onDismiss: () => void
    order: any
    formattedOrder?: any
}
const DetailLimitOrderModal: React.FC<DetailLimitOrderModalProps> = ({
    onDismiss,
    order,
    isOpen: isOpenModal,
    formattedOrder
}) => {
    const isPhoneSize = useMedia('(max-width: 960px)')
    const theme = useContext(ThemeContext)
    const { handleLimitOrderCancellation } = useGelatoLimitOrdersHandlers()
    const { t } = useTranslation();
    const [txHash, setTxHash] = useState<string | undefined>(undefined)
    const [attemptingTxn, setAttemptingTxn] = useState<boolean>(false)

    const [cancellationErrorMessage, setCancellationState] = useState<
        // attemptingTxn: boolean
        // cancellationErrorMessage:
        string | undefined
    // txHash: string | undefined
    >(
        // attemptingTxn: false,
        // cancellationErrorMessage: 
        undefined
        // txHash: undefined,
    )
    formattedOrder = useFormattedOrderData(order);


    const onCancelOrder = useCallback(() => {
        if (!handleLimitOrderCancellation) {
            return
        }
        setCancellationState(
            // attemptingTxn: true,
            undefined
            // txHash: undefined,
        )
        setAttemptingTxn(true)
        setTxHash(undefined)
        const orderDetails =
            formattedOrder.inputToken?.symbol &&
                formattedOrder.outputToken?.symbol &&
                formattedOrder.inputAmount &&
                formattedOrder.outputAmount
                ? {
                    inputTokenSymbol: formattedOrder.inputToken.symbol,
                    outputTokenSymbol: formattedOrder.outputToken.symbol,
                    inputAmount: formattedOrder.inputAmount,
                    outputAmount: formattedOrder.outputAmount,
                } : undefined
        handleLimitOrderCancellation(order, orderDetails)
            .then(({ hash }) => {
                setCancellationState(
                    // attemptingTxn: false,
                    undefined,
                    // txHash: hash,
                )
                setAttemptingTxn(false)
                setTxHash(hash)
            })
            .catch((error: any) => {
                setCancellationState(
                    // attemptingTxn: false,
                    error.message
                    // txHash: undefined,
                )
                setAttemptingTxn(false)
                setTxHash(undefined)
            })
    }, [
        handleLimitOrderCancellation,
        formattedOrder,
        order?.id,
        order,
    ])
    const limitPriceExchangeRateText = `1 ${formattedOrder.inputToken?.symbol} = ${formattedOrder.executionPrice} ${formattedOrder.outputToken?.symbol}`
    const limitPriceExchangeRateTextReversed = `1 ${formattedOrder.outputToken?.symbol} = ${formattedOrder.invertedExecutionPrice} ${formattedOrder.inputToken?.symbol}`
    const { isOpen, isExecuted, isCancelled, isSubmissionPending, isCancellationPending, bscScanUrls, isDealing, isPengding } = formattedOrder
    const orderDetails = (
        <>
            <Flex width="100%" justifyContent="space-between">
                <CellFormat
                    firstRow={<Text fontWeight={700} color={theme.text7}>{formattedOrder.inputAmount}</Text>}
                    secondRow={<CurrencyFormat bold size={isPhoneSize ? '18px' : '24px'} currency={formattedOrder.inputToken} />}
                />
                <Img src={DownNew} alt="AIA" />
                <CellFormat
                    firstRow={<Text fontWeight={700} color={theme.text7}>{formattedOrder.outputAmount}</Text>}
                    secondRow={<CurrencyFormat bold size={isPhoneSize ? '18px' : '24px'} currency={formattedOrder.outputToken} />}
                />
            </Flex>
            <LimitTradeInfoCard

                limitPriceExchangeRateText={limitPriceExchangeRateText}
                limitPriceExchangeRateTextReversed={limitPriceExchangeRateTextReversed}
                isOpen={isOpen}
                isExecuted={isExecuted}
                isCancelled={isCancelled}
                isSubmissionPending={isSubmissionPending}
                isCancellationPending={isCancellationPending}
                isDealing={isDealing}
                isPengding={isPengding}
            />
            <LimitOrderDisclaimer />
            <Flex flexDirection="column" mt="24px">
                {isOpen ? (
                    <>
                        <ButtonError
                            className="showBox"
                            target="_blank"
                            mt="16px"
                            as="a"
                            href={formattedOrder.bscScanUrls.created}>
                            {t('View on AIAScan')}
                        </ButtonError>
                        {!isSubmissionPending && (
                            <ButtonError
                                className="showBox"
                                variant="danger"
                                mt="16px"
                                onClick={onCancelOrder}>
                                {t('Cancel Order')}
                            </ButtonError>
                        )}
                    </>
                ) : (
                    <ButtonError
                        className="showBox"
                        target="_blank"
                        mt="16px"
                        as="a" href={formattedOrder.bscScanUrls.created}>
                        {t('View order creation on AIAScan')}
                    </ButtonError>
                )}
                {isCancelled && bscScanUrls.cancelled && (
                    <ButtonError className="showBox" target="_blank" mt="16px" as="a" href={bscScanUrls.cancelled}>
                        {t('View order cancellation on AIAScan')}
                    </ButtonError>
                )}
                {isExecuted && bscScanUrls.executed && (
                    <ButtonError className="showBox" target="_blank" mt="16px" as="a" href={bscScanUrls.executed}>
                        {t('View order execution on AIAScan')}
                    </ButtonError>
                )}
            </Flex>
        </>
    )

    return (<>
        <Modal
            centered={!isMobile}
            style={{
                width: '50vw',
                maxWidth: '420px',
                maxHeight: '80vh'
            }}
            className={`pcmodal ${isMobile ? 'momodal' : ''}`}
            bodyStyle={{
                padding: '0'
            }}
            onCancel={onDismiss}
            visible={isOpenModal}
            closable={false}
            destroyOnClose
            footer={null}>
            <Wrapper>
                <Section>
                    <RowBetween>
                        <Text fontWeight={600} fontSize={18} color={theme.text7}>
                            {t('Open Order Details')}
                        </Text>
                        <CloseIcon onClick={onDismiss} />
                    </RowBetween>
                </Section>
                <Container>
                    {
                        attemptingTxn ? <LoadingContent /> :
                            txHash ? (
                                <TransactionSubmittedContent hash={txHash} onDismiss={onDismiss} />
                            ) :
                                cancellationErrorMessage ? (
                                    <TransactionErrorContent onDismiss={onDismiss} message={cancellationErrorMessage} />
                                ) :
                                    orderDetails
                    }
                </Container>
            </Wrapper>
        </Modal>
    </>)
}

const LimitTradeInfoCard: React.FC<any> = memo(
    ({
        limitPriceExchangeRateText,
        limitPriceExchangeRateTextReversed,
        isOpen,
        isExecuted,
        isCancelled,
        isSubmissionPending,
        isCancellationPending,
        isDealing,
        isPengding
    }) => {
        const { t } = useTranslation()
        const theme = useContext(ThemeContext)

        return (
            <InfoCardWrapper>
                <Box mb="4px">

                    {isOpen && !isSubmissionPending && (
                        <Tag color="#FF9900">
                            {t('Open')}
                        </Tag>
                    )}

                    {isPengding&&(
                        <Tag color="#FF9900">
                            {t('Pending')}
                        </Tag>
                    )}

                    {isExecuted && (
                        <Tag color="#87d068">
                            {t('Filled')}
                        </Tag>
                    )}
                    {isCancellationPending && (
                        <Tag color="#f50">
                            {t('Cancelling')}
                        </Tag>
                    )}
                    {isCancelled && !isCancellationPending && (
                        <Tag color="rgb(255 10 0)">
                            {t('Cancelled')}
                        </Tag>
                    )}
                    <Flex mt="16px">
                        <Text fontSize="14px" marginRight={'15px'} color={theme.text6} fontWeight={600}>
                            {t('Limit Price')}
                        </Text>
                        <Flex flexDirection="column">
                            <Text color="#2750EB" fontSize="14px" fontWeight={600}>
                                {limitPriceExchangeRateText}
                            </Text>
                            <Text color="#2750EB" fontSize="14px" fontWeight={600}>
                                {limitPriceExchangeRateTextReversed}
                            </Text>
                        </Flex>
                    </Flex>
                </Box>
            </InfoCardWrapper>
        )
    },
)
const LoadingContent: React.FC = memo(() => {
    const { t } = useTranslation()
    const theme = useContext(ThemeContext)
    return (
        <Flex>
            <Flex flexDirection="column">
                <Text fontWeight={600} fontSize={18} color={theme.text7}>
                    {t('Confirm')}
                </Text>
                <Text fontWeight={600} fontSize={15} color={theme.text7} mt="8px">
                    {t('Please confirm the transaction in your wallet')}
                </Text>
            </Flex>
        </Flex>
    )
})


const Wrapper = styled.div`
  width: 100%;
  .showBox {
    box-shadow: 0px 3px 0 1px #0090FF;
    padding: 12px 18px;
    margin-top:16px;
  }
`
const Section = styled(AutoColumn)`
  padding: 20px;
  border-bottom: 1px solid #F5F6F7;
`
const Flex = styled(Box) <FlexProps>`
  display: flex;
  ${flexbox}
`;
const InfoCardWrapper = styled.div`
  border-radius: 16px;
  padding: 20px;
  margin-top: 16px;
  border: 1px solid ${({ theme }) => '#E7E3EB'};
  background-color: ${({ theme }) => '#FAF9FA'};
`
const Container = styled.div`
    padding:20px;
    padding-bottom: 30px;
    max-height:calc(80vh - 60px);
    overflow-y:auto;
`
const Tag = styled.div<{ color: string }>`
  color:${({ color }) => color};
  border:${({ color }) => `2px solid ${color}`};
  background: #FAF9FA;
  padding: 8px;
  font-size: 12px;
  height: 24px;
  display: inline-flex;
  align-items: center;
  border-radius: 16px;
`
const Img = styled.img`
    height: 18px;
    width: 18px;
    margin:0 8px;
    transform: rotate(-90deg);
`
export default DetailLimitOrderModal;