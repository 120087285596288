import Tag from 'antd/lib/tag';
import React from 'react'
import { useTranslation } from 'react-i18next';
import { Text } from 'rebass'
export enum StatusElementType {
  TAG = 'Tag',
  TEXT = 'Text',
}

const StatusElement: React.FC<{
  element: StatusElementType;
  text: string;
  color: any
}> = ({
  element,
  text,
  color,
}) => {
    if (element === StatusElementType.TAG) {
      return (
        <Tag>
          {text}
        </Tag>
      )
    }
    return <Text color={color} fontWeight={'700'}>{text}</Text>
  }

const OrderStatus: React.FC<{
  formattedOrder: any
  showOpenTag?: boolean
  element?: StatusElementType
}> = ({ formattedOrder, showOpenTag = false, element = StatusElementType.TAG }) => {
  const { t } = useTranslation()
  const { isOpen, isSubmissionPending, isCancelled, isCancellationPending, isExecuted, isDealing, isPengding } = formattedOrder

  if (isOpen && !isSubmissionPending && showOpenTag) {
    return <StatusElement element={element} text={t('Open')} color="#FF9900" />
  }
  if (isOpen && isSubmissionPending) {
    return <StatusElement element={element} text={t('Pending')} color="#FF9900" />
  }
  if (isPengding && isSubmissionPending) {
    return <StatusElement element={element} text={t('Pending')} color="#FF9900" />
  }
  if (isPengding) {
    return <StatusElement element={element} text={t('Pending')} color="#FF9900" />
  }
  if (isCancelled && !isCancellationPending) {
    return <StatusElement element={element} text={t('Cancelled')} color="#F96E6E" />
  }
  if (isCancellationPending) {
    return <StatusElement element={element} text={t('Cancelling')} color="#F96E6E" />
  }
  if (isDealing) {
    return <StatusElement element={element} text={t('Dealing')} color="#FF9900" />
  }
  if (isExecuted) {
    return <StatusElement element={element} text={t('Filled')} color="#2750EB" />
  }
  return null
}

export default OrderStatus
