import { useCallback } from 'react'
import { Currency, Price } from '@uniswap/sdk'
import { Field, Rate } from 'state/limitOrders/types'
import { useOrderActionHandlers } from 'state/limitOrders/hooks'
import { TransactionResponse } from '@ethersproject/abstract-provider'
import { Overrides } from '@ethersproject/contracts'
import { useActiveWeb3React } from 'hooks'
import { BigNumber } from '@ethersproject/bignumber'
import { useTransactionAdder } from 'state/transactions/hooks'
import useGelatoLimitOrdersLib from 'pages/LimitOrders/constants/useGelatoLimitOrdersLib'
export interface GelatoLimitOrdersHandlers {
    handleInput: (field: Field, value: string) => void
    handleCurrencySelection: (field: Field.INPUT | Field.OUTPUT, currency: Currency) => void
    handleSwitchTokens: () => void
    handleRateType: (rateType: Rate, price?: Price) => void
    handleLimitOrderSubmission: (orderToSubmit: any) => Promise<TransactionResponse>
    handleLimitOrderCancellation: (
        order: Order,
        orderDetails?: {
            inputTokenSymbol: string
            outputTokenSymbol: string
            inputAmount: string
            outputAmount: string
        },
        overrides?: Overrides,
    ) => Promise<TransactionResponse>
}
export interface Order {
    id: string;
    owner: string;
    inputToken: string;
    outputToken: string;
    minReturn: string;
    maxReturn?: string;
    adjustedMinReturn: string;
    module: string;
    witness: string;
    secret: string;
    inputAmount: string;
    vault: string;
    bought: string | null;
    auxData: string | null;
    status: string;
    createdTxHash: string;
    executedTxHash: string | null;
    cancelledTxHash: string | null;
    blockNumber: string;
    createdAt: string;
    updatedAt: string;
    updatedAtBlock: string;
    updatedAtBlockHash: string;
    data: string;
    inputData: string;
    handler: string | null;
    isExpired: boolean;
}
const useGelatoLimitOrdersHandlers = (): GelatoLimitOrdersHandlers => {
    const { chainId, account } = useActiveWeb3React()
    const { onSwitchTokens, onCurrencySelection, onUserInput, onChangeRateType } = useOrderActionHandlers()
    const gelatoLimitOrders: any = useGelatoLimitOrdersLib()
    const handleInput = useCallback(
        (field: Field, value: string) => {
            onUserInput(field, value)
        },
        [onUserInput]
    )
    const addTransaction = useTransactionAdder()
    const handleCurrencySelection = useCallback(
        (field: Field.INPUT | Field.OUTPUT, currency: Currency) => {
            onCurrencySelection(field, currency)
        },
        [onCurrencySelection],
    )

    const handleSwitchTokens = useCallback(() => {
        onSwitchTokens()
    }, [onSwitchTokens])

    const handleRateType = useCallback(
        async (rateType: Rate, price?: Price) => {
            // console.log(rateType,price?.toSignificant(6));

            if (rateType === Rate.MUL) {
                if (price) onUserInput(Field.PRICE, price.invert().toSignificant(6))
                onChangeRateType(Rate.DIV)
            } else {
                if (price) onUserInput(Field.PRICE, price.toSignificant(6))
                onChangeRateType(Rate.MUL)
            }
        },
        [onChangeRateType, onUserInput],
    )

    const handleLimitOrderSubmission = useCallback(
        async (
            orderToSubmit: {
                inputToken: string
                outputToken: string
                inputAmount: number
                outputAmount: number
                owner: string
            },
            overrides?: Overrides,
        ) => {
            if (!gelatoLimitOrders) {
                throw new Error('Could not reach Gelato Limit Orders library')
            }
            if (!chainId) {
                throw new Error('No chainId')
            }
            if (!account) {
                throw new Error('No account')
            }

            if (!gelatoLimitOrders.signer) {
                throw new Error('No signer')
            }
            const { witness, payload, order } = await gelatoLimitOrders.encodeLimitOrderSubmissionWithSecret(
                {
                    inputToken: orderToSubmit.inputToken,
                    outputToken: orderToSubmit.outputToken,
                    inputAmount: orderToSubmit.inputAmount,
                    minReturnToBeParsed: orderToSubmit.outputAmount,
                    owner: orderToSubmit.owner,
                }
            )
            const tx = await gelatoLimitOrders.signer.sendTransaction({
                ...(overrides ?? {}),
                to: payload.to,
                data: payload.data,
                value: BigNumber.from(payload.value),
            })
            const now = Math.round(Date.now() / 1000)
            addTransaction(tx, {
                summary: `Order submission`,
                type: 'limit-order-submission',
                order: {
                    ...order,
                    createdTxHash: tx?.hash.toLowerCase(),
                    witness,
                    status: 'pengding',
                    updatedAt: now.toString(),
                } as Order,
            })
            

            //   mutate(OPEN_ORDERS_SWR_KEY)
            //   mutate(EXECUTED_CANCELLED_ORDERS_SWR_KEY)
            // mutate
            // gelatoLimitOrders
            return tx
        },
        [addTransaction, chainId],
    )
    const handleLimitOrderCancellation = useCallback(
        async (
            orderToCancel: Order,
            orderDetails?: {
                inputTokenSymbol: string
                outputTokenSymbol: string
                inputAmount: string
                outputAmount: string
            },
            overrides?: Overrides,
        ) => {
            
            if (!gelatoLimitOrders) {
                throw new Error('Could not reach Gelato Limit Orders library')
            }

            if (!chainId) {
                throw new Error('No chainId')
            }

            if (!account) {
                throw new Error('No account')
            }
          
            const checkIfOrderExists = Boolean(
                orderToCancel.module &&
                orderToCancel.inputToken &&
                orderToCancel.owner &&
                orderToCancel.witness &&
                orderToCancel.data,
            )
            let tx: any;
            try {
                // console.info('2',orderToCancel, orderDetails)
                
                tx = await gelatoLimitOrders.cancelLimitOrder(
                    orderToCancel,
                    checkIfOrderExists,
                    overrides ?? {
                        gasLimit: 2000000,
                    },
                )
                const now = Math.round(Date.now() / 1000)
                const summary = orderDetails
                    ? `Order cancellation: ${orderDetails.inputAmount} ${orderDetails.inputTokenSymbol} for ${orderDetails.outputAmount} ${orderDetails.outputTokenSymbol}`
                    : 'Order cancellation'

                addTransaction(tx, {
                    summary,
                    type: 'limit-order-cancellation',
                    order: {
                        ...orderToCancel,
                        updatedAt: now.toString(),
                        status: 'cancelled',
                        cancelledTxHash: tx?.hash.toLowerCase(),
                    }
                })

              
            } catch (error) {
                const err:any = error
                throw new Error(err.message||err)
            }
            // mutate(OPEN_ORDERS_SWR_KEY)
            // mutate(EXECUTED_CANCELLED_ORDERS_SWR_KEY)
            return tx
        },
        [gelatoLimitOrders, chainId, account, addTransaction],
    )
    return {
        handleInput,
        handleCurrencySelection,
        handleSwitchTokens,
        handleRateType,
        handleLimitOrderSubmission,
        handleLimitOrderCancellation
    }
}



export default useGelatoLimitOrdersHandlers