import { utils,ethers } from 'ethers'

import _abi from './abi/erc20factory'

const ERC20__factory = {
    connect: (address:string, signerOrProvider?:any) => new ethers.Contract(address, _abi, signerOrProvider),
    createInterface: () => new utils.Interface(_abi),
    _abi
}

export default ERC20__factory;